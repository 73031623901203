<app-header></app-header>
<div class="container main-container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h2>Clientes</h2>
            </div>
        </div>
        <div class="loader">
            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
        </div>
        <div style="position: relative; min-height: 70vh;" class="container row" *ngIf="clientes && clientes.length>0">
            <div class="cliente-card col-3" *ngFor="let c of clientes">
                <mat-card style="width: 100%;">
                    <div class="cliente-inner">
                        <div class="row" style="min-height: 120px;" (click)="irDetalleCliente(c.id)">
                            <div class="col-12 h-center">
                                <div class="cliente-img-container" id="dv_marca" [ngStyle]="{'background':'url('+c.logo+') no-repeat'}">
                                </div>
                                <span *ngIf="!c.logo || c.logo==null">
                                    Marca sin logo
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 b-center">
                                <div class="row">
                                    <div class="col-6" style="text-align: left;" (click)="irDetalleCliente(c.id)">
                                        <h6 [ngStyle]="{'text-decoration':!c.activo?'line-through':'none'}">
                                            {{c.nombre}}
                                        </h6>
                                    </div>
                                    <div class="col-6" style="text-align: right;">
                                        <span *ngIf="c.estatus_loading">
                                            <i class="fa-spin fas fa-spinner"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <button class="mx-2" *ngIf="!c.estatus_loading" mat-button [color]=" c.activo ? 'warn' : 'primary'" (click)="cambiarEstatusCliente(c)">
                                    <small *ngIf="c.activo">
                                        INACTIVAR
                                    </small>
                                    <small *ngIf="!c.activo">
                                        ACTIVAR
                                    </small>
                                </button>
                                <button class="mx-2" *ngIf="!c.estatus_loading" mat-flat-button color="warn" (click)="eliminarCliente(c)">
                                    <small>ELIMINAR</small>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="add-fab">
                <button (click)="irDetalleCliente()" mat-fab color="primary" aria-label="Agregar un nuevo cliente">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="!loading && clientes && clientes.length==0" class="row" style="text-align:center;height: 60vh;">
            <div class="col-12 v-center">
                <h4>No hay clientes para mostrar</h4>
                <h4>Presiona el botón para agregar al primer cliente</h4>
                <div (click)="irDetalleCliente()" class="add-button">
                    <i class="fas fa-plus-circle"></i>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>