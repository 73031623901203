<app-header></app-header>
<div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Clientes segundos</h2>
                        </div>
                    </div>
                    <div class="row" *ngIf="es_cliente_segundo && esEditor">
                        <div class="col-12 d-flex justify-content-end">
                            <button mat-raised-button color="primary" (click)="editarMiPerfil()">
                                <i class="fas fa-user-edit mr-2"></i>
                                <small>EDITAR MI PERFIL</small>
                            </button>
                        </div>
                    </div>
                    <div class="loader">
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </div>
                    <div style="position: relative; min-height: 70vh;" class="container row" *ngIf="clientes && clientes.length>0 && !loading">
                        <div class="cliente-card col-3" *ngFor="let cliente_segundo of clientes">
                            <mat-card style="width: 100%;">
                                <div class="cliente-inner">
                                    <div class="row">
                                        <div class="col-12 b-center">
                                            <div class="row">
                                                <div class="col-12" style="text-align: left;" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}" (click)="irDetalleCliente(cliente_segundo.id)">
                                                    <h4 class="text-break">
                                                        {{cliente_segundo.user.first_name}}
                                                    </h4>
                                                </div>
                                                <div class="col-12" style="text-align: left;" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}" (click)="irDetalleCliente(cliente_segundo.id)">
                                                    <h6 class="text-break">
                                                        {{cliente_segundo.user.email}}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3 d-flex justify-content-center" *ngIf="esEditor">
                                            <button class="mx-2" mat-flat-button color="warn" (click)="eliminarCliente(cliente_segundo)">
                                                <small>ELIMINAR</small>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                        <div class="add-fab" *ngIf="esEditor">
                            <button (click)="irDetalleCliente()" mat-fab color="primary" aria-label="Agregar un nuevo cliente">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!loading && clientes && clientes.length==0" class="row" style="text-align:center;height: 60vh;">
                        <div class="col-12 v-center">
                            <h4>No hay clientes para mostrar</h4>
                            <h4>Presiona el botón para agregar a un cliente</h4>
                            <div (click)="irDetalleCliente()" class="add-button">
                                <i class="fas fa-plus-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>