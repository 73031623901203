import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { MatDialog } from '@angular/material';
import { NuevoChatbootDialogComponent } from 'src/app/dialogs/nuevo-chatboot-dialog/nuevo-chatboot-dialog.component';

@Component({
  selector: 'app-chatbot-screen',
  templateUrl: './chatbot-screen.component.html',
  styleUrls: ['./chatbot-screen.component.scss']
})
export class ChatbotScreenComponent implements OnInit {
  public loading:boolean = false;
  public chatbots:any=[];
  public marcas:any=[];
  public id_marca:number;
  esEditor: any;
  constructor(public router:Router, public facade:FacadeService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_chatbots");
    this.loadMarcas()
  }
  
  listarChatbots(id_marca){
    this.loading = true
    this.facade.listarChatbots(id_marca)
    .subscribe(
      (response)=>{
        this.chatbots = response
        this.loading = false
        for(let m of this.marcas){
          for(let c of response){
            if(m.id == c.marca){
              c.marca_nombre = m.nombre
            }
          }
        }
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        console.log(error)
        this.loading = false
      }
    )
  }

  loadMarcas(){
    this.loading = true;
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.listarChatbots(response[0].id)
        this.id_marca = response[0].id
        this.marcas = response
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }
  aplicarFiltro(){
    this.listarChatbots(this.id_marca)
  }
  irDetalleChatbot(id_chatbot){
    if (!this.esEditor){
      return;
    }
    if(!id_chatbot){
      id_chatbot = 0;
    }
    this.router.navigate(['cliente/chatbot/'+id_chatbot]);
  }

  AgregarChatbot(id_chatbot){
    if(!id_chatbot){
      id_chatbot = 0;
    }
    this.abrirDialog()
    //this.router.navigate(['cliente/chatbot/'+id_chatbot]);
  }

  abrirDialog(): void {
    const dialogRef = this.dialog.open(NuevoChatbootDialogComponent,{width: '800px', });
  }
}
