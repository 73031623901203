<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="irListado()" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2 *ngIf="!id_punto_soporte || id_punto_soporte==0">Alta de nuevo punto de videosoporte</h2>
                            <h2 *ngIf="id_punto_soporte && id_punto_soporte!=0">Edición de punto de videosoporte</h2>
                            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row" *ngIf="punto_soporte.videocall_public_link">
                        <div class="col-12">
                            <h6 class="black-text" style="font-weight: 200;" *ngIf="punto_soporte.estatus_asesor=='en_llamada' && punto_soporte.estatus_totem=='en_llamada'">
                                <i class="fas fa-circle color-primary"></i>
                                En sesión
                            </h6>
                            <h6 class="black-text" style="font-weight: 200;" *ngIf="punto_soporte.estatus_totem=='en_espera'">
                                <i style="color: yellow" class="fas fa-circle"></i>
                                En espera de agente
                            </h6>
                            <h6 class="black-text" style="font-weight: 200;" *ngIf="punto_soporte.estatus_totem=='apagado' || punto_soporte.estatus_totem==null">
                                <i style="color: red" class="fas fa-circle color-primary"></i>
                                Fuera de línea
                            </h6>
                            <h5>
                                <strong>URL para totems:</strong><br/>
                                <a  style="text-transform: none" href="{{punto_soporte.videocall_public_link}}" target="_blank">
                                    {{punto_soporte.videocall_public_link}}
                                </a>
                            </h5>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-sm-12 my-1">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
                                            <mat-label>A partir del</mat-label>
                                            <input [disabled]="loading" matInput [max]="maxDate" mat-raised-button [matDatepicker]="initialPicker" [(ngModel)]="fecha_inicio" [value]="fecha_inicio"
                                            (click)="initialPicker.open()"
                                            (dateChange)="_monthSelectedHandler(initialPicker,$event)"
                                            (keydown.arrowdown)="initialPicker.open()"
                                            (keydown.enter)="initialPicker.open()" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="initialPicker">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #initialPicker color="color-primary" startView="month"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
                                            <mat-label>Hasta el</mat-label>
                                            <input [disabled]="loading" matInput [max]="maxDate" mat-raised-button [matDatepicker]="finalPicker" [(ngModel)]="fecha_fin" [value]="fecha_fin"
                                            (click)="finalPicker.open()"
                                            (dateChange)="_monthSelectedHandler(finalPicker,$event)"
                                            (keydown.arrowdown)="finalPicker.open()"
                                            (keydown.enter)="finalPicker.open()" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="finalPicker">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #finalPicker color="color-primary" startView="month"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <div class="row">
                                <div class="col-sm-6" *ngIf="punto_soporte?.metricas?.tiempos_acumulados?.length > 0">
                                    <google-chart style="width: 100%; height: 400px;" [options]="opcionesGraficaDePastel" [dynamicResize]="true" [data]="tiemposPorEstatus" [type]="tipoGraficaDePastel"></google-chart>
                                </div>
                                <div class="col-sm-6 d-flex align-items-center">
                                    <div class="col-12 my-1 card">
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo en actividad</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{punto_soporte?.metricas?.tiempo_actividad_con_formato}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="col-sm-3" *ngFor="let tiempo of punto_soporte?.metricas?.tiempos_acumulados">
                                    <div class="col-12 my-1 card">
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">{{etiquetasEstatus[tiempo?.estatus]}}</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempo?.tiempo_con_formato}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-accordion multi>
                                <mat-expansion-panel [expanded]="true" class="headers-align">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h5 style="width: 50%;">
                                            Datos generales
                                        </h5>
                                    </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-md-3">&nbsp;</div>
                                        <div class="col-md-6 col-12">
                                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                                            <mat-label>Nombre</mat-label>
                                            <input type="text" matInput placeholder="Nombre" [(ngModel)]="punto_soporte.nombre">
                                        </mat-form-field>
                                        </div>
                                        <div class="col-md-3">&nbsp;</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">&nbsp;</div>
                                        <div class="col-md-6 col-12">
                                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                                            <mat-label>Dirección completa</mat-label>
                                            <textarea type="text" matInput placeholder="Nombre" [(ngModel)]="punto_soporte.direccion" rows="5" cols="20"></textarea>
                                        </mat-form-field>
                                        </div>
                                        <div class="col-md-3">&nbsp;</div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel [expanded]="true" class="headers-align">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h5 style="width: 50%;">
                                            Marca asociada
                                        </h5>
                                    </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-md-3">&nbsp;</div>
                                        <div class="col-md-6 col-12">
                                            <mat-form-field style="width: 100%;" appearance="outline">
                                                <mat-label>Marca</mat-label>
                                                <mat-select [(ngModel)]="marca_seleccionada_id" (selectionChange)="cambiarMarca()">
                                                    <mat-option [value]="m.id" *ngFor="let m of marcas">{{m.nombre}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-3">&nbsp;</div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel [expanded]="true" class="headers-align">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h5 style="width: 50%;">
                                            Agente que atiende
                                        </h5>
                                    </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <form [formGroup]="agentesForm">
                                      <div formArrayName="listaAgentes">
                                        <div *ngFor="let item of getAgentes.controls; let i=index" [formGroupName]="i">
                                          <div class="row">
                                            <div class="col-md-3">&nbsp;</div>
                                            <div class="col-md-6 col-12">
                                              <mat-form-field style="width: 100%;" appearance="outline" name="agentes">
                                                <mat-label>Agente</mat-label>
                                                <mat-select formControlName="value">
                                                  <mat-option [value]="a.user" *ngFor="let a of agentes">{{a.nombre_completo}} - {{a.email}}</mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                            </div>

                                            <ng-container *ngIf="!item.value.extra; else eliminarBtn">
                                                <div class="col-md-3">
                                                    <button mat-button class="agregar-ingrediente" (click)="agregarAgentes()">
                                                        Agregar <i class="fas fa-plus" style="margin-left: 5px;"></i>
                                                    </button>
                                                </div>
                                            </ng-container>

                                            <ng-template #eliminarBtn>
                                                <div class="col-md-3">
                                                    <button mat-button class="agregar-ingrediente" (click)="eliminarAgentes(i)">
                                                        Eliminar <i class="fas fa-plus" style="margin-left: 5px;"></i>
                                                    </button>
                                                </div>
                                            </ng-template>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                </mat-expansion-panel>
                                <mat-expansion-panel [expanded]="true" class="headers-align">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h5 style="width: 50%;">
                                            Videos para espera
                                        </h5>
                                    </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-6">
                                            <div *ngIf="(!videos_marca || videos_marca.length==0) && !loading">
                                                <h6>No hay videos disponibles de la marca</h6>
                                            </div>
                                            <table class="ws-table" style="width: 100%;" *ngIf="(!videos_marca || videos_marca.length>0) && !loading">
                                                <tr>
                                                    <th>
                                                        Nombre video / peso
                                                    </th>
                                                    <th>
                                                        &nbsp;
                                                    </th>
                                                </tr>
                                                <tr *ngFor="let video of videos_marca" style="cursor: pointer;">
                                                    <td>
                                                        <h6>
                                                            <a href="{{video.url}}" target="_blank">
                                                                {{video.nombre_archivo}} / {{video.size/1024/1024 | number:'1.3-3'}} MB
                                                            </a>
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <button [disabled]="video.asignado" mat-flat-button color="primary" (click)="asignarVideo(video)">
                                                            <span *ngIf="video.asignado">
                                                                Asignado
                                                            </span>
                                                            <span *ngIf="!video.asignado">
                                                                Asignar
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-6">
                                            <div *ngIf="(!videos_asignados || videos_asignados.length==0)">
                                                <h6>No hay videos asignados a este punto de soporte</h6>
                                            </div>
                                            <table class="ws-table" style="width: 100%;" *ngIf="(!videos_asignados || videos_asignados.length>0) && !loading">
                                                <tr>
                                                    <th>
                                                        Nombre video / peso
                                                    </th>
                                                    <th>
                                                        &nbsp;
                                                    </th>
                                                </tr>
                                                <tr *ngFor="let video of videos_asignados" style="cursor: pointer;">
                                                    <td>
                                                        <h6>
                                                            <a href="{{video.url}}" target="_blank">
                                                                {{video.nombre_archivo}} / {{video.size/1024/1024 | number:'1.3-3'}} MB
                                                            </a>
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <button mat-flat-button color="primary" (click)="desasignarVideo(video)">
                                                            <span *ngIf="video.asignado">
                                                                Desasignar
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <div class="row">&nbsp;</div>
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <button
                                                (click)="guardarPuntoSoporte()"
                                                mat-raised-button
                                                color="primary" >
                                                Guardar
                                                <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                        </button>
                                    </div>
                                </div>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>
