import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FacadeService } from 'src/app/services/facade.service';

export interface DialogData {
  marca: any,
}
@Component({
  selector: 'app-limpiar-marca-dialog',
  templateUrl: './limpiar-marca-dialog.component.html',
  styleUrls: ['./limpiar-marca-dialog.component.scss']
})
export class LimpiarMarcaDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LimpiarMarcaDialogComponent>,
    private facade : FacadeService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = true; 
    }
  
  public tipoDeArchivosABorrar : string = "";

  public waitingResponse : boolean = false;
  public cantidades : any;
  ngOnInit(): void {
    this.waitingResponse = true;
    this.facade.obtenerCantidadArchivosALimpiar(this.data?.marca?.id).subscribe(
      response => {
        this.cantidades = response;
        this.waitingResponse = false;
      }, error =>{
        this.waitingResponse = false;
      }
    );
  }

  limpiarMarca(): void{
    if (this.tipoDeArchivosABorrar) { 
      this.waitingResponse = true;
      this.facade.limpiarMarca(this.data?.marca?.id,{
        tipo : this.tipoDeArchivosABorrar
      }).subscribe(
        response => {
          this.dialogRef.close();
        }, error => {
          this.waitingResponse = false;
        }
      );
    }

  }

}
