<mat-accordion multi>
    <mat-expansion-panel [expanded]="true" class="headers-align">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 style="width: 20%;">
                    Datos del cliente segundo
                </h5>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Nombre del cliente</mat-label>
                <input type="text" matInput placeholder="Nombre del cliente" [(ngModel)]="nombre_cliente">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="headers-align">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 style="width: 20%;">
                    Permisos
                </h5>
            </mat-panel-title>
            <mat-panel-description>
                Secciones a las que podrá administrar el cliente
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-12" *ngFor="let llave of obtenerLlavesDePermisos()">
                <div class="row">
                    <div class="col-lg-6">
                        <span>{{permisos[llave].etiqueta}}</span>
                    </div>
                    <div class="col-lg-3">
                        <mat-checkbox color="primary" [disabled]="loading" [(ngModel)]="permisos[llave].acceso">Vista</mat-checkbox>
                    </div>
                    <div class="col-lg-3">
                        <mat-checkbox color="primary" [disabled]="loading" [(ngModel)]="permisos[llave].edicion">Edición</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="headers-align">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h5>
                Datos de acceso
            </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input type="email" matInput placeholder="Correo electrónico" [(ngModel)]="username">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput placeholder="Contraseña" [(ngModel)]="password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Confirmar contraseña</mat-label>
                <input type="password" matInput placeholder="Confirmar contraseña" [(ngModel)]="c_password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </mat-expansion-panel>
    <div class="row">&nbsp;</div>
    <div class="row">
        <div class="col-12 h-center">
            <button 
                    (click)="guardarClienteSegundo()"
                    mat-raised-button 
                    color="primary" >
                    <span>
                        Guardar
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </span>
            </button>
        </div>
    </div>
</mat-accordion>