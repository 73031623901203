import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmarAccionDialogComponent } from 'src/app/dialogs/confirmar-accion-dialog/confirmar-accion-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-video-soporte-screen',
  templateUrl: './video-soporte-screen.component.html',
  styleUrls: ['./video-soporte-screen.component.scss']
})
export class VideoSoporteScreenComponent implements OnInit {

  public loading: boolean = false;
  public isWaitingUpdate: boolean = false;
  public token: string = null;
  public puntos_soporte: any = [];

  constructor(public facade: FacadeService, public router: Router, private sanitizer: DomSanitizer,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadPuntosSoporte();
    this.token = this.facade.getSessionToken()
  }

  loadPuntosSoporte() {
    this.loading = true;
    this.facade.recuperarPuntosSoporteAgente()
      .subscribe(
        (response) => {
          this.loading = false;
          this.puntos_soporte = response;
        },
        (error) => {
          this.loading = false;
          console.error("ERROR:: " + error);
        }
      );

  }

  async checkSession(slug, isInApp){
    this.isWaitingUpdate = true;
    let puntoSoporteActualizado = await this.facade.recuperarPuntoSoporteDetallePorSlug(slug).toPromise();
    console.log(puntoSoporteActualizado);
    this.isWaitingUpdate = false;
    if (puntoSoporteActualizado.estatus_totem !== "en_llamada"){
      this.goToVideocall(slug, isInApp);
    } else { 
      const dialogRef = this.dialog.open(ConfirmarAccionDialogComponent,{ 
        data: {
          title : "Videototem activo",
          body : "El totem se encuentra en una videollamada. ¿Desea ingresar?"
        }}).afterClosed().subscribe( (esAfirmativo)=>{
          if (esAfirmativo){
            this.goToVideocall(slug, isInApp);
          } 
      });
    }
  }

  goToVideocall(slug, isInApp){
    if (isInApp){
      window.location.href = 'beetherevideo://agente=true&slug='+slug+'&token='+this.token;
    } else {
      this.router.navigate(["/videollamada/"+slug]);
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
