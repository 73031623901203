<div class="login-block row">
    <div class="col-12 col-md-7">
        <div id="login" class="container flex-v-container left-pad-20">
            <div class="flex-1 v-center">
                <div class="row">
                    <div class="col-12" style="padding-top: 50px">
                        <img style="width: 130px" src="assets/images/logo-whatsoporte.png"/>
                    </div>
                </div>
            </div>
            <div class="flex-2">
                <div class="row">
                    <div class="col-md-8 col-12" style="padding-top:50px">
                        <h2 style="font-weight: 200;">¡Bienvenido! escribe tus datos para ingresar.</h2>
                    </div>
                </div>
            </div>
            <div class="flex-3">
                <div class="row">
                    <div class="col-md-7 col-12">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field style="width: 100%;" appearance="outline">
                                    <mat-label>Correo electrónico</mat-label>
                                    <input matInput placeholder="Correo electrónico" [(ngModel)]="username">
                                </mat-form-field>
                                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                                    <mat-label>Contraseña</mat-label>
                                    <input (keyup.enter)="login()" type="password" matInput placeholder="Contraseña" [(ngModel)]="password">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button (click)="recoverPassView()" style="padding: 0" mat-button color="primary">¿Olvidaste tu contraseña?</button>
                            </div>
                        </div>
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div class="col-12">
                                <button (click)="login()" mat-flat-button color="primary">
                                    INICIAR SESIÓN
                                    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div class="col-12" style="text-align: center;">
                                <span class="error_message">
                                    {{error_message}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="recuperar_pass" class="container flex-v-container left-pad-20" style="display: none;">
            <div class="flex-1 v-center">
                <div class="row">
                    <div class="col-12" style="padding-top: 50px">
                        <img style="width: 130px" src="assets/images/logo-whatsoporte.png"/>
                    </div>
                </div>
            </div>
            <div class="flex-2">
                <div class="row">
                    <div class="col-md-8 col-12" style="padding-top:50px">
                        <h2 style="font-weight: 200;">Recuperar contraseña. Escribe tu correo electrónico.</h2>
                    </div>
                </div>
            </div>
            <div class="flex-3">
                <div class="row">
                    <div class="col-md-7 col-12">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field style="width: 100%;" appearance="outline">
                                    <mat-label>Correo electrónico</mat-label>
                                    <input matInput placeholder="Correo electrónico" [(ngModel)]="username_recover">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button (click)="loginView()" style="padding: 0" mat-button color="primary">Volver al incio de sesión</button>
                            </div>
                        </div>
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div class="col-12">
                                <button (click)="recoverPass()" mat-flat-button color="primary">
                                    RECUPERAR CONTRASEÑA
                                    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div class="col-12" style="text-align: center;">
                                <span class="error_message">
                                    {{error_message_recover}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5 md-only">
        <div class="login-bg">
        </div>
    </div>
</div>