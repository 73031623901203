import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-agente-perfil-screen',
  templateUrl: './agente-perfil-screen.component.html',
  styleUrls: ['./agente-perfil-screen.component.scss']
})
export class AgentePerfilScreenComponent implements OnInit {
  
  public id_agente:number;
  public pagina_de_origen:number = 1;
  public criteria:string = "";
  public id_marca:number = 0;
  public state :any;
  constructor(public router:Router, public route:ActivatedRoute) { }

  ngOnInit() {
    if(history.state.pagina_actual){
      this.pagina_de_origen = history.state.pagina_actual
    }
    if(history.state.criteria){
      this.criteria = history.state.criteria
    }
    if(history.state.id_marca){
      this.id_marca = parseInt(history.state.id_marca)
    }
    this.state = {
      pagina_actual : this.pagina_de_origen,
      criteria : this.criteria,
      id_marca : this.id_marca
    }
    this.route.params.subscribe(params => {
      this.id_agente = params['id_agente'];
    })
  }

  irListado(){
    let params = {
      pagina : this.pagina_de_origen
    };
    if(this.criteria !== ""){
      params["criteria"] = this.criteria;
    }
    if(this.id_marca !== 0){
      params["id_marca"] = this.id_marca;
    }
    this.router.navigate(['cliente/agentes'], { 
      queryParams: params
    });
  }
  
  agenteGuardado($event){
    this.irListado();
  }
}
