<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10" style="overflow-y: scroll;height:86vh">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="irListado()" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Creación de plantilla</h2>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">&nbsp;</div>
                            <app-chatbot [id_chatbot]="id_chatbot"></app-chatbot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>