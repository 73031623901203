import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
  title: string,
  body: string
}

@Component({
  selector: 'app-confirmar-accion-dialog',
  templateUrl: './confirmar-accion-dialog.component.html',
  styleUrls: ['./confirmar-accion-dialog.component.scss']
})
export class ConfirmarAccionDialogComponent implements OnInit {

  public esAfirmativo : boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmarAccionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      dialogRef.disableClose = true;
      this.esAfirmativo = false;
  }

  ngOnInit() {

  }

  cancelar(){
    this.dialogRef.close(this.esAfirmativo);
  }

  confirmar(){
    this.esAfirmativo = true;
    this.dialogRef.close(this.esAfirmativo);
  }
}
