<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-10">
            <h5 *ngIf="!data.marca">Nueva marca</h5>
            <h5 *ngIf="data.marca">Editar marca</h5>
        </div>
        <div class="col-2">
            <mat-icon (click)="onNoClick()" style="cursor: pointer" aria-hidden="false" aria-label="Cerrar">close</mat-icon>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12 my-2">
                <div *ngIf="!logo.input_file">
                    <img src="assets/images/agregar-logo.png" style="width: 20px"/>&nbsp;
                    <button (click)="clickInput('marca_logo_file')" style="padding: 0" mat-button color="primary">AGREGAR LOGOTIPO</button>
                </div>
                <input style="display: none;" type="file" id="marca_logo_file" name="marca_logo_file" (change)="onMarcaLogoSelect($event)" />
                <div *ngIf="logo.input_file">
                    <div class="logo-container">
                        <div class="row">
                            <div class="col-10 v-center">
                                <img style="width: 50%;" src="{{logo.url}}"/>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="clickInput('marca_logo_file')">
                                            <i class="fas fa-edit"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="eliminarLogo()">
                                            <i class="fas fa-trash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input type="text" matInput placeholder="Nombre de la marca" [(ngModel)]="nombre">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12"> 
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Color en hexadecimal</mat-label>
                    <input type="text" matInput placeholder="Color en hexadecimal" [(ngModel)]="color_hex">
                </mat-form-field>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Número de agentes</mat-label>
                    <input type="text" matInput placeholder="Color en hexadecimal" [(ngModel)]="maximo_agentes">
                </mat-form-field>
            </div>
            <div class="col-12">
                <span *ngIf="!validateOnlyNumbers()" style="color: #ED5C5C;" >
                    Solo se aceptan números.
                </span>
            </div>
        </div>-->
        <div class="row">
            <div class="col-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Guardar datos</mat-label>
                    <mat-select [(value)]="tipo_crm">
                        <mat-option *ngFor="let c of tipos_crm" [value]="c.id">
                            {{c.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="tipo_crm == 'modo_3' || tipo_crm == 'modo_4'" class="row">
            <div class="col-12"> 
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Api key Hubspot</mat-label>
                    <input type="text" matInput placeholder="Api key Hubspot" [(ngModel)]="api_key_hobspot">
                </mat-form-field>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12"> 
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Horario inicial para recibir mensajes (opcional)</mat-label>
                    <input type="time" matInput placeholder="Horario inicial para recibir mensajes (opcional)" [(ngModel)]="horario_inicio">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Por ejemplo si pones "08:00", los mensajes hasta las "07:59" se asignaran a agentes online, y después de eso al agente maestro
                </span>
                <br/>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Horario final para recibir mensajes (opcional)</mat-label>
                    <input type="time" matInput placeholder="Horario final para recibir mensajes (opcional)" [(ngModel)]="horario_fin">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Por ejemplo si pones "17:00", los mensajes hasta las "17:59" se asignaran a agentes online, y después de eso al agente maestro
                </span>
                <br/>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Zona Horaria (UTC +/-)</mat-label>
                    <input type="number" min="-24" max="24" matInput placeholder="El UTC de su ubicación actual es {{tiempo_universal_coordinado_local}}" [(ngModel)]="tiempo_universal_coordinado">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Zona horaria acorde al tiempo universal coordinado, agregue un signo positivo (+) para sumar horas o un signo negativo (-) para restar
                </span>
                <br/>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Máximo de participaciones en formularios</mat-label>
                    <input type="text" matInput [(ngModel)]="maximo_participaciones">
                </mat-form-field>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-lg-12">
                <h5>Etiquetas de conversación</h5>
            </div>
            <div class="col-lg-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Etiquetas</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip
                        *ngFor="let etiqueta of etiquetas_conversacion"
                        [selectable]="selectable"
                        [removable]="etiqueta.removible"
                        (removed)="removerEtiqueta(etiqueta)"
                        (click)="editarEtiqueta(etiqueta)"
                        >
                        {{etiqueta.nombre}}
                        <mat-icon matChipRemove *ngIf="etiqueta.removible">cancel</mat-icon>
                      </mat-chip>
                      <input
                        matInput
                        placeholder="Nueva etiqueta"
                        #etiquetaInput
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="agregarEtiqueta($event)"
                        [(ngModel)] = "etiqueta.nombre"
                        *ngIf="etiquetas_conversacion.length!=10"
                        >
                    </mat-chip-list>
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Escriba la etiqueta y presione la tecla Enter para guardarla.
                    Para editar la etiqueta, de click en el chip que desee editar.
                </span>
                <span style="font-size: 0.8em">
                    Puede agregar hasta 10 etiquetas, las cuales podrán ser asignadas a una conversación por un agente
                </span>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6>¿Mediante qué plataforma se conectará a whatsapp?</h6>
                <mat-radio-group
                [(ngModel)]="plataforma_whatsapp">
                    <table style="width: 50%;">
                        <tr>
                            <td style="margin: 5px">
                                <mat-radio-button color="primary" value="chat-api">Chat-API</mat-radio-button>
                            </td>
                            <td style="margin: 5px">
                                <mat-radio-button color="primary" value="twilio">Twilio</mat-radio-button>
                            </td>
                        </tr>
                    </table>
                </mat-radio-group>
            </div>
        </div>
        <div class="row" *ngIf="plataforma_whatsapp=='chat-api'">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                            <mat-label>Chat-api API URL</mat-label>
                            <input type="text" matInput placeholder="Ej: https://eu167.chat-api.com/instance111111/ " [(ngModel)]="chat_api_url">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                            <mat-label>Chat-api TOKEN</mat-label>
                            <input type="text" matInput placeholder="Ej: m3y4g8vyadsjahti " [(ngModel)]="chat_api_token">
                        </mat-form-field>
                    </div>
                </div>
                <app-chat-api-config [button_text]="'Configurar chat-api'"
                                      [chat_api_url]="chat_api_url"
                                      [chat_api_token]="chat_api_token"
                ></app-chat-api-config>

            </div>
        </div>
        <div class="row" *ngIf="plataforma_whatsapp=='twilio'">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                            <mat-label>Número celular de Twilio</mat-label>
                            <input type="text" matInput placeholder="Número celular de Twilio" [(ngModel)]="twilio_celular">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                            <mat-label>Twilio account SID</mat-label>
                            <input type="text" matInput placeholder="Twilio account SID" [(ngModel)]="twilio_sid">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                            <mat-label>Twilio token</mat-label>
                            <input type="text" matInput placeholder="Twilio token" [(ngModel)]="twilio_token">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        
    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button 
                    (click)="guardarMarca()"
                    mat-raised-button 
                    color="primary" >
                        GUARDAR
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
        </div>
    </div>
</div>