import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';

declare var $;
declare var document;

export interface DialogData {
  chatbot_padre: any,
  marca: number,
  esFormulario: boolean,
  disparador:string,
  isEdit:boolean,
  all_data:any
}

@Component({
  selector: 'app-nuevo-chatboot-hijo-dialog',
  templateUrl: './nuevo-chatboot-hijo-dialog.component.html',
  styleUrls: ['./nuevo-chatboot-hijo-dialog.component.scss']
})
export class NuevoChatbootHijoDialogComponent implements OnInit {

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<NuevoChatbootHijoDialogComponent>,public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = false;
  }

  public loading:boolean = false
  public mensaje:string = "";
  public acceso_rapido:string="";
  public marcas:any = []
  public visible_emoji:boolean=false;
  public bold_activo:boolean = false; 
  public italic_activo:boolean = false; 
  public selText:string="";
  public start_select:any;
  public end_select:any;
  public slug : string = "slug";

  public validaciones = [
    {
      nombre : "Por defecto",
      valor : ""
    },
    {
      nombre : "Texto",
      valor : "texto"
    },
    {
      nombre : "Números",
      valor : "numeros"
    },
    {
      nombre : "Imagen",
      valor : "imagen"
    }
  ];

  public cualquierLongitud : boolean = true;
  public validacion : any = {
    tipo : "",
    longitud : null,
    mensaje_error : ""
  };

  ngOnInit(): void {
    if (this.data.esFormulario){
      this.validacion.tipo = "texto";
    }
    if(this.data.isEdit){
      this.mensaje = this.data.all_data.mensaje;
      this.slug = this.data.all_data.slug;
      this.acceso_rapido = this.data.all_data.acceso_rapido;
    }
    if (this.data.all_data?.validacion){
      try {
        this.validacion = JSON.parse(this.data.all_data.validacion);
      } catch (error) {
        this.validacion = this.data.all_data.validacion;
      }
      if (this.validacion.longitud){
        this.cualquierLongitud = false;
      }
    }
  }

  isComplete(){
      if(this.mensaje != "" && this.slug !== ""){
        return false
      }else{
        return true
      }
  }
  
  crearChatbot(){
    if(this.data.isEdit){
      this.editarChatbot()
      return
    }
    this.loading = true;
    let data = {
      mensaje: this.mensaje,
      automatico: false,
      chatbot_padre_id:this.data.chatbot_padre.id,
      formulario : this.data.esFormulario,
      slug : this.slug,
      validacion:  JSON.stringify(this.validacion)
    }
    if(this.data.disparador !== '0'){
      data["disparador"] = this.data.disparador;
    }
    this.facade.crearChatbots(this.data.marca,data)
    .subscribe(
      (response)=>{
        this.dialogRef.close();
        this.loading = false;
      },
      (error)=>{
        console.log(error)
        this.loading = false;
      }
    ) 
  }

  editarChatbot(){
    this.loading = true;
    var data ={
      id: this.data.all_data.id,
      mensaje: this.mensaje,
      automatico: this.data.all_data.automatico,
      disparador: this.data.all_data.disparador,
      slug : this.slug,
      validacion:  JSON.stringify(this.validacion),
      formulario : this.data.esFormulario
    }
    this.facade.crearChatbots(this.data.all_data.marca,data)
    .subscribe(
      (response)=>{
        this.dialogRef.close();
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }

  addEmoji(event){
    console.log(event)
    this.mensaje = this.mensaje + event.emoji.native
    //this.visible_emoji = false
  } 

  setBold(){
    if(this.selText != ""){
      this.mensaje = [this.mensaje.slice(0, this.start_select), '*', this.mensaje.slice(this.start_select)].join('');
      this.mensaje = [this.mensaje.slice(0, this.end_select+1), '*', this.mensaje.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.bold_activo = !this.bold_activo;
    this.mensaje = this.mensaje +"*"
  } 

  setItalic(){
    if(this.selText != ""){
      this.mensaje = [this.mensaje.slice(0, this.start_select), '_', this.mensaje.slice(this.start_select)].join('');
      this.mensaje = [this.mensaje.slice(0, this.end_select+1), '_', this.mensaje.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.italic_activo = !this.italic_activo;
    this.mensaje = this.mensaje +"_"
  } 
 
  GetSelectedText () {
    var selText = "";
    var url = document.getElementById("message") as HTMLSelectElement;
    if (window.getSelection) {  // all browsers, except IE before version 9
        if (document.activeElement && 
                (document.activeElement.tagName.toLowerCase () == "textarea" || 
                 document.activeElement.tagName.toLowerCase () == "input")) 
        {
            var text = document.activeElement.value;
            this.start_select = document.activeElement.selectionStart
            this.end_select = document.activeElement.selectionEnd
            selText = text.substring (document.activeElement.selectionStart, 
                                      document.activeElement.selectionEnd);
        }
        else {
            var selRange = window.getSelection ();
            selText = selRange.toString ();
        }
    } 
    else {
        if (document.selection.createRange) {       // Internet Explorer
            var range = document.selection.createRange ();
            selText = range.text;
        }
    }
    return selText;
  }

  onSelect(eve){
    this.italic_activo = false
    this.bold_activo = false
    console.log(eve)
    var selText = "";
    selText = this.GetSelectedText ();
    this.selText = selText
  }

}
