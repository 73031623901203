import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-agentes-screen',
  templateUrl: './agentes-screen.component.html',
  styleUrls: ['./agentes-screen.component.scss']
})
export class AgentesScreenComponent implements OnInit {

  public loading:boolean = false;
  public agentes:any=[];
  public paginas:any=[];
  public pagina_actual:number=1;
  public marcas:any=[{id:0,nombre:"Todas las marcas"}];
  public id_marca:number=0;  
  public criteria:string="";
  esEditor: any;
  constructor(
    public router:Router, 
    public facade:FacadeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_agentes");
    this.route.queryParams
      .subscribe((params) => {
        if(params.pagina){
          this.pagina_actual = params.pagina;
        }
        if(params.criteria){
          this.criteria = params.criteria;
        }
        if(params.id_marca){
          this.id_marca = parseInt(params.id_marca);
        }
      }
    );
    this.loadAgentes();
    this.loadMarcas();
  }

  loadAgentes(){
    this.loading = true;
    //Si buscan por criterio, quitamos paginación
    this.facade.listarAgentes(this.pagina_actual, this.criteria, this.id_marca)
    .subscribe(
      (response)=>{
        this.loading = false;
        this.agentes = response.contenido;
        this.paginas = [];
        this.pagina_actual=response.pagina;
        var p = 1;
        while(p<=response.ultima_pagina){
          this.paginas.push(p);
          p = p+1;
        }
        this.actualizarUrl();
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.aplicarFiltro();
        var marcas = this.marcas
        this.marcas = marcas.concat(response);
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    ) 
  }

  cargarPagina(p){
    this.pagina_actual=p;
    this.loadAgentes();
  }

  irDetalleAgente(id_agente){
    if (!this.esEditor){
      return;
    }
    if(!id_agente){
      id_agente = 0;
    }
    this.router.navigate(['cliente/agente/perfil/'+id_agente],{ 
      state: { 
        pagina_actual : this.pagina_actual,
        criteria : this.criteria,
        id_marca : this.id_marca
      } 
    });
  }

  AgregarAgente(id_agente){
    if(!id_agente){
      id_agente = 0;
    }
    this.router.navigate(['cliente/agente/'+id_agente]);
  }

  actualizarUrl(){
    let queryParams = { 
      pagina : this.pagina_actual
    };
    if(this.criteria!==""){
      queryParams["criteria"] = this.criteria;
    }
    if(this.id_marca!==0){
      queryParams["id_marca"] = this.id_marca;
    }
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: queryParams
      }
    );
  }

  aplicarFiltro(){
    this.loadAgentes();
  }

  esFiltro(){
    if(this.id_marca != 0){
      return true 
    }else{
      return false 
    }
  }

  esBusqueda(){
    if(this.criteria != ""){
      return true 
    }else{
      return false 
    }
  }

  clearSearch(){
    this.criteria = "";
  }

  limpiarFiltro(){
    this.id_marca = 0;
    this.criteria = "";
    this.loadAgentes();
  }

}
