import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-formulario-detalle-screen',
  templateUrl: './formulario-detalle-screen.component.html',
  styleUrls: ['./formulario-detalle-screen.component.scss']
})
export class FormularioDetalleScreenComponent implements OnInit {
  public id : number;
  public formulario : any;
  public copiaFormulario : any;
  public estaEditando : boolean = false;
  public pagina : number = null;
  public id_marca : number = null;
  public loading : boolean = false;
  constructor(
    private activatedRoute : ActivatedRoute,
    private facade : FacadeService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id_formulario;
    if (history.state?.pagina){
      this.pagina = history.state.pagina;
    }
    if (history.state?.id_marca){
      this.id_marca = history.state.id_marca;
    }
    this.obtenerFormulario();
  }

  obtenerFormulario(){
    this.facade.obtenerFormulario(this.id).subscribe(
      (response) => {
        this.formulario = response;
      },  (error) => {
        console.log(error)
      }
    );
  }

  editar(){
    this.copiaFormulario = JSON.parse(JSON.stringify(this.formulario));
    this.estaEditando = true;
  }

  deshacer(){
    this.formulario = this.copiaFormulario;
    this.estaEditando = false;
  }

  guardarCambios(reditect : boolean){
    this.loading = true;
    this.estaEditando = false;
    this.facade.actualizarFormulario(this.id, this.formulario).subscribe(
      (response) => {
        this.formulario = response;
        this.loading = false;
        if (reditect){
          this.regresar();
        }
      }, (error) => {
        this.loading = false;
      }
    );
  }

  aceptarFormulario(){
    let confirmacion = confirm("¿Está seguro de aprobar este formulario?");
    if (confirmacion){
      this.formulario.estatus = "ACEPTADO";
      this.guardarCambios(true);
    }
  }

  rechazarFormulario(){
    let confirmacion = confirm("¿Está seguro de rechazar este formulario?");
    if (confirmacion){
      this.formulario.estatus = "RECHAZADO";
      this.guardarCambios(true);
    }
  }

  regresar(){
    let queryParams = {};
    if (this.pagina){
      queryParams["pagina"] = this.pagina;
    }
    if (this.id_marca){
      queryParams["id_marca"] = this.id_marca;
    }
    this.router.navigate(["cliente/formularios"], {
      queryParams : queryParams
    });
  }
}
