import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cliente-home-screen',
  templateUrl: './cliente-home-screen.component.html',
  styleUrls: ['./cliente-home-screen.component.scss']
})
export class ClienteHomeScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
