<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <h4 *ngIf="tipo == 'consumidor'" style="font-weight: 600;">
                Campos de consumidor
            </h4>        
            <h4 *ngIf="tipo == 'agente'" style="font-weight: 600;">
                Campos agente encuesta App
            </h4>        
        </div>
        <div class="col-md-12">
            <table class="ws-table" style="width: 100%;text-align: center;">
                <tr>
                    <th>
                        Nombre del campo
                    </th>
                    <th>
                        Tipo de campo
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
                <tr *ngFor="let c of campos">
                    <td>
                        {{c.etiqueta}}
                    </td>
                    <td>
                        {{c.tipo}}
                    </td>
                    <td>
                        <a (click)="editarCampo(c.id)">                            
                            <span class="color-primary">
                                <i class="fas fa-edit" style="font-size: 20px;"></i>
                            </span>
                        </a>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <a (click)="borrarCampo(c.id)" *ngIf="!c?.bloquear_borrado">                            
                            <span class="color-primary">
                                <i class="far fa-trash-alt" style="font-size: 20px;"></i>
                            </span>
                        </a>
                    </td>
                </tr>
            </table>
            <div class="row">&nbsp;</div>
            <div class="row d-flex justify-content-center">
                <button (click)="nuevoCampo()" mat-raised-button color="primary">
                    Añadir Campo
                </button>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>