import { Component, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-metricas-dashboard',
  templateUrl: './metricas-dashboard.component.html',
  styleUrls: ['./metricas-dashboard.component.scss']
})
export class MetricasDashboardComponent implements OnInit {
  public loading:boolean=false;
  public loading_respuesta:boolean=false;
  public loading_reporte:boolean=false;
  public loading_satisfaccion:boolean=false;
  public loading_clear:boolean=false;
  public mensajes_recibidos:string="-";
  public clientes_atendidos:string="-";
  public tiempo_respuesta:string="0";
  public tiempo_respuesta_request:boolean=false;
  public videollamdas_realizadas:string="-";
  public indice_satisfaccion:string;
  public id_marca:number=0;
  public marcas:any=[{id:0,nombre:"Todas las marcas"}];
  public fecha_filtro_inicial:any = "";
  public fecha_filtro_final:any = "";
  public maxDate:any=new Date()
  public select_date:any = null;
  public esEditor:boolean=false;

  constructor(public facade:FacadeService,public router:Router) { }


  ngOnInit(): void {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_dashboard");
    this.loadMarcas()
  }

  _monthSelectedHandler(picker, event: MatDatepickerInputEvent<Date>) {
    this.aplicarFiltro();
    picker.close();

  }

  loadMarcas(){
    this.loading = true;

    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.aplicarFiltro();
        var marcas = this.marcas
        this.marcas = marcas.concat(response);
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    )
  }

  loadMetricasSatisfaccion(fecha_inicial,fecha_final){
    this.facade.metricaIndiceSatisfaccion(this.id_marca,fecha_inicial,fecha_final)
    .subscribe(
      (response)=>{
        this.loading_satisfaccion = false;
        this.indice_satisfaccion = response.indice_satisfaccion;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading_satisfaccion = false;
      }
    );
  }

  loadMetricasTiempos(){
    if(this.loading_respuesta)return
    this.loading_respuesta = true
    this.facade.metricasTiemposDashboard(0,"","",0)
    .subscribe(
      (response)=>{
        this.loading_respuesta = false;
        this.tiempo_respuesta_request = true;
        this.tiempo_respuesta=response.tiempo_promedio_respuesta;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading_respuesta = false;
      }
    )
  }

  metricasFilter(id_marca,fecha_inicial,fecha_final,id_agente){
    this.facade.metricasDashboard(id_marca,fecha_inicial,fecha_final,id_agente)
    .subscribe(
      (response)=>{
        this.loading = false;
        this.mensajes_recibidos=response.mensajes_recibidos;
        this.clientes_atendidos=response.clientes_atendidos;
        this.videollamdas_realizadas="0";
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    )
  }
  metricasFilterTiempos(id_marca,fecha_inicial,fecha_final,id_agente){
    this.loading_respuesta = true
    this.tiempo_respuesta="0";
    this.facade.metricasTiemposDashboard(id_marca,fecha_inicial,fecha_final,id_agente)
    .subscribe(
      (response)=>{
        this.loading_respuesta = false;
        this.tiempo_respuesta_request = true;
        this.tiempo_respuesta=response.tiempo_promedio_respuesta;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading_respuesta = false;
      }
    )
  }

  aplicarFiltro(){
    let fecha_inicial = "";
    let fecha_final = ""
    if(this.fecha_filtro_inicial != ""){
      fecha_inicial = this.darAFormatoFecha(this.fecha_filtro_inicial)
    }
    if(this.fecha_filtro_final != ""){
      fecha_final = this.darAFormatoFecha(this.fecha_filtro_final)
    }
    this.metricasFilter(this.id_marca,fecha_inicial,fecha_final,0)
    this.metricasFilterTiempos(this.id_marca,fecha_inicial,fecha_final,0)
    this.loadMetricasSatisfaccion(fecha_inicial,fecha_final);
  }

  limpiar(){
    this.id_marca=0;
    this.fecha_filtro_inicial = ""
    this.fecha_filtro_final = ""
    this.metricasFilter(0,"","",0)
    this.metricasFilterTiempos(0,"","",0)
    this.loadMetricasSatisfaccion("","");
  }
  isFilter(){
    if(this.id_marca != 0 || this.fecha_filtro_inicial != "" || this.fecha_filtro_final != ""){
      return true
    }else{
      return false
    }
  }
  generarReporte(){
    if(this.id_marca==0){
      alert("Por favor selecciona una marca.")
      return;
    }
    if(this.loading_reporte)return;
    this.loading_reporte = true
    var nombre_reporte = "consumidores_"
    var marcas = this.marcas
    //console.log(marcas)
    for(var i=0;i<marcas.length;i++){
      if(this.id_marca == marcas[i].id){
        nombre_reporte = nombre_reporte+marcas[i].nombre
      }
    }
    var fecha_actual = new Date()
    nombre_reporte = nombre_reporte + ' '+this.darAFormatoFecha(fecha_actual)+'T'+this.formatoHora(fecha_actual);
    let fecha_inicial = "";
    let fecha_final = ""
    if(this.fecha_filtro_inicial != ""){
      fecha_inicial = this.darAFormatoFecha(this.fecha_filtro_inicial)
    }
    if(this.fecha_filtro_final != ""){
      fecha_final = this.darAFormatoFecha(this.fecha_filtro_final)
    }
    this.facade.obtenerConsumidores(this.id_marca,fecha_inicial,fecha_final).
    subscribe(
      (response)=>{
        var json_reporte = response.consumidores_crm;
        //console.log(json_reporte)
        var data = "ID,Nombre completo,Correo electrónico,Número de teléfono,Creación,Campos Dinámicos\r\n";
        for(var i=0;i<json_reporte.length;i++){
            var row = json_reporte[i];
            if(row.productos_comprados){
              row.productos_comprados = row.productos_comprados.split(",")
              row.productos_comprados = row.productos_comprados.join(" - ")
            }
            //console.log(row)
            if (row.consumidor && row.consumidor.id){
              data += row.consumidor.id+",";
            }
            else{
              data += row.id+",";
            }
            data += row.nombre+",";
            data += row.correo+",";
            data += row.celular+",";
            data += row.creacion+",";
            if(row.campos_dinamicos_json){
              var valores_dinamicos = [];
              var campos_dinamicos_json = JSON.parse(row.campos_dinamicos_json)
              for(let cd of campos_dinamicos_json){
                if(cd.tipo == "dropdown_multi" && cd.valor !=="" && cd.valor.length > 0){
                  cd.valor = cd.valor.join(" - ")
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.valor)
                }else{
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.valor)
                }
              }
              data += valores_dinamicos.join(",");
            }
            data += "\r\n";
        }
        var download_link = document.createElement('a');
        download_link.download = nombre_reporte+'.csv';
        download_link.href = "data:application/csv,"+escape(data);
        download_link.click();
        this.loading_reporte = false
      },
      (error)=>{
        alert(error.error.error)
        this.loading_reporte = false
        console.log(error)
      }
    )
  }
  generarReporteApp(){
    if(this.id_marca==0){
      alert("Por favor selecciona una marca.")
      return;
    }
    if(this.loading_reporte)return;
    this.loading_reporte = true
    var nombre_reporte = "encuestaApp_"
    var marcas = this.marcas
    //console.log(marcas)
    for(var i=0;i<marcas.length;i++){
      if(this.id_marca == marcas[i].id){
        nombre_reporte = nombre_reporte+marcas[i].nombre
      }
    }
    var fecha_actual = new Date()
    nombre_reporte = nombre_reporte + ' '+this.darAFormatoFecha(fecha_actual)+'T'+this.formatoHora(fecha_actual);
    let fecha_inicial = "";
    let fecha_final = ""
    if(this.fecha_filtro_inicial != ""){
      fecha_inicial = this.darAFormatoFecha(this.fecha_filtro_inicial)
    }
    if(this.fecha_filtro_final != ""){
      fecha_final = this.darAFormatoFecha(this.fecha_filtro_final)
    }
    this.facade.obtenerEncuestasApp(this.id_marca,fecha_inicial,fecha_final).
    subscribe(
      (response)=>{
      var json_reporte = response.encuestas;
        //console.log(json_reporte)
        var data = "ID,Agente,Creación,Campos Dinámicos\r\n";
        for(var i=0;i<json_reporte.length;i++){
            var row = json_reporte[i];
            data += row.id+",";
            data += row.agente.last_name+",";
            data += row.creacion+",";
            if(row.campos_dinamicos_json){
              // row.campos_dinamicos_json = row.campos_dinamicos_json.toLowerCase()
              var valores_dinamicos = [];

              var campos_dinamicos_json;
              try {
                campos_dinamicos_json = JSON.parse(row.campos_dinamicos_json);
              } catch (error) {
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll("'",'"')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('None','null')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('False','false')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('True','true')
                campos_dinamicos_json = JSON.parse(row.campos_dinamicos_json);
              }
              for(let cd of campos_dinamicos_json){
                if(cd.tipo == "dropdown_multi" && cd.value && cd.value !=="" && cd.value.length > 0){
                  cd.value = cd.value.join(" - ")
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.value)
                }else{
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.value)
                }
              }
              data += valores_dinamicos.join(",");
            }
            data += "\r\n";
        }
        var download_link = document.createElement('a');
        download_link.download = nombre_reporte+'.csv';
        download_link.href = "data:application/csv,"+escape(data);
        download_link.click();
        this.loading_reporte = false
        console.log(nombre_reporte)
      },
      (error)=>{
        alert(error.error.error)
        this.loading_reporte = false
        console.log(error)
      }
    )
  }

  generarReporteFormularios(){
    if(this.id_marca==0){
      alert("Por favor selecciona una marca.")
      return;
    }
    if(this.loading_reporte)return;
    this.loading_reporte = true
    let params = {
      estatus : "ACEPTADO"
    }
    if (this.fecha_filtro_inicial){
      params["fecha_inicial"] = this.darAFormatoFecha(this.fecha_filtro_inicial);
    }
    if (this.fecha_filtro_final){
      params["fecha_final"] = this.darAFormatoFecha(this.fecha_filtro_final);
    }
    let nombre_reporte = "formularios_"
    let fecha_actual = new Date()
    nombre_reporte = nombre_reporte + ' '+this.darAFormatoFecha(fecha_actual)+'T'+this.formatoHora(fecha_actual);
    this.facade.obtenerReporteFormularios(
      this.id_marca,params
    ).subscribe(
      (response) => {
        let formularios : any[]= response;
        let data =""
        formularios.forEach((formulario, index) => {
          let preguntas : any[] = formulario.preguntas;
          if (index === 0){
            data += "fecha, numero_consumidor,";
            let encabezado = preguntas.map((pregunta)=> pregunta.slug);
            data += encabezado.join(",");
            data += "\r\n";
          }
          data += formulario.creacion+","+formulario.consumidor.numero_celular+",";
          let respuestas = preguntas.map((pregunta)=> pregunta.respuesta.replace(/,/g, '.'));
          data += respuestas.join(",");
          data += "\r\n";
        });
        let download_link = document.createElement('a');
        download_link.download = nombre_reporte+'.csv';
        download_link.href = "data:application/csv,"+escape(data);
        download_link.click();
        this.loading_reporte = false
      }, (error) => {
        alert(error.error.error)
        this.loading_reporte = false
        console.log(error)
      }
    );
  }

  generarReporteVideototemFormularios(){
    if(this.id_marca==0){
      alert("Por favor selecciona una marca.")
      return;
    }
    if(this.loading_reporte)return;
    this.loading_reporte = true
    var nombre_reporte = "Formulario_videoTotem_"
    var marcas = this.marcas
    //console.log(marcas)
    for(var i=0;i<marcas.length;i++){
      if(this.id_marca == marcas[i].id){
        nombre_reporte = nombre_reporte+marcas[i].nombre
      }
    }
    var fecha_actual = new Date()
    nombre_reporte = nombre_reporte + ' '+this.darAFormatoFecha(fecha_actual)+'T'+this.formatoHora(fecha_actual);
    let fecha_inicial = "";
    let fecha_final = ""
    if(this.fecha_filtro_inicial != ""){
      fecha_inicial = this.darAFormatoFecha(this.fecha_filtro_inicial)
    }
    if(this.fecha_filtro_final != ""){
      fecha_final = this.darAFormatoFecha(this.fecha_filtro_final)
    }
    this.facade.obtenerReporteVideototemFormularios(this.id_marca,fecha_inicial,fecha_final).
    subscribe(
      (response)=>{
      var json_reporte = response;
        console.log(json_reporte)
        var data = "ID,Agente,Creación,Campos Dinámicos\r\n";
        for(var i=0;i<json_reporte.length;i++){
            var row = json_reporte[i];
            data += row.id+",";
            data += row.agente.last_name+",";
            data += row.creacion+",";
            if(row.campos_dinamicos_json){
              // row.campos_dinamicos_json = row.campos_dinamicos_json.toLowerCase()
              var valores_dinamicos = [];

              var campos_dinamicos_json;
              try {
                campos_dinamicos_json = JSON.parse(row.campos_dinamicos_json);
              } catch (error) {
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll("'",'"')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('None','null')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('False','false')
                row.campos_dinamicos_json = row.campos_dinamicos_json.replaceAll('True','true')
                campos_dinamicos_json = JSON.parse(row.campos_dinamicos_json);
              }
              for(let cd of campos_dinamicos_json){
                if(cd.tipo == "dropdown_multi" && cd.valor && cd.valor !=="" && cd.valor.length > 0){
                  cd.valor = cd.valor.join(" - ")
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.valor)
                }else{
                  valores_dinamicos.push(cd.etiqueta)
                  valores_dinamicos.push(cd.valor)
                }
              }
              data += valores_dinamicos.join(",");
            }
            data += "\r\n";
        }
        var download_link = document.createElement('a');
        download_link.download = nombre_reporte+'.csv';
        download_link.href = "data:application/csv,"+escape(data);
        download_link.click();
        this.loading_reporte = false
        console.log(nombre_reporte)
      },
      (error)=>{
        alert(error.error.error)
        this.loading_reporte = false
        console.log(error)
      }
    )
  }

  darAFormatoFecha(fecha){
    return new Date(fecha).toJSON().slice(0, 10);
  }

  formatoHora(hora){
    var fecha = new Date(hora);
    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();
    var minutos_string = ""
    if(minutos<10){
      minutos_string = '0'+minutos
    }else{
      minutos_string = ''+minutos
    }
    return horas+":"+minutos_string
  }
}
