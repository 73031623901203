<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div id="vista">
                    <div class="row mt-4 mx-1 my-2">
                        <div class="col-auto">
                            <button [routerLink]="['/cliente/videosoporte']" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                        <div class="col">
                            <h4>Métricas Videosoporte</h4>
                        </div>
                        <div class="col-auto">
                            <button [disabled]="waitingResponse" (click)="download()" mat-flat-button color="primary">
                                <i class="fa-spin fas fa-spinner" *ngIf="waitingResponse"></i> Descargar
                            </button>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-lg-3">
                            <mat-form-field class="d-flex justify-content-center" style="width: 100%;" appearance="outline">
                                <mat-label>Selecciona marca</mat-label>
                                <mat-select [disabled]="loading" [(ngModel)]="marca" (selectionChange)="aplicarFiltro()">
                                  <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                    {{marca.nombre}} 
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
                                <mat-label>A partir del</mat-label>
                                <input [disabled]="loading" matInput [max]="maxDate" mat-raised-button [matDatepicker]="initialPicker" [(ngModel)]="fecha_inicio" [value]="fecha_inicio" 
                                (click)="initialPicker.open()"
                                (dateChange)="_monthSelectedHandler(initialPicker,$event)"
                                (keydown.arrowdown)="initialPicker.open()"
                                (keydown.enter)="initialPicker.open()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="initialPicker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #initialPicker color="color-primary" startView="month"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
                                <mat-label>Hasta el</mat-label>
                                <input [disabled]="loading" matInput [max]="maxDate" mat-raised-button [matDatepicker]="finalPicker" [(ngModel)]="fecha_fin" [value]="fecha_fin" 
                                (click)="finalPicker.open()"
                                (dateChange)="_monthSelectedHandler(finalPicker,$event)"
                                (keydown.arrowdown)="finalPicker.open()"
                                (keydown.enter)="finalPicker.open()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="finalPicker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #finalPicker color="color-primary" startView="month"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isFilter()" class="col-lg-3 d-flex align-items-center">
                            <a (click)="limpiar()">
                                <div class="row d-flex align-items-center">
                                    <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="row my-2" *ngIf="!loading">
                        <div class="col-12 my-2"  *ngIf="tiemposPorEstatus.length > 0">
                            <div class="row">
                                <div class="col-lg-8 d-flex justify-content-center">
                                    <google-chart style="width: 100%; height: 400px;" (ready)="isLoadingTiempoActivo = false" [options]="opcionesGraficaDePastel" [dynamicResize]="true" [data]="tiemposPorEstatus" [type]="tipoGraficaDePastel"></google-chart>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center" *ngIf="isLoadingTiempoActivo">
                                    <div class="loader">
                                        <i class="fa-spin fas fa-spinner"></i>
                                    </div>
                                </div>
                                <div class="col-lg-4 d-flex align-items-center">
                                    <div class="card col-12 p-2">
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo de actividad</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempoActivo | number: '1.0-0'}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-1" *ngIf="tiemposPorFechaEstatusEnEspera.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 card"> 
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo en espera</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempoEnEspera?.tiempo_en_horas | number: '1.0-0'}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center" *ngIf="isLoadingTiempoEnEspera">
                                    <div class="loader">
                                        <i class="fa-spin fas fa-spinner"></i>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <google-chart (ready)="isLoadingTiempoEnEspera = false" [options]="opcionesGraficaDeBarras" [data]="tiemposPorFechaEstatusEnEspera" [columns]="['Fecha', 'En espera', { role: 'style' }]" [type]="tipoGraficaDeBarras"></google-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-1" *ngIf="tiemposPorFechaEstatusEnLlamada.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 card"> 
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo en llamada</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempoEnLlamada?.tiempo_en_horas | number: '1.0-0'}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center" *ngIf="isLoadingTiempoEnLlamada">
                                    <div class="loader">
                                        <i class="fa-spin fas fa-spinner"></i>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <google-chart (ready)="isLoadingTiempoEnLlamada = false" [options]="opcionesGraficaDeBarras" [data]="tiemposPorFechaEstatusEnLlamada" [columns]="['Fecha', 'En llamada', { role: 'style' }]" [type]="tipoGraficaDeBarras"></google-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-1" *ngIf="tiemposPorFechaEstatusApagado.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 card"> 
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo apagado</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempoApagado?.tiempo_en_horas | number: '1.0-0'}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center" *ngIf="isLoadingTiempoApagado">
                                    <div class="loader">
                                        <i class="fa-spin fas fa-spinner"></i>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <google-chart (ready)="isLoadingTiempoApagado = false" [options]="opcionesGraficaDeBarras" [data]="tiemposPorFechaEstatusApagado" [columns]="['Fecha', 'Apagado', { role: 'style' }]" [type]="tipoGraficaDeBarras"></google-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-1" *ngIf="tiemposPorFechaEstatusOffline.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 card"> 
                                        <div class="row">&nbsp;</div>
                                        <h6 style="font-weight: 600;">Tiempo offline</h6>
                                        <h1 class="color-primary text-break" style="font-weight: 200;">{{tiempoOffline?.tiempo_en_horas | number: '1.0-0'}}</h1>
                                        <div class="row">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center" *ngIf="isLoadingTiempoOffline">
                                    <div class="loader">
                                        <i class="fa-spin fas fa-spinner"></i>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <google-chart (ready)="isLoadingTiempoOffline = false" [options]="opcionesGraficaDeBarras" [data]="tiemposPorFechaEstatusOffline" [columns]="['Fecha', 'Offline', { role: 'style' }]" [type]="tipoGraficaDeBarras"></google-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-2 d-flex justify-content-center">
                            <span>Los valores de las gráficas de barras están expresados en horas</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="loading">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div class="loader">
                                <i class="fa-spin fas fa-spinner"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>