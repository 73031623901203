import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-chatbot-detalle-screen',
  templateUrl: './chatbot-detalle-screen.component.html',
  styleUrls: ['./chatbot-detalle-screen.component.scss']
})
export class ChatbotDetalleScreenComponent implements OnInit {

  public id_chatbot:number;
  public chatbots:any = [];
  constructor(public router:Router, public route:ActivatedRoute, public facade:FacadeService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id_chatbot = params['id_chatbot'];
    })
  }


  irListado(){
    this.router.navigate(['cliente/chat']);
  }
}
 