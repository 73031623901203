<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Correo electrónico encontrado</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 class="black-text">Se ha enviado un correo a {{data.email}} para cambiar la contraseña.</h6>
            </div>
        </div>

        
    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button 
                    (click)="closeDialog()"
                    mat-raised-button 
                    color="primary" >
                        OK
                    </button>
        </div>
    </div>
</div>