import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss']
})
export class MainScreenComponent implements OnInit {
  public token_encuesta:string;
  public consumidor:any;
  public tipo_acivacion:any = null;
  public atributos:any = {
    primary_color:"",
    secondary_color:"",
    titulo:"",
    logo:{
      valor:"",
      file_name:""
    }
  };
  public loading:boolean=true;

  constructor(private facade:FacadeService,public router:Router,public route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token_encuesta = params['token_encuesta'];
      if (this.token_encuesta){
        this.loading = true
        this.facade.obtenerCamposConsumidor(this.token_encuesta)
        .subscribe(
          (reponse)=>{
            console.log(reponse)
            this.loading = false
            this.consumidor = reponse.consumidor
            if(reponse.atributos_encuesta_json){
              this.atributos = JSON.parse(reponse.atributos_encuesta_json)
            }
            if(reponse.tipo_acivacion){
              this.tipo_acivacion = reponse.tipo_acivacion
            }
          },
          (error)=>{
            console.log(error)
            if(error.status == 404){
              alert("Url invalida, por favor comunicate con tu asesor.")
            }else{
              alert(error.statusText)
            }
            this.loading = false
          }
        )
      }else{
        this.facade.obtenerCamposEncuestaVideototem(params['id_marca'])
        .subscribe(
          (response)=>{
            this.loading = false
            this.consumidor = {qr_entregado:false, campos_dinamicos_json:response.consumidores_crm.formulario_campos_json, id_marca:response.consumidores_crm.id}
            // if(response.consumidores_crm.atributos_encuesta_json){
            //   this.atributos = JSON.parse(response.consumidores_crm.atributos_encuesta_json)
            // }
            this.atributos.logo.valor = response?.consumidores_crm?.logo;
            this.atributos.primary_color = "#272F6B";
            this.atributos.secondary_color = "#62A1D2";
          },(error)=>{
            console.error(error)
          }
        )
        // alert("Nueva encuesta")
      }
    })
  }
  consumidorActualizado(data){
    console.log(data)
    this.consumidor = data
  }

}
