import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const session_cookie_name = 'whatsoporte-web-token';
const user_id_cookie_name = 'whatsoporte-web.user_id';
const first_name_cookie_name = 'whatsoporte-web-first_name';
const group_name_cookie_name = 'whatsoporte-web-group_name';
const client_id_cookie_name = 'whatsoporte-web-client_id';
const second_client_id_cookie_name = 'whatsoporte-web-second_client_id';
const second_client_permissions = 'whatsoporte-web-second_client_permissions';


@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  constructor(private http: HttpClient,
                public router: Router,
                private cookieService: CookieService) {  }

  login(username, password): Observable<any> {
    var data={
      username: username,
      password: password
    }
    return this.http.post<any>(`${environment.url_api}/token/`,data);
  }

  recoverPass(email): Observable<any> {
    var data={
      email: email,
    }
    return this.http.post<any>(`${environment.url_api}/contrasena/`,data);
  }

  logout(): Observable<any> {
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/logout/`, {headers: headers});
  }

  cerrarSesionEnOtroLugar(username, password): Observable<any> {
    var data={
      username: username,
      password: password
    }
    return this.http.post<any>(`${environment.url_api}/cerrar-sesiones`,data);
  }


  recuperarInfoUsuarioFirmado(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/me`,{headers:headers});
  }

  getSessionToken(){
    return this.cookieService.get(session_cookie_name);
  }

  saveUserData(user_data){
    var secure = environment.url_api.indexOf("https")!=-1;
    this.cookieService.set(user_id_cookie_name, user_data.id, undefined, undefined, undefined, secure, secure?"None":"Lax");
    this.cookieService.set(session_cookie_name, user_data.token, undefined, undefined, undefined, secure, secure?"None":"Lax");
    this.cookieService.set(first_name_cookie_name, user_data.last_name, undefined, undefined, undefined, secure, secure?"None":"Lax");
    this.cookieService.set(group_name_cookie_name, user_data.roles, undefined, undefined, undefined, secure, secure?"None":"Lax");
    this.cookieService.set(client_id_cookie_name, user_data.cliente_id, undefined, undefined, undefined, secure, secure?"None":"Lax");
    this.cookieService.set(second_client_id_cookie_name, user_data.cliente_segundo_id, undefined, undefined, undefined, secure, secure?"None":"Lax");
    if (user_data?.permisos){
      let permisos = JSON.stringify(user_data?.permisos);
      this.cookieService.set(second_client_permissions, permisos, undefined, undefined, undefined, secure, secure?"None":"Lax");
    }

  }

  getUserFirstName(){
    return this.cookieService.get(first_name_cookie_name);
  }

  getUserId(){
    return this.cookieService.get(user_id_cookie_name);
  }

  getUserGroup(){
    return this.cookieService.get(group_name_cookie_name);
  }

  getUserClientID(){
    return this.cookieService.get(client_id_cookie_name);
  }

  getSecondClientID(){
    return this.cookieService.get(second_client_id_cookie_name);
  }

  setSecondClientPermissions(user){
    let secure = environment.url_api.indexOf("https")!=-1;
    if (user?.permisos){
      let permisos = JSON.stringify(user?.permisos);
      this.cookieService.set(second_client_permissions, permisos, undefined, undefined, undefined, secure, secure?"None":"Lax");
    }
  }

  getSecondPermissions(){
    let permissions = this.cookieService.get(second_client_permissions);
    if (permissions){
      return JSON.parse(permissions);
    } else {
      return null;
    }
  }

  destroySessionToken(){
    this.cookieService.deleteAll();
  }

  listarMarcas(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/agentes/marcas`,{headers:headers});
  }

  changechatbotflag(data){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.put<any>(`${environment.url_api}/chatbots/0`,data,{headers:headers});
  }

  listarConsumidores(id_marca,fecha_ultimo_request, page){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/soporte/marca/`+id_marca+`/consumidores?time=${fecha_ultimo_request}&p=${page}`,{headers:headers});
  }

  recuperarChat(id_consumidor,fecha_ultimo_request){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/soporte/consumidor/`+id_consumidor+`/chat?time=${fecha_ultimo_request}`,{headers:headers});
  }

  enviarMensaje(id_consumidor, mensaje){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = {
      mensaje: mensaje
    }
    return this.http.post<any>(`${environment.url_api}/soporte/mensaje/`+id_consumidor,data,{headers:headers});

  }

  subirArchivoEnConversacion(id_consumidor, archivo){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();
    data.append('archivo',archivo.input_file);

    return this.http.post<any>(`${environment.url_api}/soporte/mensaje/archivo/`+id_consumidor,data,{headers:headers});

  }

  /** Clientes */

  listarClientes(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/clientes`,{headers:headers});
  }

  listarClientesSegundos(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/clientes/admin`,{headers:headers});
  }

  recuperarCliente(id){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/clientes/`+id,{headers:headers});
  }

  comprobarPermisoEnClienteSegundo(key : string){
    let permisos = this.getSecondPermissions();
    if (permisos){
      return permisos[key]?.acceso ?? false;
    }
    return true;
  }

  comprobarPermisoDeEdicion(key : string){
    if (this.getUserGroup().includes('cliente-segundo')){
      let permisos = this.getSecondPermissions();
      if (permisos){
        return permisos[key]?.edicion ?? false;
      }
    }
    return true;
  }

  recuperarClienteSegundo(id){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/clientes/admin/`+id,{headers:headers});
  }

  guardarCliente(id_cliente,nombre_cliente, razon_social, id_giro_comercial,maximo_agentes, maximo_clientes, permite_videosoporte, max_puntos_soporte, expirar_sesiones,logo, username, password){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();
    if(id_cliente && id_cliente!=0){
      data.append('id_cliente',id_cliente);
    }
    data.append('nombre_cliente',nombre_cliente);
    data.append('razon_social',razon_social);
    if(id_giro_comercial && id_giro_comercial!=0){
      data.append('id_giro_comercial',id_giro_comercial);
    }
    data.append('maximo_agentes',maximo_agentes);
    data.append('maximo_clientes',maximo_clientes);
    data.append('permite_videosoporte',permite_videosoporte);
    data.append('max_puntos_soporte',max_puntos_soporte);
    data.append('expirar_sesiones',expirar_sesiones);
    if(logo && logo.input_file){
      data.append('logo',logo.input_file);
    }
    data.append('username',username);
    data.append('password',password);

    return this.http.post<any>(`${environment.url_api}/clientes`,data,{headers:headers});

  }

  guardarClienteSegundo(id_cliente,nombre_cliente, username, password, permisos){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();
    if(id_cliente && id_cliente!=0){
      data.append('id_cliente',id_cliente);
    }
    data.append('nombre_cliente',nombre_cliente);
    data.append('username',username);
    data.append('password',password);
    data.append('permisos',JSON.stringify(permisos));

    return this.http.post<any>(`${environment.url_api}/clientes/admin`,data,{headers:headers});

  }

  eliminarCliente(cliente_id){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/clientes/${cliente_id}`,{headers:headers});

  }

  eliminarClienteSegundo(cliente_id){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/clientes/admin/${cliente_id}`,{headers:headers});

  }

  guardarMarcaCliente(id_cliente,
                      id_marca,
                      nombre,
                      logo,
                      plataforma_whatsapp,
                      chat_api_url,
                      chat_api_token,
                      twilio_celular,
                      twilio_sid,
                      twilio_token,
                      maximo_agentes,
                      tipo_crm,
                      api_key_hobspot,
                      horario_inicio,
                      horario_fin,
                      tiempo_universal_coordinado,
                      etiquetas_conversacion,
                      maximo_participaciones){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();

    if(id_marca && id_marca!=0){
      data.append('id',id_marca);
    }
    data.append('nombre',nombre);
    if(logo && logo.input_file){
      data.append('logo',logo.input_file);
    }
    if(plataforma_whatsapp)
      data.append('plataforma_whatsapp',plataforma_whatsapp);

    if(chat_api_url)
      data.append('chat_api_url',chat_api_url);

    if(chat_api_token)
      data.append('chat_api_token',chat_api_token);

    if(twilio_celular)
      data.append('twilio_celular',twilio_celular);

    if(twilio_sid)
      data.append('twilio_sid',twilio_sid);

    if(twilio_token)
      data.append('twilio_token',twilio_token);

    if(maximo_agentes)
      data.append('maximo_agentes',maximo_agentes);

    if(tipo_crm)
      data.append('tipo_crm',tipo_crm);

    if(api_key_hobspot)
      data.append('api_key_hobspot',api_key_hobspot);

    if(maximo_participaciones)
      data.append('maximo_participaciones',maximo_participaciones);

    if(horario_inicio!=undefined)
      data.append("horario_inicio", horario_inicio.replace(':',''));

    if(horario_fin!=undefined)
      data.append("horario_fin", horario_fin.replace(':',''));

    if(!isNaN(parseInt(tiempo_universal_coordinado))){
      data.append("tiempo_universal_coordinado", tiempo_universal_coordinado);
    }
    else {
      data.append("tiempo_universal_coordinado", "0");
    }
    data.append("etiquetas_conversacion",JSON.stringify(etiquetas_conversacion));

    return this.http.post<any>(`${environment.url_api}/clientes/${id_cliente}/marcas`,data,{headers:headers});
  }

  activarMarca(id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/marcas/${id_marca}/activar`,{},{headers:headers});
  }

  inactivarMarca(id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/marcas/${id_marca}/inactivar`,{},{headers:headers});
  }

  /*como cliente cerrar sesión del agente*/
  cerrarSesionDeAgente(id_agente){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/agentes/${id_agente}/cerrar-sesion`,{},{headers:headers});
  }
  /*como cliente desactivar/activar agente*/
  desactivarAgente(id_agente, accion){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = {
      activar: accion
    }
    return this.http.post<any>(`${environment.url_api}/agentes/`+id_agente,data,{headers:headers});
  }

  buscarConsumidorCliente(id_agente,id_marca,busqueda){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/cliente/marca/${id_marca}/agente/${id_agente}/consumidores/buscar/${busqueda}`,{headers:headers});
  }

  /* Campos dinamicos para datos de consumidor por marca*/
  guardarCampos(id_marca, consumidores_campos_json,tipo){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = {
      consumidores_campos_json: consumidores_campos_json
    }
    return this.http.post<any>(`${environment.url_api}/clientes/marcas/${id_marca}/campos-${tipo}`,data,{headers:headers});
  }

  /* Metricas Dashboard y agente */
  metricasDashboard(id_marca,fecha_inicial,fecha_final,id_agente){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var query_params = ""
    if(id_marca != 0){
      query_params += "id_marca="+id_marca+"&"
    }
    if(fecha_inicial != ""){
      query_params += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      query_params += "fecha_final="+fecha_final+"&"
    }
    if(id_agente != 0){
      query_params += "id_agente="+id_agente+"&"
    }

    return this.http.get<any>(`${environment.url_api}/dashboard/?${query_params}`,{headers:headers});
  }
  /* Metricas Dashboard y agente */
  metricasTiemposDashboard(id_marca,fecha_inicial,fecha_final,id_agente){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var query_params = ""
    if(id_marca != 0){
      query_params += "id_marca="+id_marca+"&"
    }
    if(fecha_inicial != ""){
      query_params += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      query_params += "fecha_final="+fecha_final+"&"
    }
    if(id_agente != 0){
      query_params += "id_agente="+id_agente+"&"
    }
    return this.http.get<any>(`${environment.url_api}/dashboard/tiempos?${query_params}`,{headers:headers});
  }

  listarConsumidoresDeUnAgente(id_marca,id_agente,page){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/cliente/marca/${id_marca}/agente/${id_agente}/consumidores?p=${page}`,{headers:headers});
  }

  recuperarChatDeUnAgente(id_consumidor,es_pdf){
    var token = this.getSessionToken();

    let  httpOptions = {
      responseType: 'json' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+token
      })
    };
    if (es_pdf) {
      httpOptions = {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer '+token
        })
      };
    }
    return this.http.get<any>(`${environment.url_api}/cliente/consumidor/${id_consumidor}/chat?pdf=${es_pdf}`,httpOptions);
  }

  crearChatbots(id_marca,data){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/marcas/${id_marca}/chatbots`,data,{headers:headers});
  }

  recuperarChatbot(id_chatbot){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/chatbots/${id_chatbot}`,{headers:headers});
  }

  borrarChatbot(id_chatbot){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/chatbots/${id_chatbot}`,{headers:headers});
  }

  listarChatbots(id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/marcas/${id_marca}/chatbots`,{headers:headers});
  }

  listarFormularios(id_marca, params){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    let queryParams = "?";
    Object.keys(params).forEach(
      (key) => {
        queryParams += key + "=" + params[key] + "&"
      }
    );
    return this.http.get<any>(`${environment.url_api}/marca/formularios/${id_marca}${queryParams}`,{headers:headers});
  }

  obtenerReporteFormularios(id_marca, params){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    let queryParams = "?";
    Object.keys(params).forEach(
      (key) => {
        queryParams += key + "=" + params[key] + "&"
      }
    );
    return this.http.get<any>(`${environment.url_api}/marca/formularios/${id_marca}/reporte${queryParams}`,{headers:headers});
  }

  obtenerReporteVideototemFormularios(id_marca, fecha_inicial, fecha_final){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var date_param = "?"
    if(fecha_inicial != ""){
      date_param += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      date_param += "fecha_final="+fecha_final+"&"
    }
    return this.http.get<any>(`${environment.url_api}/marca/videototem-crm/${id_marca}/reporte${date_param}`,{headers:headers});
  }

  obtenerFormulario(id : number){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/formularios/${id}`,{headers:headers});
  }

  actualizarFormulario(id : number, data : any){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.post<any>(`${environment.url_api}/formularios/${id}`, data, {headers:headers});
  }

  obtenerConsumidores(id_marca,fecha_inicial,fecha_final){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var date_param = "?"
    if(fecha_inicial != ""){
      date_param += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      date_param += "fecha_final="+fecha_final+"&"
    }
    return this.http.get<any>(`${environment.url_api}/dashboard/consumidores-crm/marca/${id_marca}${date_param}`,{headers:headers});
  }

  obtenerEncuestasApp(id_marca,fecha_inicial,fecha_final){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var date_param = "?"
    if(fecha_inicial != ""){
      date_param += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      date_param += "fecha_final="+fecha_final+"&"
    }
    return this.http.get<any>(`${environment.url_api}/dashboard/encuestas-app/marca/${id_marca}${date_param}`,{headers:headers});
  }


  /** Agentes */

  listarMarcasClienteActual(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/clientes/me/marcas`,{headers:headers});
  }

  listarAgentes(pagina, criteria, id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var pagina_param = "?";
    if(pagina != undefined && pagina>=-1){
      pagina_param += "p="+pagina;
    }
    var params = "";
    if(criteria && criteria!=""){
      params = "&q="+criteria;
    }
    if(id_marca && id_marca!==0){
      params += "&id_marca="+id_marca;
    }
    return this.http.get<any>(`${environment.url_api}/agentes${pagina_param}${params}`,{headers:headers});
  }

  guardarAgente(agente, marcas){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    //Armar marcas

    if(marcas && marcas.length>0){
      agente.marcas = marcas;
    }
    return this.http.post<any>(`${environment.url_api}/agentes`,agente,{headers:headers});

  }

  recuperarAgente(id_agente){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/agentes/${id_agente}`,{headers:headers});
  }

  listarAgentesMaestroEnMarcasPorCliente(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/agentes/maestro`,{headers:headers});
  }

  recuperarClienteAlQuePerteneceAgente(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/agentes/cliente`,{headers:headers});
  }

  listarAgentesATransferir(id_marca){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/marca/${id_marca}/agentes`,{headers:headers});
  }

  listarConsumidoresCRM(){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/consumidores-crm`,{headers:headers});
  }

  borrarConcumidorCRM(id_consumidor_crm){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/consumidor-crm/${id_consumidor_crm}`,{headers:headers});
  }

  transferirChat(id_consumidor,id_agente){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/soporte/reasignar-consumidor/${id_consumidor}/agente/${id_agente}`,{},{headers:headers});
  }

  agregarConsumidor(consumidor_data){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/consumidores-crm`,consumidor_data,{headers:headers});
  }

  buscarConsumidor(id_marca,busqueda){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/soporte/marca/${id_marca}/consumidores/buscar/${busqueda}`,{headers:headers});
  }

  crearContacto(consumidor_data){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.post<any>(`${environment.url_api}/soporte/contacto`,consumidor_data,{headers:headers});
  }


  buscarMensajes(pagina, criteria, id_marca, fecha_inicial, fecha_final, remitente){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var pagina_param = "?";
    if(pagina > 0){
      pagina_param += "pagina="+pagina;
    }
    var params = "";
    if(criteria){
      params += "&q="+criteria;
    }
    if(fecha_inicial!=""){
      params += "&fecha_inicial="+fecha_inicial;
    }
    if(fecha_final!=""){
      params += "&fecha_final="+fecha_final;
    }
    if(id_marca!==0){
      params += "&id_marca="+id_marca;
    }
    if(remitente!==""){
      params += "&remitente="+remitente;
    }
    return this.http.get<any>(`${environment.url_api}/conversaciones${pagina_param}${params}`,{headers:headers});
  }

  buscarCRMConsumidor(celular){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/consumidor-crm/buscar/${celular}`,{headers:headers});
  }
  subirArchivoCampoDinamico(id_marca,telefono,archivo){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var data = new FormData();
    data.append('marca_id',id_marca);
    data.append('telefono',telefono);
    data.append('archivo',archivo.input_file);
    return this.http.post<any>(`${environment.url_api}/soporte/archivo`,data,{headers:headers});
  }

  subirArchivoEncuestaLogo(id_marca,archivo){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var data = new FormData();
    data.append('marca_id',id_marca);
    data.append('archivo',archivo.input_file);
    return this.http.post<any>(`${environment.url_api}/cliente/encuesta/archivo/logo`,data,{headers:headers});
  }

  guardarAtributosEncuesta(id_marca,atributos,tipo){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var data = {
      atributos_encuesta_json : atributos
    }
    return this.http.post<any>(`${environment.url_api}/clientes/encuesta/marca/${id_marca}/${tipo}`,data,{headers:headers});
  }

  /** Catalogos */

  listarCatalogoGirosComerciales(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/catalogos/giro_comercial`,{headers:headers});
  }

  listarCatalogoIdiomas(){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/catalogos/idiomas`,{headers:headers});

  }

  //Obtiene un agora token para iniciar videollamada en un canal específico, como host
  obtenerTokenHost(channel,uid){
    return this.http.get<any>(`${environment.url_api}/videosoporte/${channel}/${uid}`);
  }

  reportarEstatusAsesor(slug, estatus){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = {
      "estatus_asesor": estatus
    }
    return this.http.put<any>(`${environment.url_api}/videosoporte/estatus/${slug}/asesor`,data,{headers:headers});
  }

  reportarEstatusTotem(slug, estatus){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({});

    var data = {
      "estatus_totem": estatus,
      "es_totem" : true
    }
    return this.http.put<any>(`${environment.url_api}/videosoporte/estatus/${slug}/totem`,data,{headers:headers});
  }

  recuperarPuntosSoporteCliente(pagina, criteria, estatus_totem, id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var pagina_param = "?";
    if(pagina != undefined && pagina>=0){
      pagina_param += "p="+pagina;
    }
    var params = "";
    if(criteria && criteria!=""){
      params += "&q="+criteria;
    }
    if(estatus_totem!=""){
      params += "&estatus_totem="+estatus_totem;
    }
    if(id_marca!==0){
      params += "&id_marca="+id_marca;
    }

    return this.http.get<any>(`${environment.url_api}/clientes/me/videosoporte/puntos${pagina_param}${params}`,{headers:headers});
  }

  recuperarPuntoSoporteDetalle(puntosoporte_id, params = null){
    let queryParams = "";
    if (params) {
      queryParams = this.getQueryParams(params);
    }
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/clientes/me/videosoporte/puntos/${puntosoporte_id}${queryParams}`,{headers:headers});
  }

  recuperarPuntoSoporteDetallePorSlug(slug){
    return this.http.get<any>(`${environment.url_api}/videosoporte/puntos/slug/${slug}`);
  }

  guardarPuntoSoporte(punto_soporte, videos_ids){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    if(videos_ids){
      punto_soporte.videos_ids = videos_ids;
    }

    return this.http.post<any>(`${environment.url_api}/clientes/me/videosoporte/puntos`,punto_soporte,{headers:headers});
  }

  recuperarPuntosSoporteAgente(){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/agentes/videosoporte/puntos`,{headers:headers});
  }

  eliminarPuntoSoporte(id_punto_soporte){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/clientes/me/videosoporte/puntos/${id_punto_soporte}`,{headers:headers});
  }

  obtenerMetricasVideosoporte(params : any){
    let queryParams = this.getQueryParams(params);
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.get<any>(`${environment.url_api}/videosoporte/metricas${queryParams}`,{headers:headers});
  }

  getQueryParams(params : any): string{
    let queryParams = "?";
    Object.keys(params).forEach(
      (key) => {
        queryParams += key + "=" + params[key] + "&"
      }
    );
    return queryParams;
  }

  loadVideosMarca(id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/marcas/${id_marca}/videos`,{headers:headers});
  }

  subirVideoMarca(id_marca, file){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();
    data.append('video',file);

    return this.http.post<any>(`${environment.url_api}/marcas/${id_marca}/videos`,data,{headers:headers});
  }

  subirFondoVirtual(id_marca, file){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = new FormData();
    data.append('fondo_virtual',file);

    return this.http.post<any>(`${environment.url_api}/marcas/${id_marca}/fondos_virtuales`,data,{headers:headers});
  }

  eliminarFondoVirtual(id_marca){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.delete<any>(`${environment.url_api}/marcas/${id_marca}/fondos_virtuales`,{headers:headers});
  }

  eliminarVideo(id_video){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.delete<any>(`${environment.url_api}/videos/${id_video}`,{headers:headers});
  }

  metricaIndiceSatisfaccion(id_marca, fecha_inicial, fecha_final){
    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    var id_marca_param = "";
    if(id_marca){
      id_marca_param="id_marca="+id_marca+"&";
    }
    var date_param = "";
    if(fecha_inicial != ""){
      date_param += "fecha_inicial="+fecha_inicial+"&"
    }
    if(fecha_final != ""){
      date_param += "fecha_final="+fecha_final+"&"
    }
    return this.http.get<any>(`${environment.url_api}/dashboard/satisfaccion?${id_marca_param}${date_param}`,{headers:headers});
  }

  actualizarEstatusCliente(cliente_id, nuevo_status){

    var headers = undefined;
    var token = this.getSessionToken();
    headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    var data = {
      cliente_activo: nuevo_status
    };
    return this.http.put<any>(`${environment.url_api}/clientes/${cliente_id}/estatus`,data,{headers:headers});

  }

  listarConsumidoresCliente(criteria, id_marca, pagina){
    let token = this.getSessionToken();
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    let query_params = "?"
    if (criteria!==""){
      query_params+="q="+criteria
    }
    if (pagina !== 0){
      query_params+="&pagina="+pagina
    }
    if(id_marca!==0){
      query_params += "&id_marca="+id_marca;
    }
    return this.http.get<any>(`${environment.url_api}/cliente/consumidor${query_params}`,{headers:headers});
  }

  eliminarConsumidor(id_consumidor){
    let token = this.getSessionToken();
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});

    return this.http.delete<any>(`${environment.url_api}/cliente/consumidor/${id_consumidor}`,{headers:headers});
  }

  establecerEtiquetaAConsumidor(id_consumidor,id_etiqueta){
    let token = this.getSessionToken();
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    let data = {
      id_consumidor,
      id_etiqueta
    }
    return this.http.post<any>(`${environment.url_api}/soporte/consumidor/etiqueta`,data,{headers:headers});
  }


  ping(){
    return this.http.get<any>(`${environment.url_api}/videosoporte/ping`);
  }
  /*Encuesta*/
  obtenerCamposConsumidor(token_encuesta){
    return this.http.get<any>(`${environment.url_api}/encuesta/consumidor-crm/${token_encuesta}`);
  }
  guardarDatosConsumidor(token_encuesta,data){
    return this.http.post<any>(`${environment.url_api}/encuesta/consumidor-crm/${token_encuesta}`,data);
  }

  obtenerCamposEncuestaVideototem(id_marca){
    return this.http.get<any>(`${environment.url_api}/videototem-crm/${id_marca}`);
  }
  guardarDatosEncuestaVideototem(data, id_marca){
    let token = this.getSessionToken();
    console.log(data)
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.post<any>(`${environment.url_api}/videototem-crm/${id_marca}`,data, {headers:headers});
  }

  subirArchivoCampoDinamicoConsumidor(id_marca,telefono,archivo,token_encuesta){
    var data = new FormData();
    data.append('marca_id',id_marca);
    data.append('telefono',telefono);
    data.append('archivo',archivo.input_file);
    data.append('token_encuesta',token_encuesta);
    return this.http.post<any>(`${environment.url_api}/encuesta/archivo`,data);
  }

  /***********SERVICIOS QR (API EXTERNA)*********/
  obtenerQR(project){
    var data = {
      id_usuario:"17", //
      type:"A", //Solo en project GANABOX
      project:project,
      token:"e9ee92e19a98958fba962748b1b388a6"
    }
    return this.http.post<any>(`${environment.url_qr_api}/codes/`,data);
  }

  obtenerCantidadArchivosALimpiar(marca){
    let token = this.getSessionToken();
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.get<any>(`${environment.url_api}/marcas/${marca}/limpieza`, {headers:headers});
  }

  limpiarMarca(marca, form){
    let token = this.getSessionToken();
    let headers = new HttpHeaders({'Authorization': 'Bearer '+token});
    return this.http.post<any>(`${environment.url_api}/marcas/${marca}/limpieza`, form, {headers:headers});
  }
}
