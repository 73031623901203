import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material';
import { FacadeService } from 'src/app/services/facade.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-formularios-screen',
  templateUrl: './formularios-screen.component.html',
  styleUrls: ['./formularios-screen.component.scss']
})
export class FormulariosScreenComponent implements OnInit {
  public formularios : any[] = [];
  public id_marca:number=null;
  public marcas:any=[];
  public criteria : string = "";
  public remitente : string = "";
  public pagina_actual : number = 1;
  public ultima_pagina : number = 1;
  public paginas : number[];
  public loading : boolean = false;
  public subscriber : any;
  esEditor: any;

  constructor(
    public facade:FacadeService,
    public router:Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_formularios");
    this.loadMarcas();
    this.route.queryParams
      .subscribe((params) => {
        if(params.pagina){
          this.pagina_actual = params.pagina;
        }
        if(params.criteria){
          this.criteria = params.criteria;
        }
        if(params.id_marca){
          this.id_marca = parseInt(params.id_marca);
        }
        this.listarFormularios();
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.loading = false;
        this.marcas = response;
      },
      (error)=>{
        console.log(error);
        this.loading = false;
      }
    ) 
  }
  
  listarFormularios(){
    this.loading = true;
    if (this.subscriber){
      this.subscriber.unsubscribe();
    }
    this.subscriber = this.facade.listarFormularios(
      this.id_marca,
      {
        p : this.pagina_actual,
        maximo_aceptados : 3,
        criteria : this.criteria
      }
    ).subscribe(
      (response) => {
        this.formularios = response.data;
        this.paginas = [];
        this.pagina_actual=response.current_page;
        let numero_de_pagina = 1;
        this.ultima_pagina = response.num_pages;
        if (this.ultima_pagina<=10){
          while(numero_de_pagina<=response.ultima_pagina){
            this.paginas.push(numero_de_pagina);
            numero_de_pagina++;
          }
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error)
      }
    );
  }

  limpiarBusqueda(){
    this.formularios = [];
    this.pagina_actual = 1;
    this.paginas = [];
    this.criteria = "";
    this.aplicarFiltro();
  }

  aplicarFiltro() {
    this.actualizarUrl();
  }


  esFiltro(){
    if (this.id_marca !==0){
      return true;
    }
    else{
      return false;
    }
  }

  limpiarFiltro(){
    this.id_marca=0;
    this.remitente = "";
    this.aplicarFiltro();
  }

  cargarPagina(pagina : number){
    this.pagina_actual = pagina;
    this.aplicarFiltro();
  }

  actualizarUrl(){
    let queryParams = { 
      pagina : this.pagina_actual
    };
    if(this.criteria!==""){
      queryParams["criteria"] = this.criteria;
    }
    if(this.id_marca!==0){
      queryParams["id_marca"] = this.id_marca;
    }
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: queryParams
      }
    );
  }

  verDetallesFormulario(id){
    if (!this.esEditor){
      return;
    }
    this.router.navigate(["cliente/formularios/"+id], {
      state : {
        pagina :  this.pagina_actual,
        id_marca : this.id_marca
      }
    });
  }
}
