import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-detalle-perfil',
  templateUrl: './detalle-perfil.component.html',
  styleUrls: ['./detalle-perfil.component.scss']
})
export class DetallePerfilComponent implements OnInit {

  constructor(public router:Router, public route:ActivatedRoute, public facade:FacadeService) { }

  ngOnInit(): void {
  }
  irPerfil(){
    this.router.navigate(['cliente/perfil']);
  }
}
