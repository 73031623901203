import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-menu-cliente',
  templateUrl: './menu-cliente.component.html',
  styleUrls: ['./menu-cliente.component.scss']
})
export class MenuClienteComponent implements OnInit {

  public current_view:string;
  public cliente:any;
  constructor(public router:Router, public facade:FacadeService) { }

  ngOnInit() {
    this.loadCliente();
  }

  loadCliente(){
    this.facade.recuperarInfoUsuarioFirmado()
    .subscribe(
      (response)=>{
        if (this.facade.getUserGroup().includes('cliente')){
          this.cliente = response.cliente;
          this.setupMenu();   
        }
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  comprobarAcceso(key : string){
    if (this.facade.getUserGroup().includes('cliente-segundo')){
      return this.facade.comprobarPermisoEnClienteSegundo(key);
    }
    return true;
  }

  setupMenu(){
    if(this.router.url.indexOf("cliente/agente")!==-1){
      this.current_view = 'agentes';
    }else if(this.router.url.indexOf("cliente/videosoporte")!==-1){
      this.current_view = 'videosoporte';
    }else if(this.router.url.indexOf("cliente/consumidores")!==-1){
      this.current_view = 'consumidores';
    }else if(this.router.url.indexOf("cliente/conversaciones")!==-1){
      this.current_view = 'conversaciones';
    }else if(this.router.url.indexOf("cliente/perfil")!==-1){
      this.current_view = 'perfil';
    }else if(this.router.url.indexOf("cliente/home")!==-1){
      this.current_view = 'home';
    }else if(this.router.url.indexOf("cliente/chat")!==-1){
      this.current_view = 'chat';
    }else if(this.router.url.indexOf("cliente/admin")!==-1){
      this.current_view = 'admin';
    }
  }

  navegar(ruta){
    this.router.navigate([ruta]);
  }

}
