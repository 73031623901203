import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';

export interface DialogData {
  id_punto_videosoporte: number,
}

@Component({
  selector: 'app-borrar-videosoporte-dialog',
  templateUrl: './borrar-videosoporte-dialog.component.html',
  styleUrls: ['./borrar-videosoporte-dialog.component.scss']
})

export class BorrarVideosoporteDialogComponent implements OnInit {

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<BorrarVideosoporteDialogComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData 
  ) { 
    dialogRef.disableClose = false;
  }

  public loading: boolean = false

  ngOnInit(): void {
  }

  eliminarPunto(){
    this.loading = true;
    this.facade.eliminarPuntoSoporte(this.data.id_punto_videosoporte).subscribe(
      (response) => {
        this.dialogRef.close();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    )
  }

}
