<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Configuración de chatbot</h2>
                        </div>
                    </div>
                    <div class="loader">
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field class="d-flex justify-content-start" style="width: 100%;" appearance="outline">
                                <mat-label>Selecciona marca</mat-label>
                                <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
                                  <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                    {{marca.nombre}} 
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div style="position: relative; min-height: 61vh;" *ngIf="(chatbots && chatbots.length>0) && !loading">
                        
                        <table class="ws-table" style="width: 100%;">
                            <tr>
                                <th>
                                    Nombre del Chatbot
                                </th>
                                <th>
                                    Marca
                                </th>
                                 <th>
                                    Periodos de actividad
                                </th>
                                <th>
                                    Fecha de creación
                                </th>
                                <th>&nbsp;</th>
                            </tr>
                            <tr *ngFor="let c of chatbots" (click)="irDetalleChatbot(c.id)" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}" [style.opacity]="c.activo ? '1' : '0.3'">
                                <td>
                                    {{c.mensaje}}
                                </td>
                                <td>
                                    {{c.marca_nombre}}
                                </td>
                                <td>
                                   <div *ngIf="c.horario">
                                    <span *ngIf="c.horario.primer_periodo_hora_inicio!==null && c.horario.primer_periodo_hora_fin!==null"> 
                                        [ {{c.horario.primer_periodo_hora_inicio}} - {{c.horario.primer_periodo_hora_fin}} ]
                                     </span>
                                     <span *ngIf="c.horario.segundo_periodo_hora_inicio!==null && c.horario.segundo_periodo_hora_fin!==null"> 
                                        [ {{c.horario.segundo_periodo_hora_inicio}} - {{c.horario.segundo_periodo_hora_fin}} ]
                                     </span>
                                     <span *ngIf="c.horario.primer_periodo_hora_inicio===null && c.horario.primer_periodo_hora_fin===null && c.horario.segundo_periodo_hora_inicio===null && c.horario.segundo_periodo_hora_fin===null"> 
                                         Siempre disponible
                                      </span>
                                     <span>
                                         UTC {{c.horario.tiempo_universal_coordinado}}
                                     </span>
                                   </div>
                                </td>
                                <td>
                                    {{c.creacion | date}}
                                </td>
                                <td>
                                    <span class="color-primary">
                                        <i class="fas fa-chevron-right"></i>
                                    </span>
                                </td>
                            </tr>
                        </table>
                        <div class="add-fab" *ngIf="esEditor">
                            <button (click)="AgregarChatbot()" mat-fab color="primary" aria-label="Agregar un nuevo cliente">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!loading && chatbots && chatbots.length==0" class="row" style="text-align:center;height: 60vh;">
                        <div class="col-12 v-center">
                            <h4>No hay chatbot para mostrar</h4>
                            <h4>Presiona el botón para agregar al primer chatbot</h4>
                            <div (click)="AgregarChatbot()" class="add-button">
                                <i class="fas fa-plus-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>