import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { FacadeService } from 'src/app/services/facade.service';

declare var $;

@Component({
  selector: 'app-videosoporte-detalle-screen',
  templateUrl: './videosoporte-detalle-screen.component.html',
  styleUrls: ['./videosoporte-detalle-screen.component.scss']
})
export class VideosoporteDetalleScreenComponent implements OnInit {
  public agentesForm:FormGroup;

  public loading:boolean=false;
  public id_punto_soporte:number;
  public punto_soporte:any={};
  public marcas:any=[];
  public agentes:any=[];
  public videos_marca:any=[];
  public marca_seleccionada_id:number;
  public agente_seleccionado_id:number;
  public videos_asignados:any=[];
  public pagina_de_origen:number = 0;
  public criteria:string = "";
  public estatus_totem:string = "";
  public id_marca:number = 0;
  public fecha_inicio:any = "";
  public fecha_fin:any = "";
  public maxDate:any = new Date();
  public tiemposPorEstatus : any[] = [];

  public etiquetasEstatus : any = {
    en_espera : "En espera",
    en_llamada : "En llamada",
    offline : "Offline",
    apagado : "Apagado"
  };
  public tipoGraficaDePastel : ChartType = ChartType.PieChart;

  public opcionesGraficaDePastel = {
    backgroundColor: 'transparent',
    legend :{
      textStyle:{color: 'black'},
      position : "bottom"
    },
    slices : {
      0 : { color : '#2962FF'},
      1 : { color : '#D50000'},
      2 : { color : '#455A64'},
      3 : { color : '#33691E'}
    },
    width: "100%",
    chartArea: {
      width: '100%',
    }
  };

  constructor(public facade:FacadeService,
              public route:ActivatedRoute,
              private fb:FormBuilder,
              public router:Router) { }

  ngOnInit(): void {
    this.agentesForm = this.fb.group({
      listaAgentes: this.fb.array([this.fb.group({value:'', extra: false})])
    });


    const today = new Date();
    this.fecha_inicio = new Date(today.setDate(today.getDate() - 7));
    this.fecha_fin = new Date();
    if(history.state.pagina_actual){
      this.pagina_de_origen = history.state.pagina_actual
    }
    if(history.state.criteria){
      this.criteria = history.state.criteria
    }
    if(history.state.estatus_totem){
      this.estatus_totem = history.state.estatus_totem
    }
    if(history.state.id_marca){
      this.id_marca = history.state.id_marca
    }
    this.route.params.subscribe(params => {
      this.id_punto_soporte = params['id_punto_soporte'];
      this.loadMarcas();
    })

  }

  _monthSelectedHandler(picker, event: MatDatepickerInputEvent<Date>) {
    this.loadPuntoSoporte();
    picker.close();
  }

  cambiarMarca(){
    this.loadVideosMarca();
  }

  loadVideosMarca(){
    this.loading=true;
    this.facade.loadVideosMarca(this.marca_seleccionada_id)
    .subscribe(
      (response)=>{
        this.loading=false;
        this.videos_marca=response;

        if(this.punto_soporte.videos){
          //Seleccionar los videos del punto de soporte
          for(var i=0;i<this.videos_marca.length;i++){
            var video_marca = this.videos_marca[i];
            for(var j=0;j<this.punto_soporte.videos.length;j++){
              var video_ps = this.punto_soporte.videos[j];
              if(video_marca.id==video_ps.id){
                video_marca.asignado=true;
                this.asignarVideo(video_marca);
              }
            }
          }
        }

      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadMarcas(){
    this.loading=true;
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.loading=false;
        this.marcas=response;
        this.loadAgentes();

      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadAgentes(){
    this.loading=true;
    this.facade.listarAgentes(-1,"",0)
    .subscribe(
      (response)=>{
        this.loading=false;
        this.agentes=response.contenido;
        if(this.id_punto_soporte!=0){
          this.loadPuntoSoporte();
        }

      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadPuntoSoporte(){
    this.loading=true;
    let params : any = {};
    if (this.fecha_inicio){
      params["fecha_inicio"] = new Date(this.fecha_inicio).toISOString().substring(0, 10);
    }
    if (this.fecha_fin){
      params["fecha_fin"] = new Date(this.fecha_fin).toISOString().substring(0, 10);
    }
    this.facade.recuperarPuntoSoporteDetalle(this.id_punto_soporte, params)
    .subscribe(
      (response)=>{
        this.loading=false;
        this.punto_soporte=response;
        let tiemposAcumulados = this.punto_soporte?.metricas?.tiempos_acumulados;
        this.tiemposPorEstatus = tiemposAcumulados.map(tiempo => [tiempo?.estatus, tiempo?.tiempo_en_horas]);
        this.setupDropdowns();
        this.loadVideosMarca();
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  setupDropdowns(){
    if(this.punto_soporte.marca){
      this.marca_seleccionada_id = this.punto_soporte.marca.id;
    }

    if(this.punto_soporte.agente.length!=0){
      let agentes = []
      for(let i=0;i<this.punto_soporte.agente.length;i++){
        // console.log(this.punto_soporte.agente[i])
        i==0?agentes.push(this.fb.group({value:this.punto_soporte.agente[i],extra:false})):agentes.push(this.fb.group({value:this.punto_soporte.agente[i],extra:true}))
      }
      this.agentesForm = this.fb.group({
        listaAgentes: this.fb.array(agentes)
      })
      // this.agente_seleccionado_id = this.punto_soporte.agente.id;
    }

    var url = window.location.href;
    url = url.split("#")[0];
    if(this.punto_soporte.slug){
      this.punto_soporte.videocall_public_link=url+"#/videollamada/"+this.punto_soporte.slug;
    }
  }

  guardarPuntoSoporte(){

    if(this.marca_seleccionada_id){
      this.punto_soporte.marca_id = this.marca_seleccionada_id;
    }else{
      alert("Por favor selecciona una marca");
      return;
    }

    const control = <FormArray>this.agentesForm.controls['listaAgentes'];
    // console.log(control.value[0]);
    if(control.value[0].value!=''){
      this.punto_soporte.agente_id = control.value;
    }else{
      alert("Por favor selecciona un agente");
      return;
    }

    if(this.id_punto_soporte && this.id_punto_soporte!=0){
      this.punto_soporte.id=this.id_punto_soporte;
    }

    //Armar arreglo de videos_ids
    var videos_ids = [];
    if(this.videos_asignados){
      for(var i=0;i<this.videos_asignados.length;i++){
        var video_asignado = this.videos_asignados[i];
        videos_ids.push(video_asignado.id);
      }
    }

    this.loading=true;

    // console.log("SOPORTE:",this.punto_soporte)

    this.facade.guardarPuntoSoporte(this.punto_soporte, videos_ids)
    .subscribe(
      (response)=>{
        // console.log(response);
        this.loading=false;
        this.irListado();
      },
      (error)=>{
        this.loading=false;
        console.error(error);
        if(error.status==400){
          alert(error.error.mensaje);
        }
      }
    );
  }

  irListado(){
    let params = {
      pagina : this.pagina_de_origen
    };
    if(this.criteria !== ""){
      params["criteria"] = this.criteria;
    }
    if(this.estatus_totem !== ""){
      params["estatus_totem"] = this.estatus_totem;
    }
    if(this.id_marca !== 0){
      params["id_marca"] = this.id_marca;
    }
    this.router.navigate(['cliente/videosoporte'],
    {
      queryParams: params
    });
  }

  asignarVideo(video){
    video.asignado=true;
    this.videos_asignados.push(video);
  }

  desasignarVideo(video){
    video.asignado=false;
    for(var i=0;i<this.videos_asignados.length;i++){
      var v = this.videos_asignados[i];
      if(v.id==video.id){
        this.videos_asignados.splice(i,1);
      }
    }
  }


  get getAgentes(){
    return this.agentesForm.get('listaAgentes') as FormArray;
  }

  public agregarAgentes(){
    // this.activarEliminar = false;
    const control = <FormArray>this.agentesForm.controls['listaAgentes'];
    control.push(this.fb.group({value:'', extra: true}));
  }

  public eliminarAgentes(index: number){
    const control = <FormArray>this.agentesForm.controls['listaAgentes'];
    control.removeAt(index);
  }
}
