import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-eliminar-consumidor-dialog',
  templateUrl: './eliminar-consumidor-dialog.component.html',
  styleUrls: ['./eliminar-consumidor-dialog.component.scss']
})
export class EliminarConsumidorDialogComponent implements OnInit {

  public eliminar_consumidor :boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EliminarConsumidorDialogComponent>
  ) { 
    this.eliminar_consumidor = false;
  }

  ngOnInit(): void {
  }

  cancelar(){
    this.dialogRef.close(this.eliminar_consumidor);
  }

  eliminarConsumidor(){
    this.eliminar_consumidor = true;
    this.dialogRef.close(this.eliminar_consumidor);
  }
}
