<div class="left-menu">
    <div id="spacer" class="row" style="height: 40px;">&nbsp;</div>
    <div *ngIf="comprobarAcceso('seccion_dashboard')" (click)="navegar('cliente/home')" class="row menu-row" [ngClass]="{'active':current_view=='home'}">
        <div class="col-12">
            <div class="horizontal-float" style="width: 20%;">
                <img src="assets/images/dashboard-icon.png" style="width: 20px;"/>
            </div>
            <div class="horizontal-float" style="width: 80%;">
                Dashboard
            </div>
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_agentes')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/agentes')" class="row menu-row" [ngClass]="{'active':current_view=='agentes'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <img src="assets/images/usuario-icon.png" style="width: 20px;"/>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Agentes
                </div>
            </div> 
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_conversaciones')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/conversaciones')" class="row menu-row" [ngClass]="{'active':current_view=='conversaciones'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <i class="fas fa-comments"></i>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Conversaciones
                </div>
            </div> 
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_consumidores')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/consumidores')" class="row menu-row" [ngClass]="{'active':current_view=='consumidores'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <i class="fa fa-address-card" aria-hidden="true"></i>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Consumidores
                </div>
            </div> 
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_chatbots')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/chat')" class="row menu-row" [ngClass]="{'active':current_view=='chat'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <img src="assets/images/chat-icon.png" style="width: 20px;"/>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Chatbots
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_formularios')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/formularios')" class="row menu-row" [ngClass]="{'active':current_view=='formularios'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <i class="fa fa-th-list" style="width: 20px;"></i>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Formularios
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="cliente && cliente.permite_videosoporte && comprobarAcceso('seccion_puntos_videosoporte')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/videosoporte')" class="row menu-row" [ngClass]="{'active':current_view=='videosoporte'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <i class="fas fa-headset"></i>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Puntos de videosoporte
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_perfil_de_cliente')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/perfil')" class="row menu-row" [ngClass]="{'active':current_view=='perfil'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <img src="assets/images/configuracion-icon.png" style="width: 20px;"/>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Perfil de cliente
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="comprobarAcceso('seccion_administrar_clientes')">
        <div class="row">&nbsp;</div>
        <div (click)="navegar('cliente/admin')" class="row menu-row" [ngClass]="{'active':current_view=='admin'}">
            <div class="col-12">
                <div class="horizontal-float" style="width: 20%;">
                    <i class="fa fa-users" aria-hidden="true"></i>
                </div>
                <div class="horizontal-float" style="width: 80%;">
                    Administrar clientes
                </div>
            </div>
        </div>
    </div>
</div>