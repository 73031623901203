<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10" style="overflow-y: scroll;height:86vh">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="irPerfil()" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">&nbsp;</div>
                            <app-cliente-consumidor-campos [id_marca]="id_marca" [tipo]='tipo'></app-cliente-consumidor-campos>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>