import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { EliminarConsumidorDialogComponent } from 'src/app/dialogs/eliminar-consumidor-dialog/eliminar-consumidor-dialog.component';

@Component({
  selector: 'app-consumidores-screen',
  templateUrl: './consumidores-screen.component.html',
  styleUrls: ['./consumidores-screen.component.scss']
})
export class ConsumidoresScreenComponent implements OnInit {
  public consumidores : any[] = [];
  public criteria : string = "";
  public id_marca:number=0;
  public marcas:any=[{id:0,nombre:"Todas las marcas"}];
  public pagina_actual : number = 1;
  public ultima_pagina : number = 1;
  public paginas : number[];
  public loading : boolean = false;
  esEditor: any;
  
  constructor(
    public facade:FacadeService,
    public router:Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_consumidores");
    this.loadMarcas();
    this.route.queryParams
      .subscribe((params) => {
        if(params.pagina){
          this.pagina_actual = params.pagina;
        }
        if(params.criteria){
          this.criteria = params.criteria;
        }
        if(params.id_marca){
          this.id_marca = parseInt(params.id_marca);
        }
        this.buscarConsumidores();
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.loading = false;
        var marcas = this.marcas
        this.marcas = marcas.concat(response);
      },
      (error)=>{
        console.log(error);
        this.loading = false;
      }
    ) 
  }
  
  buscarConsumidores(){
    this.loading = true;
    this.facade.listarConsumidoresCliente(
      this.criteria,
      this.id_marca,
      this.pagina_actual
    ).subscribe(
      (response) => {
        this.consumidores = response.contenido;
        this.paginas = [];
        this.pagina_actual=response.pagina;
        let numero_de_pagina = 1;
        this.ultima_pagina = response.ultima_pagina;
        if (this.ultima_pagina<=10){
          while(numero_de_pagina<=response.ultima_pagina){
            this.paginas.push(numero_de_pagina);
            numero_de_pagina++;
          }
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error)
      }
    );
  }

  eliminarConsumidor(id_consumidor){
    this.dialog.open(EliminarConsumidorDialogComponent,{restoreFocus: false}).afterClosed().subscribe(
      (respuesta) => {
       if (respuesta) {
        this.facade.eliminarConsumidor(id_consumidor).subscribe(
          (response) => {
            this.buscarConsumidores();
            this.actualizarUrl();
          },
          (error) => {
            console.log(error);
          }
        )
       }
      }
    );
  }

  aplicarFiltro() {
    this.actualizarUrl();
  }

  esFiltro(){
    if (this.id_marca !==0){
      return true;
    }
    else{
      return false;
    }
  }

  limpiarFiltro(){
    this.id_marca=0;
    this.aplicarFiltro();
  }

  limpiarBusqueda(){
    this.criteria = "";
    this.aplicarFiltro();
  }


  cargarPagina(pagina : number){
    this.pagina_actual = pagina;
    this.aplicarFiltro();
  }

  actualizarUrl(){
    let queryParams = { 
      pagina : this.pagina_actual
    };
    if(this.criteria!==""){
      queryParams["criteria"] = this.criteria;
    }
    if(this.id_marca!==0){
      queryParams["id_marca"] = this.id_marca;
    }
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: queryParams
      }
    );
  }

}
