import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { ChartType } from 'angular-google-charts';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

@Component({
  selector: 'app-videosoporte-metricas-screen',
  templateUrl: './videosoporte-metricas-screen.component.html',
  styleUrls: ['./videosoporte-metricas-screen.component.scss']
})
export class VideosoporteMetricasScreenComponent implements OnInit {
  public marcas : any = [];
  public loading : boolean = false;
  public marca : number = null;
  public fecha_inicio:any = "";
  public fecha_fin:any = "";
  public maxDate:any = new Date();
  public tipoGraficaDeBarras : ChartType = ChartType.ColumnChart;
  public tipoGraficaDePastel : ChartType = ChartType.PieChart;
  public tiemposPorFechaEstatusEnEspera : any[] = [];
  public tiemposPorFechaEstatusEnLlamada : any[] = [];
  public tiemposPorFechaEstatusApagado : any[] = [];
  public tiemposPorFechaEstatusOffline : any[] = [];

  public tiemposPorEstatus : any[] = []

  public tiempoEnEspera : any;
  public tiempoEnLlamada : any;
  public tiempoApagado : any;
  public tiempoOffline : any;
  public tiempoActivo: any;

  public isLoadingTiempoEnEspera : boolean = false;
  public isLoadingTiempoEnLlamada : boolean = false;
  public isLoadingTiempoApagado : boolean = false;
  public isLoadingTiempoOffline : boolean = false;
  public isLoadingTiempoActivo: boolean = false;

  public waitingResponse : boolean = false;

  public opcionesGraficaDeBarras = {
    backgroundColor: 'transparent',
    legend : "none", 
    width: "100%",
    chartArea: {
      width: '80%',
    },
    vAxis: {format: 'decimal'}
  };
  public opcionesGraficaDePastel = {
    backgroundColor: 'transparent',
    legend :{
      textStyle:{color: 'black'},
      position : "bottom"
    }, 
    slices : {
      0 : { color : '#2962FF'},
      1 : { color : '#D50000'},
      2 : { color : '#455A64'},
      3 : { color : '#33691E'}
    },
    width: "100%",
    chartArea: {
      width: '100%',
    }
  };
  constructor(
    private facade : FacadeService,
    private router : Router
  ) { }

  ngOnInit(): void {
    const today = new Date();
    this.fecha_inicio = new Date(today.setDate(today.getDate() - 7));
    this.fecha_fin = new Date();
    this.loadMarcas();
  }

  _monthSelectedHandler(picker, event: MatDatepickerInputEvent<Date>) {
    this.aplicarFiltro();
    picker.close();
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        let marcas = this.marcas
        this.marcas = marcas.concat(response);
        this.aplicarFiltro();
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    ) 
  }


  aplicarFiltro(){
    this.isLoadingTiempoEnEspera  = true;
    this.isLoadingTiempoEnLlamada  = true;
    this.isLoadingTiempoApagado  = true;
    this.isLoadingTiempoOffline  = true;
    this.isLoadingTiempoActivo = true;
    this.loading = true;
    let params = {};
    if (this.marca){
      params["marca"] = this.marca;
    }
    if (this.fecha_inicio){
      params["fecha_inicio"] = new Date(this.fecha_inicio).toISOString().substring(0, 10);
    }
    if (this.fecha_fin){
      params["fecha_fin"] = new Date(this.fecha_fin).toISOString().substring(0, 10);
    }
    this.facade.obtenerMetricasVideosoporte(params).subscribe(
      response => {
        this.tiempoActivo = response?.tiempo_actividad ?? "0"
        this.transformarTiemposPorFecha(response?.tiempos_por_fecha);
        this.obtenerTiemposAcumulados(response?.tiempos_acumulados);
        this.loading = false;
      }, error => {
        console.log(error)
      }
    );
  }

  transformarTiemposPorFecha(tiemposPorFecha : any[]){
    let todasLasFechas = new Set(tiemposPorFecha.map(fecha => fecha[0]));
    this.tiemposPorFechaEstatusEnEspera = [];
    this.tiemposPorFechaEstatusEnLlamada = [];
    this.tiemposPorFechaEstatusApagado = [];
    this.tiemposPorFechaEstatusOffline = [];
    todasLasFechas.forEach(fecha =>{
      this.tiemposPorFechaEstatusEnEspera.push([
        fecha, this.buscarValorEnArreglo("en_espera", fecha, tiemposPorFecha), "#2962FF"
      ]);
      this.tiemposPorFechaEstatusEnLlamada.push([
        fecha, this.buscarValorEnArreglo("en_llamada", fecha, tiemposPorFecha), "#D50000"
      ]);
      this.tiemposPorFechaEstatusApagado.push([
        fecha, this.buscarValorEnArreglo("apagado", fecha, tiemposPorFecha), "#455A64"
      ]);
      this.tiemposPorFechaEstatusOffline.push([
        fecha, this.buscarValorEnArreglo("offline", fecha, tiemposPorFecha), "#33691E"
      ]);
    });
  }

  obtenerTiemposAcumulados(tiemposAcumulados : any[]){
    this.tiempoEnEspera = tiemposAcumulados.find( tiempo => tiempo?.estatus === "en_espera") ?? {tiempo_con_formato : "0"};
    this.tiempoEnLlamada = tiemposAcumulados.find( tiempo => tiempo?.estatus === "en_llamada") ?? {tiempo_con_formato : "0"};
    this.tiempoApagado = tiemposAcumulados.find( tiempo => tiempo?.estatus === "apagado") ?? {tiempo_con_formato : "0"};
    this.tiempoOffline = tiemposAcumulados.find( tiempo => tiempo?.estatus === "offline") ?? {tiempo_con_formato : "0"};
    this.tiemposPorEstatus = tiemposAcumulados.map(tiempo => [tiempo?.estatus, tiempo?.tiempo_en_horas]);
  }

  buscarValorEnArreglo(estatus : string, fecha : string, datos : any[]){
    let dato = datos.find(dato => dato[0] === fecha && dato[1] === estatus);
    if (dato){
      return dato[3];
    }
    return 0;
  }

  isFilter(){
    if(this.marca != null){
      return true 
    }else{
      return false 
    }
  }

  limpiar(){
    this.marca = null;
    const today = new Date();
    this.fecha_inicio = new Date(today.setDate(today.getDate() - 7));
    this.fecha_fin = new Date();
  }

  download(): void{
    this.waitingResponse = true;
    let node = document.getElementById('vista');
    let parent = this;
    if (node) {
      htmlToImage.toPng(node)
      .then(function (image : any) {
          let downloadLink = document.createElement('a');
          downloadLink.download = 'dashboard.jpeg';
          downloadLink.href = image;
          downloadLink.target = "_blank";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          parent.waitingResponse = false;
      })
      .catch(function (error) {
        parent.waitingResponse = false;
        console.error('oops, something went wrong!', error);
      });
    }
  }
   
}
