import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';

export interface DialogData {
  chatbot_id: number,
}
@Component({
  selector: 'app-borrar-chatbot-dialog',
  templateUrl: './borrar-chatbot-dialog.component.html',
  styleUrls: ['./borrar-chatbot-dialog.component.scss']
})
export class BorrarChatbotDialogComponent implements OnInit {

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<BorrarChatbotDialogComponent>,public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = false;
  }

  public loading:boolean = false
  public mensaje:string = "" 
  public marcas:any = []


  ngOnInit(): void {
  }

  borrarChatbot(){
    this.loading = true;

    this.facade.borrarChatbot(this.data.chatbot_id)
    .subscribe(
      (response)=>{
        this.dialogRef.close();
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }

}
