import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
declare var $;
@Component({
  selector: 'app-chats-desde-cliente',
  templateUrl: './chats-desde-cliente.component.html',
  styleUrls: ['./chats-desde-cliente.component.scss']
})
export class ChatsDesdeClienteComponent implements OnInit {

  public api_url:string;
  public consumidores:any[]=[];
  public marcas:any=[];
  public mensaje_actual:string="";
  public nombre_completo:string="";

  public consumidores_interval:any;
  public chat_interval:any;
  public marca_actual:any;
  public consumidor_actual:any;
  public estatus_chat_actual: any = {
    id : 0
  };
  public editando_estatus_chat:boolean = false;
  public loading_marcas:boolean=false;
  public loading_chat:boolean=false;
  public loading_consumidores:boolean=false;
  public loading_envio_mensaje:boolean=false;
  public loading_transfer:boolean=false;
  public loading_pdf:boolean=false;
  
  public enviando_archivo:boolean=false;
  public archivo:any={};
  public chats_nuevo_mensaje:any=[];
  public activity_timeout:any;
  public agregar_consumidor:boolean = false; 
  public transferir_chat:boolean = false; 
  public nuevo_consumidor:any={
    celular:"",
    nombre:"",
    email:""
  }
  public id_asesor:string = "";
  public asesores:any=[]
  public ultimo_request:any = "";
  public previos_date:any = "";
  public ultimo_request_chat:any = "";
  public id_agente:number = 0
  public search:string="";
  public loading_search_consumidor:boolean=false;
  public current_page:any=0;
  public loading_vermas:boolean=false;
  public has_more:boolean=false;
  public pagina_de_origen:number = 1;
  public criteria:string = "";
  public id_marca:number = 0;
  public etiquetas_conversacion_marca:any[];
  constructor(private facade:FacadeService, private router:Router, private route:ActivatedRoute) { 

    }

    ngOnInit() {
      if(history.state.pagina_actual){
        this.pagina_de_origen = history.state.pagina_actual
      }
      if(history.state.criteria){
        this.criteria = history.state.criteria
      }
      if(history.state.id_marca){
        this.id_marca = parseInt(history.state.id_marca)
      }
      this.route.params.subscribe(params => {
        this.id_agente = params['id_agente'];
      })
      this.loadMarcas();
    }
  
    loadMarcas(){
      this.loading_marcas=true;
      this.facade.recuperarAgente(this.id_agente)
      .subscribe(
        (response)=>{
          this.loading_marcas=false;
          this.marcas = response.marcas
          this.nombre_completo = response.nombre_completo
          if(this.marcas.length>0){
            
            this.seleccionarMarca(this.marcas[0])
          }
        },
        (error)=>{
          if(error.status == 403){
            this.router.navigate(['']);
          }
          this.loading_marcas=false;
          console.log("ERROR:: "+JSON.stringify(error));
        }
      );
    }
  
    listarAgentesATransferir(){
      this.loading_transfer = true
      this.facade.listarAgentesATransferir(this.marca_actual.id)
      .subscribe(
        (response)=>{
          this.loading_transfer=false;
          this.asesores = response
        },
        (error)=>{
          if(error.status == 403){
            this.router.navigate(['']);
          }
          this.loading_transfer=false;
          console.log("ERROR:: "+JSON.stringify(error));
        }
      );
    }
  
    transferConsumer(){
      this.listarAgentesATransferir()
      this.transferir_chat=true

    }
    cancelTransferConsumer(){
      this.transferir_chat=false
    }
    transferToIdConsumer(){
      if(this.id_asesor == "")return
      if(this.loading_transfer)return
      this.transferirChat(this.id_asesor,this.consumidor_actual.id)
    }

    transferirChat(id_agente,id_consumidor){
      this.loading_transfer = true
      this.facade.transferirChat(id_consumidor,id_agente)
      .subscribe(
        (response)=>{
          let index_de_consumidor_actual = this.consumidores.indexOf(this.consumidor_actual);
          this.consumidores.splice(index_de_consumidor_actual,1);
          this.consumidor_actual = null;
          this.transferir_chat=false;
          this.loading_transfer = false;
        },
        (error)=>{
          this.loading_transfer=false;
          console.log("ERROR:: "+JSON.stringify(error));
        }
      )
    }
  
    dateToISOLikeButLocal(date) {
      const offsetMs = date.getTimezoneOffset() * 60 * 1000;
      const msLocal =  date.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      const iso = dateLocal.toISOString();
      const isoLocal = iso.slice(0, 19);
      return isoLocal;
    }

    verMasConsumidores(){
      this.current_page=this.current_page+1;
      this.loadConsumidores(this.marca_actual.id, false, true);
    }
  
    loadConsumidores(id_marca,show_loader, ver_mas){
      this.loading_vermas = ver_mas
      this.loading_consumidores = show_loader&&true;
      var date_request = new Date();
      this.previos_date = date_request
      this.facade.listarConsumidoresDeUnAgente(id_marca,this.id_agente, this.current_page)
      .subscribe(
        (response)=>{
          if(!response.es_parcial){
            this.has_more = response.has_more;
          }
          if(this.ultimo_request != ""){
            this.ultimo_request = this.previos_date.toISOString().slice(0,-5);     
            var new_consumidores = response.consumidores
            if(new_consumidores.length > 0){
              for(let i=0;i<new_consumidores.length;i++){
                if(new_consumidores[i].direccion=="incoming"){
                  new_consumidores[i].new_message = true
                }
                for(var j=0;j<this.consumidores.length;j++){
                  var c = this.consumidores[j];
                  if(c.id==new_consumidores[i].id){
                    this.consumidores.splice(j, 1)
                  }
                }
              }
              this.consumidores = [...this.consumidores,...new_consumidores]
              console.log(this.consumidores);
              //Si habia un consumidor seleccionado, recordarlo
              if(this.consumidor_actual){
                for(var i=0;i<this.consumidores.length;i++){
                  var c = this.consumidores[i];
                  if(c.id==this.consumidor_actual.id){
                    c.new_message = false
                    c.selected = true;
                  }
                }
              }
            }
            if(ver_mas){
              this.loading_vermas = false;
              let new_consumidores = response.consumidores
              for(let c of new_consumidores){
                  c.new_message = false
              }
            }
            this.loading_consumidores = show_loader&&false;
          }else{
            var new_consumidores = response.consumidores
            for(let c of new_consumidores){
                c.new_message = false
            }
            this.consumidores = new_consumidores;
            var date_request = new Date();
            this.ultimo_request = date_request.toISOString().slice(0,-5);
            //console.log(this.ultimo_request)
            this.loading_consumidores = show_loader&&false;
          }
            /* 
          //Si habia un consumidor seleccionado, recordarlo
          if(this.consumidor_actual){
            for(var i=0;i<this.consumidores.length;i++){
              var c = this.consumidores[i];
              if(c.id==this.consumidor_actual.id){
                c.selected = true;
              }
            }
          }
          //Si hay nuevos mensajes en los ultimos 5 segundos, marcar como nuevos mensajes
         var chats_nuevos = []
          for(var i=0;i<this.consumidores.length;i++){
            var c = this.consumidores[i];
            var date = new Date(c.fecha_ultimo_mensaje)
            var current_date = new Date()
            if(date.getTime() >= current_date.getTime()-5000 && !c.selected && c.direccion=="incoming"){
              c.new_message = true;
              chats_nuevos.push(c.id)
            }else{
              for(var j=0;j<this.chats_nuevo_mensaje.length;j++){
                var d = this.chats_nuevo_mensaje[j];
                if(c.id == d){
                  c.new_message = true;
                }
              }
            }
          }
          //se agrega a un arreglo los ids de mensajes nuevos para que no desparezcan en la siguiente petición
          this.chats_nuevo_mensaje = this.chats_nuevo_mensaje.concat(chats_nuevos)
          //Ordenar chats por más reciente
          this.consumidores =  this.consumidores.sort((a, b) => {
            return <any>new Date(b.fecha_ultimo_mensaje) - <any>new Date(a.fecha_ultimo_mensaje);
          });
          //this.consumidores=consumidores;
          */
        },
        (error)=>{
          //console.log(error.status)
          this.loading_consumidores = show_loader&&false;
          console.log("ERROR: "+JSON.stringify(error));
        }
      )
    }
  
    seleccionarMarca(marca){
      this.editando_estatus_chat = false;
      for(var i=0;i<this.marcas.length;i++){
        var m = this.marcas[i];
        m.selected = false;
      }
      this.marca_actual = marca;
      this.marca_actual.selected = true;
      //vaciar lista de chats
      this.consumidores=[];
      this.consumidor_actual={};
      this.ultimo_request = "";
      this.previos_date = "";
      //Listo consumidores, cuando regresen se comenzará el polling
      this.current_page=0;
      this.etiquetas_conversacion_marca = marca.etiquetas_conversacion;
      this.loadConsumidores(this.marca_actual.id,true, false);
  
    }
  
    seleccionarConsumidor(consumidor){
      this.borrarCambioEnEstatusDeChat();
      if (consumidor.etiqueta_conversacion){
        this.estatus_chat_actual = consumidor.etiqueta_conversacion;
      }
      this.ultimo_request_chat = ""
      this.stopChatPolling();
      for(var i=0;i<this.consumidores.length;i++){
        var c = this.consumidores[i];
        c.selected = false;
      }
      for(var j=0;j<this.chats_nuevo_mensaje.length;j++){
        var d = this.chats_nuevo_mensaje[j];
        if(consumidor.id == d){
          c.new_message = false;
          this.chats_nuevo_mensaje.splice(j, 1)
        }
      }
      consumidor.new_message = false;
      consumidor.selected = true;
      this.consumidor_actual=consumidor;
  
      this.loading_chat = true;
      this.recargarChat();
    }
  
    recargarChat(){
      //Cargamos el chat de ese consumidor
      this.facade.recuperarChatDeUnAgente(this.consumidor_actual.id,false)
      .subscribe(
        (response)=>{
          this.loading_chat = false;
          this.updateChatConsumidorActual(response);
          //this.startChatPolling();
          //TODO: Scroll down de div de chat
        },
        (error)=>{
          this.loading_chat = false;
          console.log("Error al recuperar chat:: "+error);
        }
      );
    }
  
    updateChatConsumidorActual(incomingChat){
      var scroll_to_bottom = false;
      if(!this.consumidor_actual.chat_ids){
        this.consumidor_actual.chat_ids=[];
      }
      if(!this.consumidor_actual.chat){
        this.consumidor_actual.chat=[];
      }
  
      //Checar que coincida el incoming chat con el consumidor actual
      if(incomingChat && incomingChat.length>0){
        var i_chat = incomingChat[0];
        if(i_chat.consumidor != this.consumidor_actual.id){
          //Ignoramos
          return;
        }
      }
  
      for(var i=0;i<incomingChat.length;i++){
        var i_chat = incomingChat[i];
        if(!this.consumidor_actual.chat_ids.includes(i_chat.id)){
          this.consumidor_actual.chat_ids.push(i_chat.id);
          this.consumidor_actual.chat.push(i_chat);
          scroll_to_bottom = true;    
        }
      }
      if(this.consumidor_actual){
        for(var i=0;i<this.consumidores.length;i++){
          var c = this.consumidores[i];
          if(c.id==this.consumidor_actual.id){
            c.ultimo_mensaje_preview = incomingChat[incomingChat.length-1].mensaje;
            if(c.ultimo_mensaje_preview && 
                c.ultimo_mensaje_preview!="" && 
                c.ultimo_mensaje_preview.length>=45){
                  c.ultimo_mensaje_preview = c.ultimo_mensaje_preview.substring(0,45)+"...";
            }
          }
        }
      }
      /*
      if(this.ultimo_request_chat == ""){
        this.consumidor_actual.chat=incomingChat;
      }else{
        var consumidor = this.consumidor_actual.chat
        this.consumidor_actual.chat = consumidor.concat(incomingChat)
      }
      console.log(incomingChat)
      var date_request = new Date();
      this.ultimo_request_chat = date_request.toISOString().slice(0,-5);
      if(incomingChat && incomingChat.length>0){*/
      if(scroll_to_bottom){
        setTimeout(function(){
          var h = $("#chat-container-scroll").get(0).scrollHeight;
          var chat_container_scroll = $("#chat-container-scroll");
          chat_container_scroll.animate({
            scrollTop: h
          });
        },500);
      }
    }
  
    startConsumidoresPolling(){
      var parent = this;
      this.consumidores_interval = setTimeout(function(){
        parent.loadConsumidores(parent.marca_actual.id, false, false);
      },5000);
    }
  
    startChatPolling(){
      var parent = this;
      //Y comenzamos intervalo para leer mensajes entrantes
      this.chat_interval = setTimeout(function(){
        parent.recargarChat();
      },2000);
    }
  
    stopConsumidoresPolling(){
      if(this.consumidores_interval){
        clearTimeout(this.consumidores_interval);
      }
    }
  
    stopChatPolling(){
      if(this.chat_interval){
        clearTimeout(this.chat_interval);
      }
    }
  
    enviarMensaje(){
      this.stopChatPolling();
      if(!this.consumidor_actual) return;
      var mensaje_a_enviar = this.mensaje_actual;
      this.mensaje_actual = "";
      this.loading_envio_mensaje = true;
      
      this.facade.enviarMensaje(this.consumidor_actual.id, mensaje_a_enviar)
      .subscribe(
        (response)=>{
          this.loading_envio_mensaje = false;
          this.recargarChat();
        },
        (error)=>{
          this.loading_envio_mensaje = false;
          console.log("ERROR:: "+JSON.stringify(error));
        }
      );
    }
  
    iniciales(nombre){
      var nombre_arr = nombre.split(" ");
      var iniciales = "";
      if(nombre_arr.length>1){
        iniciales = nombre_arr[0][0]+nombre_arr[1][0];
      }else{
        iniciales = nombre_arr[0][0];
      }
  
      return iniciales.toUpperCase();
    }
    irPerfil(){
      let params = {
        pagina_actual : this.pagina_de_origen
      };
      if(this.criteria !== ""){
        params["criteria"] = this.criteria;
      }
      if(this.id_marca !== 0){
        params["id_marca"] = this.id_marca;
      }
      this.router.navigate(['cliente/agente/perfil/'+this.id_agente],{
        state : params
      });
    }

    onSearch(){
      this.stopConsumidoresPolling()
      if(this.loading_search_consumidor)return
      this.loading_search_consumidor = true
      this.facade.buscarConsumidorCliente(this.id_agente,this.marca_actual.id,this.search)
      .subscribe(
        (response)=>{
          this.loading_search_consumidor = false
          this.consumidores = response.consumidores
        },
        (error)=>{
          this.loading_search_consumidor = false
          console.log(error)
        }
      )
    }
  
    clearSearch(){
      this.seleccionarMarca(this.marca_actual)
      this.search=''
    }
  
    checkIsClear(){
      if(this.search == ''){
        this.clearSearch()
        return
      }
    }

    descargarChat(){
      this.loading_pdf = true;
      let filename = "REPORTE_CHAT_"+this.consumidor_actual.numero_celular;
      this.facade.recuperarChatDeUnAgente(this.consumidor_actual.id,true).subscribe(
        (response) => {
          let pdf_en_blob = new Blob([response], {type: 'application/pdf'});
          let url = URL.createObjectURL(pdf_en_blob,);
          let fileLink = document.createElement('a');
          fileLink.href = url;
          fileLink.download = filename;
          fileLink.click();
          this.loading_pdf = false;
        },
        (error) =>{
          this.loading_pdf = false;
          console.log(error);
        }
      )
    }
    
    establecerEtiquetaAConsumidor(){
      this.facade.establecerEtiquetaAConsumidor(
        this.consumidor_actual.id,
        this.estatus_chat_actual.id
      ).subscribe(
        (response) => {
          let nueva_etiqueta = this.etiquetas_conversacion_marca.find(
            etiqueta => {return etiqueta.id == this.estatus_chat_actual.id}
          );
          this.consumidor_actual.etiqueta_conversacion = {...nueva_etiqueta};
          this.editando_estatus_chat = false;
        },
        (error) => {
          console.log(error);
        }
      )
    }

    borrarCambioEnEstatusDeChat(){
      this.editando_estatus_chat = false;
      this.estatus_chat_actual = {
        id : 0
      };
    }
  

}
