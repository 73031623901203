import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ChatApiFacadeService {

  constructor(private http: HttpClient, 
    public router: Router, 
    private cookieService: CookieService) {  }

  status(chat_api_url,chat_api_token){
    var headers = undefined;
    headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.get<any>(chat_api_url+"status?token="+chat_api_token,{headers:headers});
  }

  takeover(chat_api_url,chat_api_token){
    var headers = undefined;
    headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(chat_api_url+"takeover?token="+chat_api_token,{headers:headers});
  }
}
