import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { ReturnStatement } from '@angular/compiler';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatDialog } from '@angular/material';
import { sesionExpiradaDialogComponent } from 'src/app/dialogs/sesion-expirada-dialog/sesion-expirada-dialog.component';
import { BorrarConsumidorCMRDialogComponent } from 'src/app/dialogs/borrar-consumidor-cmr-dialog/borrar-consumidor-cmr-dialog.component';
import { NuevoContactoDialogComponent } from 'src/app/dialogs/nuevo-contacto-dialog/nuevo-contacto-dialog.component';
import {Md5} from 'ts-md5/dist/md5';
declare var $;
declare var document;

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {
  public md5 = new Md5();

  public api_url:string;
  public consumidores:any=[];
  public consumidores_crm:any=[];
  public marcas:any=[];
  public mensaje_actual:string="";
  public search:string="";
  public selText:string="";
  public start_select:any;
  public end_select:any;
  public has_more:boolean=false;

  public consumidores_interval:any;
  public chat_interval:any;
  public marca_actual:any;
  public consumidor_actual:any = null;
  public estatus_chat_actual: any = {
    id : 0
  };
  public editando_estatus_chat:boolean = false;
  public loading_marcas:boolean=false;
  public visible_emoji:boolean=false;
  public loading_chat:boolean=false;
  public loading_consumidores:boolean=false;
  public loading_vermas:boolean=false;
  public loading_consumidores_crm:boolean=false;
  public loading_envio_mensaje:boolean=false;
  public loading_transfer:boolean=false;
  public loading_add_consumidor:boolean=false;
  public loading_search_consumidor_crm:boolean=false;
  public loading_search_consumidor:boolean=false;
  public loading_upload_file:boolean=false;
  public current_page:any=0;

  public enviando_archivo:boolean=false;
  public archivo:any={};
  public chats_nuevo_mensaje:any=[];
  public activity_timeout:any;
  public agregar_consumidor:boolean = false;
  public editar_consumidor:boolean = false;
  public transferir_chat:boolean = false;
  public nuevo_consumidor:any={
    celular:"",
    nombre:"",
    email:""
  }
  public id_asesor:string = "";
  public asesores:any=[]
  public ultimo_request:any = "";
  public previos_date:any = "";
  public ultimo_request_chat:any = "";
  //guardar todos los consumidores por marca para que no se recargue el servicio completo al cambio de marca
  public consumidores_marca:any=[];
  public url_video_call:string = ""
  public etiquetas_conversacion_marca:any[];

  //Estilos de texto
  public bold_activo:boolean = false;
  public italic_activo:boolean = false;
  public cliente_actual_guardado:boolean = true;
  public consumidor_crm_actual:any
  public multiple_select:any = ["Nescafé Clásico 120 gr.","Nescafé Clásico 225 gr.","Nescafé Clásico 300 gr."]
  public multiple_select_value:any = []
  public notification_player:any = []
  public campos_dinamicos:any[] = []
  public formatos_permitidos_archivo:any = []

  constructor(private facade:FacadeService,
    private router:Router, private appService:AppService,private idle: Idle,public dialog: MatDialog) {
      this.api_url=environment.url_api;
    }

  ngOnInit() {
    this.loadMarcas();
    this.loadMiPerfil();
    this.appService.setUserLoggedIn(true);
    this.formatos_permitidos_archivo = ['jpeg', 'jpg','png', 'gif', 'pdf', 'doc', 'docx']
  }

  changeChatbotUse(){
    console.log(this.consumidor_actual);
    this.facade.changechatbotflag({id:this.consumidor_actual.id})
    .subscribe(
      (response)=>{
        this.consumidor_actual.usar_chatbot = !this.consumidor_actual.usar_chatbot
        console.log("Exito en el cambio");
      },
      (error)=>{
        // console.log("ERROR:: "+JSON.stringify(error));
        this.consumidor_actual.usar_chatbot = this.consumidor_actual.usar_chatbot
        if(error.status == 403){
          this.logout()
        }
      }
    )
  }

  loadMarcas(){
    this.loading_marcas=true;
    this.facade.listarMarcas()
    .subscribe(
      (response)=>{
        this.loading_marcas=false;
        this.marcas = response
        var consumidores_marca = []
        for(let m of this.marcas){
          consumidores_marca.push({id:m.id,consumidores:[],ultimo_request:""})
        }
        this.consumidores_marca = consumidores_marca
        if(this.marcas.length>0){

          this.seleccionarMarca(this.marcas[0])
        }
      },
      (error)=>{
        this.loading_marcas=false;
        console.log("ERROR:: "+JSON.stringify(error));
        if(error.status == 403){
          this.logout()
        }
      }
    );
  }

  listarAgentesATransferir(){
    this.loading_transfer = true
    this.facade.listarAgentesATransferir(this.marca_actual.id)
    .subscribe(
      (response)=>{
        this.loading_transfer=false;
        this.asesores = response
      },
      (error)=>{
        this.loading_transfer=false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }


  refreshMarcas(){
    this.facade.listarMarcas()
    .subscribe(
      (response)=>{
          if(this.marca_actual){
            for(var i=0;i<response.length;i++){
              var m = response[i];
              if(m.id==this.marca_actual.id){
                m.selected = true;
              }
            }
          }
          this.marcas = response
      },
      (error)=>{
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  transferirChat(id_agente,id_consumidor){
    this.loading_transfer = true
    this.facade.transferirChat(id_consumidor,id_agente)
    .subscribe(
      (response)=>{
        console.log("chat transferido")
        for(let i=0;i<this.consumidores_marca.length;i++){
          if(this.consumidores_marca[i].id == this.marca_actual.id){
            for(let j=0;j<this.consumidores_marca[i].consumidores.length;j++){
              if(this.consumidor_actual.id == this.consumidores_marca[i].consumidores[j].id){
                this.consumidores_marca[i].consumidores.splice(j, 1)
              }
            }
          }
        }
        for(let i=0;i<this.consumidores.length;i++){
          if(this.consumidor_actual.id == this.consumidores[i].id){
            this.consumidores.splice(i, 1)
          }
        }
        this.consumidor_actual = {}
        this.transferir_chat=false
        this.loading_transfer = false
      },
      (error)=>{
        this.loading_transfer=false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    )
  }

  dateToISOLikeButLocal(date) {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const msLocal =  date.getTime() - offsetMs;
    const dateLocal = new Date(msLocal);
    const iso = dateLocal.toISOString();
    const isoLocal = iso.slice(0, 19);
    return isoLocal;
  }

  verMasConsumidores(){
    this.current_page=this.current_page+1;
    this.loadConsumidores(this.marca_actual.id, false, "", true);
  }

  loadConsumidores(id_marca,show_loader,time, ver_mas){
    this.loading_consumidores = show_loader&&true;
    this.loading_vermas = ver_mas;
    var date_request = new Date();
    this.previos_date = date_request
    this.facade.listarConsumidores(id_marca,time, this.current_page)
    .subscribe(
      (response)=>{
        if(!response.es_parcial){
          this.has_more = response.has_more;
        }
        response = response.consumidores;
        // console.log(response);
        if(time != ""){
          this.ultimo_request = this.previos_date.toISOString().slice(0,-5);
          var new_consumidores = response
          var consumidores = this.consumidores
          if(new_consumidores.length > 0){
            for(let i=0;i<new_consumidores.length;i++){
              if(new_consumidores[i].mensajes_no_leidos!=0){
                new_consumidores[i].new_message = true
                this.notification_player = <HTMLVideoElement>document.getElementById("notificationAudio")
                this.notification_player.play();
                setTimeout(()=>{
                  this.notification_player.pause();
                  this.notification_player.currentTime = 0;
                },1000)
              }
              for(var j=0;j<this.consumidores.length;j++){
                var c = this.consumidores[j];
                if(c.id==new_consumidores[i].id){
                  this.consumidores.splice(j, 1)
                }
              }
            }
            this.consumidores = new_consumidores.concat(consumidores)
            //Si habia un consumidor seleccionado, recordarlo
            if(this.consumidor_actual){
              for(var i=0;i<this.consumidores.length;i++){
                var c = this.consumidores[i];
                if(c.id==this.consumidor_actual.id){
                  c.new_message = false
                  c.selected = true;
                }
              }
            }
          }
          //GUARDAR DATOS PARA QUE NO SE PIERDAN POR SI SE CAMBIA DE MARCA
          for(let cm of this.consumidores_marca){
            if(cm.id == id_marca){
              for(let i=0;i<new_consumidores.length;i++){
                if(new_consumidores[i].mensajes_no_leidos!=0){
                  new_consumidores[i].new_message = true
                }
                for(var j=0;j<cm.consumidores.length;j++){
                  var c = cm.consumidores[j];
                  if(c.id==new_consumidores[i].id){
                    cm.consumidores.splice(j, 1)
                  }
                }
              }
              cm.consumidores = new_consumidores.concat(cm.consumidores)
              cm.ultimo_request = this.previos_date.toISOString().slice(0,-5)
            }
          }
          this.loading_consumidores = show_loader&&false;
        }else{
          var new_consumidores = response
          for(let c of new_consumidores){
            if(c.mensajes_no_leidos!=0){
              c.new_message = true
            }
          }

          //Si fue un "ver más", los sumamos a lo que ya teniamos
          if(ver_mas){
            for(var i=0;i<new_consumidores.length;i++){
              var consumidor_extra=new_consumidores[i];
              this.consumidores.push(consumidor_extra);
            }
            this.scrollConsumidoresToBottom();
          }else{
            this.consumidores = new_consumidores;
          }
          var date_request = new Date();
          this.ultimo_request = date_request.toISOString().slice(0,-5);
          for(let cm of this.consumidores_marca){
            if(cm.id == id_marca){
              cm.consumidores = this.consumidores
              cm.ultimo_request = date_request.toISOString().slice(0,-5)
            }
          }

          this.loading_consumidores = show_loader&&false;
        }
        this.loading_vermas = false;
        this.startConsumidoresPolling();
      },
      (error)=>{
        //console.log(error.status)
        if(error.status == 405 || error.status == 403){
          this.logout()
        }
        this.loading_consumidores = show_loader&&false;
        console.log("ERROR: "+JSON.stringify(error));
      }
    )
  }

  scrollConsumidoresToBottom(){
    setTimeout(function(){
      var h = $("#consumidores-container-scroll").get(0).scrollHeight;
      var chat_container_scroll = $("#consumidores-container-scroll");
      chat_container_scroll.animate({
        scrollTop: h
      });
    },500);
  }

  seleccionarMarca(marca){
    if(this.loading_consumidores) return
    //Clear intervalos
    this.stopChatPolling();

    for(var i=0;i<this.marcas.length;i++){
      var m = this.marcas[i];
      m.selected = false;
    }
    this.marca_actual = marca;
    if(this.marca_actual.consumidores_campos_json){
      var campos_dinamicos = JSON.parse(this.marca_actual.consumidores_campos_json)
      for(let campo of campos_dinamicos){
        campo.valor = ""
      }
      this.campos_dinamicos = campos_dinamicos
      //console.log(campos_dinamicos)
    }else{
      this.campos_dinamicos = []
    }
    this.marca_actual.selected = true;
    //vaciar lista de chats
    this.consumidores=[];
    this.consumidor_actual={};
    this.previos_date = "";
    this.etiquetas_conversacion_marca = marca.etiquetas_conversacion;
    this.stopConsumidoresPolling();
    for(let cm of this.consumidores_marca){
      if(cm.id == this.marca_actual.id){
        for(let c of cm.consumidores){
          c.selected = false;
        }
        this.consumidores = cm.consumidores
        this.ultimo_request = cm.ultimo_request
      }
    }
    //Listo consumidores, cuando regresen se comenzará el polling
    this.current_page=0;
    this.loadConsumidores(this.marca_actual.id,true,"", false);

  }

  seleccionarConsumidor(consumidor){
    this.borrarCambioEnEstatusDeChat();
    if (consumidor.etiqueta_conversacion){
      this.estatus_chat_actual = consumidor.etiqueta_conversacion;
    }
    //Clear intervalos
    this.ultimo_request_chat = ""
    this.stopChatPolling();
    for(var i=0;i<this.consumidores.length;i++){
      var c = this.consumidores[i];
      c.selected = false;
    }
    for(var j=0;j<this.chats_nuevo_mensaje.length;j++){
      var d = this.chats_nuevo_mensaje[j];
      if(consumidor.id == d){
        c.new_message = false;
        this.chats_nuevo_mensaje.splice(j, 1)
      }
    }
    if(consumidor.new_message){
      if(this.marca_actual){
        for(var i=0;i<this.marcas.length;i++){
          var m = this.marcas[i];
          if(m.id==this.marca_actual.id){
            m.mensajes_no_leidos = m.mensajes_no_leidos - consumidor.mensajes_no_leidos
          }
        }
      }
    }
    if(this.marca_actual.tipo_crm == 'modo_2' || this.marca_actual.tipo_crm == 'modo_4'){
      this.searchCosumerCRM(consumidor.numero_celular)
    }
    consumidor.new_message = false;
    consumidor.selected = true;
    this.consumidor_actual=consumidor;

    this.loading_chat = true;
    this.recargarChat();
  }

  recargarChat(){
    //Cargamos el chat de ese consumidor
    this.facade.recuperarChat(this.consumidor_actual.id,this.ultimo_request_chat)
    .subscribe(
      (response)=>{
        this.loading_chat = false;
        this.updateChatConsumidorActual(response);
        this.startChatPolling();
        //TODO: Scroll down de div de chat
      },
      (error)=>{
        this.loading_chat = false;
        console.log("Error al recuperar chat:: "+error);
      }
    );
  }

  updateChatConsumidorActual(incomingChat){
    var scroll_to_bottom = false;
    if(!this.consumidor_actual.chat_ids){
      this.consumidor_actual.chat_ids=[];
    }
    if(!this.consumidor_actual.chat){
      this.consumidor_actual.chat=[];
    }

    //Checar que coincida el incoming chat con el consumidor actual
    if(incomingChat && incomingChat.length>0){
      var i_chat = incomingChat[0];
      if(i_chat.consumidor != this.consumidor_actual.id){
        //Ignoramos
        return;
      }
    }

    for(var i=0;i<incomingChat.length;i++){
      var i_chat = incomingChat[i];
      if(!this.consumidor_actual.chat_ids.includes(i_chat.id)){
        this.consumidor_actual.chat_ids.push(i_chat.id);
        this.consumidor_actual.chat.push(i_chat);
        scroll_to_bottom = true;
      }
    }
    if(this.consumidor_actual){
      for(var i=0;i<this.consumidores.length;i++){
        var c = this.consumidores[i];
        if(c.id==this.consumidor_actual.id){
          c.ultimo_mensaje_preview = incomingChat[incomingChat.length-1].mensaje;
          if(c.ultimo_mensaje_preview &&
              c.ultimo_mensaje_preview!="" &&
              c.ultimo_mensaje_preview.length>=45){
                c.ultimo_mensaje_preview = c.ultimo_mensaje_preview.substring(0,45)+"...";
          }
        }
      }
    }
    /*
    if(this.ultimo_request_chat == ""){
      this.consumidor_actual.chat=incomingChat;
    }else{
      var consumidor = this.consumidor_actual.chat
      this.consumidor_actual.chat = consumidor.concat(incomingChat)
    }
    console.log(incomingChat)
    var date_request = new Date();
    this.ultimo_request_chat = date_request.toISOString().slice(0,-5);
    if(incomingChat && incomingChat.length>0){*/
    if(scroll_to_bottom){
      setTimeout(function(){
        var h = $("#chat-container-scroll").get(0).scrollHeight;
        var chat_container_scroll = $("#chat-container-scroll");
        chat_container_scroll.animate({
          scrollTop: h
        });
      },500);
    }
  }

  startConsumidoresPolling(){
    var parent = this;
    this.consumidores_interval = setTimeout(function(){
      parent.loadConsumidores(parent.marca_actual.id, false, parent.ultimo_request, false);
      parent.refreshMarcas();
    },5000);
  }

  startChatPolling(){
    var parent = this;
    //Y comenzamos intervalo para leer mensajes entrantes
    this.chat_interval = setTimeout(function(){
      parent.recargarChat();
    },2000);
  }

  stopConsumidoresPolling(){
    if(this.consumidores_interval){
      clearTimeout(this.consumidores_interval);
    }
  }

  stopChatPolling(){
    if(this.chat_interval){
      clearTimeout(this.chat_interval);
    }
  }

  enviarMensaje(){
    if(this.bold_activo){this.mensaje_actual = this.mensaje_actual+"*"; this.bold_activo=false}
    if(this.italic_activo){this.mensaje_actual = this.mensaje_actual+"_"; this.italic_activo=false}
    this.stopChatPolling();
    if(!this.consumidor_actual) return;
    var mensaje_a_enviar = this.mensaje_actual;
    this.mensaje_actual = "";
    this.loading_envio_mensaje = true;

    this.facade.enviarMensaje(this.consumidor_actual.id, mensaje_a_enviar)
    .subscribe(
      (response)=>{
        this.loading_envio_mensaje = false;
        this.recargarChat();
      },
      (error)=>{
        this.loading_envio_mensaje = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  iniciales(nombre){
    var nombre_arr = nombre.split(" ");
    var iniciales = "";
    if(nombre_arr.length>1){
      iniciales = nombre_arr[0][0]+nombre_arr[1][0];
    }else{
      iniciales = nombre_arr[0][0];
    }

    return iniciales.toUpperCase();
  }

  clickInput(input){
    document.getElementById(input).click();
  }

  onFileSelect($event){
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        this.archivo.input_file = file;
        this.archivo.nombre_archivo = file.name;
        //TODO: Validar solo imagenes
        this.subirArchivo(this.archivo);
    }
  }

  onFileSelectInputCustom($event,campo){
    var archivo = {input_file:null,nombre_archivo:null}
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        archivo.input_file = file;
        //campo.valor = file.name
        archivo.nombre_archivo = file.name;
        this.subirArchivoCampoDinamico(archivo,campo)
      }
    }

  subirArchivoCampoDinamico(archivo,campo){
      if(this.loading_upload_file)return;
      this.loading_upload_file = true
      var id_marca = this.marca_actual.id
      var telefono = this.nuevo_consumidor.celular
      this.facade.subirArchivoCampoDinamico(id_marca,telefono,archivo)
      .subscribe(
      (response)=>{
          this.loading_upload_file = false
          campo.valor = response.url
          campo.file_name = archivo.nombre_archivo
          console.log(campo)
      },
      (error)=>{
          this.loading_upload_file = false
          alert(JSON.stringify(error))
      }
    );
  }

  async abrirArchivo(archivo_base64){
    const archivo_response = await fetch(archivo_base64);
    const archivo_blob = await archivo_response.blob();
    let url = URL.createObjectURL(archivo_blob);
    window.open(url, '_blank');
  }

  subirArchivo(archivo){
    if(this.formatos_permitidos_archivo.includes(archivo.input_file.name.split('.').pop())){
      this.enviando_archivo = true;
      this.loading_envio_mensaje = true;

      this.facade.subirArchivoEnConversacion(this.consumidor_actual.id,this.archivo)
      .subscribe(
          (response)=>{
              this.enviando_archivo = false;
              this.loading_envio_mensaje = false;
          },
          (error)=>{
              this.enviando_archivo = false;
              this.loading_envio_mensaje = false;
              archivo.loading = false;
              archivo.input_file = undefined;
              archivo.error = JSON.parse(error._body).content.archivo;
          }
      );
    }
    else{
      alert('Formato de arhivo no permitido.');
    }
  }

  clearDataFile(campo){
    campo.valor = ""
    campo.file_name = null
  }

  ngOnDestroy(){
    this.stopChatPolling();
    this.stopConsumidoresPolling();
  }

  logout(){
    this.facade.logout()
    .subscribe(
      (response)=>{
        clearTimeout(this.activity_timeout);
        this.facade.destroySessionToken();
        this.appService.setUserLoggedIn(false);
        this.idle.stop();
        this.ngOnDestroy()
        let currentUrl = this.router.url;
        if(currentUrl == "/home"){
          this.abrirDialog()
        }
      },
      (error)=>{
        clearTimeout(this.activity_timeout);
        this.facade.destroySessionToken();
        this.ngOnDestroy()
        let currentUrl = this.router.url;
        if(currentUrl == "/home"){
          this.abrirDialog()
        }
        this.appService.setUserLoggedIn(false);
      }
    )
  }

  addConsumer(){
    this.agregar_consumidor=true
    this.nuevo_consumidor.celular = this.consumidor_actual.numero_celular
    this.multiple_select_value = [];
    this.campos_dinamicos = JSON.parse(this.marca_actual.consumidores_campos_json)
    this.campos_dinamicos.map(campo => {
      campo.valor = "";
      return campo;
    })
    let campo = this.campos_dinamicos.find(campo => campo?.slug === "telefono");
    let telefonoIndex = this.campos_dinamicos.indexOf(campo);
    this.campos_dinamicos[telefonoIndex].valor = this.nuevo_consumidor.celular;
  }

  editConsumer(){
    this.editar_consumidor=true
    if(this.loading_consumidores_crm)return
    this.loading_consumidores_crm = true
    this.facade.listarConsumidoresCRM()
    .subscribe(
      (response)=>{
        this.loading_consumidores_crm = false
        this.consumidores_crm = response.consumidores_crm
      },
      (error)=>{
        this.loading_consumidores_crm = false
        console.log(error)
      }
    )
  }
  editarConsumidorCRM(perfil_consumidor){
    this.agregar_consumidor=true
    this.editar_consumidor=false
    this.nuevo_consumidor = perfil_consumidor
    this.campos_dinamicos = JSON.parse(perfil_consumidor.campos_dinamicos_json)
    this.campos_dinamicos.map( campo => {
      if (campo?.slug === "nombre"){
        campo.valor = this.nuevo_consumidor?.nombre;
      }
      if (campo?.slug === "telefono"){
        campo.valor = this.nuevo_consumidor?.celular;
      }
      if (campo?.slug === "email"){
        campo.valor = this.nuevo_consumidor?.correo;
      }
      return campo;
    });
    //console.log(perfil_consumidor.productos_comprados.split(","))
    this.multiple_select_value = perfil_consumidor.productos_comprados.split(",")
  }
  async borrarConsumidorCRM(id){
    const dialogRef = this.dialog.open(BorrarConsumidorCMRDialogComponent,{
      data: {consumidor_id: id}}).afterClosed().subscribe(()=>{this.searchCosumerCRM(this.consumidor_actual.numero_celular)});
  }

  async saveConsumer(){
    var consumidor = this.nuevo_consumidor
    await this.searchCosumerCRM(this.nuevo_consumidor.celular)
    if(this.nuevo_consumidor.qr_entregado){
      if (!confirm("Este consumidor ya ha completado sus datos y su información se podría perder. ¿Desea guardar?")) {
        return
      }
    }
    this.nuevo_consumidor = consumidor
    console.log(this.multiple_select_value)
    this.loading_add_consumidor = true
    if(this.nuevo_consumidor.id){
      this.nuevo_consumidor.id_consumidor_crm = this.nuevo_consumidor.id
    }else{
      var date = new Date()
      var timestamp = date.getTime()
      this.nuevo_consumidor.token_encuesta = this.md5.appendStr(this.nuevo_consumidor.celular).end()
      this.nuevo_consumidor.token_encuesta = this.nuevo_consumidor.token_encuesta + timestamp
    }
    if(this.multiple_select_value.length >0){
      this.nuevo_consumidor.productos_comprados = this.multiple_select_value.join()
    }
    this.nuevo_consumidor.id_marca = this.marca_actual.id
    this.nuevo_consumidor.campos_dinamicos_json = JSON.stringify(this.campos_dinamicos)
    this.nuevo_consumidor.nombre = this.campos_dinamicos.find(campo => campo?.slug === "nombre")?.valor;
    this.nuevo_consumidor.celular = this.campos_dinamicos.find(campo => campo?.slug === "telefono")?.valor;
    this.nuevo_consumidor.correo = this.campos_dinamicos.find(campo => campo?.slug === "email")?.valor;
    await this.facade.agregarConsumidor(this.nuevo_consumidor)
    .subscribe(
      (response)=>{
        this.loading_add_consumidor = false
        if(response.error){
          alert(response.error)
          return
        }
        if(!this.cliente_actual_guardado)this.cliente_actual_guardado = true;
        this.searchCosumerCRM(this.nuevo_consumidor.celular)
        this.agregar_consumidor=false
        this.nuevo_consumidor={}
        var campos_dinamicos = JSON.parse(this.marca_actual.consumidores_campos_json)
        for(let campo of campos_dinamicos){
          campo.valor = ""
        }
        this.campos_dinamicos = campos_dinamicos
      },
      (error)=>{
        this.loading_add_consumidor = false
          alert("ha ocurrido un error por favor intentelo más tarde"+JSON.stringify(error))
      }
    )
  }

  async searchCosumerCRM(celular){
    if(this.loading_search_consumidor_crm)return
    this.loading_search_consumidor_crm = true
    await this.facade.buscarCRMConsumidor(celular)
    .subscribe(
      (response)=>{
        this.consumidor_crm_actual = response.consumidor_crm
        this.cliente_actual_guardado = true;
        this.loading_search_consumidor_crm = false
      },
      (error)=>{
        this.cliente_actual_guardado = false;
        this.loading_search_consumidor_crm = false
      }
    )
  }

  cancelAddConsumer(){
    this.editar_consumidor=false
    this.agregar_consumidor=false
    this.nuevo_consumidor = {}
    this.multiple_select_value = []
    var campos_dinamicos = JSON.parse(this.marca_actual.consumidores_campos_json)
    for(let campo of campos_dinamicos){
      campo.valor = ""
    }
    this.campos_dinamicos = campos_dinamicos
  }
  transferConsumer(){
    this.listarAgentesATransferir()
    this.transferir_chat=true
  }
  cancelTransferConsumer(){
    this.transferir_chat=false
  }
  transferToIdConsumer(){
    if(this.id_asesor == "")return
    if(this.loading_transfer)return
    this.transferirChat(this.id_asesor,this.consumidor_actual.id)
  }
  abrirDialog(): void {
    const dialogRef = this.dialog.open(sesionExpiradaDialogComponent,{ disableClose: true });
  }

  fechaDeNacimientoMax(){
    var now = new Date();
    now.setFullYear( now.getFullYear() - 13 );
    return now;
  }

  onSearch(){
    this.stopConsumidoresPolling()
    if(this.loading_search_consumidor)return
    this.loading_search_consumidor = true
    this.facade.buscarConsumidor(this.marca_actual.id,this.search)
    .subscribe(
      (response)=>{
        this.loading_search_consumidor = false
        this.consumidores = response.consumidores
      },
      (error)=>{
        this.loading_search_consumidor = false
        console.log(error)
      }
    )
  }

  clearSearch(){
    this.seleccionarMarca(this.marca_actual)
    this.search=''
  }

  checkIsClear(){
    if(this.search == ''){
      this.clearSearch()
      return
    }
  }

  loadMiPerfil(){
    this.facade.recuperarInfoUsuarioFirmado()
    .subscribe(
      (response)=>{
        var arrayUsername = response.username.split("@")
        var string = arrayUsername[0].replace('_', 'A')
        string = string.replace('z', 'x')
        string = string.replace('Z', 'X')
        string = string.replace('.', 'B')
        string = string.replace('-', 'C')
        var hash = ""
          for (var m = 0; m < string.length; m++) {
            var char = string.charCodeAt(m);
            var sum = char + 1
            hash = hash + (String.fromCharCode(sum))
          }
        this.url_video_call = hash
      },
      (error)=>{
        console.log(error)
      }
    )
  }

  enviarLink(){
    //console.log(document.location.href = "/#/videollamada/"+this.url_video_call);
    var array_location = document.location.href.split("#")
    var current_location = array_location[0]
    this.mensaje_actual = current_location+"#/videollamada/"+this.url_video_call
    this.enviarMensaje()
  }

  addEmoji(event){
    console.log(event)
    this.mensaje_actual = this.mensaje_actual + event.emoji.native
    //this.visible_emoji = false
  }

  setBold(){
    if(this.selText != ""){
      this.mensaje_actual = [this.mensaje_actual.slice(0, this.start_select), '*', this.mensaje_actual.slice(this.start_select)].join('');
      this.mensaje_actual = [this.mensaje_actual.slice(0, this.end_select+1), '*', this.mensaje_actual.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.bold_activo = !this.bold_activo;
    this.mensaje_actual = this.mensaje_actual +"*"
  }

  setItalic(){
    if(this.selText != ""){
      this.mensaje_actual = [this.mensaje_actual.slice(0, this.start_select), '_', this.mensaje_actual.slice(this.start_select)].join('');
      this.mensaje_actual = [this.mensaje_actual.slice(0, this.end_select+1), '_', this.mensaje_actual.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.italic_activo = !this.italic_activo;
    this.mensaje_actual = this.mensaje_actual +"_"
  }

  GetSelectedText () {
    var selText = "";
    var url = document.getElementById("message") as HTMLSelectElement;
    if (window.getSelection) {  // all browsers, except IE before version 9
        if (document.activeElement &&
                (document.activeElement.tagName.toLowerCase () == "textarea" ||
                 document.activeElement.tagName.toLowerCase () == "input"))
        {
            var text = document.activeElement.value;
            this.start_select = document.activeElement.selectionStart
            this.end_select = document.activeElement.selectionEnd
            selText = text.substring (document.activeElement.selectionStart,
                                      document.activeElement.selectionEnd);
        }
        else {
            var selRange = window.getSelection ();
            selText = selRange.toString ();
        }
    }
    else {
        if (document.selection.createRange) {       // Internet Explorer
            var range = document.selection.createRange ();
            selText = range.text;
        }
    }
    return selText;
  }

  onSelect(eve){
    this.italic_activo = false
    this.bold_activo = false
    console.log(eve)
    var selText = "";
    selText = this.GetSelectedText ();
    this.selText = selText
  }

  soloNumeros(e,campo){
    if(e.keyCode < 48 || e.keyCode > 57){
      campo.valor = campo.valor.substring(0, campo.valor.length - 1);
    }
  }
  enviarUrlEncuesta(consumidor){
    var url_encuesta = window.location.protocol+'//'+window.location.hostname+'/#/encuesta/'+consumidor.token_encuesta
    console.log(url_encuesta)
    this.mensaje_actual = url_encuesta
    this.enviarMensaje()
  }

  establecerEtiquetaAConsumidor(){
    this.facade.establecerEtiquetaAConsumidor(
      this.consumidor_actual.id,
      this.estatus_chat_actual.id
    ).subscribe(
      (response) => {
        let nueva_etiqueta = this.etiquetas_conversacion_marca.find(
          etiqueta => {return etiqueta.id == this.estatus_chat_actual.id}
        );
        this.consumidor_actual.etiqueta_conversacion = {...nueva_etiqueta};
        this.editando_estatus_chat = false;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  borrarCambioEnEstatusDeChat(){
    this.editando_estatus_chat = false;
    this.estatus_chat_actual = {
      id : 0
    };
  }

  nuevoChat(){
    this.dialog.open(NuevoContactoDialogComponent,{restoreFocus: false}).afterClosed().subscribe(
      (respuesta) => {
        console.log(respuesta)
        if (respuesta && respuesta!=""){
          let data = {
            "id_marca" : this.marca_actual.id,
            "numero_celular" : respuesta.tel,
            "lada": respuesta.lada
          };
          this.facade.crearContacto(data).subscribe(
            (response) => {
              this.seleccionarMarca(this.marca_actual)
            },
            (error) => {
              alert(error.error.error)
            }
          );
        }
      }
    );
  }

}
