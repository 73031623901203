import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';

export interface DialogData {
  email: string,
}

@Component({
  selector: 'app-recuperar-pass-dialog',
  templateUrl: './recuperar-pass-dialog.component.html',
  styleUrls: ['./recuperar-pass-dialog.component.scss']
})
export class RecuperarPassDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecuperarPassDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = true;
  }
  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
