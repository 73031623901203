import { Component, OnInit } from '@angular/core';
import { ViewChild, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ChatApiFacadeService } from 'src/app/services/chat-api-facade.service';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sesion-expirada-dialog',
  templateUrl: './sesion-expirada-dialog.component.html',
  styleUrls: ['./sesion-expirada-dialog.component.scss']
})
export class sesionExpiradaDialogComponent implements OnInit {

  public loading_chat_api:boolean = false;
  public loading:boolean=false;
  public nombre:string;
  public logo:any={};
  public color_hex:string;
  public plataforma_whatsapp:string;

  //Chat-api
  public chat_api_url:string;
  public chat_api_token:string;
  //Twilio
  public twilio_celular:string;
  public twilio_sid:string;
  public twilio_token:string;

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<sesionExpiradaDialogComponent>,public router: Router ) {
      dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  irALogin(){
    this.dialogRef.close();
    this.router.navigate(['']);
  }



}
