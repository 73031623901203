import { Component, OnInit,Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NuevoCampoConsumidorComponent } from 'src/app/dialogs/nuevo-campo-consumidor/nuevo-campo-consumidor.component';
import { FacadeService } from 'src/app/services/facade.service';


@Component({
  selector: 'app-cliente-consumidor-campos',
  templateUrl: './cliente-consumidor-campos.component.html',
  styleUrls: ['./cliente-consumidor-campos.component.scss']
})
export class ClienteConsumidorCamposComponent implements OnInit {
  @Input() id_marca:any;
  @Input() tipo:any;
  public campos:any = [];
  public campos_default:any = [
    {
      id:"d1",
      slug: "nombre",
      tipo: "text",
      etiqueta: "Nombre del consumidor",
      bloquear_borrado : true
    },
    {
      id:"d2",
      slug: "email",
      tipo: "text",
      etiqueta: "Correo electrónico",
      bloquear_borrado : true
    },
    {
      id:"d3",
      slug: "telefono",
      tipo: "number",
      etiqueta: "Número telefónico",
      bloquear_borrado : true
    },
  ];
  public loading:boolean = false;
  public marca:any;
  constructor(public dialog: MatDialog, private facade: FacadeService) { }

  ngOnInit(): void {
    this.obtenerCampos()
  }

  obtenerCampos(){
    this.loading = true
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.loading = false
        for(let marca of response){
          if(marca.id == this.id_marca){
            this.marca = marca
            if(this.tipo == 'agente'){
              if(marca.agentes_campos_json){
                var campos_custom = JSON.parse(marca.agentes_campos_json)
                this.campos = campos_custom
              }else{
                this.campos = []
              }
            }else if(this.tipo == 'consumidor'){
              if(marca.consumidores_campos_json){
                this.campos = JSON.parse(marca.consumidores_campos_json);
              }else{
                this.campos = this.campos_default
              }
            }else if(this.tipo == 'formulario'){
              if(marca.formulario_campos_json){
                this.campos = JSON.parse(marca.formulario_campos_json);
              }else{
                this.campos = []
              }
            }
          }
        }
        console.log(this.campos);
      },
      (error)=>{
        console.log(error)
        this.loading = false
      }
    )
  }

  nuevoCampo(){
    const dialogRef = this.dialog.open(NuevoCampoConsumidorComponent,{
      width: '800px',
      data:{marca:this.marca,id_campo:0,borrar:false,tipo:this.tipo,campos_dinamicos:this.campos}
    }).afterClosed().subscribe(()=>{this.obtenerCampos()});
  }

  editarCampo(id_campo){
    const dialogRef = this.dialog.open(NuevoCampoConsumidorComponent,{
      width: '800px',
      data:{marca:this.marca,id_campo:id_campo,borrar:false,tipo:this.tipo,campos_dinamicos:this.campos}
    }).afterClosed().subscribe(()=>{this.obtenerCampos()});
  }

  borrarCampo(id_campo){
    const dialogRef = this.dialog.open(NuevoCampoConsumidorComponent,{
      width: '800px',
      data:{marca:this.marca,id_campo:id_campo,borrar:true,tipo:this.tipo,campos_dinamicos:this.campos}
    }).afterClosed().subscribe(()=>{this.obtenerCampos()});
  }
}
