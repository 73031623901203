<div class="streaming-block">
    <div class="row">
        <div class="col-12" *ngIf="view=='setup' && !skip_config">
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-12 h-center" style="text-align: center">
                    <h3>Configura tu audio y video</h3>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-4">&nbsp;</div>
                <div class="col-md-4 col-12">
                    <div class="row" *ngIf="devices.audioinput && devices.audioinput.length>0">
                        <div class="col-4">
                            Micrófono:
                        </div>
                        <div class="col-8">
                            <select style="width: 100%; padding: 5px;cursor: pointer;" [(ngModel)]="audioinput_device">
                                <option *ngFor="let device of devices.audioinput" [ngValue]="device">
                                    {{device.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row" *ngIf="!isSafari()">
                        <div class="col-12">
                            <h6>Tu velocidad de conexión es: {{downlink_speed}} mb/s 
                                    <span style="font-weight: bold;" *ngIf="downlink_speed>=7.5">(ÓPTIMA)</span>
                                    <span style="font-weight: bold;" *ngIf="downlink_speed>=5 && download_speed<7.5">(BUENA)</span>
                                    <span style="font-weight: bold;" *ngIf="downlink_speed>=2.5 && downlink_speed<5">(REGULAR)</span>
                                    <span style="font-weight: bold;" *ngIf="downlink_speed<2.5">(NO RECOMENDABLE)</span>
                            </h6>
                        </div>
                    </div>
                    <!--div class="row" *ngIf="devices.audioinput && devices.audioinput.length>0">
                        <div class="col-4">
                            Audio:
                        </div>
                        <div class="col-8">
                            <select style="width: 100%; padding: 5px;cursor: pointer;" [(ngModel)]="audiooutput_device">
                                <option *ngFor="let device of devices.audiooutput" [ngValue]="device">
                                    {{device.label}}
                                </option>
                            </select>
                        </div>
                    </div-->
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button mat-flat-button color="primary"
                            style="width: 100% !important;height: 100% !important;"
                            (click)="initAgora()"
                            >
                                INICIAR
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">&nbsp;</div>
            </div>
        </div>
        <div class="col-12" *ngIf="view=='loop'">
            <div id="retry-info" class="waiting-room" *ngIf="info_retries>0">
                <div class="message">Reintentando conexión...</div>
                <br/>
                <i class="fa-spin fas fa-spinner"></i>
            </div>
            <div id="video-loop" style="display: none;" class="waiting-room">
                <div id="video_{{video.id}}_holder" style="display: none;" class="video-holder" *ngFor="let video of context.videos">
                    <video id="video_{{video.id}}" width="100%" height="100%" playsinline>
                        <source src="{{video.url}}" type="{{video.mime}}">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div id="img-loop" style="display: none;" class="waiting-room">
                <h1 class="fade-in-top" style="margin-top:5vh">Esperando a un asesor</h1>
                <div style="width: 100%;" class="h-center">
                    <div *ngIf="context.marca.logo" class="fade-in-top video-loop-img" 
                        style="background-size:contain; background: url({{context.marca.logo}}) no-repeat;">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="view=='session'">
            <div class="row">
                <div class="col-12 h-center" style="text-align: center;position:relative">
                    <div id="waiting-room" class="waiting-room" *ngIf="!skip_config">
                        <div class="message">Iniciando la videoconferencia</div>
                        <br/>
                        <i class="fa-spin fas fa-spinner"></i>
                    </div>
                    <div class="conference-container" id="conference-placeholder" style="display: none;">
                        <div 
                                class="conference-control-container" 
                                id="conference-control-container"
                                *ngIf="mode=='host'">
                            <div class="row">
                                <div class="col-4" style="padding: 25px 10px 10px 10px">
                                    <h5>
                                        <span *ngIf="context">
                                            {{context.nombre}}
                                        </span>
                                    </h5>
                                </div>
                                <div class="col-4">
                                    <div class="row" >
                                        <div class="col-4 r-float" style="text-align: center;">
                                            <div class="conference-control-button" (click)="switchMic()">
                                                <span *ngIf="rtc.hasMic">
                                                    <i class="fas fa-microphone"></i>
                                                </span>
                                                <span *ngIf="!rtc.hasMic">
                                                    <i class="fas fa-microphone-slash"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4 c-float" style="text-align: center;color: white">
                                            <div class="conference-control-button red" (click)="leaveCall(true)">
                                                <i class="fas fa-phone-slash"></i>
                                            </div>
                                        </div>
                                        <div class="col-4 l-float" style="text-align: center;">
                                            <div class="conference-control-button" (click)="switchVideo()">
                                                <span *ngIf="rtc.hasVideo">
                                                    <i class="fas fa-video"></i>
                                                </span>
                                                <span *ngIf="!rtc.hasVideo">
                                                    <i class="fas fa-video-slash"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 r-float">
                                    <div class="share-screen-container" (click)="reloadCall()">
                                        <i class="fas fa-retweet"></i>
                                        <a>
                                            Recargar videollamada
                                        </a>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div 
                            class="mini-feeds-container"
                            [ngStyle]="{'opacity':mode!='host'?'0':'1'}"
                            id="mini-feeds-container"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>