<mat-accordion multi>
    <mat-expansion-panel [expanded]="true" class="headers-align">
        <mat-expansion-panel-header>
        <mat-panel-title>
            <h5 style="width: 20%;">
                Datos personales
            </h5>
        </mat-panel-title>
        </mat-expansion-panel-header>
  
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Nombre completo</mat-label>
                <input type="text" matInput placeholder="Nombre completo" [(ngModel)]="agente.nombre_completo">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Fecha de nacimiento</mat-label>
                    <input matInput [max]="fechaDeNacimientoMax()" [matDatepicker]="picker" [(ngModel)]="agente.fecha_de_nacimiento" [value]="agente.fecha_de_nacimiento" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Genero</mat-label>
                    <mat-select [(value)]="agente.genero">
                        <mat-option [value]="'femenino'">Femenino</mat-option>
                        <mat-option [value]="'masculino'">Masculino</mat-option>
                        <mat-option [value]="'no_especificado'">No especificado</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Número celular</mat-label>
                <input type="text" matInput placeholder="Número celular" [(ngModel)]="agente.numero_celular">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Tipo de Agente</mat-label>
                    <mat-select [(value)]="agente.tipo">
                        <mat-option [value]="'agente-maestro'" *ngIf="!existeAgenteMaestro">Maestro (recibe conversaciones fuera de horario)</mat-option>
                        <mat-option [value]="'agente-tipo-1'">Tipo 1</mat-option>
                        <mat-option [value]="'agente-tipo-2'">Tipo 2</mat-option>
                        <mat-option [value]="'agente-tipo-3'">Tipo 3</mat-option>
                        <mat-option [value]="'agente-tipo-4'">Tipo 4</mat-option>
                        <mat-option [value]="'agente-tipo-5'">Tipo 5</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>


    </mat-expansion-panel>

    <mat-expansion-panel class="headers-align">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h5>
                Datos de acceso
            </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
        <div *ngIf="id_agente!=0" class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Usuario (Login)</mat-label>
                <input type="text" matInput disabled placeholder="Usuario (Login)" [(ngModel)]="agente.username">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
  
        <div *ngIf="id_agente==0" class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Usuario (Login)</mat-label>
                <input type="text" matInput placeholder="Ej. usuario123 o agente@mail.com" [(ngModel)]="agente.username">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
  
        <div *ngIf="id_agente!=0" class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input type="email" matInput disabled placeholder="Correo electrónico" [(ngModel)]="agente.email">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
  
        <div *ngIf="id_agente==0" class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input type="email" matInput placeholder="Correo electrónico" [(ngModel)]="agente.email">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput placeholder="Contraseña" [(ngModel)]="agente.password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Confirmar contraseña</mat-label>
                <input type="password" matInput placeholder="Confirmar contraseña" [(ngModel)]="agente.c_password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="headers-align">
        <mat-expansion-panel-header>
          <mat-panel-title>
              <h5>
                  Datos de servicio
              </h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
    
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <i *ngIf="loading_idiomas" class="fa-spin fas fa-spinner"></i>
                    <mat-label>Idiomas</mat-label>
                    <mat-select [(value)]="id_idiomas" multiple>
                        <mat-option *ngFor="let i of idiomas" [value]="i.id">
                            {{i.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <!--<div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>URL Gruveo</mat-label>
                <input type="text" matInput placeholder="URL Gruveo" [(ngModel)]="agente.url_gruveo">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>-->
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6>Seleccione las marcas a las que este agente podrá dar soporte</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row" *ngFor="let m of marcas">
                    <div class="col-12">
                        <mat-checkbox color="primary"
                                        [(ngModel)]="m.selected"
                                        [value]="m.id"
                                        style="margin: 5px">{{m.nombre}}
                                    </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

      </mat-expansion-panel>

    <div class="row">&nbsp;</div>
    <div class="row">
        <div class="col-12 h-center">
            <button 
                    (click)="guardarAgente()"
                    mat-raised-button 
                    color="primary" >
                    Guardar
                    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
            </button>
        </div>
    </div>
  </mat-accordion>