<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Formularios</h2>
                        </div>
                    </div>
                    <div style="position: relative; min-height: 70vh;" class="container">
                        <div class="row w-100 align-items-center">
                            <div class="col-lg-4 d-flex justify-content-center">
                                <mat-form-field class="m-1" style="width: 100%;" appearance="outline">
                                    <mat-label>Buscar</mat-label>
                                    <input type="search" matInput placeholder="Buscar respuesta" [(ngModel)]="criteria" (keyup.enter)="aplicarFiltro()">
                                    <button *ngIf="criteria==''" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="aplicarFiltro()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button mat-button *ngIf="criteria!=''" matSuffix mat-icon-button aria-label="Clear" (click)="limpiarBusqueda()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline" style="width: 100%;">
                                    <mat-label>Selecciona marca</mat-label>
                                    <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
                                      <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                        {{marca.nombre}} 
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row  w-100 table-wrapper">
                            <table class="ws-table text-center" style="width: 100%;">
                                <tr>
                                    <th>
                                        Celular del consumidor
                                    </th>
                                    <th>
                                        Fecha
                                    </th>
                                    <th>
                                        
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                                <tr *ngFor="let formulario of formularios" class="fila" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}" (click)="verDetallesFormulario(formulario.id)">
                                    <td class="text-break" style="max-width: 150px;">
                                        {{formulario.consumidor.numero_celular}}
                                    </td>
                                    <td>
                                        {{formulario.creacion | date}}
                                    </td>
                                    <td>
                                        <span class="color-primary">
                                            <i class="fas fa-chevron-right"></i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="loader">
                            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                        </div>
                        <div class="row w-100 d-flex justify-content-center" *ngIf="formularios.length===0 && !loading">
                            <span class="text-center">No se encontraron resultados</span>
                        </div>
                        <div class="row w-100 my-2">
                            <div class="col-lg-12 d-flex justify-content-end" style="width: 100%;" *ngIf="!loading">
                                <table>
                                    <tr *ngIf="ultima_pagina > 10">
                                        <td>
                                            <span (click)="cargarPagina(pagina_actual-1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=1" style="cursor: pointer;">
                                                <<
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual-2)" 
                                                class="page_number"
                                                *ngIf="pagina_actual-2>0" style="cursor: pointer;">
                                                {{pagina_actual-2}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual-1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual>1" style="cursor: pointer;">
                                                {{pagina_actual-1}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual)"
                                                class="page_number"
                                                style="font-weight: bold; text-decoration: underline;">
                                                {{pagina_actual}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=ultima_pagina" style="cursor: pointer;">
                                                {{pagina_actual+1}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+2)" 
                                                class="page_number"
                                                *ngIf="pagina_actual+2 <= ultima_pagina" style="cursor: pointer;">
                                                {{pagina_actual+2}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=ultima_pagina" style="cursor: pointer;">
                                                >>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="ultima_pagina <= 10">
                                        <td *ngFor="let pagina of paginas">
                                            <span (click)="cargarPagina(pagina)"
                                                class="page_number"
                                                *ngIf="pagina_actual===pagina"
                                                style="font-weight: bold; text-decoration: underline;">
                                                {{pagina}}
                                            </span>
                                            <span (click)="cargarPagina(pagina)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!==pagina" style="cursor: pointer;">
                                                {{pagina}}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>