import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-cerrar-otras-sesiones-dialog',
  templateUrl: './cerrar-otras-sesiones-dialog.component.html',
  styleUrls: ['./cerrar-otras-sesiones-dialog.component.scss']
})
export class CerrarOtrasSesionesDialogComponent implements OnInit {

  public cerrar_sesiones : boolean;

  constructor(
    public dialogRef: MatDialogRef<CerrarOtrasSesionesDialogComponent>) {
      dialogRef.disableClose = true;
      this.cerrar_sesiones = false;
  }

  ngOnInit() {

  }

  cancelar(){
    this.dialogRef.close(this.cerrar_sesiones);
  }

  abrirNuevaSesion(){
    this.cerrar_sesiones = true;
    this.dialogRef.close(this.cerrar_sesiones);
  }
}
