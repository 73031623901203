import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-encuesta-configuracion',
  templateUrl: './encuesta-configuracion.component.html',
  styleUrls: ['./encuesta-configuracion.component.scss']
})
export class EncuestaConfiguracionComponent implements OnInit {

  public id_marca:number;
  public tipo:string;

  constructor(public router:Router, public route:ActivatedRoute) { }
  
    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id_marca = params['id_marca'];
        this.tipo = params['tipo'];
      })
    }
    irPerfil(){
      this.router.navigate(['cliente/perfil']);
    }
}
