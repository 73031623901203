import { Component, OnInit,Input } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { MatDialog } from '@angular/material';
import { NuevoChatbootHijoDialogComponent } from 'src/app/dialogs/nuevo-chatboot-hijo-dialog/nuevo-chatboot-hijo-dialog.component';
import { BorrarChatbotDialogComponent } from 'src/app/dialogs/borrar-chatbot-dialog/borrar-chatbot-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  public nombre_chatbot:string = ""
  @Input() id_chatbot:number
  public chatbots:any = []
  public list:any = [];
  public marcas:any = []
  public loading:boolean = false
  public loading_active:boolean = false
  public puede_activar:boolean = true
  public horario : any;
  public editanto:boolean = false;
  public copia_horario:any;
  public loading_edit:boolean = false

  constructor(public facade:FacadeService, public dialog: MatDialog, public router:Router) { }

  ngOnInit(): void {
    this.loadChatbots()
  }

  loadChatbots(){
    this.facade.recuperarChatbot(this.id_chatbot)
    .subscribe(
      (response)=>{
        if (response.horario){
          this.horario = response.horario;
        }
        else{
           let date = new Date();
          this.horario = {
            id : 0,
            primer_periodo_hora_fin: null,
            primer_periodo_hora_inicio: null,
            segundo_periodo_hora_fin: null,
            segundo_periodo_hora_inicio: null,
            tiempo_universal_coordinado:  date.getHours() - date.getUTCHours()
          }
        }
        
        this.chatbots = response
        this.list = [response]
        this.chatbots.mensaje = this.chatbots.mensaje.replace(/\\n/g, "<br>").replace(/&/g, "&amp;")
        this.loadMarcas()
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        console.log(error)
      }
    )
  }

  puedeAgregarHijos(chatbot : any){
    let _chatbot = {...chatbot};
    if (_chatbot.validacion){
     try {
      _chatbot.validacion = JSON.parse(_chatbot.validacion);
     } catch (error) {
       
     }
      if (_chatbot.validacion.tipo !== ""){
        if (_chatbot.hijos.length === 0){
          return true;
        } 
      } else {
        if (_chatbot.disparador && _chatbot.hijos.length === 0){
          return true;
        } else if (!_chatbot.disparador){
          return true;
        }
      }
    } else if(! _chatbot.disparador){
      return true;
    }
    return false;
  }

  nuevoHijo(marca,chatbot_padre,disparador,mensaje_inicial){
    console.log("empieza",chatbot_padre, disparador, mensaje_inicial)
    try {
      chatbot_padre.validacion = JSON.parse(chatbot_padre?.validacion);
    } catch (error) {
      chatbot_padre.validacion = chatbot_padre?.validacion;
    }
    console.log(chatbot_padre.validacion)
    if (chatbot_padre.formulario){
      if (chatbot_padre.validacion){
        if (chatbot_padre.validacion.tipo !== ""){
          disparador = 0;
        }
        else if(chatbot_padre.validacion.tipo === "" ){
          if (!chatbot_padre.disparador){
            disparador = chatbot_padre.hijos.length + 1;
          }   
        }
      } else {
        disparador = chatbot_padre.hijos.length + 1;
      }
    }
    if(mensaje_inicial && disparador == 0){
      const dialogRef = this.dialog.open(NuevoChatbootHijoDialogComponent,{ 
      width: '800px',
      data: {marca: marca, esFormulario : this.chatbots.formulario, chatbot_padre: chatbot_padre,disparador:disparador.toString(),isEdit:false}}).afterClosed().subscribe(()=>{
        if (chatbot_padre?.validacion?.tipo !== ""){
          this.loadChatbots();
        } else {
          this.nuevoHijo(marca,chatbot_padre,disparador+1,mensaje_inicial)
        }
      });
    }else{
      //console.log(chatbot_padre)
      const dialogRef = this.dialog.open(NuevoChatbootHijoDialogComponent,{ 
      width: '800px',
      data: {marca: marca, esFormulario : this.chatbots.formulario, chatbot_padre: chatbot_padre,disparador:disparador.toString(),isEdit:false}}).afterClosed().subscribe(()=>{this.loadChatbots()});
    }

  }

  borrarChatbot(chatbot_id, esRaiz : boolean){
    //console.log(chatbot_id)
    const dialogRef = this.dialog.open(BorrarChatbotDialogComponent,{ 
    data: {chatbot_id: chatbot_id}}).afterClosed().subscribe(()=>{
     if (esRaiz){
      this.router.navigate(['cliente/chat'])
     } else {
      this.loadChatbots()
     }
    });
  }

  borrarChatbotPadre(chatbot_id){
    //console.log(chatbot_id)
    const dialogRef = this.dialog.open(BorrarChatbotDialogComponent,{ 
    data: {chatbot_id: chatbot_id}}).afterClosed().subscribe(()=>{});
  }

  editarChatbot(all_data){
    const dialogRef = this.dialog.open(NuevoChatbootHijoDialogComponent,{ 
    width: '800px',
    data: {esFormulario : all_data.formulario, all_data:all_data,isEdit:true}}).afterClosed().subscribe(()=>{this.loadChatbots()});
  }

  editarInformacionChatbotPadre(){
    this.copia_horario = {...this.horario};
    this.editanto = true;
  }

  deshacerCambiosChatbotPadre(){
    this.horario = {...this.copia_horario};
    this.editanto = false;
  }

  guardarCambiosChatbotPadre(){
    let primer_periodo_valido = false;
    let segundo_periodo_valido = false;
    if (
      (this.horario.primer_periodo_hora_inicio !=="" && this.horario.primer_periodo_hora_fin !== "") ||
      (this.horario.primer_periodo_hora_inicio ==="" && this.horario.primer_periodo_hora_fin === "") 
    ){
      primer_periodo_valido = true;
    }
    
    if (
      (this.horario.segundo_periodo_hora_inicio !=="" && this.horario.segundo_periodo_hora_fin !== "") ||
      (this.horario.segundo_periodo_hora_inicio ==="" && this.horario.segundo_periodo_hora_fin === "")
    ){
      segundo_periodo_valido = true;
    }
    
    
    if(!primer_periodo_valido || !segundo_periodo_valido){
      alert("Por favor, revise el horario de los periodos del chatbot")
      return;
    }

    if(this.horario.tiempo_universal_coordinado === null || this.horario.tiempo_universal_coordinado > 24 || this.horario.tiempo_universal_coordinado < -24){
      alert("Zona horaria no válida. Revise las horas de UTC")
      return;
    }
    this.loading_edit = true;
    let data = {
      id : this.chatbots.id,
      mensaje: this.chatbots.mensaje,
      automatico: this.chatbots.automatico,
      disparador: this.chatbots.disparador,
      activo : this.chatbots.activo
    }
    data = {...this.horario, ...data}
    this.facade.crearChatbots(this.chatbots.marca,data)
    .subscribe(
      (response)=>{
        this.loadChatbots();
        this.editanto = false;
        this.loading_edit = false;
      },
      (error)=>{
        this.loading_edit = false;
        console.log(error);
      }
    ) 
  }

  returnValue(val){
    return parseInt(val) + 1
  }

  isLast(val,key){
    if(val == parseInt(key)+1){
      return true
    }else{
      return false
    }
  }

  activarChatboot(){
    this.loading_active = true;
    var data ={
      id: this.chatbots.id,
      mensaje: this.chatbots.mensaje,
      automatico: this.chatbots.automatico,
      disparador: this.chatbots.disparador,
      activo:true
    }
    this.facade.crearChatbots(this.chatbots.marca,data)
    .subscribe(
      (response)=>{
        this.loading_active = false;
        this.router.navigate(['cliente/chat']);
      },
      (error)=>{
        this.loading_active = false;
      }
    ) 
  }
  desactivarChatboot(){
    this.loading_active = true;
    var data ={
      id: this.chatbots.id,
      mensaje: this.chatbots.mensaje,
      automatico: this.chatbots.automatico,
      disparador: this.chatbots.disparador,
      activo:false
    }
    this.facade.crearChatbots(this.chatbots.marca,data)
    .subscribe(
      (response)=>{
        this.loading_active = false;
        this.router.navigate(['cliente/chat']);
      },
      (error)=>{
        this.loading_active = false;
      }
    ) 
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.marcas = response;
        this.loading = false;
        this.listarChatbots(response[0].id)
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }

  listarChatbots(id_marca){
    this.loading = true
    this.facade.listarChatbots(id_marca)
    .subscribe(
      (response)=>{
        this.loading = false
        var found = false
          for(let c of response){
            if(this.chatbots.marca == c.marca){
              if(c.activo){
                this.puede_activar = true
                found = true
              }
            }
        }
        if(!found){
          this.puede_activar = false
        }
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        console.log(error)
        this.loading = false
      }
    )
  }
  
}
    