import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { MatDialog } from '@angular/material';
import { BorrarVideosoporteDialogComponent } from 'src/app/dialogs/borrar-videosoporte-dialog/borrar-videosoporte-dialog.component';

@Component({
  selector: 'app-videosoporte-lista-screen',
  templateUrl: './videosoporte-lista-screen.component.html',
  styleUrls: ['./videosoporte-lista-screen.component.scss']
})
export class VideosoporteListaScreenComponent implements OnInit, OnDestroy {

  public puntos_soporte:any=[];
  public puntos_soporte_preguardados:any=[];
  public loading:boolean=false;
  public paginas:any=[];
  public pagina_actual:number=0;
  public estados:any=[];
  public estatus_totem:string="";
  public marcas:any=[{id:0,nombre:"Todas las marcas"}];
  public id_marca:number=0;  
  public criteria:string="";
  public autoreload:any;
  public search:boolean = false;
  esEditor: any;
  constructor(
    public facade:FacadeService, 
    public router:Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_puntos_videosoporte");
    this.estados = [
      { 
        descripcion:"Todos los estatus",
        valor:''
      }, 
      { 
        descripcion:"En llamada",
        valor:'en_llamada'
      }, 
      { 
        descripcion:"En espera",
        valor:'en_espera'
      },
      { 
        descripcion:"Apagado",
        valor:'apagado'
      }
    ];
    this.loadMarcas();
    this.route.queryParams
      .subscribe((params) => {
        if(params.pagina){
          this.pagina_actual = params.pagina;
        }
        if(params.criteria){
          this.criteria = params.criteria;
        }
        if(params.estatus_totem){
          this.estatus_totem = params.estatus_totem;
        }
        if(params.id_marca){
          this.id_marca = parseInt(params.id_marca);
        }
        this.loadPuntosSoporte();
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.aplicarFiltro();
        var marcas = this.marcas
        this.marcas = marcas.concat(response);
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    ) 
  }

  loadPuntosSoporte(){
    this.loading = true;
     //Si buscan por criterio, quitamos paginación
    if(this.criteria && this.criteria!=""){
      this.pagina_actual=0;
      this.search = true
    }
    /*else if(this.criteria==""){
      this.pagina_actual=0;
    }*/
    if(this.autoreload){
      clearTimeout(this.autoreload)
    }
    
    this.facade.recuperarPuntosSoporteCliente(this.pagina_actual, this.criteria, this.estatus_totem, this.id_marca)
    .subscribe(
      (response)=>{
        if(this.pagina_actual >= 0){
          this.puntos_soporte_preguardados = response.contenido;
        }
        if(this.criteria==""){
          this.search = false
        }
        this.puntos_soporte = [];
        this.puntos_soporte = response.contenido;
        this.setupLinks();
        this.loading = false;
        this.paginas = [];
        this.pagina_actual=response.pagina;
        var p = 0;
        while(p<=response.ultima_pagina){
          this.paginas.push(p);
          p = p+1;
        }
        var parent = this;
        this.autoreload = setTimeout(()=>{
          parent.autoreload = parent.loadPuntosSoporte();
        },5000);
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  cargarPagina(p){
    this.pagina_actual=p;
    this.loadPuntosSoporte();
    this.actualizarUrl();
  } 

  clearSearch(){
    this.criteria=""
    if(this.autoreload){
      clearTimeout(this.autoreload)
    }
    this.loadPuntosSoporte();
    this.actualizarUrl();
  }

  setupLinks(){
    var url = window.location.href;
    url = url.split("#")[0];
    this.puntos_soporte.forEach(ps => {
      if(ps.slug){
        ps.videocall_public_link = url + "#/videollamada/" + ps.slug;
      }
    });
  }

  irDetallePuntoSoporte(id){
    if (!this.esEditor){
      return;
    }
    this.router.navigate(['cliente/videosoporte/'+id], { 
      state: { 
        pagina_actual : this.pagina_actual,
        criteria : this.criteria,
        estatus_totem : this.estatus_totem,
        id_marca : this.id_marca
      } 
    });
  }

  actualizarUrl(){
    let queryParams = { 
      pagina : this.pagina_actual
    };
    if(this.criteria!==""){
      queryParams["criteria"] = this.criteria;
    }
    if(this.estatus_totem!==""){
      queryParams["estatus_totem"] = this.estatus_totem;
    }
    if(this.id_marca!==0){
      queryParams["id_marca"] = this.id_marca;
    }
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: queryParams
      }
    );
  }

  agregarPuntoSoporte(){
    this.router.navigate(['cliente/videosoporte/0']);
  }

  mostrarDialogParaEliminarPunto(id){
    const referencia_dialog = this.dialog.open(BorrarVideosoporteDialogComponent,{ 
    data: {id_punto_videosoporte: id}}).afterClosed().subscribe( ()=>{
      this.loadPuntosSoporte();
      if (this.puntos_soporte_preguardados.length === 1){
        this.pagina_actual <= 1 ? this.pagina_actual=0 : this.pagina_actual--;
      } 
      this.actualizarUrl();
    });
    
  }

  buscarPuntosSoporte(e){
    if(e.key == "Enter" && this.criteria != ""){
      this.loadPuntosSoporte()
      this.search = true
      return
    }
    if(e.key == "Backspace" && this.criteria == ""){
      this.loadPuntosSoporte()
      return
    }
    this.actualizarUrl();
  }

  aplicarFiltro(){
    this.loadPuntosSoporte();
    this.actualizarUrl();
  }

  esFiltro(){
    if(this.estatus_totem != "" || this.id_marca != 0){
      return true 
    }else{
      return false 
    }
  }

  limpiarFiltro(){
    this.estatus_totem = "";
    this.id_marca = 0;
    this.criteria = "";
    this.loadPuntosSoporte();
    this.actualizarUrl();
  }

  ngOnDestroy(){
    if(this.autoreload){
      clearTimeout(this.autoreload)
    }
  }
}
