<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Agentes</h2>
                        </div>
                    </div>
                    <div class="loader">
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </div>
                    <div style="position: relative; min-height: 70vh;" *ngIf="((agentes && agentes.length>0) || esFiltro() || esBusqueda()) && !loading">
                        <div class="row w-100 align-items-start">
                            <div class="col-lg-4 d-flex justify-content-center">
                                <mat-form-field class="m-1" style="width: 100%;" appearance="outline">
                                    <mat-label>Buscar</mat-label>
                                    <input type="search" matInput placeholder="Buscar por nombre o email" [(ngModel)]="criteria" (keyup.enter)="loadAgentes($event)">
                                    <button *ngIf="criteria==''" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="loadAgentes()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button mat-button *ngIf="criteria!=''" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline">
                                    <mat-label>Selecciona marca</mat-label>
                                    <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
                                      <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                        {{marca.nombre}} 
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="esFiltro()" class="col-lg-2 d-flex align-items-center">
                                <a (click)="limpiarFiltro()">
                                    <div class="row mt-3 d-flex align-items-center">
                                        <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <table class="ws-table" style="width: 100%;">
                            <tr>
                                <th>
                                    Nombre del agente
                                </th>
                                <th>
                                    Marcas asignadas
                                </th>
                                <th>
                                    Conversaciones sin atender
                                </th>
                                <th>
                                    Estatus sesión
                                </th>
                                <th>&nbsp;</th>
                            </tr>
                            <tr *ngFor="let a of agentes" (click)="irDetalleAgente(a.id)" [style.opacity]="a.usuario_activo ? '1' : '0.3'" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}">
                                <td>
                                    {{a.nombre_completo}}
                                </td>
                                <td>
                                    {{a.marcas_nombres}}
                                </td>
                                <td>
                                    {{a.cantidad_conversaciones_no_atendidas}}
                                </td>
                                <td *ngIf="a.en_linea">
                                    <h6 class="black-text" style="font-weight: 200;"><i class="fas fa-circle color-success"></i> En linea</h6>
                                </td> 
                                <td *ngIf="!a.en_linea">
                                    <h6 class="black-text" style="font-weight: 200;"><i class="fas fa-circle "></i> Desconectado</h6>
                                </td>
                                <td>
                                    <span class="color-primary">
                                        <i class="fas fa-chevron-right"></i>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: right;">
                                    <div class="r-float" style="width: 100%;" *ngIf="loading">
                                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                    </div>
                                    <div class="r-float" style="width: 100%;" *ngIf="!loading">
                                        <table>
                                            <tr>
                                                <td *ngFor="let p of paginas">
                                                    <span (click)="cargarPagina(p)"
                                                     class="page_number"
                                                     *ngIf="pagina_actual==p"
                                                     style="font-weight: bold; text-decoration: underline;">
                                                        {{p}}
                                                    </span>
                                                    <span (click)="cargarPagina(p)" 
                                                        class="page_number"
                                                        *ngIf="pagina_actual!=p" style="cursor: pointer;">
                                                        {{p}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="add-fab" *ngIf="esEditor">
                            <button (click)="AgregarAgente()" mat-fab color="primary" aria-label="Agregar un nuevo cliente">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!loading && agentes && agentes.length==0 && !esFiltro() && !esBusqueda()" class="row" style="text-align:center;height: 60vh;">
                        <div class="col-12 v-center">
                            <h4>No hay agentes para mostrar</h4>
                            <h4>Presiona el botón para agregar al primer agente</h4>
                            <div (click)="AgregarAgente()" class="add-button">
                                <i class="fas fa-plus-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>