<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Borrar chatbot</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6>Este chatbot se borará con todos sus hijos. ¿Desea continuar?</h6>
            </div>
        </div> 
        <div class="row">&nbsp;</div>
        <div class="row">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button (click)="borrarChatbot()" mat-raised-button color="primary">
                BORRAR <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
            </button>
        </div>
    </div>
</div>