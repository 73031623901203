import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  @Input() public consumidor;
  @Input() public atributos;
  @Input() public tipo_acivacion;
  constructor() { }

  ngOnInit(): void {
  }

}
