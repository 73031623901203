import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  
  constructor() { }

  required(input){
    return (input != undefined && input != null && input != "" && input.toString().trim().length > 0);
  }

  max(input, size){
    return (input.length <= size);
  }

  min(input, size){
    return (input.length >= size);
  }

  email(input){
    var regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return input.match(regEx); // Invalid format
  }

  date(input){
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!input.match(regEx)) return false;  // Invalid format
    var d = new Date(input);
    if(Number.isNaN(d.getTime())) return false; // Invalid date
    return d.toISOString().slice(0,10) === input;
  }
  
  between(input, min, max){
    return (max >= input >= min);
  }

  numeric(input){
    return (!isNaN(parseFloat(input)) && isFinite(input));
  }

  maxDecimals(input, size) {
    let decimals = 0;
    
    if (Math.floor(input) !== input && input.toString().split(".")[1]){
      decimals = input.toString().split(".")[1].length
    }

    return (decimals <= size);
  } 

  minDecimals(input, size) {
    let decimals = 0;
    
    if (Math.floor(input) !== input && input.toString().split(".")[1]){
      decimals = input.toString().split(".")[1].length
    }

    return (decimals >= size);
  } 

  dateBetween(input, min, max){

    input = new Date(input).getTime();
    min = new Date(min).getTime();
    max = new Date(max).getTime();

    return  (max >= input && input  >= min);

  }

  words(input){
    let pat = new RegExp('^([A-Za-zÑñáéíóúÁÉÍÓÚ ]+)$');
    console.log(pat.test(input), input);
    return pat.test(input);
  }

}
