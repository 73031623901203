import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginScreenComponent } from './screens/login-screen/login-screen.component';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';
import { AdminHomeScreenComponent } from './screens/admin-home-screen/admin-home-screen.component';
import { ClienteHomeScreenComponent } from './screens/cliente/cliente-home-screen/cliente-home-screen.component';
import { AdminDetalleClienteComponent } from './screens/admin-detalle-cliente/admin-detalle-cliente.component';
import { AgentesScreenComponent } from './screens/cliente/agentes-screen/agentes-screen.component';
import { PerfilScreenComponent } from './screens/cliente/perfil-screen/perfil-screen.component';
import { AgenteDetalleScreenComponent } from './screens/cliente/agente-detalle-screen/agente-detalle-screen.component';
import { AgentePerfilScreenComponent } from './screens/cliente/agente-perfil-screen/agente-perfil-screen.component';
import { ChatsDesdeClienteComponent } from './screens/cliente/chats-desde-cliente/chats-desde-cliente.component';
import { ConversacionesScreenComponent } from './screens/cliente/conversaciones-screen/conversaciones-screen.component';
import { ConsumidoresScreenComponent } from './screens/cliente/consumidores-screen/consumidores-screen.component';
import { ChatbotScreenComponent } from './screens/cliente/chatbot-screen/chatbot-screen.component';
import { ChatbotDetalleScreenComponent } from './screens/cliente/chatbot-detalle-screen/chatbot-detalle-screen.component';
import { VideoSoporteScreenComponent } from './screens/video-soporte-screen/video-soporte-screen.component';
import { VideoWrapperScreenComponent } from './screens/video-wrapper-screen/video-wrapper-screen.component';
import { VideosoporteListaScreenComponent } from './screens/cliente/videosoporte-lista-screen/videosoporte-lista-screen.component';
import { VideosoporteDetalleScreenComponent } from './screens/cliente/videosoporte-detalle-screen/videosoporte-detalle-screen.component';
import { DetallePerfilComponent } from './screens/cliente/detalle-perfil/detalle-perfil.component';
import { CamposConsumidorComponent } from './screens/cliente/campos-consumidor/campos-consumidor.component';
import { MainScreenComponent } from './encuesta/main-screen/main-screen.component';
import { EncuestaConfiguracionComponent } from './screens/cliente/encuesta-configuracion/encuesta-configuracion.component';
import { AdministrarClientesScreenComponent } from './screens/cliente/administrar-clientes-screen/administrar-clientes-screen.component';
import { DetalleClienteSegundoComponent } from './screens/cliente/detalle-cliente-segundo/detalle-cliente-segundo.component';
import { FormulariosScreenComponent } from './screens/cliente/formularios-screen/formularios-screen.component';
import { FormularioDetalleScreenComponent } from './screens/cliente/formulario-detalle-screen/formulario-detalle-screen.component';
import { ClienteSegundoGuard } from './guards/cliente-segundo.guard';
import { VideosoporteMetricasScreenComponent } from './screens/cliente/videosoporte-metricas-screen/videosoporte-metricas-screen.component';


const routes: Routes = [
  { path: '', component: LoginScreenComponent, pathMatch: 'full' },
  { path: 'admin/home', component: AdminHomeScreenComponent, pathMatch: 'full' },
  { path: 'admin/cliente/:id_cliente', component: AdminDetalleClienteComponent, pathMatch: 'full' },
  { path: 'cliente/home', canActivate:[ClienteSegundoGuard], data : {key:"seccion_dashboard"} ,component: ClienteHomeScreenComponent, pathMatch: 'full' },
  { path: 'cliente/videosoporte/metricas', canActivate:[ClienteSegundoGuard], data : {key:"seccion_puntos_videosoporte"} ,component: VideosoporteMetricasScreenComponent, pathMatch: 'full' },
  { path: 'cliente/videosoporte', canActivate:[ClienteSegundoGuard], data : {key:"seccion_puntos_videosoporte"} ,component: VideosoporteListaScreenComponent, pathMatch: 'full' },
  { path: 'cliente/videosoporte/:id_punto_soporte', canActivate:[ClienteSegundoGuard], data : {key:"seccion_puntos_videosoporte", isEdit : true} ,component: VideosoporteDetalleScreenComponent, pathMatch: 'full' },
  { path: 'cliente/agentes', canActivate:[ClienteSegundoGuard], data : {key:"seccion_agentes"} ,component: AgentesScreenComponent, pathMatch: 'full' },
  { path: 'cliente/perfil', canActivate:[ClienteSegundoGuard], data : {key:"seccion_perfil_de_cliente"} ,component: PerfilScreenComponent, pathMatch: 'full' },
  { path: 'cliente/perfil/editar', canActivate:[ClienteSegundoGuard], data : {key:"seccion_perfil_de_cliente", isEdit : true} ,component: DetallePerfilComponent, pathMatch: 'full' },
  { path: 'cliente/agente/:id_agente', canActivate:[ClienteSegundoGuard], data : {key:"seccion_agentes", isEdit : true} ,component: AgenteDetalleScreenComponent, pathMatch: 'full' },
  { path: 'cliente/agente/perfil/:id_agente', canActivate:[ClienteSegundoGuard], data : {key:"seccion_agentes", isEdit : true} ,component: AgentePerfilScreenComponent, pathMatch: 'full' },
  { path: 'cliente/agente/chats/:id_agente', canActivate:[ClienteSegundoGuard], data : {key:"seccion_agentes", isEdit : true} ,component: ChatsDesdeClienteComponent, pathMatch: 'full' },
  { path: 'cliente/conversaciones', canActivate:[ClienteSegundoGuard], data : {key:"seccion_conversaciones"} ,component: ConversacionesScreenComponent, pathMatch: 'full' },
  { path: 'cliente/consumidores', canActivate:[ClienteSegundoGuard], data : {key:"seccion_consumidores"} ,component: ConsumidoresScreenComponent, pathMatch: 'full' },
  { path: 'cliente/chat', canActivate:[ClienteSegundoGuard], data : {key:"seccion_chatbots"} ,component: ChatbotScreenComponent, pathMatch: 'full' },
  { path: 'cliente/chatbot/:id_chatbot', canActivate:[ClienteSegundoGuard], data : {key:"seccion_chatbots", isEdit : true} ,component: ChatbotDetalleScreenComponent, pathMatch: 'full' },
  { path: 'cliente/formularios', canActivate:[ClienteSegundoGuard], data : {key:"seccion_formularios"} ,component: FormulariosScreenComponent, pathMatch: 'full' },
  { path: 'cliente/formularios/:id_formulario', canActivate:[ClienteSegundoGuard], data : {key:"seccion_formularios", isEdit : true} ,component: FormularioDetalleScreenComponent, pathMatch: 'full' },
  { path: 'cliente/admin', canActivate:[ClienteSegundoGuard], data : {key:"seccion_administrar_clientes"} ,component: AdministrarClientesScreenComponent, pathMatch: 'full' },
  { path: 'cliente/admin/:id_cliente', canActivate:[ClienteSegundoGuard], data : {key:"seccion_administrar_clientes", isEdit : true} ,component: DetalleClienteSegundoComponent, pathMatch: 'full' },
  { path: 'home', component: HomeScreenComponent, pathMatch: 'full' },
  { path: 'videosoporte', component: VideoSoporteScreenComponent, pathMatch: 'full' },
  { path: 'videollamada/:slug', component: VideoWrapperScreenComponent, pathMatch: 'full' },
  { path: 'cliente/marca/:id_marca/:tipo', canActivate:[ClienteSegundoGuard], data : {key:"seccion_perfil_de_cliente", isEdit : true},component: CamposConsumidorComponent, pathMatch: 'full' },
  { path: 'encuesta/:token_encuesta', component: MainScreenComponent, pathMatch: 'full' },
  { path: 'cliente/encuesta/:id_marca', component: MainScreenComponent, pathMatch: 'full' },
  { path: 'cliente/encuesta/atributos/:id_marca/:tipo', canActivate:[ClienteSegundoGuard], data : {key:"seccion_perfil_de_cliente", isEdit : true} ,component: EncuestaConfiguracionComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
