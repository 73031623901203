import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-agente-info',
  templateUrl: './agente-info.component.html',
  styleUrls: ['./agente-info.component.scss']
})
export class AgenteInfoComponent implements OnInit {

  public loading_idiomas:boolean=false;
  public loading:boolean=false;
  @Input() id_agente:number;
  @Output() public guardadoCompleto: EventEmitter<any> = new EventEmitter;
  public agente:any={
    email:''
  };

  public idiomas:any=[];
  public id_idiomas:any=[];
  public marcas:any=[];
  public existeAgenteMaestro:boolean=false;

  constructor(public facade:FacadeService) { }

  ngOnInit() {
    this.comprobarNoExistenciaDeAgenteMaestro();
    this.loadIdiomas();
  }

  comprobarNoExistenciaDeAgenteMaestro(){
    this.facade.listarAgentesMaestroEnMarcasPorCliente().subscribe(
      (response) => {
        if (response.length!=0){
          this.existeAgenteMaestro = true;
        }
      },
      (error) => {
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadIdiomas(){
    this.loading_idiomas=true;

    this.facade.listarCatalogoIdiomas()
    .subscribe(
      (response)=>{
        this.loading_idiomas = false;
        this.idiomas = response;
        this.loadMarcas();
      },
      (error)=>{
        this.loading_idiomas = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.loading = false;
        this.marcas = response;
        this.loadAgente();
      },
      (error)=>{
        this.loading = false;
      }
    )
    
  }

  loadAgente(){
    if(this.id_agente && this.id_agente!=0){
      this.loading = true;
      this.facade.recuperarAgente(this.id_agente)
      .subscribe(
        (response)=>{
          this.loading = false;
          this.agente = response;
          if(this.agente.tipo==="agente-maestro"){
            this.existeAgenteMaestro=false;
          }
          this.agente.password = "**********";
          this.agente.c_password = "**********";

          //Idiomas
          if(this.agente.id_idiomas){
            this.id_idiomas = this.agente.id_idiomas.split(",");
            for(var i=0;i<this.id_idiomas.length;i++){
              var id_idioma = this.id_idiomas[i];
              this.id_idiomas[i]=parseInt(id_idioma);
            }
          }

          //Deseleccionar todas las marcas
          for(var i=0;i<this.marcas.length;i++){
            var uni_m = this.marcas[i];
            uni_m.selected = false;
          }
          //Seleccionar las marcas del universo que este agente tiene
          if(this.agente.marcas && this.agente.marcas.length>0){
            for(var i=0;i<this.marcas.length;i++){
              var uni_m = this.marcas[i];
              uni_m.selected = false;
              for(var j=0;j<this.agente.marcas.length;j++){
                var age_m = this.agente.marcas[j];
                if(age_m.id==uni_m.id){
                  uni_m.selected = true;
                  uni_m.is_previus = true;
                }
              }
            }
          }
        },
        (error)=>{
          this.loading = false;
        }
      )
    }
  }

  guardarAgente(){
    if(!this.agente) return false;
    if(!this.agente.username || !this.agente.password || !this.agente.nombre_completo){
      alert("Por favor proporcione nombre y datos de acceso")
      return false;
    }

    var marcas_seleccionadas = [];
    var number_marcas = 0
    for(var i=0;i<this.marcas.length;i++){
      var m = this.marcas[i];
      if(m.selected){
        marcas_seleccionadas.push({
          id: m.id
        })
        number_marcas++
      }else if(m.is_previus){
        marcas_seleccionadas.push({
          id: m.id,
          estatus: 'eliminada'
        })
      }
    }
    if(number_marcas==0){
      alert("Por favor proporcione al menos una marca para el agente");
      return false;
    }

    if(this.id_idiomas && this.id_idiomas.length>0){
      this.agente.id_idiomas=this.id_idiomas.join(",");
    }


    this.loading = true;
    this.facade.guardarAgente(this.agente, marcas_seleccionadas)
    .subscribe(
      (response)=>{
        this.loading = false;
        if(this.guardadoCompleto){
          this.guardadoCompleto.emit(response);
        }
      },
      (error)=>{
        this.loading = false;
        if(error.status == 400){

          if(error.error.mensaje){
            alert(error.error.mensaje);
          }else if(error.error.message){
            alert(error.error.message);
          }else if(error.error.error){
            alert(error.error.error);
          }
        }
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  fechaDeNacimientoMax(){
    var now = new Date();
    now.setFullYear( now.getFullYear() - 18 );
    return now;
  }

}
