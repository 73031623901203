import { Component, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ChatApiFacadeService } from 'src/app/services/chat-api-facade.service';
import { FacadeService } from 'src/app/services/facade.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map} from 'rxjs/operators';

export interface DialogData {
  id_cliente: number,
  marca: any
}
@Component({
  selector: 'app-editarEtiqueta-marca-dialog',
  templateUrl: './editar-marca-dialog.component.html',
  styleUrls: ['./editar-marca-dialog.component.scss']
})
export class EditarMarcaDialogComponent implements OnInit {

  public loading_chat_api:boolean = false;
  public loading_videos:boolean = false;
  public loading_fondo_virtual:boolean = false;
  public loading_eliminando:boolean = false;
  public loading:boolean=false;
  public validNumber:boolean=true;
  public nombre:string;
  public logo:any={};
  public videos:any=[];
  public color_hex:string;
  public maximo_agentes:string;
  public plataforma_whatsapp:string;
  public tipo_crm:string;
  public api_key_hobspot:string;
  public horario_inicio;
  public horario_fin;
  public tiempo_universal_coordinado:number = 0;
  public tiempo_universal_coordinado_local:number = 0;
  public maximo_participaciones : number = null;

  //Chat-api
  public chat_api_url:string;
  public chat_api_token:string;
  //Twilio
  public twilio_celular:string;
  public twilio_sid:string;
  public twilio_token:string;

  //Chips
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable: boolean = true;
  se_esta_editando_chip:boolean = false;
  chip_editando:any;
  etiqueta = {
    nombre:""
  };
  etiquetas_conversacion: any[] = [];
  @ViewChild('etiquetaInput') etiquetaInput: ElementRef<HTMLInputElement>;

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<EditarMarcaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  ngOnInit() {
    let date = new Date();
    this.tiempo_universal_coordinado_local = date.getHours() - date.getUTCHours();
    this.tiempo_universal_coordinado = this.tiempo_universal_coordinado_local;
    if(this.data.marca && this.data.marca.id && this.data.marca.id!=0){
      this.leerInfoMarca();
    }
  }

  leerInfoMarca(){
    this.nombre = this.data.marca.nombre;
    this.plataforma_whatsapp = this.data.marca.plataforma_whatsapp;
    this.chat_api_url=this.data.marca.chat_api_url;
    this.chat_api_token = this.data.marca.chat_api_token;
    this.twilio_celular = this.data.marca.twilio_celular;
    this.twilio_sid = this.data.marca.twilio_sid;
    this.twilio_token = this.data.marca.twilio_token;
    this.maximo_agentes = this.data.marca.maximo_agentes;
    this.tipo_crm = this.data.marca.tipo_crm;
    this.api_key_hobspot = this.data.marca.api_key_hobspot;
    this.maximo_participaciones = this.data.marca.maximo_participaciones;

    if(this.data.marca.horario_inicio!=undefined && this.data.marca.horario_inicio!=null){
      let horario_inicio = ('0'+this.data.marca.horario_inicio.toString()).slice(-4);
      this.horario_inicio = horario_inicio.substr(0,2) + ':' + horario_inicio.substr(2,2);
    }

    if(this.data.marca.horario_fin!=undefined && this.data.marca.horario_fin!=null){
      let horario_fin = ('0'+this.data.marca.horario_fin.toString()).slice(-4);
      this.horario_fin = horario_fin.substr(0,2) + ':' + horario_fin.substr(2,2);
    }
    if(this.data.marca.tiempo_universal_coordinado!=undefined){
      this.tiempo_universal_coordinado = this.data.marca.tiempo_universal_coordinado;
    }
    if(this.data.marca.logo){
      this.cargarLogo(this.data.marca.logo);
    }
    this.data.marca.etiquetas_conversacion.forEach(etiqueta => {
      if (etiqueta.nombre === "Nuevo"){
        etiqueta["removible"] = false;
        this.etiquetas_conversacion.push(etiqueta);
      }
      else{
        etiqueta["removible"] = true;
        this.etiquetas_conversacion.push(etiqueta);
      }
    });
    this.loadVideosMarca();
  }

  loadVideosMarca(){
    this.loading_videos = true;
    this.facade.loadVideosMarca(this.data.marca.id)
    .subscribe(
      (response)=>{
        this.loading_videos = false;
        this.videos = response;
      },
      (error)=>{
        this.loading_videos = false;
        console.error("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  guardarMarca(){
    //Guardar marca
    this.loading = true;
    var id_marca = undefined;
    if(this.data && this.data.marca){
      id_marca = this.data.marca.id;
    }
    this.facade.guardarMarcaCliente(
      this.data.id_cliente,
      id_marca,
      this.nombre,
      this.logo,
      this.plataforma_whatsapp,
      this.chat_api_url,
      this.chat_api_token,
      this.twilio_celular,
      this.twilio_sid,
      this.twilio_token,
      this.maximo_agentes,
      this.tipo_crm,
      this.api_key_hobspot,
      this.horario_inicio,
      this.horario_fin,
      this.tiempo_universal_coordinado,
      this.etiquetas_conversacion,
      this.maximo_participaciones)
      .subscribe(
        (response)=>{
          this.onNoClick();
          this.loading = false;
        },
        (error)=>{
          this.loading = false;
          if(error.status==400){
            alert(error.error.mensaje);
          }
        }
      );
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clickInput(input){
    document.getElementById(input).click();
  }

  onMarcaVideoSelect($event){
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        if(file.name.indexOf(".mp4")==-1 && 
        file.name.indexOf(".m4v")==-1
        ){
          alert("Solo se permiten videos tipo mp4 o m4v");
          return;
        }
        this.subirVideo(file);
    }
  }

  onMarcaFondoVirtualSelect($event){
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        var file_name = file.name;
        file_name = file_name.toLowerCase();
        if(file_name.indexOf(".jpg") === -1 && file_name.indexOf(".png") === -1 && file_name.indexOf(".jpeg") === -1){
          alert("Solo se aceptan imágenes en formato .jpg, .jpeg o .png");
          return;
        }
        this.subirFondoVirtual(file);
    }
  }

  subirFondoVirtual(file){
    this.loading_fondo_virtual = true;
    this.facade.subirFondoVirtual(this.data.marca.id, file)
    .subscribe(
      (response)=>{
        this.loading_fondo_virtual = false;
        this.data.marca.fondo_virtual_url = response.fondo_virtual_url;
        
      },
      (error)=>{
        if(error.status==400){
          alert(error.error.mensaje);
          this.loading_fondo_virtual = false;
        }
      },
    )
  }
  
  subirVideo(file){
    this.loading_videos = true;
    this.facade.subirVideoMarca(this.data.marca.id, file)
    .subscribe(
      (response)=>{
        this.loading_videos = false;
        this.loadVideosMarca();
      },
      (error)=>{
        if(error.status==400){
          alert(error.error.mensaje);
          this.loading_videos = false;
          this.loadVideosMarca();
        }
      },
    )
  }

  onMarcaLogoSelect($event){
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        if(file.name.indexOf(".jpg")==-1 && 
        file.name.indexOf(".jpeg")==-1 && 
        file.name.indexOf(".png")==-1
        ){
          alert("Solo se permiten imagenes para el logotipo");
          return;
        }

        var parent = this;
        var reader = new FileReader();
        reader.onload = function(e) {
          parent.logo.url=e.target['result'];
        }
        reader.readAsDataURL(file); // convert to base64 string
        this.logo.input_file = file;
        this.logo.nombre_archivo = file.name;
    }
  }

  eliminarFondoVirtual(){
    this.loading_fondo_virtual = true;
    this.facade.eliminarFondoVirtual(this.data.marca.id)
    .subscribe(
      (response)=>{
        this.loading_fondo_virtual = false;
        this.data.marca.fondo_virtual_url = undefined;
      },
      (error)=>{
        if(error.status==400){
          alert(error.error.mensaje);
          this.loading_fondo_virtual = false;
        }
      },
    )
  }

  async cargarLogo (imagen_base64) {
    const imagen_response = await fetch(imagen_base64);
    const imagen_blob = await imagen_response.blob();
    let image_file = new File([imagen_blob],'logo.'+imagen_blob.type.split('/')[1], { type: imagen_blob.type });
    this.logo.url = imagen_base64;
    this.logo.input_file = image_file;
    this.logo.nombre_archivo = image_file.name;
  }

  eliminarLogo(){
    this.logo={};
  }

  eliminarVideo(video){
    if(confirm("¿Estas seguro de eliminar este video permanentemente? (Se eliminará de todos los puntos de soporte)")){
      this.loading_eliminando = true;
      this.facade.eliminarVideo(video.id)
      .subscribe(
        (response)=>{
          this.loading_eliminando = false;
          this.loadVideosMarca();
        },
        (error)=>{
          this.loading_eliminando = false;
          console.error("ERROR:: "+JSON.stringify(error));
        }
      );
    }
  }

  validateOnlyNumbers(){
    var reg = new RegExp(/^\d+$/);
    if(reg.test(this.maximo_agentes)){
      this.validNumber = true
      return true
    }else{
      this.validNumber = false
      return false
    }
  }

  editarEtiqueta(etiqueta): void {
    if (this.se_esta_editando_chip) {
      this.etiquetas_conversacion.push(this.chip_editando)
    }
    this.se_esta_editando_chip = true;
    this.chip_editando = {...etiqueta};
    this.etiqueta.nombre = this.chip_editando.nombre;
    this.removerEtiqueta(etiqueta);
  }

  agregarEtiqueta(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      if(this.se_esta_editando_chip){
        this.chip_editando.nombre = value.trim();
        this.etiquetas_conversacion.push(this.chip_editando);
        this.se_esta_editando_chip = false;
        this.etiqueta.nombre = ""
      }
      else{
        this.etiquetas_conversacion.push({
          nombre : value.trim(),
          removible : true
        });
        this.etiqueta.nombre = ""
      }
    }
  }

  removerEtiqueta(etiqueta): void {
    const index = this.etiquetas_conversacion.indexOf(etiqueta);

    if (index >= 0) {
      this.etiquetas_conversacion.splice(index, 1);
    }
  }

}
