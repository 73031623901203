import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';

export interface DialogData {
  consumidor_id: number,
}

@Component({
  selector: 'app-borrar-consumidor-cmr-dialog',
  templateUrl: './borrar-consumidor-cmr-dialog.component.html',
  styleUrls: ['./borrar-consumidor-cmr-dialog.component.scss']
})
export class BorrarConsumidorCMRDialogComponent implements OnInit {

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<BorrarConsumidorCMRDialogComponent>,public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = false;
  }

  public loading:boolean = false
  public mensaje:string = "" 
  public marcas:any = []


  ngOnInit(): void {
  }

  borrarConcumidor(){
    this.loading = true;

    this.facade.borrarConcumidorCRM(this.data.consumidor_id)
    .subscribe(
      (response)=>{
        this.dialogRef.close();
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }
}
