import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { AppService } from 'src/app/services/app.service';
import { MatDialog } from '@angular/material';
import { RecuperarPassDialogComponent } from 'src/app/dialogs/recuperar-pass-dialog/recuperar-pass-dialog.component';
import { CerrarOtrasSesionesDialogComponent } from 'src/app/dialogs/cerrar-otras-sesiones-dialog/cerrar-otras-sesiones-dialog.component';

declare var $;

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {

  public username:string;
  public username_recover:string;
  public password:string;
  public loading:boolean=false;
  public error_message:string;
  public error_message_recover:string;
  public cerrar_sesiones = false;
  constructor(private router:Router, private facade:FacadeService,private appService:AppService,public dialog: MatDialog,) { }

  ngOnInit() {
  }

  goToScreen(roles){
    if(roles.includes("admin")){
      this.router.navigate(['admin/home']);
    }else if(roles.includes("cliente")){
      this.router.navigate(['cliente/home']);
    }else if(roles.includes("agente")){
      this.appService.setUserLoggedIn(true)
      this.router.navigate(['home']);
    }
  }

  login(){
    this.error_message = "";
    if(!this.username || this.username==""){
      this.error_message="Por favor proporciona tu usuario";
      return false;
    }
    if(!this.password || this.password==""){
      this.error_message="Por favor proporciona tu contraseña";
      return false;
    }

    this.loading=true;
    this.facade.login(this.username,this.password)
    .subscribe(
      (response)=>{
        this.loading = false;
        this.facade.saveUserData(response);
        this.goToScreen(response.roles);
      },
      (error)=>{
        this.loading = false;
        if(error.status && error.status==400){
          if(error.error && error.error.non_field_errors && error.error.non_field_errors.length>0){
            if(error.error.non_field_errors[0]=="Unable to log in with provided credentials."){
              this.error_message="Credenciales incorrectas, por favor verifícalas."
            }
          }
        }else if(error.status && error.status==404){
          this.error_message=error.error.mensaje;
          if (error.error.mensaje === "Debe cerrar sesión antes de abrir una nueva sesión") {
            this.confirmarCerrarSesiones();
          }
        }else{
          console.log(error);
        }
      }
    );
  }
  recoverPassView(){
    if(this.loading)return
    $("#login").fadeOut(10)
    $("#recuperar_pass").fadeIn(300)
    this.username="";
    this.username_recover="";
    this.password="";
    this.error_message="";
    this.error_message_recover="";
  }
  loginView(){
    if(this.loading)return
    $("#login").fadeIn(10)
    $("#recuperar_pass").fadeOut(300)
    this.username="";
    this.username_recover="";
    this.password="";
    this.error_message="";
    this.error_message_recover="";
  }
  recoverPass(){
    this.loading=true;
    this.facade.recoverPass(this.username_recover)
    .subscribe(
      (response)=>{
        this.dialogConfirm()
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
        if(error.status && error.status==400){
          this.error_message_recover = "Correo electrónico no encontrdo."
        }else{
          console.log("ERROR:: "+JSON.stringify(error));
        }
      }
    );
  }

  dialogConfirm(){
    const dialogRef = this.dialog.open(RecuperarPassDialogComponent,{ 
    data: {email:this.username_recover}}).afterClosed().subscribe(()=>{this.loginView()});
  }

  confirmarCerrarSesiones() {
    this.loading = true;
    this.dialog.open(CerrarOtrasSesionesDialogComponent,{restoreFocus: false}).afterClosed().subscribe(
      ( respuesta )=>{
        this.loading = false;
        if (respuesta) {
          this.facade.cerrarSesionEnOtroLugar(this.username,this.password).subscribe(
            (response) => {
              if (response.logout){
                this.login();
              }
            },
            (error) => {
              console.log("ERROR:: "+JSON.stringify(error));
            }

          );
        }
      }
    );
  }

}
