import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule, MatNativeDateModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


//Emojis
import { PickerModule } from '@ctrl/ngx-emoji-mart';

//Libraries
import { CookieService } from 'ngx-cookie-service';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginScreenComponent } from './screens/login-screen/login-screen.component';
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';
import { LogoutButtonComponent } from './partials/ui/logout-button/logout-button.component';
import { HomeButtonComponent } from './partials/ui/home-button/home-button.component';
import { AdminHomeScreenComponent } from './screens/admin-home-screen/admin-home-screen.component';
import { ClienteHomeScreenComponent } from './screens/cliente/cliente-home-screen/cliente-home-screen.component';
import { AdminDetalleClienteComponent } from './screens/admin-detalle-cliente/admin-detalle-cliente.component';
import { ClienteInfoComponent } from './partials/cliente-info/cliente-info.component';
import { NuevaMarcaDialogComponent } from './dialogs/nueva-marca-dialog/nueva-marca-dialog.component';
import { MenuClienteComponent } from './screens/cliente/menu-cliente/menu-cliente.component';
import { AgentesScreenComponent } from './screens/cliente/agentes-screen/agentes-screen.component';
import { PerfilScreenComponent } from './screens/cliente/perfil-screen/perfil-screen.component';
import { AgenteInfoComponent } from './partials/agente-info/agente-info.component';
import { AgenteDetalleScreenComponent } from './screens/cliente/agente-detalle-screen/agente-detalle-screen.component';
import { ChatApiConfigComponent } from './partials/chat-api-config/chat-api-config.component';
import { AgentePerfilScreenComponent } from './screens/cliente/agente-perfil-screen/agente-perfil-screen.component';
import { AgenteProfileComponent } from './partials/agente-profile/agente-profile.component';
import { sesionExpiradaDialogComponent } from './dialogs/sesion-expirada-dialog/sesion-expirada-dialog.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { MetricasDashboardComponent } from './partials/metricas-dashboard/metricas-dashboard.component';
import { ChatsDesdeClienteComponent } from './screens/cliente/chats-desde-cliente/chats-desde-cliente.component';
import { ChatbotScreenComponent } from './screens/cliente/chatbot-screen/chatbot-screen.component';
import { ChatbotDetalleScreenComponent } from './screens/cliente/chatbot-detalle-screen/chatbot-detalle-screen.component';
import { ChatbotComponent } from './partials/chatbot/chatbot.component';
import { VideoSoporteScreenComponent } from './screens/video-soporte-screen/video-soporte-screen.component';
import { VideocallBlockComponent } from './partials/videocall-block/videocall-block.component';
import { VideoWrapperScreenComponent } from './screens/video-wrapper-screen/video-wrapper-screen.component';
import { VideosoporteListaScreenComponent } from './screens/cliente/videosoporte-lista-screen/videosoporte-lista-screen.component';
import { VideosoporteDetalleScreenComponent } from './screens/cliente/videosoporte-detalle-screen/videosoporte-detalle-screen.component';
import { NuevoChatbootDialogComponent } from './dialogs/nuevo-chatboot-dialog/nuevo-chatboot-dialog.component';
import { NuevoChatbootHijoDialogComponent } from './dialogs/nuevo-chatboot-hijo-dialog/nuevo-chatboot-hijo-dialog.component';
import { BorrarChatbotDialogComponent } from './dialogs/borrar-chatbot-dialog/borrar-chatbot-dialog.component';
import { RecuperarPassDialogComponent } from './dialogs/recuperar-pass-dialog/recuperar-pass-dialog.component';
import { ClienteProfileComponent } from './partials/cliente-profile/cliente-profile.component';
import { DetallePerfilComponent } from './screens/cliente/detalle-perfil/detalle-perfil.component';
import { ClienteInfoProfileComponent } from './partials/cliente-info-profile/cliente-info-profile.component';
import { EditarMarcaDialogComponent } from './dialogs/editar-marca-dialog/editar-marca-dialog.component';
import { BorrarConsumidorCMRDialogComponent } from './dialogs/borrar-consumidor-cmr-dialog/borrar-consumidor-cmr-dialog.component';
import { ClienteConsumidorCamposComponent } from './partials/cliente-consumidor-campos/cliente-consumidor-campos.component';
import { CamposConsumidorComponent } from './screens/cliente/campos-consumidor/campos-consumidor.component';
import { NuevoCampoConsumidorComponent } from './dialogs/nuevo-campo-consumidor/nuevo-campo-consumidor.component';
import { MainScreenComponent } from './encuesta/main-screen/main-screen.component';
import { HeaderEncuestaComponent } from './encuesta/header-encuesta/header-encuesta.component';
import { QrCodeComponent } from './encuesta/qr-code/qr-code.component';
import { FormularioComponent } from './encuesta/formulario/formulario.component';
import { EncuestaConfiguracionComponent } from './screens/cliente/encuesta-configuracion/encuesta-configuracion.component';
import { ClienteEncuestaAtributosComponent } from './partials/cliente-encuesta-atributos/cliente-encuesta-atributos.component';
import { BorrarVideosoporteDialogComponent } from './dialogs/borrar-videosoporte-dialog/borrar-videosoporte-dialog.component';
import { ConversacionesScreenComponent } from './screens/cliente/conversaciones-screen/conversaciones-screen.component';
import { CerrarOtrasSesionesDialogComponent } from './dialogs/cerrar-otras-sesiones-dialog/cerrar-otras-sesiones-dialog.component';
import { ConsumidoresScreenComponent } from './screens/cliente/consumidores-screen/consumidores-screen.component';
import { EliminarConsumidorDialogComponent } from './dialogs/eliminar-consumidor-dialog/eliminar-consumidor-dialog.component';
import { AdministrarClientesScreenComponent } from './screens/cliente/administrar-clientes-screen/administrar-clientes-screen.component';
import { DetalleClienteSegundoComponent } from './screens/cliente/detalle-cliente-segundo/detalle-cliente-segundo.component';
import { ClienteSegundoInfoComponent } from './partials/cliente-segundo-info/cliente-segundo-info.component';
import { NuevoContactoDialogComponent } from './dialogs/nuevo-contacto-dialog/nuevo-contacto-dialog.component';
import { FormulariosScreenComponent } from './screens/cliente/formularios-screen/formularios-screen.component';
import { FormularioDetalleScreenComponent } from './screens/cliente/formulario-detalle-screen/formulario-detalle-screen.component';
import { LimpiarMarcaDialogComponent } from './dialogs/limpiar-marca-dialog/limpiar-marca-dialog.component';
import { VideosoporteMetricasScreenComponent } from './screens/cliente/videosoporte-metricas-screen/videosoporte-metricas-screen.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ConfirmarAccionDialogComponent } from './dialogs/confirmar-accion-dialog/confirmar-accion-dialog.component';

registerLocaleData(localeEs, 'es-MX', localeEsExtra);

@NgModule({
  declarations: [
    AppComponent,
    LoginScreenComponent,
    HeaderComponent,
    FooterComponent,
    HomeScreenComponent,
    LogoutButtonComponent,
    HomeButtonComponent,
    AdminHomeScreenComponent,
    ClienteHomeScreenComponent,
    AdminDetalleClienteComponent,
    ClienteInfoComponent,
    NuevaMarcaDialogComponent,
    MenuClienteComponent,
    AgentesScreenComponent,
    PerfilScreenComponent,
    AgenteInfoComponent,
    AgenteDetalleScreenComponent,
    ChatApiConfigComponent,
    AgentePerfilScreenComponent,
    AgenteProfileComponent,
    sesionExpiradaDialogComponent,
    MetricasDashboardComponent,
    ChatsDesdeClienteComponent,
    ChatbotScreenComponent,
    ChatbotDetalleScreenComponent,
    ChatbotComponent,
    VideoSoporteScreenComponent,
    VideocallBlockComponent,
    VideoWrapperScreenComponent,
    VideosoporteListaScreenComponent,
    VideosoporteDetalleScreenComponent,
    NuevoChatbootDialogComponent,
    NuevoChatbootHijoDialogComponent,
    BorrarChatbotDialogComponent,
    RecuperarPassDialogComponent,
    ClienteProfileComponent,
    DetallePerfilComponent,
    ClienteInfoProfileComponent,
    EditarMarcaDialogComponent,
    BorrarConsumidorCMRDialogComponent,
    ClienteConsumidorCamposComponent,
    CamposConsumidorComponent,
    NuevoCampoConsumidorComponent,
    MainScreenComponent,
    HeaderEncuestaComponent,
    QrCodeComponent,
    FormularioComponent,
    EncuestaConfiguracionComponent,
    ClienteEncuestaAtributosComponent,
    BorrarVideosoporteDialogComponent,
    ConversacionesScreenComponent,
    CerrarOtrasSesionesDialogComponent,
    ConsumidoresScreenComponent,
    EliminarConsumidorDialogComponent,
    AdministrarClientesScreenComponent,
    DetalleClienteSegundoComponent,
    ClienteSegundoInfoComponent,
    NuevoContactoDialogComponent,
    FormulariosScreenComponent,
    FormularioDetalleScreenComponent,
    LimpiarMarcaDialogComponent,
    VideosoporteMetricasScreenComponent,
    ConfirmarAccionDialogComponent
    ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MatSliderModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatSidenavModule,
    MatRadioModule,
    MatTabsModule,
    MatCardModule,
    HttpClientModule,
    MatListModule,
    MatExpansionModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    PickerModule,
    MatProgressBarModule,
    GoogleChartsModule,
    MatSlideToggleModule
  ],
  entryComponents:[
    NuevaMarcaDialogComponent
  ],
  providers: [
    CookieService,
    CurrencyPipe,
    DecimalPipe,
    MatDatepickerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
