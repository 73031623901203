<div class="container">
    <div class="row">&nbsp;</div>

    <ng-container *ngIf="campos_dinamicos==null; else formulario">
      <h4>No hay un formulario para este videotótem.</h4>
    </ng-container>
    <ng-template #formulario>
      <div class="row ng-star-inserted">
          <h6 style="margin: 0rem 1rem;">Completa los siguientes campos</h6>
      </div>
      <div class="row">&nbsp;</div>
      <ng-container *ngIf="consumidor.id">
        <div class="row ng-star-inserted">
          <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" [color]="color" appearance="outline">
            <mat-label>Número celular</mat-label>
            <input matInput placeholder="Número celular" [(ngModel)]="consumidor.celular" disabled>
          </mat-form-field>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row ng-star-inserted">
          <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" [color]="color" appearance="outline">
            <mat-label>Nombre completo</mat-label>
            <input matInput placeholder="Nombre completo" [(ngModel)]="consumidor.nombre" [disabled]="nombre_editable" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row ng-star-inserted">
          <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" [color]="color" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="Correo electrónico" [(ngModel)]="consumidor.correo" [disabled]="correo_editable" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="row">&nbsp;</div>
      </ng-container>
      <div class="row" *ngFor="let campo of campos_dinamicos">
        <mat-form-field *ngIf="campo.tipo == 'text'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" [color]="color" appearance="outline">
          <mat-label>{{campo.etiqueta}}</mat-label>
          <input matInput placeholder="{{campo.etiqueta}}" [(ngModel)]="campo.valor" [disabled]="!campo.editable" autocomplete="off">
        </mat-form-field>
        <mat-form-field *ngIf="campo.tipo == 'number'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
          <mat-label>{{campo.etiqueta}}</mat-label>
          <input mdInput type="text" (keyup)="soloNumeros($event,campo)" matInput placeholder="{{campo.etiqueta}}" [(ngModel)]="campo.valor" value="campo.valor" [disabled]="!campo.editable" autocomplete="off">
        </mat-form-field>
        <mat-form-field *ngIf="campo.tipo == 'date'" appearance="outline" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
          <mat-label>{{campo.etiqueta}}</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="campo.valor" [value]="campo.valor" [disabled]="!campo.editable"
          (click)="picker.open()"
          (keydown.arrowdown)="picker.open()"
          (keydown.enter)="picker.open()"
          readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="!campo.editable"></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="campo.tipo == 'dropdown'" style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
          <mat-label>{{campo.etiqueta}}</mat-label>
          <mat-select [(value)]="campo.valor" [disabled]="!campo.editable">
            <mat-option *ngFor="let op of campo.opciones" [value]="op.nombre">{{op.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="campo.tipo == 'dropdown_multi'" style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
          <mat-label>{{campo.etiqueta}}</mat-label>
          <mat-select [(value)]="campo.valor" multiple [disabled]="!campo.editable">
            <mat-option *ngFor="let op of campo.opciones" [value]="op.nombre">{{op.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="campo.tipo == 'file'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
          <div class="col-12">
            <span>{{campo.etiqueta}}</span>
          </div>
          <div *ngIf="!campo.file_name" class="col-12">
            <button class="fileButton" [disabled]="loading_upload_file" (click)="clickInput('input_file'+campo.id)" mat-stroked-button color="text-2"
            [attr.style]="'border-color:'+color+' !important'"
            ><span style="font-weight: 200;">Subir archivo <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i></span></button>
            <input style="display: none;" type="file" id="input_file{{campo.id}}" name="archivo" (change)="onFileSelectInputCustom($event,campo)" autocomplete="off"/>
          </div>
          <div *ngIf="campo.valor && campo.valor != ''" class="col-12">
            <div class="row">
              <div class="col-10">
                <a [href]="campo.valor" target="_blank">
                  <span>{{campo.file_name}}</span>
                </a>
              </div>
              <div class="col-2">
                <a *ngIf="campo.editable" (click)="clearDataFile(campo)">
                  <i class="fas fa-times color-primary" style="font-size: 18px;"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="campo.tipo == 'image'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
          <div class="col-12">
            <span>{{campo.etiqueta}}</span>
          </div>
          <div *ngIf="!campo.file_name" class="col-12">
            <button class="fileButton" [disabled]="loading_upload_file" (click)="clickInput('input_file'+campo.id)" mat-stroked-button color="text-2"
            [attr.style]="'border-color:'+color+' !important'"
            ><span style="font-weight: 200;">Subir imagen <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i></span></button>
            <input style="display: none;" type="file" id="input_file{{campo.id}}" name="archivo" (change)="onFileSelectInputCustom($event,campo)" autocomplete="off" accept="image/x-png,image/jpeg"/>
          </div>
          <div *ngIf="campo.valor && campo.valor != ''" class="col-12">
            <div class="row">
              <div class="col-10">
                <a [href]="campo.valor" target="_blank">
                  <span>{{campo.file_name}}</span>
                </a>
              </div>
              <div class="col-2">
                <a *ngIf="campo.editable" (click)="clearDataFile(campo)">
                  <i class="fas fa-times color-primary" style="font-size: 18px;"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">&nbsp;</div>
      </div>
      <div class="row">&nbsp;</div>
      <div class="row">
        <button (click)="enviarDatos()"
        style="margin:0rem 1rem 0rem 0.5rem; width: -webkit-fill-available;"
        [ngStyle]="{'background-color': color}"
        mat-flat-button color="primary">
        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
        ENVIAR DATOS
      </button>
    </div>
    <div class="row">&nbsp;</div>

    </ng-template>


</div>
