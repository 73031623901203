
<div *ngIf="punto_soporte">
    <app-videocall-block
        (leftCall)="leave()"
        [channel]="'whatsoportechannel-'+punto_soporte.id" 
        [mode]="mode"  
        [context]="punto_soporte"
    >

    </app-videocall-block>
</div>
