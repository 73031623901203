<app-header></app-header>
<div class="container main-container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <button (click)="irListado()" style="padding: 0" mat-button color="primary">
                    <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 *ngIf="!id_cliente || id_cliente==0">Alta de nuevo cliente</h2>
                <h2 *ngIf="id_cliente && id_cliente!=0">Edición de cliente</h2>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <app-cliente-info [id_cliente]="id_cliente"></app-cliente-info>
            </div>
        </div>
        <div class="row">&nbsp;</div>
    </div>
<app-footer></app-footer>