<div>
    <app-header></app-header>
    <div class="main-container container">
        <div class="row">&nbsp;</div>
        <div class="row" *ngIf="!loading && puntos_soporte && puntos_soporte.length>0">
            <div class="col-12">
                <h3>Tienes acceso a los siguientes puntos de video soporte. Selecciona alguno para iniciar una videollamada</h3>
                <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">&nbsp;</div>
        <div *ngIf="!loading && puntos_soporte && puntos_soporte.length==0" class="row" style="text-align:center;height: 60vh;">
            <div class="col-12 v-center">
                <h4>No tienes puntos de videosoporte asignados</h4>
            </div>
        </div>
        <div class="row" *ngIf="puntos_soporte && puntos_soporte.length>0">
            <div class="col-md-2">&nbsp;</div>
            <div class="col-12 col-md-8">

                <table class="ws-table" style="width: 100%;">
                    <tr>
                        <th>
                            Punto(s) de soporte
                        </th>
                        <th>
                            &nbsp;
                        </th>
                    </tr>
                    <tr *ngFor="let ps of puntos_soporte" style="cursor: pointer;">
                        <td>
                            <h5>
                                {{ps.nombre}}, en {{ps.direccion}}
                            </h5>
                        </td>
                        <td>
                            <a (click)="checkSession(ps?.slug, false)" target="_blank">
                                <button mat-flat-button color="primary" [disabled]="isWaitingUpdate">
                                    INICIAR VIDEO SOPORTE
                                </button>
                            </a>
                        </td>
                        <td>
                            <a (click)="checkSession(ps?.slug, true)" target="_blank">
                                <button mat-flat-button color="primary" [disabled]="isWaitingUpdate">
                                    INICIAR VIDEO SOPORTE EN APP
                                </button>
                            </a>
                        </td>
                        <td>
                            <a href="/#/cliente/encuesta/{{ps.marca.id}}" target="_blank">
                                <button mat-flat-button color="primary">
                                    ABRIR FORMULARIO
                                </button>
                            </a>
                        </td>
                    </tr>
                </table>

            </div>
            <div class="col-md-2">&nbsp;</div>
        </div>
    </div>
</div>
