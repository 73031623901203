<mat-toolbar *ngIf="atributos && atributos.logo && atributos.logo.valor !=''" color="primary" 
[ngStyle]="{'background-color': atributos.primary_color}">
    <img 
        [src]="atributos.logo.valor"
        style="box-sizing:unset; height: 45px; padding: 10px;cursor: pointer;">

    <!-- This fills the remaining space of the current row -->
    <div style="padding-left: 25px;">
        <div *ngIf="atributos.titulo != ''" class="right-header-container menu-link">
            {{atributos.titulo}}
        </div>
    </div>
</mat-toolbar>

<mat-toolbar *ngIf="atributos.logo.valor =='' && atributos.primary_color != ''" color="primary" 
[ngStyle]="{'background-color': atributos.primary_color}">
    <!--img 
        src="/assets/images/logo-whatsoporte-header.png"
        style="box-sizing:unset; height: 45px; padding: 10px;cursor: pointer;">-->

    <!-- This fills the remaining space of the current row -->
    <div style="padding-left: 65px;">
        <div *ngIf="atributos.titulo != ''" class="right-header-container menu-link">
            {{atributos.titulo}}
        </div>
        <div *ngIf="!atributos.titulo || atributos.titulo == ''" class="right-header-container menu-link">
            Whatsoporte
        </div>
    </div>
</mat-toolbar>
<mat-toolbar *ngIf="atributos.logo.valor =='' && atributos.primary_color == ''" color="primary" style="background-color: #8B0309;">
    <!--<img 
        src="/assets/images/logo-whatsoporte-header.png"
        style="box-sizing:unset; height: 45px; padding: 10px;cursor: pointer;">-->

    <!-- This fills the remaining space of the current row -->
    <div style="padding-left: 65px;">
        <div *ngIf="atributos.titulo != ''" class="right-header-container menu-link">
            {{atributos.titulo}}
        </div>
        <div *ngIf="!atributos.titulo || atributos.titulo == ''" class="right-header-container menu-link">
            Whatsoporte
        </div>
    </div>
</mat-toolbar>

