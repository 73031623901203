import { Component, OnInit,Input,EventEmitter, Output} from '@angular/core';
import {ValidatorService} from 'src/app/services/tools/validator.service'
import {FacadeService} from 'src/app/services/facade.service'

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  @Output() public consumidorActualizado: EventEmitter<any> = new EventEmitter;
  @Input() public consumidor:any;
  @Input() public atributos:any;
  @Input() public tipo_acivacion:any;
  public campos_dinamicos:any;
  public color:string="#8B0309";
  public loading_upload_file:boolean=false;
  public loading:boolean=false;
  public nombre_editable:boolean=false;
  public correo_editable:boolean=false;
  constructor(private validator:ValidatorService,private facade:FacadeService) { }

  ngOnInit(): void {
    if(this.atributos.secondary_color && this.atributos.secondary_color !=''){
      this.color = this.atributos.secondary_color
    }
    this.campos_dinamicos = JSON.parse(this.consumidor.campos_dinamicos_json)
    for(let campo of this.campos_dinamicos){
      if(campo.valor && campo.valor != ""){
        campo.editable = false
      }else{
        campo.editable = true
      }
    }
    console.log(this.campos_dinamicos)
    if(this.consumidor.nombre != ""){
      this.nombre_editable = true
    }
    if(this.consumidor.correo != ""){
      this.correo_editable = true
    }
  }

  soloNumeros(e,campo){
    if(e.keyCode < 48 || e.keyCode > 57){
      campo.valor = campo.valor.substring(0, campo.valor.length - 1);
    }
  }

  clickInput(input){
    document.getElementById(input).click();
  }


  onFileSelectInputCustom($event,campo){
    var archivo = {input_file:null,nombre_archivo:null}
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        archivo.input_file = file;
        //campo.valor = file.name
        archivo.nombre_archivo = file.name;
        this.subirArchivoCampoDinamico(archivo,campo)
      }
  }


  subirArchivoCampoDinamico(archivo,campo){
    if(this.loading_upload_file)return;
    this.loading_upload_file = true
    var id_marca = this.consumidor.marca
    var telefono = this.consumidor.celular
    this.facade.subirArchivoCampoDinamicoConsumidor(id_marca,telefono,archivo,this.consumidor.token_encuesta)
    .subscribe(
    (response)=>{
        this.loading_upload_file = false
        campo.valor = response.url
        campo.file_name = archivo.nombre_archivo
        console.log(campo)
    },
    (error)=>{
        this.loading_upload_file = false
        alert(JSON.stringify(error))
    }
  );
}

  clearDataFile(campo){
    campo.valor = ""
    campo.file_name = null
  }

  async enviarDatos(){
    if(this.loading)return;
    if(this.consumidor.id){
      if(!this.validator.email(this.consumidor.correo.trim())){
        alert("Por favor revisar el formato del correo electrónico ingresado.")
        return
      }
      for(let campo of this.campos_dinamicos){
        if(campo.valor == ""){
          alert("Por favor completa todos los campos para continuar.")
          return
        }
      }
      this.loading=true
      this.consumidor.campos_dinamicos_json = JSON.stringify(this.campos_dinamicos)
      this.consumidor.id_consumidor_crm = this.consumidor.id

      //validación que sea una activación de tipo QR
      if(this.tipo_acivacion && this.tipo_acivacion.tipo == 'qr'){
        //obtener QR del api externa y guardar url de la imagen
        this.facade.obtenerQR(this.tipo_acivacion.slug)
        .subscribe(
          (response)=>{
            console.log(response)
            this.consumidor.url_qr = response.image
            this.guardarDatosConsumidor()
          },
          (error)=>{
            this.loading=false
            console.log(error)
            alert("Ha ocurrido un error, por favor intentelo más tarde")
          }
        )
      }else{
        this.consumidor.url_qr = ""
        this.guardarDatosConsumidor()
      }
    }else{
      for(let campo of this.campos_dinamicos){
        if(campo.valor == ""){
          alert("Por favor completa todos los campos para continuar.")
          return
        }
      }
      this.loading=true
      let data = {campos_dinamicos_json:JSON.stringify(this.campos_dinamicos)}
      // this.consumidor.campos_dinamicos_json = JSON.stringify(this.campos_dinamicos)
      this.facade.guardarDatosEncuestaVideototem(data, this.consumidor.id_marca)
      .subscribe(
        (response)=>{
          this.loading=false
          alert("Datos guardados correctamente")
          window.location.reload()
          console.log(response)
        },(error)=>{
          this.loading=false
          alert("Hubo un error al subir las respuestas. Revise los datos e intente de nuevo")
          console.error(error)
        }
      )
      // console.error("Nueva funcion de enviar")
    }
  }

  guardarDatosConsumidor(){
    this.facade.guardarDatosConsumidor(this.consumidor.token_encuesta,this.consumidor)
    .subscribe(
      (response)=>{
        console.log(response)
        this.loading=false
        if(this.consumidorActualizado){
          this.consumidorActualizado.emit(response.consumidor);
        }
      },
      (error)=>{
        this.loading=false
        if(error.status == 400){
          alert(error.error.error)
        }else{
          alert("Ha ocurrido un error, por favor intentelo más tarde")
          console.log(error)
        }
      }
    )
  }

}
