<div *ngIf="!data.borrar" class="container" style="padding-bottom: 20px;overflow-y: auto; max-height: 90vh;">
    <div class="col-md-12">
        <div class="row">
            <h6 style="font-weight: 600;">
                Nuevo campo
            </h6>  
        </div>
    </div>
    <div class="col-md-12">
        <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 0.1rem;" appearance="outline">
            <mat-label>Nombre del campo</mat-label>
            <input matInput placeholder="Nombre del campo" [(ngModel)]="campo.etiqueta">
        </mat-form-field>
    </div>
    <div class="col-md-12">
        <mat-form-field style="width: 100%;margin: 0.1rem 0.1rem;margin-bottom: 0" appearance="outline">
            <mat-label>Tipo de campo</mat-label>
            <mat-select [disabled]="campo?.bloquear_borrado" [(value)]="campo.tipo">
                <mat-option *ngFor="let t of tipos" [value]="t.slug">{{t.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="campo.tipo == 'dropdown' || campo.tipo == 'dropdown_multi'">
        <div class="col-md-12">
            <div class="row">
                <h6 style="font-weight: 200;">
                    Opciones
                </h6>  
            </div>
        </div>
        <div class="row" *ngFor="let o of campo.opciones">
            <div class="col-md-11">
                <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 0.1rem;" appearance="outline">
                    <mat-label>Opción</mat-label>
                    <input matInput placeholder="Opción" [(ngModel)]="o.nombre">
                </mat-form-field>
            </div>
            <div *ngIf="o.id !== 0" class="col-md-1 d-flex align-items-center">
                <a (click)="borrarOpcion(o.id)">
                    <i class="fas fa-times color-primary" style="font-size: 30px;"></i>
                </a>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row d-flex justify-content-start">
                <button (click)="agregarOpcion()" mat-raised-button color="primary">
                    Agregar opción
                </button>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="col-md-12">
        <div class="row d-flex justify-content-end">
            <button (click)="guardarCampo()" mat-raised-button color="primary">
                <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                Guardar Campo
            </button>
        </div>
    </div>
</div>
<div *ngIf="data.borrar" class="container" style="padding-bottom: 20px;overflow-y: auto; max-height: 90vh;">
    <div class="col-md-12">
        <div class="row">
            <h6 style="font-weight: 600;">
                Borrar campo
            </h6>  
        </div>
    </div>
    <div>
        <h6 style="font-weight: 200;">
            ¿Esta seguro que desea borrar este campo? 
        </h6>  
    </div>
    <div class="row">&nbsp;</div>
    <div class="col-md-12">
        <div class="row d-flex justify-content-end">
            <button (click)="dialogRef.close()" mat-raised-button color="secondary">
                Cancelar
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button (click)="borrarCampo()" mat-raised-button color="primary">
                <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                Borrar campo
            </button>
        </div>
    </div>
</div>