import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare var $;
declare var document;

@Component({
  selector: 'app-nuevo-chatboot-dialog',
  templateUrl: './nuevo-chatboot-dialog.component.html',
  styleUrls: ['./nuevo-chatboot-dialog.component.scss']
})
export class NuevoChatbootDialogComponent implements OnInit {
  public loading:boolean = false;
  public mensaje:string = "";
  public automatico:boolean = true;
  public encuesta:boolean = false;
  public formulario:boolean = false;
  public marcas:any = []
  public chatbots:any = []
  public visible_emoji:boolean=false;
  public bold_activo:boolean = false; 
  public italic_activo:boolean = false; 
  public selText:string="";
  public start_select:any;
  public end_select:any;
  public primer_periodo_hora_inicio:string="";
  public primer_periodo_hora_fin:string="";
  public segundo_periodo_hora_inicio:string="";
  public segundo_periodo_hora_fin:string="";
  public tiempo_universal_coordinado:number=0;
  public tiempo_universal_coordinado_local:number=0;
  public tiposDeChatbot : any = [
    {
      nombre : "Normal",
      valor : {
        encuesta : false,
        formulario : false
      }
    },
    {
      nombre : "Encuesta",
      valor : {
        encuesta : true,
        formulario : false
      }
    },
    {
      nombre : "Formulario",
      valor : {
        encuesta : false,
        formulario : true
      }
    }
  ];
  public tipoChatbot : any;

  public acceso_rapido:string="";

  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<NuevoChatbootDialogComponent>,public router: Router ) {
      dialogRef.disableClose = false;
  }

  ngOnInit(): void {
    let date = new Date();
    this.tiempo_universal_coordinado_local = date.getHours() - date.getUTCHours();
    this.tiempo_universal_coordinado = this.tiempo_universal_coordinado_local;
    this.loadMarcas()
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        this.marcas = response;
        this.loading = false;
        this.listarChatbots(response[0].id)
      },
      (error)=>{
        this.loading = false;
      }
    ) 
  }

  listarChatbots(id_marca){
    this.loading = true
    this.facade.listarChatbots(id_marca)
    .subscribe(
      (response)=>{
        this.chatbots = response
        this.loading = false
        /*for(let m of this.marcas){
          for(let c of response){
            if(m.id == c.marca){
              if(c.activo){
                m.isUsed = true;
              }
            }
          }
        }*/
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        console.log(error)
        this.loading = false
      }
    )
  }

  isComplete(){
    for(let m of this.marcas){
      if(m.selected && this.mensaje != "" && this.tipoChatbot){
        return false
      }
    }
    return true
  }

  onSelectOption(event){
    console.log(event)
    for(let m of this.marcas){
      if(event.source.value == m.id && event.checked){
        m.selected = true
      }else{
        m.selected = false
      }
    }
  }

  establecerTipo(event : any): void{
    this.formulario = event.value.formulario;
    this.encuesta = event.value.encuesta;
    if (this.formulario){
      this.automatico = true;
    }
  }
  crearChatbot(){
    let primer_periodo_valido = false;
    let segundo_periodo_valido = false;
    if (
      (this.primer_periodo_hora_inicio !=="" && this.primer_periodo_hora_fin !== "") ||
      (this.primer_periodo_hora_inicio ==="" && this.primer_periodo_hora_fin === "") 
    ){
      primer_periodo_valido = true;
    }
    
    if (
      (this.segundo_periodo_hora_inicio !=="" && this.segundo_periodo_hora_fin !== "") ||
      (this.segundo_periodo_hora_inicio ==="" && this.segundo_periodo_hora_fin === "")
    ){
      segundo_periodo_valido = true;
    }
    
    
    if(!primer_periodo_valido || !segundo_periodo_valido){
      alert("Por favor, revise el horario de los periodos del chatbot")
      return;
    }

    if(this.tiempo_universal_coordinado === null || this.tiempo_universal_coordinado > 24 || this.tiempo_universal_coordinado < -24){
      alert("Zona horaria no válida. Revise las horas de UTC")
      return;
    }
    this.loading = true;
    var id_marca
    for(let m of this.marcas){
      if(m.selected){
        id_marca = m.id
      }
    }
    var data ={
      mensaje: this.mensaje,
      automatico: this.automatico,
      acceso_rapido: this.acceso_rapido,
      encuesta: this.encuesta,
      formulario : this.formulario,
      slug : "inicio",
      primer_periodo_hora_inicio : this.primer_periodo_hora_inicio,
      primer_periodo_hora_fin : this.primer_periodo_hora_fin,
      segundo_periodo_hora_inicio : this.segundo_periodo_hora_inicio,
      segundo_periodo_hora_fin : this.segundo_periodo_hora_fin,
      tiempo_universal_coordinado : this.tiempo_universal_coordinado
    }
    this.facade.crearChatbots(id_marca,data)
    .subscribe(
      (response)=>{
        this.router.navigate(['cliente/chatbot/'+response.id]);
        this.dialogRef.close();
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
        console.log(error);
      }
    ) 
  }

  addEmoji(event){
    console.log(event)
    this.mensaje = this.mensaje + event.emoji.native
    //this.visible_emoji = false
  } 

  setBold(){
    if(this.selText != ""){
      this.mensaje = [this.mensaje.slice(0, this.start_select), '*', this.mensaje.slice(this.start_select)].join('');
      this.mensaje = [this.mensaje.slice(0, this.end_select+1), '*', this.mensaje.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.bold_activo = !this.bold_activo;
    this.mensaje = this.mensaje +"*"
  } 

  setItalic(){
    if(this.selText != ""){
      this.mensaje = [this.mensaje.slice(0, this.start_select), '_', this.mensaje.slice(this.start_select)].join('');
      this.mensaje = [this.mensaje.slice(0, this.end_select+1), '_', this.mensaje.slice(this.end_select+1)].join('');
      this.selText =""
      this.start_select =0
      this.end_select =0
      return
    }
    this.italic_activo = !this.italic_activo;
    this.mensaje = this.mensaje +"_"
  } 
 
  GetSelectedText () {
    var selText = "";
    var url = document.getElementById("message") as HTMLSelectElement;
    if (window.getSelection) {  // all browsers, except IE before version 9
        if (document.activeElement && 
                (document.activeElement.tagName.toLowerCase () == "textarea" || 
                 document.activeElement.tagName.toLowerCase () == "input")) 
        {
            var text = document.activeElement.value;
            this.start_select = document.activeElement.selectionStart
            this.end_select = document.activeElement.selectionEnd
            selText = text.substring (document.activeElement.selectionStart, 
                                      document.activeElement.selectionEnd);
        }
        else {
            var selRange = window.getSelection ();
            selText = selRange.toString ();
        }
    } 
    else {
        if (document.selection.createRange) {       // Internet Explorer
            var range = document.selection.createRange ();
            selText = range.text;
        }
    }
    return selText;
  }

  onSelect(eve){
    this.italic_activo = false
    this.bold_activo = false
    console.log(eve)
    var selText = "";
    selText = this.GetSelectedText ();
    this.selText = selText
  }

}
