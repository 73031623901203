import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-detalle-cliente-segundo',
  templateUrl: './detalle-cliente-segundo.component.html',
  styleUrls: ['./detalle-cliente-segundo.component.scss']
})
export class DetalleClienteSegundoComponent implements OnInit {
  public id_cliente:number;
  
  constructor(public router:Router,public route:ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id_cliente = params['id_cliente'];
    })
  }

  irListado(){
    this.router.navigate(['cliente/admin']);
  }

}
