<div>
    <app-header></app-header>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <button (click)="irPerfil()" style="padding: 0" mat-button color="primary">
                    <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Chats de agente {{nombre_completo}}</h2>
            </div>
        </div>
    </div>
    <div class="main-container">
        <div class="row no-margin">
            <div class="col-12">
                <div class="chat-column-container w1">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/marca-icon.png"/>
                            <span class="card-title">
                                Marcas
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <div class="marcas-container">
                            <i *ngIf="loading_marcas" class="fa-spin fas fa-spinner"></i>
                            <div class="marca-container-wrapper" 
                                        [ngClass]="{'active':m.selected}"
                                        *ngFor="let m of marcas"
                                        (click)="seleccionarMarca(m)">
                                <div class="marca-container">
                                    <div class="row">
                                        <div class="col-12">
                                            <img class="logo" src="{{m.logo}}"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="titulo">
                                                Marca:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span class="subtitulo">
                                                {{m.nombre}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="titulo">
                                                Sesiones:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span class="subtitulo">
                                                {{m.sesiones}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w2">
                    
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/consumidores-icon.png"/>
                            <span class="card-title">
                                Consumidores
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Buscar</mat-label>
                            <input matInput placeholder="Buscar por número o mensaje" [(ngModel)]="search" (change)="onSearch()" (keyup)="checkIsClear()">
                            <mat-icon matPrefix>search</mat-icon>
                            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div class="consumidores-container">
                            <div class="loader">
                                <i *ngIf="loading_consumidores || loading_search_consumidor" class="fa-spin fas fa-spinner"></i>
                            </div>
                            <div (click)="seleccionarConsumidor(c)" 
                                class="consumidor-container-wrapper" 
                                [ngClass]="{'active':c.selected}"
                                *ngFor="let c of consumidores">
                                <div class="consumidor-container">
                                    <div class="row h-center">
                                        <div class="col-2 v-center">
                                            <div class="perfil-placeholder v-center">
                                                {{iniciales(c.nombre_completo)}}
                                            </div>
                                        </div>
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="nombre">
                                                        {{c.nombre_completo}}
                                                    </span>
                                                </div>
                                                <div *ngIf="c.new_message" class="badge-new-message"></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-10" style="overflow: hidden;">
                                                    <span class="ultimo_mensaje_preview">
                                                        {{c.ultimo_mensaje_preview}}
                                                    </span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="ultimo_mensaje_preview">
                                                        {{c.fecha_ultimo_mensaje | date:"dd'/'MM" :locale:'es-MX'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;padding: 10px" class="h-center" *ngIf="has_more && (!search || search=='')">
                            <button (click)="verMasConsumidores()" mat-flat-button color="primary">
                                VER MÁS
                                <i *ngIf="loading_vermas" class="fa-spin fas fa-spinner"></i>
                            </button>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w3">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/sesiones-activas-icon.png"/>
                            <span class="card-title">
                                Sesión activa
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <mat-card class="sesion-top-info">
                            <div class="row" *ngIf="marca_actual">
                                <div class="col-3">
                                    <span class="titulo">
                                        Cliente
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                        {{marca_actual.nombre}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="consumidor_actual">
                                <div class="col-3">
                                    <span class="titulo">
                                        Chat con
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                        {{consumidor_actual.nombre_completo}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="!consumidor_actual">
                                <div class="col-3">
                                    <span class="titulo" style="color: white;">
                                        Chat con
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="consumidor_actual && consumidor_actual.etiqueta_conversacion">
                                <div class="col-4">
                                    <span class="titulo">
                                        Estatus del chat
                                    </span>
                                </div>
                                <div class="col-8">
                                    <span class="subtitulo">
                                        {{consumidor_actual.etiqueta_conversacion.nombre}}
                                    </span>
                                </div>
                            </div>
                        </mat-card>
                        <div id="chat-container-scroll" class="chat-container-wrapper">
                            <div class="chat-container" *ngIf="consumidor_actual">
                                <div class="row" *ngFor="let message of consumidor_actual.chat" [ngClass]="{'r-float':message.direccion=='outgoing','l-float':message.direccion=='incoming'}">
                                    <div class="col-12">
                                        <div class="row" *ngIf="message.tipo=='evento'">
                                            <div class="col-12 d-flex justify-content-center">
                                                <span class="wrap evento">
                                                    {{message.mensaje}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="chat-message-bubble" *ngIf="message.tipo!=='evento'" [ngClass]="{'incoming':message.direccion=='incoming','outgoing':message.direccion=='outgoing'}">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span *ngIf="message.tipo=='texto'">
                                                        {{message.mensaje}}
                                                    </span>
                                                    <span *ngIf="message.tipo=='imagen'">
                                                        <span *ngIf="message.direccion=='outgoing'">
                                                            <img style="margin: 10px; width: 100px" src="{{message.archivo}}"/>
                                                        </span>
                                                        <span *ngIf="message.direccion=='incoming'">
                                                            <img style="margin: 10px; width: 100px" src="{{message.imagen_entrante_link}}"/>
                                                            <a href="{{message.imagen_entrante_link}}" target="_blank">Guardar imagen</a>
                                                            <br/>
                                                            {{message.mensaje}}
                                                        </span>
    
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12" style="text-align:right;font-size:0.75em">
                                                    {{message.creacion | date:"dd'/'MMM'/'yyyy HH:mm" :locale:'es-MX'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="chat-bottom-anchor"></div>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w2">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/herramientas-icon.png"/>
                            <span class="card-title">
                                Herramientas
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <div class="actions-container-wrapper"  *ngIf="consumidor_actual && consumidor_actual.id">
                            <div class="row">
                                <mat-form-field class="mx-3" style="width: 100%;" appearance="outline">
                                    <mat-label>Estatus del chat</mat-label>
                                    <mat-select [(ngModel)]="estatus_chat_actual.id" (selectionChange)="editando_estatus_chat=true">
                                        <mat-option *ngFor="let etiqueta of etiquetas_conversacion_marca" [value]="etiqueta.id">
                                        {{etiqueta.nombre}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row d-flex justify-content-center" *ngIf="editando_estatus_chat">
                                <button mat-flat-button color="primary" (click)="establecerEtiquetaAConsumidor()">
                                    <small>GUARDAR</small>
                                </button>
                            </div>
                            <div class="actions-container" *ngIf="!transferir_chat">
                                <div class="action-container flex-10">
                                    <div class="row align-items-center mt-3">
                                        <div class="col-2">
                                            <img src="assets/images/transferir-icon.png" style="width: 24px"/>
                                        </div>
                                        <div class="col-10">
                                            <button (click)="transferConsumer()" style="padding: 0" mat-button color="text-2"><span style="font-weight: 200;">Transferir sesión</span></button>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                        <div class="col-2">
                                            <i class="fa fa-download fa-lg color-primary"></i>
                                        </div>
                                        <div class="col-10">
                                            <button (click)="descargarChat()" [disabled]="loading_pdf ? true : false" style="padding: 0" mat-button color="text-2">
                                                <i *ngIf="loading_pdf" class="fa-spin fas fa-spinner mx-1"></i>
                                                <span style="font-weight: 200;">Decargar chat</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="actions-container" *ngIf="transferir_chat">
                                <div class="action-container flex-8 new-consumidores-container">
                                    <div class="row">
                                        <span class="action-logo-button" style="margin: 0rem 1rem;">Transferir con</span>
                                        <mat-form-field style="margin-bottom: 0; display: flex; justify-content: flex-start;flex: 1;margin-left: 20px;" appearance="outline">
                                            <mat-label>Nombre del agente</mat-label>
                                            <mat-select [(ngModel)]="id_asesor">
                                              <mat-option *ngFor="let asesor of asesores" [value]="asesor.id">
                                                {{asesor.nombre_completo}} / {{asesor.tipo}}
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div> 
                                <div class="row bottom-actions-container container-shadow flex-1 ">
                                    <div class="col-6 p-0">
                                        <button style="margin:0rem 0.5rem 0rem 1rem; width: -webkit-fill-available" (click)="cancelTransferConsumer()" mat-stroked-button color="text-1">CANCELAR</button>
                                    </div>
                                    <div class="col-6 p-0">
                                        <button style="margin:0rem 1rem 0rem 0.5rem; width: -webkit-fill-available" (click)="transferToIdConsumer()" mat-flat-button color="primary">TRANSFERIR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    
    </div>
    <app-footer></app-footer>
</div>