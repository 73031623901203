<div class="loader">
    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
</div>
<div *ngIf="!loading" class="row">
    <div class="col-sm-8 col-8">&nbsp;</div>
    <div class="col-sm-4 col-4">
        <div class="row">
            <div class="col-sm-2 col-2">&nbsp;</div>
            <div class="col-sm-5 col-5">
                <a *ngIf="agente.usuario_activo" (click)="irEditarAgente()">
                <div class="row">
                        <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .5rem;">
                        <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;">&nbsp;EDITAR</h6>
                    </div>
                </a>
            </div>
            <div class="col-sm-5 col-5">
                <a (click)="desactivarAgente()">
                    <div class="row">
                        <i *ngIf="agente.usuario_activo" class="fas fa-user-times color-text-1" style="font-size: 24px;"></i>
                        <i *ngIf="!agente.usuario_activo" class="fas fa-user color-text-1" style="font-size: 24px;"></i>
                        <h6 *ngIf="agente.usuario_activo" style="font-weight: 600;margin-top: .2rem;">&nbsp;DESACTIVAR</h6>
                        <h6 *ngIf="!agente.usuario_activo" style="font-weight: 600;margin-top: .2rem;">&nbsp;ACTIVAR</h6>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<mat-card *ngIf="!loading && !agente.usuario_activo">
    <div class="row">
        <div class="col-md-12">
            <h4 style="font-weight: 600;">
                Para ver y editar el perfil de este agente, es necesario activarlo.
            </h4>        
        </div>
    </div>
</mat-card>
<!--Sección datos personales-->
<mat-card *ngIf="!loading && agente.usuario_activo">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos personales
            </h4>        
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <h3 class="color-primary" style="font-weight: 200;">{{agente.nombre_completo}}</h3>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Fecha de nacimiento</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.fecha_de_nacimiento | date: 'dd/MM/yyyy'}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Genero</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.genero | titlecase}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Número celular</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.numero_celular}}</h6>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>

<!--Sección datos de acceso-->
<mat-card *ngIf="!loading && agente.usuario_activo">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos de acceso
            </h4>        
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Correo electrónico</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.username}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Contraseña</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 700;">•  •  •  •  •  •  •  •</h6>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>

<!--Sección datos profesionales-->
<mat-card *ngIf="!loading && agente.usuario_activo">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos profesionales
            </h4>        
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Idiomas</h6> 
                </div> 
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.idiomas_concat}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <!--<div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">URL Gruveo</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.url_gruveo}}</h6>
                </div>
            </div>-->
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Marcas asignadas</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{agente.marcas_concat}}</h6>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>

<!--Estadisticas-->
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="loader">
    <i *ngIf="!loading && loading_estadisticas" class="fa-spin fas fa-spinner"></i>
</div>
<div *ngIf="!loading && loading_estadisticas" class="row">&nbsp;</div>
<div *ngIf="!loading && loading_estadisticas" class="row">&nbsp;</div>
<div *ngIf="!loading && loading_estadisticas" class="row">&nbsp;</div>
<div *ngIf="!loading && !loading_estadisticas" class="row">
    <div class="col-md-3"></div>
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" style="width: 100%;" appearance="outline">
            <mat-label>Selecciona marca</mat-label>
            <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
              <mat-option *ngFor="let marca of marcas_lista" [value]="marca.id">
                {{marca.nombre}} 
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
            <mat-label>A partir del</mat-label>
            <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="initialPicker" [(ngModel)]="fecha_filtro_inicial" [value]="fecha_filtro_inicial" 
            (click)="initialPicker.open()"
            (dateChange)="_monthSelectedHandler(initialPicker,$event)"
            (keydown.arrowdown)="initialPicker.open()"
            (keydown.enter)="initialPicker.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="initialPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #initialPicker color="color-primary" startView="month"></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
            <mat-label>Hasta el</mat-label>
            <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="finalPicker" [(ngModel)]="fecha_filtro_final" [value]="fecha_filtro_final" 
            (click)="finalPicker.open()"
            (dateChange)="_monthSelectedHandler(finalPicker,$event)"
            (keydown.arrowdown)="finalPicker.open()"
            (keydown.enter)="finalPicker.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="finalPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #finalPicker color="color-primary" startView="month"></mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngIf="isFilter()" class="col-lg-3 d-flex align-items-center">
        <a (click)="limpiar()">
            <div class="row d-flex align-items-center">
                <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
            </div>
        </a>
    </div>
</div>
<div class="row">&nbsp;</div>  
<div *ngIf="!loading && agente.usuario_activo && !loading_estadisticas" class="row">
    <div class="col-md-3">
        <mat-card class="card">
            <h6 style="font-weight: 600;">Tiempo promedio de respuesta</h6> 
            <div class="row">&nbsp;</div>
            <div *ngIf="tiempo_respuesta_request" class="row d-flex align-items-end">
                <div *ngIf="loading_respuesta" class="col-4">
                    <h1 class="color-primary" style="font-weight: 200;">{{tiempo_respuesta}}</h1>
                </div>
                <div *ngIf="!loading_respuesta" class="col-6">
                    <h1 class="color-primary" style="font-weight: 200;">{{tiempo_respuesta}}</h1>
                </div>
                <div class="col-6">
                    <h6 class="black-text" style="font-weight: 200;">segundos</h6>
                </div>
                <div *ngIf="loading_respuesta" class="col-2">
                    <i *ngIf="loading_respuesta" class="fa-spin fas fa-spinner"></i>
                </div>
            </div>
            <div *ngIf="!tiempo_respuesta_request" class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="loadMetricasTiempos()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> CARGAR TIEMPOS <i *ngIf="loading_respuesta" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div *ngIf="!tiempo_respuesta_request && loading_respuesta" class="row">
                <div class="col-12">
                    <h6 class="black-text" style="font-weight: 200;">Estamos calculando los tiempos, esto puede llevar algunos minutos</h6>
                </div>
            </div>
            <div *ngIf="!tiempo_respuesta_request" class="row">&nbsp;</div>
        </mat-card>
    </div>
    <div class="col-md-3">
        <mat-card class="card">
            <h6 style="font-weight: 600;">Sesiones atendidas</h6> 
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-12">
                    <h1 class="color-primary" style="font-weight: 200;">{{sesiones_antendidas}}</h1>
                </div>
            </div>
        </mat-card>
    </div>
    <!--<div class="col-md-3">
        <mat-card class="card">
            <h6 style="font-weight: 600;">Promedio diario de tiempos muertos</h6> 
            <div class="row">&nbsp;</div>
            <div class="row d-flex align-items-end">
                <div class="col-6">
                    <h1 class="color-primary" style="font-weight: 200;">0:00</h1>
                </div>
                <div class="col-6">
                    <h6 class="black-text" style="font-weight: 200;">horas</h6>
                </div>
            </div>
        </mat-card>
    </div>-->
    <div class="col-md-3">
        <mat-card class="card">
            <h6 style="font-weight: 600;">Mensajes recibidos</h6> 
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-12">
                    <h1 class="color-primary" style="font-weight: 200;">{{mensajes_recibidos}}</h1>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-3">
        <mat-card>
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-12 d-flex justify-content-center">
                    <a (click)="irChatsAgente()">
                        <div class="row">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><img src="../../../../assets/images/chat.png" width="24" /> VER CHATS</h6>
                        </div>
                    </a>
                </div>
            </div> 
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
        </mat-card>
    </div>
</div>
<div class="row">&nbsp;</div>
<div *ngIf="!loading && agente.usuario_activo && !loading_estadisticas" class="row">
    <div class="col-md-6">
        <mat-card>
            <h6 style="font-weight: 600;">Actividad de sesiones</h6>
            <div class="row">&nbsp;</div>
            <div *ngFor="let sesiones of actividad_sesiones" class="row">
                <div class="col-md-2">
                    <i *ngIf="sesiones.tipo == 'cierre'" class="fas fa-arrow-left" style="color: red;"></i>
                    <i *ngIf="sesiones.tipo == 'inicio'" class="fas fa-arrow-right color-primary"></i>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <h6 *ngIf="sesiones.tipo == 'cierre'" class="black-text" style="font-weight: 200;">Cierre de sesión</h6>
                        <h6 *ngIf="sesiones.tipo == 'inicio'" class="black-text" style="font-weight: 200;">Inicio de sesión</h6>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <h6 class="black-text" style="font-weight: 200;">{{sesiones.creacion | date:'shortTime'}} - {{sesiones.creacion | date}}</h6>
                    </div>
                </div>
            </div> 
        </mat-card>
    </div>
    <div class="col-md-3">
        <mat-card>
            <h6 style="font-weight: 600;">Estatus de sesión</h6>
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div *ngIf="agente.en_linea" class="row">
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;"><i class="fas fa-circle color-primary"></i> En linea</h6>
                </div>
                <div class="col-md-6">
                    <a (click)="cerrarSesionDeAgente()">
                        <div class="row">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-sign-out-alt"></i> CERRAR SESIÓN <i *ngIf="loading_button" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div> 
            <h6 *ngIf="!agente.en_linea" class="black-text" style="font-weight: 200;"><i class="fas fa-circle "></i> Desconectado</h6>
            <div *ngIf="!agente.en_linea" class="row">&nbsp;</div>
        </mat-card>
    </div>
</div>
<div class="row">&nbsp;</div>