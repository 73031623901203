<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5 *ngIf="this.data.disparador && this.data.disparador == '0'">Mensaje inicial chatbot</h5>
            <h5 *ngIf="this.data.disparador && this.data.disparador != '0'">Opción {{this.data.disparador}} chatbot</h5>
            <h5 *ngIf="this.data.isEdit && !this.data.all_data.disparador">Mensaje inicial chatbot</h5>
            <h5 *ngIf="this.data.isEdit && this.data.all_data.disparador">Opción {{this.data.all_data.disparador}} chatbot</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 *ngIf="this.data.disparador && this.data.disparador == '0'">Agregue el mensaje inicial que se mostrará selecionando esta opción.</h6>
                <h6 *ngIf="this.data.disparador && this.data.disparador != '0'">Agregue el mensaje que se mostrará selecionando esta opción.</h6>
                <h6 *ngIf="this.data.isEdit && !this.data.all_data.disparador">Agregue el mensaje inicial que se mostrará selecionando esta opción.</h6>
                <h6 *ngIf="this.data.isEdit && this.data.all_data.disparador">Agregue el mensaje que se mostrará selecionando esta opción.</h6>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 style="font-weight: 600;">Mensaje</h6>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Escribe el mensaje</mat-label>
                    <textarea matInput placeholder="Escribe el mensaje..." (select)="onSelect($event)" [(ngModel)]="mensaje"></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
                <div *ngIf="visible_emoji" class="emoji-container">
                    <div class="row" style="background-color: black;margin: 0px;padding: 5px;margin-bottom: -5px;">
                        <a (click)="visible_emoji = false">
                            <h6 style="color: white;font-size: 18px;"><i class="fas fa-times" style="color: white;font-size: 18px;"></i> Cerrar</h6>
                        </a>
                    </div>
                    <emoji-mart
                        [set]="'google'"
                        [hideRecent]="true"
                        (emojiSelect)="addEmoji($event)"
                        title="Selecciona emoji…"
                        emoji="point_up"
                        >
                    </emoji-mart>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <a (click)="visible_emoji = true">
                            <i class="far fa-smile" style="font-size: 24px;"></i>
                        </a>
                    </div>
                    <div *ngIf="!bold_activo" class="col-sm-4">
                        <a (click)="setBold()">
                            <i class="fas fa-bold" style="font-size: 24px;"></i>
                        </a>
                    </div>
                    <div *ngIf="bold_activo" class="col-sm-4">
                        <a (click)="setBold()">
                            <i class="fas fa-bold color-primary" style="font-size: 24px;"></i>
                        </a>
                    </div>
                    <div *ngIf="!italic_activo" class="col-sm-4">
                        <a (click)="setItalic()">
                            <i class="fas fa-italic" style="font-size: 24px;"></i>
                        </a>
                    </div>
                    <div *ngIf="italic_activo" class="col-sm-4">
                        <a (click)="setItalic()">
                            <i class="fas fa-italic color-primary" style="font-size: 24px;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row" *ngIf="data.esFormulario">
            <div class="col-lg-12 mb-2">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Slug</mat-label>
                    <input type="text" [(ngModel)]="slug" matInput>
                </mat-form-field>
            </div>
            <div class="col-lg-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Validación</mat-label>
                    <mat-select [(ngModel)]="validacion.tipo">
                    <mat-option *ngFor="let validacion of validaciones" [value]="validacion.valor">{{validacion.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-4 d-flex justify-content-center align-items-center" *ngIf="validacion.tipo !== 'imagen' && validacion.tipo !== ''">
                <mat-checkbox (change)="validacion.longitud = null" color="primary"  [(ngModel)]="cualquierLongitud">
                    Sin límite de caracteres
                </mat-checkbox>
            </div>
            <div class="col-lg-8" *ngIf="validacion.tipo !== 'imagen' && validacion.tipo !== ''">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Número de caracteres</mat-label>
                    <input [(ngModel)]="validacion.longitud" [disabled]="cualquierLongitud" type="number" min="0" max="255" matInput>
                </mat-form-field>
            </div>
            <div class="col-lg-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Mensaje de error</mat-label>
                    <input type="text" [(ngModel)]="validacion.mensaje_error" matInput>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button (click)="crearChatbot()" mat-raised-button color="primary" [disabled]="isComplete()">
                GUARDAR <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
            </button>
        </div>
    </div>
</div>