import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-agente-detalle-screen',
  templateUrl: './agente-detalle-screen.component.html',
  styleUrls: ['./agente-detalle-screen.component.scss']
})
export class AgenteDetalleScreenComponent implements OnInit {

  public id_agente:number;
  public pagina_de_origen:number = 1;
  public criteria:string = "";
  public id_marca:number = 0;
  constructor(public router:Router, public route:ActivatedRoute) { }

  ngOnInit() {
    if(history.state.pagina_actual){
      this.pagina_de_origen = history.state.pagina_actual
    }
    if(history.state.criteria){
      this.criteria = history.state.criteria
    }
    if(history.state.id_marca){
      this.id_marca = parseInt(history.state.id_marca)
    }
    this.route.params.subscribe(params => {
      this.id_agente = params['id_agente'];
    })
  }

  irListado(){ 
    if(this.id_agente == 0){
      this.router.navigate(['cliente/agentes']);
    }else{
      let params = {
        pagina_actual : this.pagina_de_origen
      };
      if(this.criteria !== ""){
        params["criteria"] = this.criteria;
      }
      if(this.id_marca !== 0){
        params["id_marca"] = this.id_marca;
      }
      this.router.navigate(['cliente/agente/perfil/'+this.id_agente], { 
        state: params
      });
    }
  }
  agenteGuardado($event){
    this.irListado();
  }

}
