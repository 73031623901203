<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="regresar()" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Detalles del formulario</h2>
                        </div>
                    </div>
                    <div style="position: relative; min-height: 70vh;" class="container">
                        <div class="row w-100 align-items-center" *ngIf="formulario">
                            <div class="col-12 m-3">
                                <mat-card>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h5 class="color-primary">Número de teléfono : {{formulario.consumidor.numero_celular}}</h5>
                                        </div>
                                        <div class="col-lg-12 d-flex justify-content-end" *ngIf="!estaEditando">
                                            <button [disabled]="loading" mat-button color="primary" (click)="editar()">
                                                <small>EDITAR INFORMACIÓN <i *ngIf="loading" class="fa-spin fas fa-spinner"></i></small>
                                            </button>
                                        </div>
                                        <div class="col-lg-12 d-flex justify-content-center" *ngIf="estaEditando">
                                            <div class="col-lg-6 d-flex justify-content-start">
                                                <button [disabled]="loading" mat-button color="primary" (click)="deshacer()">
                                                    <small>DESHACER CAMBIOS</small>
                                                </button>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end">
                                                <button [disabled]="loading" mat-raised-button color="primary" (click)="guardarCambios(false)">
                                                    <small>GUARDAR CAMBIOS <i *ngIf="loading" class="fa-spin fas fa-spinner"></i></small>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-12 m-3" *ngFor="let pregunta of formulario.preguntas">
                                <mat-card>
                                    <p class="color-primary">
                                        {{pregunta.titulo}}
                                    </p>
                                    <div class="col-12" *ngIf="pregunta.tipo === 'texto'">
                                        <mat-form-field style="width: 100%;" appearance="outline">
                                            <mat-label>Respuesta</mat-label>
                                            <textarea [(ngModel)]="pregunta.respuesta" matInput [readonly]="!estaEditando"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12" *ngIf="pregunta.tipo !== 'texto'">
                                        <img [src]="pregunta.respuesta" width="100%">
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-12 m-3">
                                <mat-card>
                                    <div class="row">
                                        <div class="col-lg-6 d-flex justify-content-center">
                                            <button [disabled]="loading" mat-raised-button color="primary" (click)="rechazarFormulario()">
                                                <i class="fa fa-times mr-1" aria-hidden="true"></i>
                                                <small>RECHAZAR FORMULARIO <i *ngIf="loading" class="fa-spin fas fa-spinner"></i></small>
                                            </button>
                                        </div>
                                        <div class="col-lg-6 d-flex justify-content-center">
                                            <button [disabled]="loading" mat-raised-button color="primary" (click)="aceptarFormulario()">
                                                <i class="fa fa-check mr-1" aria-hidden="true"></i>
                                                <small>APROBAR FORMULARIO <i *ngIf="loading" class="fa-spin fas fa-spinner"></i></small>
                                            </button>
                                        </div>                                        
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>