<div class="row">
    <div class="col-12">
        <button 
            (click)="configurarChatAPI()"
            mat-raised-button 
            color="primary" >
            {{button_text}}
            <i *ngIf="loading_chat_api" class="fa-spin fas fa-spinner"></i>
        </button>
    </div>
</div>
<div class="row">&nbsp;</div>
<div class="row">
    <div class="col-12">
        <div *ngIf="loading_chat_api">
            <mat-card>
                <h6>
                    Estamos verificando el estatus de tu cuenta chat-api, espera unos segundos
                </h6>
            </mat-card>
        </div>
    </div>
</div>
<div class="row" *ngIf="!loading_chat_api && chat_api_account_status && chat_api_account_status!=''">
    <div class="col-12 h-center">
        <div *ngIf="chat_api_account_status=='loading'">
            <mat-card>
                <h6>
                    Tu cuenta esta autenticada, pero tu telefono esta desconectado. Asegurate de que el teléfono que configuraste para chat-api tenga Internet (WiFi) y que tenga instalado y funcionando la aplicación Whatsapp y vuelve a intentarlo
                </h6>
                <h6>
                    Si tu teléfono ya esta conectado, o se esta usando en otra computadora presiona "Usar AQUÍ" para conectar con whatsoporte
                </h6>
                <button 
                (click)="takeoverChatAPI()"
                mat-raised-button 
                color="primary" >
                Usar AQUÍ
                <i *ngIf="loading_takeover" class="fa-spin fas fa-spinner"></i>
                </button>
            </mat-card>
        </div>
    </div>
    <div class="col-12 h-center">
        <div *ngIf="chat_api_account_status=='authenticated'">
            <mat-card>
                <h6>
                    ¡Tu cuenta esta autenticada y lista para usarse! Recuerda no usar Whatsapp WEB con tu línea
                </h6>
            </mat-card>
        </div>
    </div>
</div>