import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-campos-consumidor',
  templateUrl: './campos-consumidor.component.html',
  styleUrls: ['./campos-consumidor.component.scss']
})
export class CamposConsumidorComponent implements OnInit {
  public id_marca:number;
  public tipo:string;

  constructor(public router:Router, public route:ActivatedRoute, public facade:FacadeService) { }
  
    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id_marca = params['id_marca'];
        this.tipo = params['tipo'];
      })
    }
    irPerfil(){
      this.router.navigate(['cliente/perfil']);
    }
}
