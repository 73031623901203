<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Conversaciones</h2>
                        </div>
                    </div>
                    <div style="position: relative; min-height: 70vh;" class="container">
                        <div class="row w-100 align-items-center" *ngIf="esEditor">
                            <div class="col-lg-8 d-flex justify-content-center">
                                <mat-form-field class="m-1" style="width: 100%;" appearance="outline">
                                    <mat-label>Buscar</mat-label>
                                    <input type="search" matInput placeholder="Buscar mensaje" [(ngModel)]="criteria" (keyup.enter)="aplicarFiltro()">
                                    <button *ngIf="criteria==''" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="aplicarFiltro()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button mat-button *ngIf="criteria!=''" matSuffix mat-icon-button aria-label="Clear" (click)="limpiarBusqueda()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline" style="width: 100%;">
                                    <mat-label>Selecciona marca</mat-label>
                                    <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
                                      <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                        {{marca.nombre}} 
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row w-100 align-items-center" *ngIf="esEditor">
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline" style="width: 100%;">
                                    <mat-label>A partir del</mat-label>
                                    <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="initialPicker" [(ngModel)]="fecha_filtro_inicial" 
                                    (click)="initialPicker.open()"
                                    (dateChange)="_monthSelectedHandler(initialPicker,$event)"
                                    (keydown.arrowdown)="initialPicker.open()"
                                    (keydown.enter)="initialPicker.open()" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="initialPicker">
                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #initialPicker color="color-primary" startView="month"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline" style="width: 100%;">
                                    <mat-label>Hasta el</mat-label>
                                    <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="finalPicker" [(ngModel)]="fecha_filtro_final" 
                                    (click)="finalPicker.open()"
                                    (dateChange)="_monthSelectedHandler(finalPicker,$event)"
                                    (keydown.arrowdown)="finalPicker.open()"
                                    (keydown.enter)="finalPicker.open()" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="finalPicker">
                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #finalPicker color="color-primary" startView="month"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline" style="width: 100%;">
                                    <mat-label>Selecciona remitente</mat-label>
                                    <mat-select [(ngModel)]="remitente" (selectionChange)="aplicarFiltro()">
                                      <mat-option value="outgoing">
                                        AGENTE
                                      </mat-option>
                                      <mat-option value="incoming">
                                        CONSUMIDOR
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="esFiltro()" class="col-lg-3 d-flex align-items-center">
                                <a (click)="limpiarFiltro()">
                                    <div class="row mt-3 d-flex align-items-center">
                                        <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="row  w-100 table-wrapper">
                            <table class="ws-table text-center" style="width: 100%;">
                                <tr>
                                    <th>
                                        Mensaje
                                    </th>
                                    <th>
                                        Nombre del consumidor
                                    </th>
                                    <th>
                                        Celular del consumidor
                                    </th>
                                    <th>
                                        Agente que atiende
                                    </th>
                                    <th>
                                        Marca
                                    </th>
                                    <th>
                                        Fecha
                                    </th>
                                    <th>
                                        Remitente
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                                <tr *ngFor="let mensaje of mensajes" style="border-bottom: solid 1px lightgray;">
                                    <td class="text-break" style="max-width: 150px;">
                                        {{mensaje.mensaje}}
                                    </td>
                                    <td>
                                        {{mensaje.consumidor}}
                                    </td>
                                    <td>
                                        {{mensaje.numero_celular}}
                                    </td>
                                    <td>
                                        {{mensaje.agente_asignado}}
                                    </td>
                                    <td class="text-break" style="max-width: 100px;">
                                        {{mensaje.marca}}
                                    </td>
                                    <td>
                                        {{darFormatoAFechaYHora(mensaje.fecha)}}
                                    </td>
                                    <td>
                                        <span *ngIf="mensaje.direccion==='incoming'">
                                            CONSUMIDOR
                                        </span>
                                        <span *ngIf="mensaje.direccion!=='incoming'">
                                            AGENTE
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row w-100 d-flex justify-content-center" *ngIf="mensajes.length===0 && criteria!=='' && !loading">
                            <span class="text-center">No se encontraron resultados</span>
                        </div>
                        <div class="row w-100 my-2">
                            <div class="col-lg-12 d-flex justify-content-center" style="width: 100%;" *ngIf="loading">
                                <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                            </div>
                            <div class="col-lg-12 d-flex justify-content-end" style="width: 100%;" *ngIf="!loading">
                                <table>
                                    <tr *ngIf="ultima_pagina > 10">
                                        <td>
                                            <span (click)="cargarPagina(pagina_actual-1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=1" style="cursor: pointer;">
                                                <<
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual-2)" 
                                                class="page_number"
                                                *ngIf="pagina_actual-2>0" style="cursor: pointer;">
                                                {{pagina_actual-2}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual-1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual>1" style="cursor: pointer;">
                                                {{pagina_actual-1}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual)"
                                                class="page_number"
                                                style="font-weight: bold; text-decoration: underline;">
                                                {{pagina_actual}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=ultima_pagina" style="cursor: pointer;">
                                                {{pagina_actual+1}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+2)" 
                                                class="page_number"
                                                *ngIf="pagina_actual+2 <= ultima_pagina" style="cursor: pointer;">
                                                {{pagina_actual+2}}
                                            </span>
                                            <span (click)="cargarPagina(pagina_actual+1)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!=ultima_pagina" style="cursor: pointer;">
                                                >>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="ultima_pagina <= 10">
                                        <td *ngFor="let pagina of paginas">
                                            <span (click)="cargarPagina(pagina)"
                                                class="page_number"
                                                *ngIf="pagina_actual===pagina"
                                                style="font-weight: bold; text-decoration: underline;">
                                                {{pagina}}
                                            </span>
                                            <span (click)="cargarPagina(pagina)" 
                                                class="page_number"
                                                *ngIf="pagina_actual!==pagina" style="cursor: pointer;">
                                                {{pagina}}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>