<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <h2>Dashboard</h2>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <app-metricas-dashboard></app-metricas-dashboard>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>