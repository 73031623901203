<mat-toolbar color="primary">
    <img 
        (click)="goToHome()"
        src="/assets/images/logo-whatsoporte-header.png"
        style="box-sizing:unset; height: 70%; padding: 10px;cursor: pointer;">

    <!-- This fills the remaining space of the current row -->
    <div style="padding-left: 25px;" *ngIf="grupo=='agente'">
        <div [ngClass]="{'active':view=='whatsapp'}"
                class="right-header-container menu-link"
                (click)="goToHome()">
            Whatsapp
        </div>
        <div  [ngClass]="{'active':view=='videosoporte'}"
            class="right-header-container menu-link"
            (click)="goToVideoSoporte()">
            Videosoporte
        </div>
    </div>

    <span class="fill-remaining-space"></span>

    <div>
        <div class="right-header-container">
            Hola {{first_name}}
        </div>
        <div (click)="logout()" class="right-header-container" style="cursor: pointer;">
            <img src="/assets/images/logout.png" style="height: 24px"/>
        </div>
    </div>
</mat-toolbar>