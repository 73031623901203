import { Component, OnInit,Input } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-cliente-encuesta-atributos',
  templateUrl: './cliente-encuesta-atributos.component.html',
  styleUrls: ['./cliente-encuesta-atributos.component.scss']
})
export class ClienteEncuestaAtributosComponent implements OnInit {
  
    @Input() id_marca:any;
    @Input() tipo:any;
    public campos:any = [];
    public loading_upload_file:boolean = false;
    public atributos_json:any = {
      primary_color:"",
      secondary_color:"",
      titulo:"",
      logo:{
        valor:"",
        file_name:""
      }
    }
    public loading:boolean = false;
    public loading_button:boolean = false;
    public marca:any;
    constructor(private facade: FacadeService,public router:Router) { }
  
    ngOnInit(): void {
      this.obtenerCampos()
    }
  
    obtenerCampos(){
      this.loading = true
      this.facade.listarMarcasClienteActual()
      .subscribe(
        (response)=>{
          this.loading = false
          for(let marca of response){
            if(marca.id == this.id_marca){
              this.marca = marca
              console.log(marca)
              if(marca.atributos_encuesta_json && marca.atributos_encuesta_json != "" && this.tipo=='consumidor'){
                this.atributos_json = JSON.parse(marca.atributos_encuesta_json)
              }else if(marca.atributos_agente_encuesta_json && marca.atributos_agente_encuesta_json != "" && this.tipo=='agente'){
                this.atributos_json = JSON.parse(marca.atributos_agente_encuesta_json)
              }
            }
          }
        },
        (error)=>{
          console.log(error)
          this.loading = false
        }
      )
    }

    clickInput(input){
      document.getElementById(input).click();
    }
  
  
    onFileSelect($event){
      var archivo = {input_file:null,nombre_archivo:null}
      if ($event.target.files.length > 0) {
          const file = $event.target.files[0];
          archivo.input_file = file;
          //campo.valor = file.name
          archivo.nombre_archivo = file.name;
          this.subirArchivo(archivo)
        }
    }
  
        
    subirArchivo(archivo){
      if(this.loading_upload_file)return;
      this.loading_upload_file = true
      this.facade.subirArchivoEncuestaLogo(this.id_marca,archivo)
      .subscribe(
      (response)=>{
          this.loading_upload_file = false
          this.atributos_json.logo.valor = response.url
          this.atributos_json.logo.file_name = archivo.nombre_archivo
          console.log(this.atributos_json)
      },
      (error)=>{
          this.loading_upload_file = false
          alert(JSON.stringify(error))
      }
    );
    }

    guardarAtributos(){
      if(this.loading_button)return;
      if(this.loading_upload_file)return;
      this.loading_button = true
      var data = JSON.stringify(this.atributos_json)
      this.facade.guardarAtributosEncuesta(this.id_marca,data,this.tipo)
      .subscribe(
        (response)=>{
            console.log(response)
            this.loading_button = false
            this.irPerfil()
        },
        (error)=>{
          this.loading_button = false
            alert(JSON.stringify(error))
        })
    }

    irPerfil(){
      this.router.navigate(['cliente/perfil']);
    }
  }
  