<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Limpiar {{data?.marca?.nombre}}</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12 my-2">
                <mat-form-field class="full-width" color="primary" appearance="outline"> 
                    <mat-label>Elementos a eliminar</mat-label>
                    <mat-select [disabled]="waitingResponse" [(ngModel)]="tipoDeArchivosABorrar">
                      <mat-option value="borrar_archivos_videototems">
                        Archivos videototems ({{cantidades?.cantidad_videos}} archivos)
                      </mat-option>
                      <mat-option value="borrar_archivos_consumidores">
                        Archivos campos consumidores ({{cantidades?.cantidad_consumidores}} archivos)
                      </mat-option>
                      <mat-option value="borrar_archivos_encuestas">
                        Archivos encuestas ({{cantidades?.cantidad_encuestas}} archivos)
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="col-12 my-2" *ngIf="waitingResponse">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div mat-dialog-actions>
        <div class="col-12 d-flex justify-content-between">
            <button [disabled]="waitingResponse" mat-button mat-dialog-close>
                CANCELAR
            </button>
            <button [disabled]="waitingResponse" (click)="limpiarMarca()" mat-raised-button color="primary">
                LIMPIAR
            </button>
        </div>
    </div>
</div>