import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-detalle-cliente',
  templateUrl: './admin-detalle-cliente.component.html',
  styleUrls: ['./admin-detalle-cliente.component.scss']
})
export class AdminDetalleClienteComponent implements OnInit {

  public id_cliente:number;

  constructor(public router:Router,public route:ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id_cliente = params['id_cliente'];
    })
  }

  irListado(){
    this.router.navigate(['admin/home']);
  }

}
