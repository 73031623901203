<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Se ha encontrado otra sesión</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 class="black-text">Tiene una sesión activa en otro lugar. ¿Está de acuerdo en cerrar 
                    esa sesión y abrir una nueva aquí?
                </h6>
            </div>
        </div>

        
    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button 
                (click)="cancelar()"
                mat-button 
                >
                    CANCELAR
            </button>
            <button 
                cdkFocusInitial 
                (click)="abrirNuevaSesion()"
                mat-raised-button 
                color="primary" >
                    OK
            </button>
        </div>
    </div>
</div>