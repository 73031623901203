import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { FacadeService } from '../services/facade.service';
@Injectable({
  providedIn: 'root'
})
export class ClienteSegundoGuard implements CanActivate {
  constructor(
    private router : Router,
    private facade : FacadeService,
    private appService:AppService
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Observable<boolean | UrlTree>(observable => {
        this.facade.recuperarInfoUsuarioFirmado().subscribe(
          response => {
            this.facade.setSecondClientPermissions(response);
            if (this.facade.getUserGroup().includes('cliente-segundo')){
              if(this.facade.comprobarPermisoEnClienteSegundo(route?.data?.key)){
                if (route?.data?.isEdit){
                  if (this.facade.comprobarPermisoDeEdicion(route?.data?.key)){
                    return observable.next(true);
                  }
                  return observable.next(false);
                }
                return observable.next(true);
              } else {
                let permisos = this.facade.getSecondPermissions();
                if (permisos){
                  let permisosKeys = Object.keys(permisos);
                  let key = null;
                  for (const permiso of permisosKeys) {
                    if (permisos[permiso]?.acceso){
                      key = permiso;
                      break;
                    }
                  }
                  let route = this.router.config.find(config => config?.data?.key === key);
                  if (route){
                    return observable.next(this.router.parseUrl(route.path));
                  } else {
                    this.appService.setUserLoggedIn(false);
                    this.facade.destroySessionToken();
                    return observable.next(this.router.parseUrl(""));
                  }
      
                } 
                return observable.next(true);
              }
            }
            return observable.next(true);
          }
        );
      });
  }
  
}
