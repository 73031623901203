<div class="loader">
    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
</div>
<div *ngIf="!loading" class="row">
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" style="width: 100%;" appearance="outline">
            <mat-label>Selecciona marca</mat-label>
            <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
              <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                {{marca.nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
            <mat-label>A partir del</mat-label>
            <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="initialPicker" [(ngModel)]="fecha_filtro_inicial" [value]="fecha_filtro_inicial"
            (click)="initialPicker.open()"
            (dateChange)="_monthSelectedHandler(initialPicker,$event)"
            (keydown.arrowdown)="initialPicker.open()"
            (keydown.enter)="initialPicker.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="initialPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #initialPicker color="color-primary" startView="month"></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-3">
        <mat-form-field class="d-flex justify-content-center" appearance="outline" style="width: 100%;">
            <mat-label>Hasta el</mat-label>
            <input matInput [max]="maxDate" mat-raised-button [matDatepicker]="finalPicker" [(ngModel)]="fecha_filtro_final" [value]="fecha_filtro_final"
            (click)="finalPicker.open()"
            (dateChange)="_monthSelectedHandler(finalPicker,$event)"
            (keydown.arrowdown)="finalPicker.open()"
            (keydown.enter)="finalPicker.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="finalPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #finalPicker color="color-primary" startView="month"></mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngIf="isFilter()" class="col-lg-3 d-flex align-items-center">
        <a (click)="limpiar()">
            <div class="row d-flex align-items-center">
                <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
            </div>
        </a>
    </div>
</div>
<div class="row">&nbsp;</div>
<div *ngIf="!loading" class="row">
    <div class="col-md-4">
            <div class="col-12 card">
                <div class="row">&nbsp;</div>
                <h6 style="font-weight: 600;">Mensajes recibidos</h6>
                <h1 class="color-primary" style="font-weight: 200;">{{mensajes_recibidos | number}}</h1>
                <div class="row">&nbsp;</div>
            </div>
    </div>

    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Clientes atendidos</h6>
            <h1 class="color-primary" style="font-weight: 200;">{{clientes_atendidos | number}}</h1>
            <div class="row">&nbsp;</div>
        </div>
</div>
</div>
<div class="row">&nbsp;</div>
<div *ngIf="!loading" class="row">
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Tiempo promedio de respuesta</h6>
            <div *ngIf="tiempo_respuesta_request" class="row d-flex align-items-end">
                <div class="col-4">
                    <h1 class="color-primary" style="font-weight: 200;">{{tiempo_respuesta | number }}</h1>
                </div>
                <div class="col-6">
                    <h6 class="black-text" style="font-weight: 200;">segundos</h6>
                </div>
                <div *ngIf="loading_respuesta" class="col-2">
                    <i *ngIf="loading_respuesta" class="fa-spin fas fa-spinner"></i>
                </div>
            </div>
            <div *ngIf="!tiempo_respuesta_request" class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="loadMetricasTiempos()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> CARGAR TIEMPOS <i *ngIf="loading_respuesta" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div *ngIf="!tiempo_respuesta_request && loading_respuesta" class="row">
                <div class="col-12">
                    <h6 class="black-text" style="font-weight: 200;">Estamos calculando los tiempos, esto puede llevar algunos minutos</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Índice de satisfacción</h6>
            <div class="row d-flex align-items-end">
                <div class="col-4">
                    <h1 class="color-primary" style="font-weight: 200;">{{indice_satisfaccion | number }}</h1>
                </div>
                <div class="col-6">
                    <h6 class="black-text" style="font-weight: 200;">/100</h6>
                </div>
                <div *ngIf="loading_satisfaccion" class="col-2">
                    <i *ngIf="loading_satisfaccion" class="fa-spin fas fa-spinner"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">&nbsp;</div>
<div class="row" *ngIf="!loading && esEditor">
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Consumidores</h6>
            <div class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="generarReporte()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> DESCARGAR REPORTE CSV<i *ngIf="loading_reporte" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Encuestas App</h6>
            <div class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="generarReporteApp()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> DESCARGAR REPORTE CSV<i *ngIf="loading_reporte" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Formularios</h6>
            <div class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="generarReporteFormularios()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> DESCARGAR REPORTE CSV<i *ngIf="loading_reporte" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">&nbsp;</div>

<div class="row" *ngIf="!loading && esEditor">
    <div class="col-md-4">
        <div class="col-12 card">
            <div class="row">&nbsp;</div>
            <h6 style="font-weight: 600;">Formularios videotótem</h6>
            <div class="row d-flex align-items-end">
                <div class="col-12">
                    <a (click)="generarReporteVideototemFormularios()">
                        <div class="row d-flex align-items-center" style="margin-left: 0.1rem;">
                            <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"> DESCARGAR REPORTE CSV<i *ngIf="loading_reporte" class="fa-spin fas fa-spinner"></i></h6>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
    </div>
</div>
