<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="irListado()" style="padding: 0" mat-button color="primary">
                                <i class="fas fa-arrow-left"></i>&nbsp;Regresar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2 *ngIf="!id_agente || id_agente==0">Alta de nuevo agente</h2>
                            <h2 *ngIf="id_agente && id_agente!=0">Edición de agente</h2>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col-12">
                            <app-agente-info (guardadoCompleto)="agenteGuardado($event)" [id_agente]="id_agente"></app-agente-info>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>