import { Route } from '@angular/compiler/src/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EditarMarcaDialogComponent } from 'src/app/dialogs/editar-marca-dialog/editar-marca-dialog.component';
import { FacadeService } from 'src/app/services/facade.service';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  selector: 'app-cliente-info-profile',
  templateUrl: './cliente-info-profile.component.html',
  styleUrls: ['./cliente-info-profile.component.scss']
})
export class ClienteInfoProfileComponent implements OnInit {

  public url_api:string;
  public loading:boolean=false;
  public loading_activar:boolean=false;

  public logo:any={};
  public loading_giros:boolean=false;

  public nombre_cliente:string;
  public razon_social:string;
  public id_giro_comercial:number;
  public permite_videosoporte:boolean;
  public max_puntos_soporte:number;
  public expirar_sesiones:boolean;
  public username:string;
  public password:string;
  public c_password:string;
  public maximo_agentes:string;
  public maximo_clientes:string;
  public es_cliente_segundo:boolean=false;

  public displayedColumns: string[] = ['logo', 'nombre', 'plataforma_whatsapp',];
  public marcas:any=[];

  public giros_comerciales:any=[];
  constructor(public facade:FacadeService, public router:Router, public dialog: MatDialog) {
    this.url_api = environment.url_api;
  }

  ngOnInit() {
    if (this.facade.getUserGroup().includes("cliente-segundo")){
      this.es_cliente_segundo = true;
    }
    this.loadCatalogoGirosComerciales()
  }

  loadCatalogoGirosComerciales(){
    this.loading_giros=true;

    this.facade.listarCatalogoGirosComerciales()
    .subscribe(
      (response)=>{
        this.loading_giros = false;
        this.giros_comerciales = response;
        this.loadCliente();
      },
      (error)=>{
        this.loading_giros = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
      );
  }

  loadCliente(){
    
      this.loading = true;
      this.facade.recuperarCliente(this.facade.getUserClientID())
      .subscribe(
        (response)=>{
          this.loading = false;
          this.leerInfoCliente(response);
        },
        (error)=>{
          this.loading = false;
          console.log("ERROR:: "+JSON.stringify(error));
        }
      );
  }

  leerInfoCliente(res){
    this.nombre_cliente = res.nombre;
    this.razon_social = res.razon_social;
    this.username = res.username;
    this.password = "**********";
    this.c_password = "**********";

    if(res.giro_comercial && res.giro_comercial!=0){
      this.id_giro_comercial=res.giro_comercial;
    }

    if(res.logo && res.logo!=null){
      this.logo = {
        archivo_cargado: this.url_api+res.logo,
        url:  res.logo
      }
    }

    if(res.marcas && res.marcas.length>0){
      this.marcas = res.marcas;
    }

    this.permite_videosoporte=res.permite_videosoporte;
    this.maximo_agentes=res.maximo_agentes;
    this.maximo_clientes=res.maximo_clientes;
    this.expirar_sesiones = res.expirar_sesiones;
    this.max_puntos_soporte=res.max_puntos_soporte;

  }

  clickInput(input){
    document.getElementById(input).click();
  }

  onFileSelect($event){
    if ($event.target.files.length > 0) {
        const file = $event.target.files[0];
        //TODO: Validar solo imagenes
        if(file.name.indexOf(".jpg")==-1 && 
        file.name.indexOf(".jpeg")==-1 && 
        file.name.indexOf(".png")==-1
        ){
          alert("Solo se permiten imagenes para el logotipo");
          return;
        }

        var parent = this;
        var reader = new FileReader();
        reader.onload = function(e) {
          parent.logo.url=e.target['result'];
        }
        reader.readAsDataURL(file); // convert to base64 string
        this.logo.input_file = file;
        this.logo.nombre_archivo = file.name;
    }
  }

  eliminarLogo(){
    this.logo={};
  }

  abrirNuevaMarcaDialog(marca): void {
    const dialogRef = this.dialog.open(EditarMarcaDialogComponent, {
      width: '800px',
      data: {marca: marca, id_cliente: this.facade.getUserClientID()}
    }); 

    dialogRef.afterClosed().subscribe(result => {
      this.loadCliente();
    });
  }

  eliminarMarca(marca){
    if(confirm("Esto inactivará la marca y no se podrá usar por los agentes. Se podrá reactivar en cualquier momento y todos sus datos se conservarán. ¿Continuar?"))
    {
      this.loading_activar = true;
      this.facade.inactivarMarca(marca.id)
      .subscribe(
        (response)=>{
          this.loading_activar = false;
          marca.activa = false;
        },
        (error)=>{
          this.loading_activar = false;
        }
      );
    }
  }

  reactivarMarca(marca){
    if(confirm("Esto hará visible a la marca nuevamente por los agentes. ¿Continuar?")){
      this.loading_activar = true;
      this.facade.activarMarca(marca.id)
      .subscribe(
        (response)=>{
          this.loading_activar = false;
          marca.activa = true;
        },
        (error)=>{
          this.loading_activar = false;
        }
      );
      
    }
  }

  goToClientes(){
    this.router.navigate(['cliente/perfil']);
  }

  guardarCliente(){

    //TODO: Validar forma
    this.loading=true;
    this.facade.guardarCliente(this.facade.getUserClientID(),
                              this.nombre_cliente,
                              this.razon_social,
                              this.id_giro_comercial,
                              this.maximo_agentes,
                              this.maximo_clientes,
                              this.permite_videosoporte,
                              this.max_puntos_soporte,
                              this.expirar_sesiones,
                              this.logo,
                              this.username,
                              this.password)
    .subscribe(
      (response)=>{
        this.loading=false;
        this.goToClientes();
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
    
  }



}
