import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDatepicker} from '@angular/material';

@Component({
  selector: 'app-agente-profile',
  templateUrl: './agente-profile.component.html',
  styleUrls: ['./agente-profile.component.scss']
})
export class AgenteProfileComponent implements OnInit {

  public loading_idiomas:boolean=false;
  public loading_button:boolean=false;
  public loading:boolean=true;
  public loading_respuesta:boolean=false;
  public loading_estadisticas:boolean=false;
  @Input() id_agente:number;
  @Input() state:any;
  @Output() public guardadoCompleto: EventEmitter<any> = new EventEmitter;
  public agente:any={};
  public tiempo_respuesta_request:boolean=false;
  public idiomas:any=[];
  public id_idiomas:any=[];
  public marcas:any=[{id:0,nombre:"Todas las marcas"}];
  public marcas_lista:any=[{id:0,nombre:"Todas las marcas"}];
  public mensajes_recibidos:string="0";
  public clientes_atendidos:string="0";
  public tiempo_respuesta:string="0";
  public sesiones_antendidas:string="0";
  public actividad_sesiones:any=[];
  public id_marca:number=0;
  public fecha_filtro_inicial:any = "";
  public fecha_filtro_final:any = "";
  public maxDate:any=new Date()

  constructor(public facade:FacadeService, public router:Router,) { }

  ngOnInit() {
    this.loadIdiomas();
  }

  loadIdiomas(){
    this.loading_idiomas=true;

    this.facade.listarCatalogoIdiomas()
    .subscribe(
      (response)=>{
        this.loading_idiomas = false;
        this.idiomas = response;
        this.loadMarcas();
      },
      (error)=>{
        this.loading_idiomas = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadMarcas(){
    this.loading = true;
    
    this.facade.listarMarcasClienteActual()
    .subscribe(
      (response)=>{
        var marcas = this.marcas
        this.marcas = marcas.concat(response);
        this.loadAgente();
        //this.loading = false;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
      }
    )
    
  }

  loadAgente(){
    if(this.id_agente && this.id_agente!=0){
      this.loading = true;
      this.facade.recuperarAgente(this.id_agente)
      .subscribe(
        (response)=>{
          this.agente = response;

          this.agente.password = "**********";
          this.agente.c_password = "**********";

          //Idiomas
          if(this.agente.id_idiomas){
            this.id_idiomas = this.agente.id_idiomas.split(",");
            var idiomas_concat = []
            for(var i=0;i<this.id_idiomas.length;i++){
              for(var j=0;j<this.idiomas.length;j++){
                var id_idioma = this.id_idiomas[i];
                if(this.idiomas[j].id == parseInt(id_idioma)){
                  idiomas_concat.push(this.idiomas[j].nombre)
                }
              }
            }
            this.agente.idiomas_concat = idiomas_concat.join()
          }
          var marcas_nombres = this.agente.marcas_nombres
          this.agente.marcas_concat = marcas_nombres.join()

          //Deseleccionar todas las marcas
          for(var i=0;i<this.marcas.length;i++){
            var uni_m = this.marcas[i];
            uni_m.selected = false;
          }
          //Seleccionar las marcas del universo que este agente tiene
          if(this.agente.marcas && this.agente.marcas.length>0){
            for(var i=0;i<this.marcas.length;i++){
              var uni_m = this.marcas[i];
              for(var j=0;j<this.agente.marcas.length;j++){
                var age_m = this.agente.marcas[j];
                if(age_m.id==uni_m.id){
                  uni_m.selected = true;
                }
              }
            }
          }
          
          var marcas_lista = this.marcas_lista
          this.marcas_lista = marcas_lista.concat(response.marcas);
          //this.loading = false;
          this.aplicarFiltro()
          this.loading=false;
        },
        (error)=>{
          this.loading = false;
        }
      )
    }
  }

  guardarAgente(){
    if(!this.agente) return false;
    if(!this.agente.username || !this.agente.password || !this.agente.nombre_completo){
      alert("Por favor proporcione nombre y datos de acceso")
      return false;
    }

    var marcas_seleccionadas = [];
    for(var i=0;i<this.marcas.length;i++){
      var m = this.marcas[i];
      if(m.selected){
        marcas_seleccionadas.push({
          id: m.id
        })
      }else{
        marcas_seleccionadas.push({
          id: m.id,
          estatus: 'eliminada'
        })
      }
    }

    if(marcas_seleccionadas.length==0){
      alert("Por favor proporcione al menos una marca para el agente");
      return false;
    }

    if(this.id_idiomas && this.id_idiomas.length>0){
      this.agente.id_idiomas=this.id_idiomas.join(",");
    }


    this.loading = true;
    this.facade.guardarAgente(this.agente, marcas_seleccionadas)
    .subscribe(
      (response)=>{
        this.loading = false;
        if(this.guardadoCompleto){
          this.guardadoCompleto.emit(response);
        }
      },
      (error)=>{
        this.loading = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  fechaDeNacimientoMax(){
    var now = new Date();
    now.setFullYear( now.getFullYear() - 18 );
    return now;
  }

  irEditarAgente(){
    if(!this.id_agente){
      this.id_agente = 0;
    }
    this.router.navigate(['cliente/agente/'+this.id_agente],
      {state:this.state}
    );
  }

  irChatsAgente(){
    this.router.navigate(['cliente/agente/chats/'+this.id_agente],
    {state:this.state});
  }

  desactivarAgente(){
    if(this.loading_button)return
    this.loading_button = true
    this.facade.desactivarAgente(this.id_agente,!this.agente.usuario_activo)
    .subscribe(
      (response)=>{
        this.loading_button = false;
        this.router.navigate(['cliente/agentes']);
      },
      (error)=>{
        this.loading_button = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }
  cerrarSesionDeAgente(){
    if(this.loading_button)return
    this.loading_button = true
    this.facade.cerrarSesionDeAgente(this.id_agente)
    .subscribe(
      (response)=>{
        this.loading_button = false;
        this.agente.en_linea = false
      },
      (error)=>{
        this.loading_button = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  metricasFilterTiempos(id_marca,fecha_inicial, fecha_final){
    this.loading_respuesta = true
    this.tiempo_respuesta="0";
    this.facade.metricasTiemposDashboard(id_marca, fecha_inicial, fecha_final,this.id_agente)
    .subscribe(
      (response)=>{
        this.loading_respuesta = false
        this.tiempo_respuesta_request = true;
        this.tiempo_respuesta=response.tiempo_promedio_respuesta;
      },
      (error)=>{
        this.loading_respuesta = false
      }
    )
  }

  metricasFilter(id_marca,fecha_inicial,fecha_final){
    this.loading_estadisticas = true;
    this.facade.metricasDashboard(id_marca, fecha_inicial, fecha_final, this.id_agente)
    .subscribe(
      (response)=>{
        this.loading_estadisticas = false;
        this.mensajes_recibidos=response.mensajes_recibidos;
        this.clientes_atendidos=response.clientes_atendidos;
        this.sesiones_antendidas=response.sesiones_antendidas;
        this.actividad_sesiones=response.actividad_sesiones;
      },
      (error)=>{
        this.loading_estadisticas = false;
      }
    )
  }

  aplicarFiltro(){
    let fecha_inicial = "";
    let fecha_final = ""
    if(this.fecha_filtro_inicial != ""){
      fecha_inicial = this.darAFormatoFecha(this.fecha_filtro_inicial)
    }
    if(this.fecha_filtro_final != ""){
      fecha_final = this.darAFormatoFecha(this.fecha_filtro_final)
    }
    this.metricasFilter(this.id_marca,fecha_inicial,fecha_final)
    this.metricasFilterTiempos(this.id_marca,fecha_inicial,fecha_final)
  }

  limpiar(){
    this.id_marca=0;
    this.fecha_filtro_inicial = "";
    this.fecha_filtro_final = "";
    this.metricasFilter(0,"","")
    this.metricasFilterTiempos(0,"","")
  }
  isFilter(){
    if(this.id_marca != 0 || this.fecha_filtro_inicial != "" || this.fecha_filtro_final != ""){
      return true 
    }else{
      return false 
    }
  }

  _monthSelectedHandler(picker: MatDatepicker<Date>, chosenMonthDate:Date) {
    this.aplicarFiltro();
    picker.close();
  }

  darAFormatoFecha(fecha){
    return new Date(fecha).toJSON().slice(0, 10);
  }

}