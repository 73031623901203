<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Nuevo contacto</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row p-1">
          <div class="col-12">
              <mat-form-field appearance="outline">
                  <mat-label>Lada</mat-label>
                  <input matInput cdkFocusInitial placeholder="52" [(ngModel)]="lada" maxlength=3>
              </mat-form-field>
          </div>
        </div>

        <div class="row p-1">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Número de teléfono</mat-label>
                    <input matInput cdkFocusInitial placeholder="Número de teléfono" [(ngModel)]="numero_celular" (keyup.enter)="guardar()" maxlength=10 type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' >
                </mat-form-field>
            </div>
        </div>


    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button
                (click)="cancelar()"
                mat-button
                >
                    CANCELAR
            </button>
            <button
                cdkFocusInitial
                (click)="guardar()"
                mat-raised-button
                color="primary" >
                    CREAR
            </button>
        </div>
    </div>
</div>
