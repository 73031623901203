import { Component, OnInit } from '@angular/core';
import { ViewChild, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FacadeService } from 'src/app/services/facade.service';

export interface DialogData {
  marca: any,
  id_campo:any,
  borrar:boolean,
  tipo:string,
  campos_dinamicos:any
}

@Component({
  selector: 'app-nuevo-campo-consumidor',
  templateUrl: './nuevo-campo-consumidor.component.html',
  styleUrls: ['./nuevo-campo-consumidor.component.scss']
})

export class NuevoCampoConsumidorComponent implements OnInit {
  /*
    {
        id: 1,
        slug: “telefono”,
        tipo: “text”,
        etiqueta: “Número de teléfono”,
        valor: “5523011974"
      }
  */
  public campo:any = {
    id:0,
    etiqueta:'',
    tipo:'',
    slug:'',
    opciones:[
      {id:0,nombre:''}
    ]
  }
  public tipos:any = [
    {
      id:1,
      nombre:"Texto",
      slug:"text",
    },
    {
      id:2,
      nombre:"Númerico",
      slug:"number",
    },
    {
      id:3,
      nombre:"Fecha",
      slug:"date",
    },
    {
      id:4,
      nombre:"Selección",
      slug:"dropdown",
    },
    {
      id:5,
      nombre:"Selección multiple",
      slug:"dropdown_multi",
    },
    {
      id:6,
      nombre:"Archivo",
      slug:"file",
    },
    {
      id:7,
      nombre:"Imagen",
      slug:"image",
    },
  ]
  public loading:boolean = false
  constructor(
    public facade:FacadeService,
    public dialogRef: MatDialogRef<NuevoCampoConsumidorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    if(this.data.id_campo != 0){
      let campos : any[] = this.data.campos_dinamicos;
      this.campo = campos.find(campo => campo.id === this.data.id_campo);
      if (this.campo.opciones == null){
        this.campo.opciones = [{id:0,nombre:''}];
      }
    }
    console.log(this.campo);

  }

  guardarCampo(){
    if(this.loading)return
    this.loading = true
    var consumidores_campos_json = null
    this.campo.etiqueta = this.campo.etiqueta.trim()
    var date = new Date()
    var id = date.getTime()
    var nombre = this.campo.etiqueta.replaceAll(" ","_")
    if (!this.campo?.bloquear_borrado){
      var slug = nombre.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "");
      slug = slug.toLowerCase()
      this.campo.slug = slug
    }
    this.campo.id = id
    //console.log(this.campo)

    if(this.campo.tipo == 'text' || this.campo.tipo == 'number' || this.campo.tipo == 'date'){
      //console.log("no tiene opciones")
      this.campo.opciones = null
    }
    if(this.data.campos_dinamicos){
      var campos = this.data.campos_dinamicos;
      if(this.data.id_campo == 0){
        campos.push(this.campo)
        consumidores_campos_json = JSON.stringify(campos)
      }else{
        for(let c of campos){
          if(c.id == this.data.id_campo){
            c.etiqueta=this.campo.etiqueta,
            c.tipo=this.campo.tipo,
            c.slug=this.campo.slug,
            c.opciones=this.campo.opciones
          }
        }
        //console.log(campos)
        consumidores_campos_json = JSON.stringify(campos)
      }
    }else{
      var campos_nuevos = []
      campos_nuevos.push(this.campo)
      consumidores_campos_json = JSON.stringify(campos_nuevos)
    }
    console.log(consumidores_campos_json)
    this.facade.guardarCampos(this.data.marca.id,consumidores_campos_json,this.data.tipo)
    .subscribe(
      (response)=>{
        //console.log(response)
        this.loading = false
        this.dialogRef.close();
      },
      (error)=>{
        console.log(error)
        this.loading = false
        alert(JSON.stringify(error))
        this.dialogRef.close();
      }
    )
  }
  agregarOpcion(){
    var date = new Date()
    this.campo.opciones.push({id:date.getTime(),nombre:''})
    //console.log(this.campo)
  }
  borrarOpcion(id){
    for(let i=0;i<this.campo.opciones.length;i++){
      var opcion = this.campo.opciones[i]
      if(opcion.id == id){
        this.campo.opciones.splice(i,1);
      }
    }
  }
  borrarCampo(){
    if(this.loading)return
    this.loading = true
    var consumidores_campos_json = null
    //Verificar que no se use el JSON.Parse en otra parte porque eso causaba errores
    var campos = JSON.parse(JSON.stringify(this.data.campos_dinamicos))
    for(let i=0;i<campos.length;i++){
      var c = campos[i]
      if(c.id == this.data.id_campo){
        campos.splice(i,1);
      }
    }
    consumidores_campos_json = JSON.stringify(campos)
    this.facade.guardarCampos(this.data.marca.id,consumidores_campos_json,this.data.tipo)
      .subscribe(
        (response)=>{
          //console.log(response)
          this.loading = false
          this.dialogRef.close();
        },
        (error)=>{
          console.log(error)
          this.loading = false
          alert(JSON.stringify(error))
          this.dialogRef.close();
        }
      )
    }
}
