import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cliente-profile',
  templateUrl: './cliente-profile.component.html',
  styleUrls: ['./cliente-profile.component.scss']
})
export class ClienteProfileComponent implements OnInit {
  public loading:boolean = false;
  public cliente:any;
  public giros:any;
  public campos_dinamicos:boolean = false;
  esEditor: any;
  constructor(public facade:FacadeService, public router:Router) { }

  ngOnInit() {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_perfil_de_cliente");
    this.loadGiros();
  }

  loadPerfil(){
    this.loading = true;
    this.facade.recuperarCliente(this.facade.getUserClientID())
    .subscribe(
      (response)=>{
        for(let g of this.giros){
          if(response.giro_comercial == g.id){
            response.giro_comercial_nombre = g.nombre
          }
        }
        response.marcas_nombres = []
        for(let m of response.marcas){
          response.marcas_nombres.push(m.nombre)
          if(m.tipo_crm == "modo_2" || m.tipo_crm == "modo_4"){
            this.campos_dinamicos = true
          }
          if(m.atributos_encuesta_json && m.atributos_encuesta_json!=""){
            m.atributos_encuesta_json = JSON.parse(m.atributos_encuesta_json)
          }
        }
        this.cliente = response
        this.loading = false;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  loadGiros(){
    this.loading = true;
    this.facade.listarCatalogoGirosComerciales()
    .subscribe(
      (response)=>{
        this.giros = response
        this.loadPerfil()
        this.loading = false;
      },
      (error)=>{
        this.loading = false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  irEditarCliente(){
    this.router.navigate(['cliente/perfil/editar']);
  }
  irCampos(marca_id,tipo){
    console.log(marca_id)
    this.router.navigate(['cliente/marca/'+marca_id+'/'+tipo]);
  }
  irEncuesta(marca_id,tipo){
    console.log(marca_id)
    this.router.navigate(['cliente/encuesta/atributos/'+marca_id+'/'+tipo]);
  }
}
