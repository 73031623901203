import { Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { AppService } from 'src/app/services/app.service';
import { MatDialog } from '@angular/material';
import { sesionExpiradaDialogComponent } from './dialogs/sesion-expirada-dialog/sesion-expirada-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'whatsoporte-web-angular-app';

  public cliente : any;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive, 
    private router: Router,
    private facade:FacadeService,
    private appService:AppService,
    public dialog: MatDialog) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1800);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout()
    }); 
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      //console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn && this.facade.getUserGroup() === "agente") {
        idle.watch()
        this.timedOut = false;
        this.recuperarClienteDeAgente();
      } else {
        idle.stop();
      }
    })
    //this.reset();
  }
  
  ngOnInit(){
  }

  recuperarClienteDeAgente(){
    this.facade.recuperarClienteAlQuePerteneceAgente().subscribe(
      (response) => {
        this.cliente = response;
        if (!this.cliente.expirar_sesiones){
          this.idle.stop();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
  
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  abrirDialog(): void {
    const dialogRef = this.dialog.open(sesionExpiradaDialogComponent);
  }

  logout(){
    this.facade.logout()
    .subscribe(
      (response)=>{
        //this.facade.destroySessionToken();
        //this.abrirDialog()
      },
      (error)=>{
        //this.facade.destroySessionToken();
        //this.abrirDialog()
      }
    )
  }

}
