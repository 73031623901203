<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Crear chatbot</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6>Seleccione la marca en la que funcionará este chatbot</h6>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <div class="row" *ngFor="let m of marcas">
                    <div class="col-12">
                        <mat-checkbox color="primary"
                            (change)="onSelectOption($event)" 
                            [(ngModel)]="m.selected"
                            [value]="m.id"
                            style="margin: 5px"
                            [disabled]="m.isUsed"
                            >{{m.nombre}}
                            
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 style="font-weight: 600;">¿Es automático?</h6>
                <mat-checkbox color="primary"
                [(ngModel)]="automatico"
                [value]="automatico"
                [disabled]="formulario"
                style="margin: 5px">
                </mat-checkbox>
                <mat-label>Si marcas la casilla este chatbot contestará automaticamente. Si tienes varios chatbots automaticos por marca se utilizará el más reciente.</mat-label>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Tipo de chatbot</mat-label>
                    <mat-select [(ngModel)]="tipoChatbot" (selectionChange)="establecerTipo($event)">
                      <mat-option *ngFor="let tipo of tiposDeChatbot" [value]="tipo.valor">{{tipo.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-label *ngIf="encuesta">Este chatbot será una encuesta y guardará las respuestas de los usuarios para obtener el índice de satisfacción de la marca.</mat-label>
                <mat-label *ngIf="formulario">Este chatbot permitirá aplicar validaciones a las resuestas de los usuarios, además de guardar los datos. Los datos guardados aparecerán en la sección Formularios.</mat-label>        
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-lg-12">
                <h6 style="font-weight: 600;">Primer periodo de activación (opcional)</h6>
            </div>
            <div class="col-lg-12 my-2"> 
                <div class="row w-100">
                    <div class="col-lg-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Horario inicial de activacion</mat-label>
                            <input type="time" matInput placeholder="Horario inicial de activacion" [(ngModel)]="primer_periodo_hora_inicio">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Horario final de activacion</mat-label>
                            <input type="time" matInput placeholder="Horario final de activacion" [(ngModel)]="primer_periodo_hora_fin">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <h6 style="font-weight: 600;">Segundo periodo de activación (opcional)</h6>
            </div>
            <div class="col-lg-12 my-2"> 
                <div class="row w-100">
                    <div class="col-lg-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Horario inicial de activacion</mat-label>
                            <input type="time" matInput placeholder="Horario inicial de activacion" [(ngModel)]="segundo_periodo_hora_inicio">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Horario final de activacion</mat-label>
                            <input type="time" matInput placeholder="Horario final de activacion" [(ngModel)]="segundo_periodo_hora_fin">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 my-2">
                <div class="row w-100">
                    <div class="col-lg-12">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Zona Horaria (UTC +/-)</mat-label>
                            <input type="number" min="-24" max="24" matInput placeholder="El UTC de su ubicación actual es {{tiempo_universal_coordinado_local}}" [(ngModel)]="tiempo_universal_coordinado">
                        </mat-form-field>
                        <span style="font-size: 0.8em">
                            Zona horaria acorde al tiempo universal coordinado, agregue un signo positivo (+) para sumar horas o un signo negativo (-) para restar
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 style="font-weight: 600;">Mensaje inicial</h6>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Escribe el mensaje</mat-label>
                    <textarea matInput placeholder="Escribe el mensaje..." (select)="onSelect($event)" [(ngModel)]="mensaje"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-3 d-flex align-items-center">
            <div *ngIf="visible_emoji" class="emoji-container">
                <div class="row" style="background-color: black;margin: 0px;padding: 5px;margin-bottom: -5px;">
                    <a (click)="visible_emoji = false">
                        <h6 style="color: white;font-size: 18px;"><i class="fas fa-times" style="color: white;font-size: 18px;"></i> Cerrar</h6>
                    </a>
                </div>
                <emoji-mart
                    [set]="'google'"
                    [hideRecent]="true"
                    (emojiSelect)="addEmoji($event)"
                    title="Selecciona emoji…"
                    emoji="point_up"
                    >
                </emoji-mart>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <a (click)="visible_emoji = true">
                        <i class="far fa-smile" style="font-size: 24px;"></i>
                    </a>
                </div>
                <div *ngIf="!bold_activo" class="col-sm-4">
                    <a (click)="setBold()">
                        <i class="fas fa-bold" style="font-size: 24px;"></i>
                    </a>
                </div>
                <div *ngIf="bold_activo" class="col-sm-4">
                    <a (click)="setBold()">
                        <i class="fas fa-bold color-primary" style="font-size: 24px;"></i>
                    </a>
                </div>
                <div *ngIf="!italic_activo" class="col-sm-4">
                    <a (click)="setItalic()">
                        <i class="fas fa-italic" style="font-size: 24px;"></i>
                    </a>
                </div> 
                <div *ngIf="italic_activo" class="col-sm-4">
                    <a (click)="setItalic()">
                        <i class="fas fa-italic color-primary" style="font-size: 24px;"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row" *ngIf="!automatico || encuesta">
            <div class="col-12">
                <h6 style="font-weight: 600;">Acceso rápido</h6>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Acceso rápido</mat-label>
                    <input type="text" matInput placeholder="Ej: Bienvenida" [(ngModel)]="acceso_rapido">
                </mat-form-field>
                <p>Permitirá a los asesores activar este chatbot con un "shortcut" de teclado, escribiendo el caracter "/" y luego el texto que usted designe. Por ejemplo sí usted coloca como acceso rápido "Bienvenida" y el asesor presiona "/Bienvenida" se activará este chatbot</p>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button (click)="crearChatbot()" mat-raised-button color="primary"  [disabled]="isComplete()">
                CREAR <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
            </button>
        </div>
    </div>
</div>