import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-screen',
  templateUrl: './perfil-screen.component.html',
  styleUrls: ['./perfil-screen.component.scss']
})
export class PerfilScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
