import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { AppService } from 'src/app/services/app.service';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public first_name:string;
  public grupo:string;
  public view:string;
  constructor(private router:Router, private appService:AppService, private facade: FacadeService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.first_name = this.facade.getUserFirstName();
    this.grupo = this.facade.getUserGroup();
    this.setupMenu();
  }
  
  setupMenu(){
    $("#menu_search_marker").show();
    var url = this.router.url;

    if(url){
      if(url.indexOf('/home')!==-1){
        this.view='whatsapp';
      }else if(url.indexOf('/videosoporte')!==-1){
        this.view='videosoporte';
      }
    }
  }

  goToHome(){
    if(this.grupo == 'cliente'){
      this.router.navigate(['cliente/home']);
    }else if(this.grupo == 'admin'){
      this.router.navigate(['admin/home']);
    }else if(this.grupo == 'agente'){
      this.router.navigate(['home']);
    }
  }

  goToVideoSoporte(){
    this.router.navigate(['videosoporte']);
  }

  logout(){
    this.facade.logout()
    .subscribe(
      (response)=>{
        this.appService.setUserLoggedIn(false);
        this.facade.destroySessionToken();
        this.router.navigate(['']);
      },
      (error)=>{
        this.appService.setUserLoggedIn(false);
        this.facade.destroySessionToken();
        this.router.navigate(['']);
      }
    )
  }

}
