import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-home-screen',
  templateUrl: './admin-home-screen.component.html',
  styleUrls: ['./admin-home-screen.component.scss']
})
export class AdminHomeScreenComponent implements OnInit {

  public clientes:any=[];
  public loading:boolean=false;
  public url_api:string;

  constructor(public router:Router, 
              public facade:FacadeService) {

                this.url_api = environment.url_api;
               }

  ngOnInit() {
    this.loadClientes();
  }

  irDetalleCliente(id_cliente){
    if(!id_cliente){
      id_cliente=0;
    }
    this.router.navigate(['admin/cliente/'+id_cliente]);
  }

  loadClientes(){
    this.loading = true;

    this.facade.listarClientes()
    .subscribe(
      (response)=>{
        this.loading = false;
        this.clientes = response;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  cambiarEstatusCliente(cliente){

    var mensaje_confirm="¿Estás seguro?";
    if(cliente.activo){
      mensaje_confirm = "¿Estás seguro que deseas inactivar al cliente? Ni el cliente ni sus asesores podrán usar el sistema. La información no se eliminará y podrás reactivarlo cuando desees"
    }
    if(!confirm(mensaje_confirm)) return;
    cliente.estatus_loading = true;
    var nuevo_estatus = !cliente.activo;
    this.facade.actualizarEstatusCliente(cliente.id, nuevo_estatus)
    .subscribe(
      (response)=>{
        cliente.estatus_loading = false;
        cliente.activo = nuevo_estatus;
      },
      (error)=>{
        cliente.estatus_loading = false;
        console.error(error);
      }
    );
  }

  eliminarCliente(cliente){
    let mensaje_confirm = "¿Estás seguro que deseas eliminar al cliente? La información asociada se eliminará y nunca podrá recuperarse"
    if(confirm(mensaje_confirm)){
      cliente.estatus_loading = true;
      this.facade.eliminarCliente(cliente.id)
      .subscribe(
        (response)=>{
          cliente.estatus_loading = false;
          if(response.success===true){
            this.loadClientes();
          }
          else{
            console.log(response.error);
          }
        },
        (error)=>{
          cliente.estatus_loading = false;
          alert(error?.error?.error);
        }
      );
    }
  }
}
