<mat-accordion multi>
    <mat-expansion-panel [expanded]="true" class="headers-align">
        <mat-expansion-panel-header>
        <mat-panel-title>
            <h5 style="width: 20%;">
                Datos del cliente
            </h5>
        </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <div *ngIf="!logo.input_file && !logo.archivo_cargado">
                    <img src="assets/images/agregar-logo.png" style="width: 20px"/>&nbsp;
                    <button (click)="clickInput('logo_file')" style="padding: 0" mat-button color="primary">AGREGAR LOGOTIPO</button>
                </div>
                <input style="display: none;" type="file" id="logo_file" name="logo_file" (change)="onFileSelect($event)" />
                <div *ngIf="logo.input_file || logo.archivo_cargado">
                    <div class="logo-container">
                        <div class="row">
                            <div class="col-10 v-center">
                                <div style="background-image: url({{logo.url}});background-repeat: no-repeat;background-size: contain;" style="width: 200px; height: 200px;"></div>
                                
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="clickInput('logo_file')">
                                            <i class="fas fa-edit"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="eliminarLogo()">
                                            <i class="fas fa-trash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
  
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Nombre del cliente</mat-label>
                <input type="text" matInput placeholder="Nombre del cliente" [(ngModel)]="nombre_cliente">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Razón social</mat-label>
                <input type="text" matInput placeholder="Razón social" [(ngModel)]="razon_social">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <i *ngIf="loading_giros" class="fa-spin fas fa-spinner"></i>
                    <mat-label>Giro comercial</mat-label>
                    <mat-select [(value)]="id_giro_comercial">
                        <mat-option *ngFor="let g of giros_comerciales" [value]="g.id">
                            {{g.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Máximo de agentes</mat-label>
                <input type="number" matInput placeholder="Máximo de agentes" [(ngModel)]="maximo_agentes">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
        
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Máximo de clientes segundos</mat-label>
                    <input type="number" matInput placeholder="Máximo de clientes segundos" [(ngModel)]="maximo_clientes">
                </mat-form-field>
            </div>
        </div>

        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-label>¿Permite dar videosoporte?</mat-label>
                <mat-checkbox color="primary"
                    [(ngModel)]="permite_videosoporte"
                    [value]="permite_videosoporte"
                    style="margin: 5px">
                </mat-checkbox>
                
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row" *ngIf="permite_videosoporte">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>¿Cuántos puntos de soporte permitirá máximo? (Dejar vacio para ilimitados)</mat-label>
                <input type="number" matInput placeholder="" [(ngModel)]="max_puntos_soporte">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
                <mat-label>¿Expirar las sesiones de agentes a los 30 minutos de inactividad?</mat-label>
                <mat-checkbox color="primary"
                    [(ngModel)]="expirar_sesiones"
                    style="margin: 5px">
                </mat-checkbox>
                
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>


    </mat-expansion-panel>

    <mat-expansion-panel class="headers-align">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h5>
                Datos de acceso
            </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input type="email" matInput placeholder="Correo electrónico" [(ngModel)]="username">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput placeholder="Contraseña" [(ngModel)]="password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>

        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6 col-12">
            <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                <mat-label>Confirmar contraseña</mat-label>
                <input type="password" matInput placeholder="Confirmar contraseña" [(ngModel)]="c_password">
            </mat-form-field>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" *ngIf="id_cliente!=0" class="headers-align">
        <mat-expansion-panel-header>
          <mat-panel-title>
              <h5>
                  Marcas del cliente
              </h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
    
        <div class="row">
            <div class="col-12 h-center">
                <div class="row">
                    <div class="add-marca-icon" (click)="abrirNuevaMarcaDialog()">
                        <i class="fas fa-plus"></i>
                    </div>
                    <button (click)="abrirNuevaMarcaDialog()" style="padding: 0" mat-button color="primary">AGREGAR MARCA</button>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>

        <div class="row">
            <div class="col-12">
                <table *ngIf="marcas && marcas.length>0" class="ws-table" style="width: 100%;">
                    <tr>
                        <th>Logo</th>
                        <th>Nombre</th>
                        <th>Plataforma whatsapp</th>
                        <!--<th>Máximo de agentes</th>-->
                        <th>Acciones</th>
                    </tr>
                    <tr *ngFor="let marca of marcas">
                        <td>
                            <img style="width: 100px" src="{{marca.logo}}"/>
                        </td>
                        <td>
                            <span [ngStyle]="{'text-decoration':!marca.activa?'line-through':''}">
                                {{marca.nombre}}
                            </span>
                        </td>
                        <td>
                            <span [ngStyle]="{'text-decoration':!marca.activa?'line-through':''}">
                                {{marca.plataforma_whatsapp}}
                            </span>
                        </td>
                        <!--<td>
                            <span [ngStyle]="{'text-decoration':!marca.activa?'line-through':''}">
                                {{marca.maximo_agentes}}
                            </span>
                        </td>-->
                        <td>
                            <div *ngIf="marca.activa">
                                <div style="width: 40%" class="horizontal-float action-logo-button" (click)="abrirNuevaMarcaDialog(marca)">
                                    <i class="fas fa-edit"></i>
                                </div>
                                <div style="width: 40%" class="horizontal-float action-logo-button" (click)="eliminarMarca(marca)">
                                    <i class="fas fa-trash"></i>
                                    <i *ngIf="loading_activar" class="fa-spin fas fa-spinner"></i>
                                </div>
                            </div>
                            <div *ngIf="!marca.activa">
                                <button (click)="reactivarMarca(marca)" 
                                style="padding: 0" mat-button color="primary">
                                    REACTIVAR MARCA
                                    <i *ngIf="loading_activar" class="fa-spin fas fa-spinner"></i>
                                </button>
                            </div>
                            <div>
                                <div style="width: 40%" class="horizontal-float action-logo-button" (click)="limpiarBucket(marca)">
                                    <i class="fas fa-eraser"></i>
                                    <i *ngIf="loading_activar" class="fa-spin fas fa-spinner"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
      </mat-expansion-panel>

    <div class="row">&nbsp;</div>
    <div class="row">
        <div class="col-12 h-center">
            <button 
                    (click)="guardarCliente()"
                    mat-raised-button 
                    color="primary" >
                    <span *ngIf="id_cliente==0">
                        Guardar y asignar marcas
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </span>
                    <span *ngIf="id_cliente!=0">
                        Guardar
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </span>
            </button>
        </div>
    </div>
  </mat-accordion>