<div>
    <app-header></app-header>
    <div class="main-container">
        <div class="row no-margin">&nbsp;</div>
        <div class="row no-margin">
            <div class="col-12">
                <div class="chat-column-container w1">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/marca-icon.png"/>
                            <span class="card-title">
                                Marcas
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <div class="marcas-container">
                            <i *ngIf="loading_marcas" class="fa-spin fas fa-spinner"></i>
                            <div class="marca-container-wrapper"
                                        [ngClass]="{'active':m.selected}"
                                        *ngFor="let m of marcas"
                                        (click)="seleccionarMarca(m)">
                                <div class="marca-container">
                                    <div class="row">
                                        <div class="col-12">
                                            <img class="logo" src="{{m.logo}}"/>
                                            <div *ngIf="m.mensajes_no_leidos > 0 && m.mensajes_no_leidos < 1000" class="badge-red">{{m.mensajes_no_leidos}}</div>
                                            <div  *ngIf="m.mensajes_no_leidos > 999" class="badge-red-999">+999</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="titulo">
                                                Marca:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span class="subtitulo">
                                                {{m.nombre}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="titulo">
                                                Sesiones:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span class="subtitulo">
                                                {{m.sesiones}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w2">

                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/consumidores-icon.png"/>
                            <span class="card-title">
                                Consumidores
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Buscar</mat-label>
                            <input matInput placeholder="Buscar por número o mensaje" [(ngModel)]="search" (change)="onSearch()" (keyup)="checkIsClear()">
                            <mat-icon matPrefix>search</mat-icon>
                            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div class="col-lg-12 d-flex justify-content-center">
                            <button mat-flat-button color="primary" (click)="nuevoChat()" class="ml-3">
                                <i class="fas fa-plus mx-1"></i>
                                <small>NUEVO CHAT</small>
                            </button>
                        </div>
                        <div id="consumidores-container-scroll" class="consumidores-container">
                            <div class="loader">
                                <i *ngIf="loading_consumidores || loading_search_consumidor" class="fa-spin fas fa-spinner"></i>
                            </div>
                            <audio id="notificationAudio">
                            <source src="../../../assets/sounds/notification.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                            </audio>
                            <div (click)="seleccionarConsumidor(c)"
                                class="consumidor-container-wrapper"
                                [ngClass]="{'active':c.selected}"
                                *ngFor="let c of consumidores">
                                <div class="consumidor-container">
                                    <div class="row h-center">
                                        <div class="col-2 v-center">
                                            <div class="perfil-placeholder v-center">
                                                {{iniciales(c.nombre_completo)}}
                                            </div>
                                        </div>
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="nombre">
                                                        +{{c.lada}} {{c.nombre_completo}}
                                                    </span>
                                                </div>
                                                <div *ngIf="c.new_message && c.mensajes_no_leidos>0 && c.mensajes_no_leidos<10" class="badge-new-message">{{c.mensajes_no_leidos}}</div>
                                                <div *ngIf="c.new_message && c.mensajes_no_leidos>9" class="badge-new-message-10">+10</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-10" style="overflow: hidden;">
                                                    <span class="ultimo_mensaje_preview">
                                                        {{c.ultimo_mensaje_preview}}
                                                    </span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="ultimo_mensaje_preview">
                                                        {{c.fecha_ultimo_mensaje | date:"dd'/'MM" :locale:'es-MX'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;padding: 10px" class="h-center" *ngIf="has_more && (!search || search=='')">
                            <button (click)="verMasConsumidores()" mat-flat-button color="primary">
                                VER MÁS
                                <i *ngIf="loading_vermas" class="fa-spin fas fa-spinner"></i>
                            </button>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w3">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/sesiones-activas-icon.png"/>
                            <span class="card-title">
                                Sesión activa
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <mat-card class="sesion-top-info">
                            <div class="row" *ngIf="marca_actual">
                                <div class="col-3">
                                    <span class="titulo">
                                        Cliente
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                        {{marca_actual.nombre}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="consumidor_actual">
                                <div class="col-3">
                                    <span class="titulo">
                                        Chat con
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                        {{consumidor_actual.nombre_completo}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="consumidor_actual">
                                <div class="col-3">
                                    <span class="titulo">
                                        Chatbot
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                        <mat-slide-toggle [checked]="consumidor_actual.usar_chatbot" (change)="changeChatbotUse()">
                                            <span class="titulo" *ngIf="!consumidor_actual.usar_chatbot">
                                                Desactivado
                                            </span>
                                        </mat-slide-toggle>
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="!consumidor_actual">
                                <div class="col-3">
                                    <span class="titulo" style="color: white;">
                                        Chat con
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="subtitulo">
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="consumidor_actual && consumidor_actual.etiqueta_conversacion">
                                <div class="col-4">
                                    <span class="titulo">
                                        Estatus del chat
                                    </span>
                                </div>
                                <div class="col-8">
                                    <span class="subtitulo">
                                        {{consumidor_actual.etiqueta_conversacion.nombre}}
                                    </span>
                                </div>
                            </div>
                        </mat-card>
                        <div id="chat-container-scroll" class="chat-container-wrapper">
                            <div class="chat-container" *ngIf="consumidor_actual">
                                <div class="row" *ngFor="let message of consumidor_actual.chat" [ngClass]="{'r-float':message.direccion=='outgoing','l-float':message.direccion=='incoming'}">
                                    <div class="col-12">
                                        <div class="row" *ngIf="message.tipo=='evento'">
                                            <div class="col-12 d-flex justify-content-center">
                                                <span class="wrap evento">
                                                    {{message.mensaje}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="chat-message-bubble" *ngIf="message.tipo!=='evento'" [ngClass]="{'incoming':message.direccion=='incoming','outgoing':message.direccion=='outgoing'}">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="wrap" *ngIf="message.tipo=='texto'">
                                                        {{message.mensaje}}
                                                    </span>
                                                    <span class="wrap" *ngIf="message.tipo=='imagen'">
                                                        <span *ngIf="message.direccion=='outgoing'">
                                                            <img style="margin: 10px; width: 100px" src="{{message.archivo}}"/>
                                                        </span>
                                                        <span *ngIf="message.direccion=='incoming'">
                                                            <img style="margin: 10px; width: 100px" src="{{message.imagen_entrante_link}}"/>
                                                            <a href="{{message.imagen_entrante_link}}" target="_blank">Guardar imagen</a>
                                                            <br/>
                                                            {{message.mensaje}}
                                                        </span>

                                                    </span>
                                                    <span class="wrap" *ngIf="message.tipo=='archivo'">
                                                        <span *ngIf="message.direccion=='outgoing'" class="color-primary">
                                                            <span style="margin: 10px; width: 100px;" class="link" (click)="abrirArchivo(message.archivo)" >
                                                                Abrir archivo
                                                                <i class="fas fa-external-link-alt ml-1"></i>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12" style="text-align:right;font-size:0.75em">
                                                    {{message.creacion | date:"dd'/'MMM'/'yyyy HH:mm" :locale:'es-MX'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="chat-bottom-anchor"></div>
                        </div>
                        <div *ngIf="consumidor_actual && consumidor_actual.id" class="chat-sender-wrapper">
                            <div  class="chat-sender">
                                <div class="row">
                                    <div class="col-sm-3 d-flex align-items-center">
                                        <div *ngIf="visible_emoji" class="emoji-container">
                                            <div class="row" style="background-color: black;margin: 0px;padding: 5px;margin-bottom: -5px;">
                                                <a (click)="visible_emoji = false">
                                                    <h6 style="color: white;font-size: 18px;"><i class="fas fa-times" style="color: white;font-size: 18px;"></i> Cerrar</h6>
                                                </a>
                                            </div>
                                            <emoji-mart
                                                [set]="'google'"
                                                [hideRecent]="true"
                                                (emojiSelect)="addEmoji($event)"
                                                title="Selecciona emoji…"
                                                emoji="point_up"
                                                >
                                            </emoji-mart>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <a (click)="visible_emoji = true">
                                                    <i class="far fa-smile" style="font-size: 24px;"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="!bold_activo" class="col-sm-4">
                                                <a (click)="setBold()">
                                                    <i class="fas fa-bold" style="font-size: 24px;"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="bold_activo" class="col-sm-4">
                                                <a (click)="setBold()">
                                                    <i class="fas fa-bold color-primary" style="font-size: 24px;"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="!italic_activo" class="col-sm-4">
                                                <a (click)="setItalic()">
                                                    <i class="fas fa-italic" style="font-size: 24px;"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="italic_activo" class="col-sm-4">
                                                <a (click)="setItalic()">
                                                    <i class="fas fa-italic color-primary" style="font-size: 24px;"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="horizontal-float">
                                            <mat-form-field style="margin-bottom: 0;width: 85%;" appearance="outline">
                                                <mat-label>Escribe tu mensaje</mat-label>
                                                <textarea id="message" matInput placeholder="Escribe tu mensaje" (select)="onSelect($event)" (keyup.enter)="enviarMensaje()" autocomplete="off" [(ngModel)]="mensaje_actual"></textarea>
                                            </mat-form-field>
                                            <div class="send-icon-button d-flex align-items-center" style="margin-top:8px;float:right;">
                                                <span *ngIf="!loading_envio_mensaje">
                                                    <img style="width: 30px;cursor: pointer;" (click)="enviarMensaje()" src="assets/images/send-icon.png"/>
                                                </span>
                                                <span *ngIf="loading_envio_mensaje">
                                                    <div class="loader" style="font-size: 1.3em;color: white;">
                                                        <i class="fa-spin fas fa-spinner"></i>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="chat-column-container w2">
                    <mat-card class="container-card">
                        <mat-card-subtitle>
                            <img style="width:25px" src="assets/images/herramientas-icon.png"/>
                            <span class="card-title">
                                Herramientas
                            </span>
                        </mat-card-subtitle>
                        <hr/>
                        <div class="actions-container-wrapper"  *ngIf="consumidor_actual && consumidor_actual.id">
                        <div class="actions-container" *ngIf="!agregar_consumidor && !transferir_chat && !editar_consumidor">
                                <div class="action-container flex-10">
                                    <div class="row">
                                        <mat-form-field class="mx-3" style="width: 100%;" appearance="outline">
                                            <mat-label>Estatus del chat</mat-label>
                                            <mat-select [(ngModel)]="estatus_chat_actual.id" (selectionChange)="editando_estatus_chat=true">
                                                <mat-option *ngFor="let etiqueta of etiquetas_conversacion_marca" [value]="etiqueta.id">
                                                {{etiqueta.nombre}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row d-flex justify-content-center" *ngIf="editando_estatus_chat">
                                        <button mat-flat-button color="primary" (click)="establecerEtiquetaAConsumidor()">
                                            <small>GUARDAR</small>
                                        </button>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                        <div class="col-2">
                                            <img src="assets/images/archivo-icon.png" style="width: 24px"/>
                                        </div>
                                        <div class="col-10">
                                            <button (click)="clickInput('input_file')" style="padding: 0" mat-button color="text-2"><span style="font-weight: 200;">Enviar un archivo</span></button>
                                            <div *ngIf="!enviando_archivo">
                                                <input style="display: none;" type="file" id="input_file" name="archivo" (change)="onFileSelect($event)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                        <div class="col-2">
                                            <img src="assets/images/transferir-icon.png" style="width: 24px"/>
                                        </div>
                                        <div class="col-10">
                                            <button (click)="transferConsumer()" style="padding: 0" mat-button color="text-2"><span style="font-weight: 200;">Transferir sesión</span></button>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                        <div class="col-2">
                                            <img src="assets/images/video-icon.png" style="width: 24px"/>
                                        </div>
                                        <div class="col-10">
                                            <button (click)="enviarLink()" style="padding: 0" mat-button color="text-2"><span style="font-weight: 200;">Enviar URL de videollamda</span></button>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                        <div class="col-1">&nbsp;</div>
                                        <div class="col-10" style="width: 100%;">
                                            <a href="/#/videollamada/{{url_video_call}}" target="_blank">
                                                <button mat-flat-button color="primary" (click)="enviarLink()" style="padding: 0;width: 100%;"><Img:sizes>INICIAR VIDEOSOPORTE</Img:sizes></button>
                                            </a>
                                        </div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </div>
                                <div *ngIf="loading_search_consumidor_crm"  class="bottom-actions-container flex-1 d-flex justify-content-center" style="width: 100%;">
                                    <i class="fa-spin fas fa-spinner"></i>
                                </div>
                                <div *ngIf="!loading_search_consumidor_crm" class="bottom-actions-container flex-1" style="width: 100%;">
                                    <div *ngIf="marca_actual.tipo_crm != 'modo_1'" class="row">
                                        <!--<div *ngIf="!cliente_actual_guardado" class="col-12 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="editConsumer()" mat-stroked-button color="text-1">LISTA CONSUMIDORES</button>
                                        </div>-->
                                        <div *ngIf="!cliente_actual_guardado && marca_actual.tipo_crm != 'modo_3'" class="col-12 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="addConsumer()" mat-flat-button color="primary">AGREGAR CONSUMIDOR</button>
                                        </div>
                                        <div *ngIf="marca_actual.tipo_crm == 'modo_3'" class="col-12 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="addConsumer()" mat-flat-button color="primary">AGREGAR CONSUMIDOR</button>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!loading_search_consumidor_crm" class="bottom-actions-container flex-1" style="width: 100%;">
                                    <div *ngIf="marca_actual.tipo_crm == 'modo_2' || marca_actual.tipo_crm == 'modo_4' && cliente_actual_guardado" class="row">
                                        <div *ngIf="cliente_actual_guardado" class="col-12 p-0" style="margin:0.5rem 0.5rem">
                                            <h5>Consumidor guardado</h5>
                                        </div>
                                        <div *ngIf="cliente_actual_guardado" class="col-12 p-0">
                                            <hr/>
                                        </div>
                                        <div *ngIf="cliente_actual_guardado" class="col-12 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="enviarUrlEncuesta(consumidor_crm_actual)" mat-stroked-button color="text-1">ENVIAR ENCUESTA</button>
                                        </div>
                                        <div *ngIf="cliente_actual_guardado" class="col-6 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="editarConsumidorCRM(consumidor_crm_actual)" mat-stroked-button color="text-1">EDITAR</button>
                                        </div>
                                        <div *ngIf="cliente_actual_guardado" class="col-6 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="borrarConsumidorCRM(consumidor_crm_actual.id)" mat-flat-button color="primary">BORRAR</button>
                                        </div>
                                        <div *ngIf="cliente_actual_guardado" class="col-12 p-0">
                                            <button style="margin:0.5rem 0.5rem; width: -webkit-fill-available" (click)="addConsumer()" mat-flat-button color="primary">AGREGAR NUEVO REGISTRO</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="actions-container" *ngIf="agregar_consumidor">
                            <div class="action-container flex-8 new-consumidores-container">
                                <div class="row" *ngFor="let campo of campos_dinamicos">
                                    <mat-form-field *ngIf="campo.tipo == 'text'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>{{campo.etiqueta}}</mat-label>
                                        <input matInput placeholder="{{campo.etiqueta}}" [(ngModel)]="campo.valor">
                                    </mat-form-field>
                                    <mat-form-field *ngIf="campo.tipo == 'number'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>{{campo.etiqueta}}</mat-label>
                                        <input mdInput type="text" (keyup)="soloNumeros($event,campo)" matInput placeholder="{{campo.etiqueta}}" [(ngModel)]="campo.valor" value="campo.valor">
                                    </mat-form-field>
                                    <mat-form-field *ngIf="campo.tipo == 'date'" appearance="outline" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
                                        <mat-label>{{campo.etiqueta}}</mat-label>
                                        <input matInput [matDatepicker]="picker" [(ngModel)]="campo.valor" [value]="campo.valor"
                                        (click)="picker.id.open()"
                                        (keydown.arrowdown)="picker.open()"
                                        (keydown.enter)="picker.open()"
                                        readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="campo.tipo == 'dropdown'" style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
                                        <mat-label>{{campo.etiqueta}}</mat-label>
                                        <mat-select [(value)]="campo.valor">
                                            <mat-option *ngFor="let op of campo.opciones" [value]="op.nombre">{{op.nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="campo.tipo == 'dropdown_multi'" style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
                                        <mat-label>{{campo.etiqueta}}</mat-label>
                                        <mat-select [(value)]="campo.valor" multiple>
                                            <mat-option *ngFor="let op of campo.opciones" [value]="op.nombre">{{op.nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="campo.tipo == 'file'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
                                        <div class="col-12">
                                            <span>{{campo.etiqueta}}</span>
                                        </div>
                                        <div *ngIf="!campo.file_name" class="col-12">
                                            <button class="fileButton" [disabled]="loading_upload_file" (click)="clickInput('input_file'+campo.id)" mat-stroked-button color="text-2"><span style="font-weight: 200;">Subir archivo <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i></span></button>
                                            <input style="display: none;" type="file" id="input_file{{campo.id}}" name="archivo" (change)="onFileSelectInputCustom($event,campo)" />
                                        </div>
                                        <div *ngIf="campo.valor && campo.valor != ''" class="col-12">
                                            <div class="row">
                                                <div class="col-10">
                                                    <a [href]="campo.valor" target="_blank">
                                                        <span>{{campo.file_name}}</span>
                                                    </a>
                                                </div>
                                                <div class="col-2">
                                                    <a (click)="clearDataFile(campo)">
                                                        <i class="fas fa-times color-primary" style="font-size: 18px;"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="campo.tipo == 'image'" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
                                        <div class="col-12">
                                            <span>{{campo.etiqueta}}</span>
                                        </div>
                                        <div *ngIf="!campo.file_name" class="col-12">
                                            <button class="fileButton" [disabled]="loading_upload_file" (click)="clickInput('input_file'+campo.id)" mat-stroked-button color="text-2"><span style="font-weight: 200;">Subir imagen <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i></span></button>
                                            <input style="display: none;" type="file" id="input_file{{campo.id}}" name="archivo" (change)="onFileSelectInputCustom($event,campo)" accept="image/x-png,image/jpeg"/>
                                        </div>
                                        <div *ngIf="campo.valor && campo.valor != ''" class="col-12">
                                            <div class="row">
                                                <div class="col-10">
                                                    <a [href]="campo.valor" target="_blank">
                                                        <span>{{campo.file_name}}</span>
                                                    </a>
                                                </div>
                                                <div class="col-2">
                                                    <a (click)="clearDataFile(campo)">
                                                        <i class="fas fa-times color-primary" style="font-size: 18px;"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">&nbsp;</div>
                                </div>
                            </div>
                                <!--<div class="row">
                                    <mat-form-field style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
                                        <mat-label>Genero</mat-label>
                                        <mat-select [(value)]="nuevo_consumidor.genero">
                                            <mat-option [value]="'F'">Femenino</mat-option>
                                            <mat-option [value]="'M'">Masculino</mat-option>
                                            <mat-option [value]="'NE'">No especificado</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field appearance="outline" style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;">
                                        <mat-label>Fecha de nacimiento</mat-label>
                                        <input matInput [max]="fechaDeNacimientoMax()" [matDatepicker]="picker" [(ngModel)]="nuevo_consumidor.fecha_nacimiento" [value]="nuevo_consumidor.fecha_nacimiento"
                                        (click)="picker.open()"
                                        (keydown.arrowdown)="picker.open()"
                                        (keydown.enter)="picker.open()"
                                        readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput placeholder="Calle" [(ngModel)]="nuevo_consumidor.calle">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <div class="col-6 p-0">
                                        <mat-form-field style="margin-bottom: 0;width: 100%; padding:0rem 0.5rem 0rem 1rem" appearance="outline">
                                            <mat-label>Núm. exterior</mat-label>
                                            <input matInput placeholder="Núm. exterior" [(ngModel)]="nuevo_consumidor.numero_exterior">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6 p-0">
                                        <mat-form-field style="margin-bottom: 0;width: 100%; padding:0rem 1rem 0rem 0.5rem" appearance="outline">
                                            <mat-label>Núm. interior</mat-label>
                                            <input matInput placeholder="Núm. interior" [(ngModel)]="nuevo_consumidor.numero_interior">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>Código Postal</mat-label>
                                        <input matInput placeholder="Código Postal" [(ngModel)]="nuevo_consumidor.codigo_postal">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>Colonia</mat-label>
                                        <input matInput placeholder="Colonia" [(ngModel)]="nuevo_consumidor.colonia">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput placeholder="Estado" [(ngModel)]="nuevo_consumidor.estado">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field style="margin-bottom: 0;width: 100%;margin: 0.1rem 1rem;" appearance="outline">
                                        <mat-label>Ciudad o municipio</mat-label>
                                        <input matInput placeholder="Ciudad o municipio" [(ngModel)]="nuevo_consumidor.ciudad">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field style="width: 100%;margin: 0.1rem 1rem;margin-bottom: 0" appearance="outline">
                                        <mat-label>Productos comprados</mat-label>
                                        <mat-select [(value)]="multiple_select_value" multiple>
                                            <mat-option *ngFor="let cafe of multiple_select" [value]="cafe">{{cafe}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>-->
                            <div class="row bottom-actions-container container-shadow flex-1 ">
                                <div class="col-6 p-0">
                                    <button style="margin:0rem 0.5rem 0rem 1rem; width: -webkit-fill-available" (click)="cancelAddConsumer()" mat-stroked-button color="text-1">CANCELAR</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button style="margin:0rem 1rem 0rem 0.5rem; width: -webkit-fill-available" (click)="saveConsumer()" mat-flat-button color="primary">GUARDAR <i *ngIf="loading_add_consumidor" class="fa-spin fas fa-spinner"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="actions-container" *ngIf="editar_consumidor">
                            <div class="action-container flex-8 new-consumidores-container">
                                <div class="row">
                                    <span class="action-logo-button" style="margin: 0rem 1rem;">Selecciona consumidor para editar</span>
                                </div>
                                <div class="row">&nbsp;</div>
                                <div *ngFor="let c of consumidores_crm" class="row" style="margin-bottom: 0;width: 100%;margin-left: -8px;">
                                    <div class="col-8">
                                        <h6>{{c.celular}}</h6>
                                    </div>
                                    <div class="col-2">
                                        <a (click)="editarConsumidorCRM(c)">
                                            <i class="far fa-edit color-primary" style="font-size: 12px;"></i>
                                        </a>
                                    </div>
                                    <div class="col-2">
                                        <a (click)="borrarConsumidorCRM(c.id)">
                                            <img src="assets/images/borrar-icon.png" width="16">
                                        </a>
                                    </div>
                                    <div class="col-12">
                                        <span>{{c.nombre}}</span>
                                    </div>
                                    <div class="col-12">
                                        <hr/>
                                    </div>

                                </div>
                            </div>
                        <div class="row bottom-actions-container container-shadow flex-1 ">
                            <button style="margin:0rem 0.5rem 0rem 1rem; width: -webkit-fill-available" (click)="cancelAddConsumer()" mat-stroked-button color="text-1">CANCELAR</button>
                        </div>
                        </div>
                        <div class="actions-container" *ngIf="transferir_chat">
                            <div class="action-container flex-8 new-consumidores-container">
                                <div class="row">
                                    <span class="action-logo-button" style="margin: 0rem 1rem;">Transferir con</span>
                                    <mat-form-field style="margin-bottom: 0; display: flex; justify-content: flex-start;flex: 1;margin-left: 20px;" appearance="outline">
                                        <mat-label>Nombre del agente</mat-label>
                                        <mat-select [(ngModel)]="id_asesor">
                                          <mat-option *ngFor="let asesor of asesores" [value]="asesor.id">
                                            {{asesor.nombre_completo}} / {{asesor.tipo}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row bottom-actions-container container-shadow flex-1 ">
                                <div class="col-6 p-0">
                                    <button style="margin:0rem 0.5rem 0rem 1rem; width: -webkit-fill-available" (click)="cancelTransferConsumer()" mat-stroked-button color="text-1">CANCELAR</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button style="margin:0rem 1rem 0rem 0.5rem; width: -webkit-fill-available" (click)="transferToIdConsumer()" mat-flat-button color="primary">TRANSFERIR</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>

    </div>
    <app-footer></app-footer>
</div>
