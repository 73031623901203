<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">
    <div class="col-8">

    </div>
</div>
<div class="row" *ngIf="horario">
    <div class="col-lg-12 d-flex justify-content-end">
        <a class="mx-1" (click)="editarInformacionChatbotPadre()" *ngIf="!editanto">
            <i class="far fa-edit color-primary" style="font-size: 24px;"></i>
        </a>
        <button mat-button class="mx-1" (click)="deshacerCambiosChatbotPadre()" [disabled]="loading_edit ? true : false" *ngIf="editanto">
            <small>CANCELAR</small>
        </button>
        <button mat-flat-button class="mx-1" color="primary" [disabled]="loading_edit ? true : false" (click)="guardarCambiosChatbotPadre()" *ngIf="editanto">
            <small>GUARDAR</small>
        </button>
    </div>
    <div class="col-lg-12">
        <h6 style="font-weight: 600;">Primer periodo de activación (opcional)</h6>
    </div>
    <div class="col-lg-12 my-2"> 
        <div class="row w-100">
            <div class="col-lg-6">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Horario inicial de activacion</mat-label>
                    <input type="time" [readonly]="!editanto || loading_edit ? true : false" matInput placeholder="Horario inicial de activacion" [(ngModel)]="horario.primer_periodo_hora_inicio">
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Horario final de activacion</mat-label>
                    <input type="time" [readonly]="!editanto || loading_edit? true : false" matInput placeholder="Horario final de activacion" [(ngModel)]="horario.primer_periodo_hora_fin">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <h6 style="font-weight: 600;">Segundo periodo de activación (opcional)</h6>
    </div>
    <div class="col-lg-12 my-2"> 
        <div class="row w-100">
            <div class="col-lg-6">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Horario inicial de activacion</mat-label>
                    <input type="time" [readonly]="!editanto ? true : false" matInput placeholder="Horario inicial de activacion" [(ngModel)]="horario.segundo_periodo_hora_inicio">
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Horario final de activacion</mat-label>
                    <input type="time" [readonly]="!editanto ? true : false" matInput placeholder="Horario final de activacion" [(ngModel)]="horario.segundo_periodo_hora_fin">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-lg-12 my-2">
        <div class="row w-100">
            <div class="col-lg-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Zona Horaria (UTC +/-)</mat-label>
                    <input type="number" [readonly]="!editanto ? true : false" min="-24" max="24" matInput placeholder="El UTC de su ubicación actual es {{tiempo_universal_coordinado_local}}" [(ngModel)]="horario.tiempo_universal_coordinado">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Zona horaria acorde al tiempo universal coordinado, agregue un signo positivo (+) para sumar horas o un signo negativo (-) para restar
                </span>
            </div>
        </div>
    </div>
</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>

<ng-container
    *ngTemplateOutlet="recursiveListTmpl; context:{ list: list, esRaiz : true }"
></ng-container>
  
<ng-template #recursiveListTmpl let-list="list" let-esRaiz="esRaiz">
    <div class="row" *ngFor="let chatbot of list | keyvalue; let indice = index" class="row d-flex align-items-center">
        <div class="col-md-1" *ngIf="!esRaiz">
            <h3 class="color-primary" style="font-weight: 200;">{{chatbot.value.disparador}}</h3>
        </div>
        <div class="col-md-8 card">
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-11">
                    <h6 *ngIf="chatbot.value.disparador" style="font-weight: 600;">Opción {{chatbot.value.disparador}}</h6>
                    <h6 *ngIf="!chatbot.value.disparador" style="font-weight: 600;">
                        Mensaje encabezado
                        <div *ngIf="esRaiz">
                            <span style="font-style: italic;" *ngIf="chatbot.value.acceso_rapido && chatbot.value.acceso_rapido!=''">
                                (Acceso rápido: /{{chatbot.value.acceso_rapido}})
                            </span>
                            <span style="font-style: italic;" *ngIf="chatbot.value.encuesta">
                                Este chatbot es de tipo encuesta
                            </span>
                            <span style="font-style: italic;" *ngIf="chatbot.value.formulario">
                                Este chatbot es de tipo formulario
                            </span>
                        </div>
                    </h6>
                    <h6 style="font-weight: 200;">{{chatbot.value.mensaje}}</h6>
                </div>
                <div class="col-md-1">
    
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
        <div class="col-md-1">
            <a (click)="editarChatbot(chatbot.value)">
                <i class="far fa-edit color-primary" style="font-size: 24px;"></i>
            </a>
        </div>
        <div class="col-md-1">
            <a *ngIf="(list.length - 1) === indice" (click)="borrarChatbot(chatbot.value.id, esRaiz)">
                <img src="assets/images/borrar-icon.png" width="34">
            </a>
        </div>    
        <div class="col-md-2" *ngIf="esRaiz">
            <button 
            *ngIf="chatbot.value.activo"
            (click)="desactivarChatboot()"
            mat-raised-button 
            color="primary" >
                Desactivar
            <i *ngIf="loading_active" class="fa-spin fas fa-spinner"></i>
            </button>
            <button 
            *ngIf="!chatbot.value.activo"
            (click)="activarChatboot()"
            mat-raised-button  
            color="primary" 
            >
                Activar
            <i *ngIf="loading_active" class="fa-spin fas fa-spinner"></i>
            </button>
        </div> 
        <div class="col-12">&nbsp;</div>
        <div class="col-md-12" style="margin-left: 5rem;">
            <div class="col-md-12" *ngIf="chatbot.value.hijos.length > 0">
                <ng-container
                      *ngTemplateOutlet="recursiveListTmpl; context:{ list: chatbot.value.hijos, esRaiz : false }"
                ></ng-container>
            </div>
        </div>                     
        <div class="col-12">
            <div class="row d-flex justify-content-start" *ngIf="!chatbot.value.formulario">
                <div class="col-12" *ngIf="!esRaiz">   
                    <button 
                        *ngIf="chatbot.value.hijos.length == 0 && chatbot.value.disparador"
                        (click)="nuevoHijo(chatbot.value.marca,chatbot.value,chatbot.value.hijos.length,true)"
                        mat-stroked-button 
                        outline
                        color="primary" >
                            Agregar encabezado
                            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
                    <button 
                        *ngIf="chatbot.value.hijos.length != 0 && chatbot.value.disparador"
                        (click)="nuevoHijo(chatbot.value.marca,chatbot.value,chatbot.value.hijos.length,true)"
                        mat-stroked-button 
                        outline
                        color="primary" >
                            Agregar hijo
                            {{chatbot.value.disparador}}.{{chatbot.value.hijos.length | number}}
                             <i *ngIf="loading" class="fa-spin fas fa-spinner"></i> 
                    </button>
                </div> 
                <div class="col-12" *ngIf="esRaiz">   
                    <button 
                        (click)="nuevoHijo(chatbot.value.marca,chatbot.value,chatbot.value.hijos.length+1,false)"
                        mat-stroked-button 
                        outline
                        color="primary" >
                            Agregar hijo {{chatbot.value.hijos.length+1 | number}}
                            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-start" *ngIf="chatbot.value.formulario">
                <div class="col-12">   
                    <button 
                        *ngIf="puedeAgregarHijos(chatbot.value)"
                        (click)="nuevoHijo(chatbot.value.marca,chatbot.value,chatbot.value.hijos.length,true)"
                        mat-stroked-button 
                        outline
                        color="primary" >
                        Agregar
                        <span *ngIf="!chatbot.value.disparador">
                            hijo {{chatbot.value.hijos.length+1 | number}}
                        </span>    
                        <span *ngIf="chatbot.value.disparador">
                            encabezado
                        </span>
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
                </div> 
                <!-- <div class="col-12" *ngIf="esRaiz">   
                    <button 
                        (click)="nuevoHijo(chatbot.value.marca,chatbot.value,chatbot.value.hijos.length+1,false)"
                        mat-stroked-button 
                        outline
                        color="primary" >
                            Agregar hijo {{chatbot.value.hijos.length+1 | number}}
                            <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
                </div> -->
            </div>
        </div>
        <div class="col-12"><hr *ngIf="chatbot.value.disparador"/></div>
    </div>
</ng-template>
<div class="col-12"><hr/></div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
