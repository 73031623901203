<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-10">
            <h5 *ngIf="!data.marca">Nueva marca</h5>
            <h5 *ngIf="data.marca">Editar marca</h5>
        </div>
        <div class="col-2">
            <mat-icon (click)="onNoClick()" style="cursor: pointer" aria-hidden="false" aria-label="Cerrar">close</mat-icon>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12 my-2">
                <div *ngIf="!logo.input_file">
                    <img src="assets/images/agregar-logo.png" style="width: 20px"/>&nbsp;
                    <button (click)="clickInput('marca_logo_file')" style="padding: 0" mat-button color="primary">CAMBIAR LOGOTIPO</button>
                </div>
                <input style="display: none;" type="file" id="marca_logo_file" name="marca_logo_file" (change)="onMarcaLogoSelect($event)" />
                <div *ngIf="logo.input_file">
                    <div class="logo-container">
                        <div class="row">
                            <div class="col-10 v-center">
                                <img style="width: 50%;" src="{{logo.url}}"/>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="clickInput('marca_logo_file')">
                                            <i class="fas fa-edit"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 h-center">
                                        <div class="action-logo-button" (click)="eliminarLogo()">
                                            <i class="fas fa-trash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input type="text" matInput placeholder="Nombre de la marca" [(ngModel)]="nombre">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12"> 
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Horario inicial para recibir mensajes (opcional)</mat-label>
                    <input type="time" matInput placeholder="Horario inicial para recibir mensajes (opcional)" [(ngModel)]="horario_inicio">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Por ejemplo si pones "08:00", los mensajes hasta las "07:59" se asignaran a agentes online, y después de eso al agente maestro
                </span>
                <br/>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Horario final para recibir mensajes (opcional)</mat-label>
                    <input type="time" matInput placeholder="Horario final para recibir mensajes (opcional)" [(ngModel)]="horario_fin">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Por ejemplo si pones "17:00", los mensajes hasta las "17:59" se asignaran a agentes online, y después de eso al agente maestro
                </span>
                <br/>
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Zona Horaria (UTC +/-)</mat-label>
                    <input type="number" min="-24" max="24" matInput placeholder="Ejemplo: +6" [(ngModel)]="tiempo_universal_coordinado">
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Zona horaria acorde al tiempo universal coordinado, agregue un signo positivo (+) para sumar horas o un signo negativo (-) para restar
                </span>
                <br/>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-lg-12">
                <h5>Etiquetas de conversación</h5>
            </div>
            <div class="col-lg-12">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Etiquetas</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip
                        *ngFor="let etiqueta of etiquetas_conversacion"
                        [selectable]="selectable"
                        [removable]="etiqueta.removible"
                        (removed)="removerEtiqueta(etiqueta)"
                        (click)="editarEtiqueta(etiqueta)"
                        >
                        {{etiqueta.nombre}}
                        <mat-icon matChipRemove *ngIf="etiqueta.removible">cancel</mat-icon>
                      </mat-chip>
                      <input
                        matInput
                        placeholder="Nueva etiqueta"
                        #etiquetaInput
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="agregarEtiqueta($event)"
                        [(ngModel)] = "etiqueta.nombre"
                        *ngIf="etiquetas_conversacion.length!=10"
                        >
                    </mat-chip-list>
                </mat-form-field>
                <span style="font-size: 0.8em">
                    Escriba la etiqueta y presione la tecla Enter para guardarla.
                    Para editar la etiqueta, de click en el chip que desee editar.
                </span>
                <span style="font-size: 0.8em">
                    Puede agregar hasta 10 etiquetas, las cuales podrán ser asignadas a una conversación por un agente
                </span>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <mat-form-field style="margin-bottom: 0;width: 100%;" appearance="outline">
                    <mat-label>Máximo de participaciones en formularios</mat-label>
                    <input type="text" matInput [(ngModel)]="maximo_participaciones">
                </mat-form-field>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-6">
               <h5>Videos de la marca</h5>
            </div>
            <div class="col-6 r-float">
                <img src="assets/images/agregar-logo.png" style="width: 35px"/>&nbsp;
                <button (click)="clickInput('marca_video_file')" style="padding: 0" mat-button color="primary">SUBIR NUEVO VIDEO</button>
                <input style="display: none;" type="file" id="marca_video_file" name="marca_video_file" (change)="onMarcaVideoSelect($event)" />
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row" *ngIf="loading_videos">
            <div class="col-12 h-center" style="font-size: 1.5em;">
                <i class="fa-spin fas fa-spinner"></i>
            </div>
        </div>
        <div class="row" *ngIf="videos.length==0 && !loading_videos">
            <div class="col-12" style="text-align: center;">
                Aún no hay videos para la marca
            </div>
        </div>
        <div class="row" *ngIf="videos.length>0 && !loading_videos">
            <div class="col-12">
                <table class="ws-table" style="width: 100%;">
                    <tr>
                        <th>
                            Nombre del video
                        </th>
                        <th>
                            Peso en mb
                        </th>
                        <th>
                            Acciones
                        </th>
                    </tr>
                    <tr *ngFor="let video of videos">
                        <td>
                            {{video.nombre_archivo}}
                        </td>
                        <td>
                            {{video.size/1024/1024 | number:'1.3-3'}} MB
                        </td>
                        <td>
                            <a href="{{video.url}}" target="_blank">
                            <div style="width: 40%" class="horizontal-float action-logo-button">
                                <i class="fas fa-eye"></i>
                            </div>
                            </a>
                            <div style="color: red; width: 40%" class="horizontal-float action-logo-button" (click)="eliminarVideo(video)">
                                <div *ngIf="!loading_eliminando">
                                    <i class="fas fa-trash-alt"></i>
                                </div>
                                <div *ngIf="loading_eliminando">
                                    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-7">
                <h5>Fondo virtual de la marca</h5>
                <p>Solo si subes un fondo virtual se intentará aplicar en todos los agentes que den videosoporte para esta marca</p>
             </div>
             <div class="col-5 r-float">
                <div style="background-image: url(assets/images/agregar-logo.png);
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 35px;
                                height: 35px;
                                margin-right: 10px;
                                margin-top: 30px;">
                    <i *ngIf="loading_fondo_virtual" class="fa-spin fas fa-spinner"></i>
                </div>
                 <button (click)="clickInput('marca_fondo_virtual_file')" style="padding: 0" mat-button color="primary">SUBIR NUEVO FONDO VIRTUAL</button>
                 <input style="display: none;" type="file" id="marca_fondo_virtual_file" name="marca_fondo_virtual_file" (change)="onMarcaFondoVirtualSelect($event)" />
             </div>
        </div>
        <div class="row" *ngIf="data.marca.fondo_virtual_url">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 h-center">
                        <div style="background-image: url({{data.marca.fondo_virtual_url}});
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        width: 350px;
                                        height: 275px;">
                        </div>
                    </div>
                </div>
                <div class="row" (click)="eliminarFondoVirtual()">
                    <div class="col-12 h-center" style="cursor: pointer; font-size: 1.1em; margin-top: 5px">
                        <span class="color-danger">
                            Eliminar <i class="fas fa-trash-alt"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">&nbsp;</div>
    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button 
                    (click)="guardarMarca()"
                    mat-raised-button 
                    color="primary" >
                        GUARDAR
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
        </div>
    </div>
</div>