import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-cliente-segundo-info',
  templateUrl: './cliente-segundo-info.component.html',
  styleUrls: ['./cliente-segundo-info.component.scss']
})
export class ClienteSegundoInfoComponent implements OnInit {
  @Input() id_cliente:number;

  public nombre_cliente:string; 
  public username:string;
  public password:string;
  public c_password:string;

  public loading:boolean = false;
  public permisos : any = {
    seccion_dashboard : {
      acceso : true,
      edicion : true,
      etiqueta : "Dashboard"
    },
    seccion_agentes : {
      acceso : true,
      edicion : true,
      etiqueta : "Agentes"
    },
    seccion_conversaciones : {
      acceso : true,
      edicion : true,
      etiqueta : "Conversaciones"
    },
    seccion_consumidores : {
      acceso : true,
      edicion : true,
      etiqueta : "Consumidores"
    },
    seccion_chatbots : {
      acceso : true,
      edicion : true,
      etiqueta : "Chatbots"
    },
    seccion_formularios : {
      acceso : true,
      edicion : true,
      etiqueta : "Formularios"
    },
    seccion_puntos_videosoporte : {
      acceso : true,
      edicion : true,
      etiqueta : "Puntos videosoporte"
    },
    seccion_perfil_de_cliente : {
      acceso : true,
      edicion : true,
      etiqueta : "Perfil de cliente"
    },
    seccion_administrar_clientes : {
      acceso : true,
      edicion : true,
      etiqueta : "Administrar clientes"
    }
  };

  constructor(
    public facade:FacadeService, 
    public router:Router
    ) { }

  ngOnInit(): void {
    if (this.id_cliente != 0){
      this.loadClienteSegundo();
    }
  }

  loadClienteSegundo(){
    this.loading = true;
    this.facade.recuperarClienteSegundo(this.id_cliente).subscribe(
      (response) => {
        this.nombre_cliente = response.user.first_name;
        this.username = response.user.email;
        this.password = "**********";
        this.c_password = "**********";
        if (response?.permisos){
          response.permisos = JSON.parse(response?.permisos);
          let keys = this.obtenerLlavesDePermisos();
          keys.forEach(key => {
            this.permisos[key].acceso = response?.permisos[key]?.acceso ?? true;
            this.permisos[key].edicion = response?.permisos[key]?.edicion ?? true;
          });
        }
        this.loading = false;
      },
      (error) =>{
        console.log(error);
        this.loading = false;
      }
    );
  }

  guardarClienteSegundo(){
    this.loading = true;
    if (this.password === this.c_password){
      this.facade.guardarClienteSegundo(
        this.id_cliente,
        this.nombre_cliente, 
        this.username, 
        this.password,
        this.permisos
      ).subscribe(
        (response) => {
          this.loading = false;
          this.router.navigate(['cliente/admin']); 
        },
        (error) =>{
          alert(error.error.error);
          this.loading = false;
          console.log(error);
        }
      );
    }
    else{
      this.loading = false;
      alert("Las contraseñas con coinciden")
    }
   
  }

  obtenerLlavesDePermisos(){
    return Object.keys(this.permisos);
  }
  

}
