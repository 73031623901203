<app-header></app-header>
    <div class="main-container">
        <div class="row">
            <div class="col-2">
                <app-menu-cliente></app-menu-cliente>
            </div>
            <div class="col-10">
                <div class="container main-container">
                    <div class="row">&nbsp;</div>
                    <div class="row">
                        <div class="col">
                            <h4>Puntos de videosoporte</h4>
                        </div>
                        <div class="col-auto">
                            <button mat-raised-button [routerLink]="['/cliente/videosoporte/metricas']" color="primary">
                                Ver Métricas
                            </button>
                        </div>
                    </div>
                    <!--div class="loader">
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </div-->
                    <div style="position: relative; min-height: 70vh;" *ngIf="(puntos_soporte && puntos_soporte.length>0) ||  criteria!='' || search || esFiltro()">
                        <div class="row w-100 align-items-start">
                            <div class="col-lg-4 d-flex justify-content-center">
                                <mat-form-field class="m-1" style="width: 100%;" appearance="outline">
                                    <mat-label>Buscar</mat-label>
                                    <input type="search" matInput placeholder="Buscar por nombre o dirección" [(ngModel)]="criteria" (keyup.enter)="buscarPuntosSoporte($event)">
                                    <button *ngIf="criteria==''" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="loadPuntosSoporte()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button mat-button *ngIf="criteria!=''" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline">
                                    <mat-label>Selecciona estatus del videototem</mat-label>
                                    <mat-select [(ngModel)]="estatus_totem" (selectionChange)="aplicarFiltro()">
                                      <mat-option *ngFor="let estado of estados" [value]="estado.valor">
                                        {{estado.descripcion}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 d-flex justify-content-center">
                                <mat-form-field class="m-1" appearance="outline">
                                    <mat-label>Selecciona marca</mat-label>
                                    <mat-select [(ngModel)]="id_marca" (selectionChange)="aplicarFiltro()">
                                      <mat-option *ngFor="let marca of marcas" [value]="marca.id">
                                        {{marca.nombre}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="esFiltro()" class="col-lg-2 d-flex align-items-center">
                                <a (click)="limpiarFiltro()">
                                    <div class="row mt-3 d-flex align-items-center">
                                        <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;"><i class="fas fa-times-circle"></i> LIMPIAR FILTROS <i *ngIf="loading_clear" class="fa-spin fas fa-spinner"></i></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <table class="ws-table" style="width: 100%;">
                            <tr>
                                <th>
                                    Estatus del videototem
                                </th>
                                <th>
                                    Punto de soporte
                                </th>
                                <th>
                                    Marca
                                </th>
                                <!-- <th>
                                    Agente que atiende
                                </th> -->
                                <th>&nbsp;</th>
                            </tr>
                            <tr *ngFor="let ps of puntos_soporte" [ngStyle]="{'cursor': esEditor ? 'pointer' : 'default'}" style="border-bottom: solid 1px lightgray;">
                                <td>
                                    <h6 class="black-text" style="font-weight: 200;" *ngIf="ps.estatus_totem=='en_llamada'">
                                        <i class="fas fa-circle color-success"></i>
                                        En sesión
                                    </h6>
                                    <h6 class="black-text" style="font-weight: 200;" *ngIf="ps.estatus_totem=='en_espera'">
                                        <i style="color: yellow" class="fas fa-circle"></i>
                                        En espera de agente
                                    </h6>
                                    <h6 class="black-text" style="font-weight: 200;" *ngIf="ps.estatus_totem=='apagado' || ps.estatus_totem==null">
                                        <i style="color: red" class="fas fa-circle color-primary"></i>
                                        Fuera de línea
                                    </h6>
                                </td>
                                <td style="padding: 10px">
                                    <h6>
                                    {{ps.nombre}}, en {{ps.direccion}}
                                    </h6>
                                    <strong>URL para totem: </strong>
                                        <a style="text-transform: none" href="{{ps.videocall_public_link}}" target="_blank">
                                            {{ps.videocall_public_link}}
                                        </a>
                                </td>
                                <td (click)="irDetallePuntoSoporte(ps.id)">
                                    {{ps.marca.nombre}}
                                </td>
                                <!-- <td (click)="irDetallePuntoSoporte(ps.id)">
                                    {{ps.agente.first_name}} {{ps.agente.last_name}} - {{ps.agente.email}}
                                </td> -->
                                <td (click)="irDetallePuntoSoporte(ps.id)" *ngIf="esEditor">
                                    <span class="color-primary">
                                        Editar <i class="fas fa-edit"></i>
                                    </span>
                                </td>
                                <td (click)="mostrarDialogParaEliminarPunto(ps.id)" *ngIf="esEditor">
                                    <span class="color-danger">
                                        Eliminar <i class="fas fa-trash-alt"></i>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: right;">
                                    <div class="r-float" style="width: 100%;" *ngIf="loading">
                                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                                    </div>
                                    <div class="r-float" style="width: 100%;" *ngIf="!loading">
                                        <table>
                                            <tr>
                                                <td *ngFor="let p of paginas">
                                                    <span (click)="cargarPagina(p)"
                                                     class="page_number"
                                                     *ngIf="pagina_actual==p"
                                                     style="font-weight: bold; text-decoration: underline;">
                                                        {{p+1}}
                                                    </span>
                                                    <span (click)="cargarPagina(p)"
                                                        class="page_number"
                                                        *ngIf="pagina_actual!=p" style="cursor: pointer;">
                                                        {{p+1}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="add-fab" *ngIf="esEditor">
                            <button (click)="agregarPuntoSoporte()" mat-fab color="primary" aria-label="Agregar un nuevo punto de videosoporte">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!loading && puntos_soporte && puntos_soporte.length==0 && !search && !esFiltro()" class="row" style="text-align:center;height: 60vh;">
                        <div class="col-12 v-center">
                            <h4>No hay puntos de videosoporte para mostrar</h4>
                            <h4>Presiona el botón para agregar el primer punto</h4>
                            <div (click)="agregarPuntoSoporte()" class="add-button">
                                <i class="fas fa-plus-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>
