<div class="container" style="padding-bottom: 20px">
    <div class="row">
        <div class="col-12">
            <h5>Sesión expirada</h5>
        </div>
    </div>
    <div mat-dialog-content class="container">
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-12">
                <h6 class="black-text">Su sesión ha expirado, por favor ingrese de nuevo con su usuario y contraseña.</h6>
            </div>
        </div>

        
    </div>
    <div mat-dialog-actions>
        <div class="container" style="text-align: center; width: 100%; cursor: pointer;">
            <button 
                    (click)="irALogin()"
                    mat-raised-button 
                    color="primary" >
                        OK
                        <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
                    </button>
        </div>
    </div>
</div>