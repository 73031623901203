import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-video-wrapper-screen',
  templateUrl: './video-wrapper-screen.component.html',
  styleUrls: ['./video-wrapper-screen.component.scss']
})
export class VideoWrapperScreenComponent implements OnInit {

  public loading:boolean;
  public slug:string;
  public punto_soporte:any;
  public mode:string;
  constructor(public route:ActivatedRoute, public router:Router, public facade:FacadeService) { }

  ngOnInit(): void {
    var uid = this.facade.getUserId();
    if(uid && uid!=""){
      this.mode = "host";
    }else{
      this.mode = "viewer";
    }
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      if(this.slug){
        this.loadPuntoSoporte(this.slug);
      }
    })
  }

  loadPuntoSoporte(slug){
    this.loading=true;
    this.facade.recuperarPuntoSoporteDetallePorSlug(slug)
    .subscribe(
      (response)=>{
        this.loading=false;
        this.punto_soporte=response;
      },
      (error)=>{
        this.punto_soporte={
          id:slug,
          slug: slug,
          nombre:"Soporte Personal",
          marca:{}
        };
        this.loading=false;
      }
    );

  }

  leave(){
    this.router.navigate(['videosoporte']);
  }

}
