import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-nuevo-contacto-dialog',
  templateUrl: './nuevo-contacto-dialog.component.html',
  styleUrls: ['./nuevo-contacto-dialog.component.scss']
})
export class NuevoContactoDialogComponent implements OnInit {

  public numero_celular : number;
  public lada : String = "52";
  constructor(
    public dialogRef: MatDialogRef<NuevoContactoDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  cancelar(){
    this.dialogRef.close("");
  }

  guardar(){
    if (this.lada==""){
      this.lada = "52"
    }
    this.dialogRef.close({tel:this.numero_celular, lada:this.lada});
  }

}
