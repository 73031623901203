<div class="loader">
    <i *ngIf="loading" class="fa-spin fas fa-spinner"></i>
</div>
<div *ngIf="!loading" class="row">
    <div class="col-sm-10 col-10">&nbsp;</div>
    <div class="col-sm-2 col-2">
        <div class="row">
            <div class="col-sm-5 col-5">&nbsp;</div>
            <div class="col-sm-7 col-7">
                <a (click)="irEditarCliente()" *ngIf="esEditor">
                <div class="row">
                        <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .5rem;">
                        <h6 class="color-primary" style="font-weight: 600;margin-top: .2rem;">&nbsp;EDITAR</h6>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<!--Sección datos personales-->
<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos del cliente
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row d-flex justify-content-center">
                <img [src]="cliente?.logo" class="logo" alt="logo">
            </div>
            <h3 class="color-primary" style="font-weight: 200;">{{cliente?.nombre}}</h3>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Giro comercial</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{cliente.giro_comercial_nombre}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>

<!--Sección datos de acceso-->
<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos de acceso
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Correo electrónico</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{cliente.username}}</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Contraseña</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 700;">•  •  •  •  •  •  •  •</h6>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>

<!--Sección datos profesionales-->
<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Datos de la plataforma
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">¿Permite video soporte?</h6>
                </div>
                <div class="col-md-6">
                    <h6 *ngIf="cliente.permite_videosoporte" class="black-text" style="font-weight: 200;">Sí</h6>
                    <h6 *ngIf="!cliente.permite_videosoporte" class="black-text" style="font-weight: 200;">No</h6>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-md-6">
                    <h6 style="font-weight: 600;">Marcas</h6>
                </div>
                <div class="col-md-6">
                    <h6 class="black-text" style="font-weight: 200;">{{cliente.marcas_nombres}}</h6>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>
<!--Sección campos consumidores-->
<mat-card *ngIf="!loading && campos_dinamicos">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Campos de consumidor
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12" *ngIf="cliente?.marcas">
            <div class="row">&nbsp;</div>
            <div *ngFor="let marca of cliente.marcas">
                <div class="row" *ngIf="marca.tipo_crm == 'modo_2' || marca.tipo_crm == 'modo_4'">
                    <div class="col-md-6">
                        <h6 style="font-weight: 600;">{{marca.nombre}}</h6>
                    </div>
                    <div class="col-md-6" *ngIf="esEditor">
                        <a class="row" (click)="irCampos(marca.id,'consumidor')">
                            <h6 class="black-text" style="font-weight: 200;text-transform: initial;">Editar campos</h6>
                            <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .9rem;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>
<!-- Campos de Formulario -->
<mat-card *ngIf="!loading && campos_dinamicos">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Formulario tótem
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12" *ngIf="cliente?.marcas">
            <div class="row">&nbsp;</div>
            <div *ngFor="let marca of cliente.marcas">
                <div class="row" *ngIf="marca.tipo_crm == 'modo_2' || marca.tipo_crm == 'modo_4'">
                    <div class="col-md-6">
                        <h6 style="font-weight: 600;">{{marca.nombre}}</h6>
                    </div>
                    <div class="col-md-6" *ngIf="esEditor">
                        <a class="row" (click)="irCampos(marca.id,'formulario')">
                            <h6 class="black-text" style="font-weight: 200;text-transform: initial;">Editar campos</h6>
                            <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .9rem;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>

<div class="row">&nbsp;</div>
<mat-card *ngIf="!loading && campos_dinamicos">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Atributos encuesta
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">&nbsp;</div>
            <div *ngFor="let marca of cliente.marcas">
                <div class="row" *ngIf="marca.tipo_crm == 'modo_2' || marca.tipo_crm == 'modo_4'">
                    <div class="col-md-6">
                        <h6 style="font-weight: 600;">{{marca.nombre}}</h6>
                    </div>
                    <div class="col-md-6" *ngIf="esEditor">
                        <a class="row" (click)="irEncuesta(marca.id,'consumidor')">
                            <h6 class="black-text" style="font-weight: 200;text-transform: initial;">Editar</h6>
                            <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .9rem;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>
<mat-card>
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Campos dinámicos App
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12" *ngIf="cliente?.marcas">
            <div class="row">&nbsp;</div>
            <div *ngFor="let marca of cliente.marcas">
                <div class="row">
                    <div class="col-md-6">
                        <h6 style="font-weight: 600;">{{marca.nombre}}</h6>
                    </div>
                    <div class="col-md-6" *ngIf="esEditor">
                        <a class="row" (click)="irCampos(marca.id,'agente')">
                            <h6 class="black-text" style="font-weight: 200;text-transform: initial;">Editar campos</h6>
                            <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .9rem;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>
<mat-card *ngIf="!loading && campos_dinamicos">
    <div class="row">
        <div class="col-md-2">
            <h4 style="font-weight: 600;">
                Atributos encuesta App
            </h4>
        </div>
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-6 col-12">
            <div class="row">&nbsp;</div>
            <div *ngFor="let marca of cliente.marcas">
                <div class="row">
                    <div class="col-md-6">
                        <h6 style="font-weight: 600;">{{marca.nombre}}</h6>
                    </div>
                    <div class="col-md-6" *ngIf="esEditor">
                        <a class="row" (click)="irEncuesta(marca.id,'agente')">
                            <h6 class="black-text" style="font-weight: 200;text-transform: initial;">Editar</h6>
                            <img src="../../../../assets/images/editar.png" width="24" style="margin-bottom: .9rem;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
    </div>
    <div class="row">&nbsp;</div>
</mat-card>
<div class="row">&nbsp;</div>
