<div *ngIf="tipo_acivacion && tipo_acivacion.tipo == 'qr'" class="container-bg" [style]="
    '-webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    --bgimage:url('+tipo_acivacion.bg_image_url+')'
    "
    [ngStyle]="{
        'background-color': atributos.primary_color? atributos.primary_color : '#272F6B'
        }"
    >
    <div class="container-img-bg">
        <img *ngIf="tipo_acivacion.bg_image_url" [src]="tipo_acivacion.bg_image_url" alt="bg" style="height: 100vh;">
    </div>
    <div class="qr-container">
        <img class="qr" [src]="consumidor.url_qr">
    </div>
</div>
<div *ngIf="!tipo_acivacion" class="container-bg" style="
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    --bgimage:'';
    "
    [ngStyle]="{'background-color': atributos.primary_color? atributos.primary_color : '#272F6B'}"
    >
    <h1 style="color: #FFFFFF;">
        ¡Gracias por contestar nuestra encuesta!
    </h1>
</div>