import { Component, Input, OnInit } from '@angular/core';
import { ChatApiFacadeService } from 'src/app/services/chat-api-facade.service';

@Component({
  selector: 'app-chat-api-config',
  templateUrl: './chat-api-config.component.html',
  styleUrls: ['./chat-api-config.component.scss']
})
export class ChatApiConfigComponent implements OnInit {

  public loading_chat_api:boolean=false;
  public loading_takeover:boolean=false;
  public chat_api_account_status:string;
  @Input() button_text:string;
  @Input() chat_api_url:string;
  @Input() chat_api_token:string;

  constructor(public chat_api_facade:ChatApiFacadeService) { }

  ngOnInit() {
  }

  configurarChatAPI(){
    if(!this.chat_api_url || this.chat_api_url=="") alert("Por favor proporciona la url de la instancia chat api a configurar");
    if(!this.chat_api_token || this.chat_api_token=="") alert("Por favor proporciona el token de la instancia chat api a configurar");
    this.loading_chat_api = true;
    this.chat_api_facade.status(this.chat_api_url, this.chat_api_token)
    .subscribe(
      (response)=>{
        this.loading_chat_api = false;
        if(response.accountStatus){
          this.chat_api_account_status = response.accountStatus;
        }
      },
      (error)=>{
        this.loading_chat_api = false;
        console.error("Error from chat api:: "+JSON.stringify(error));
      }
    )
  }

  takeoverChatAPI(){
    this.loading_takeover = true;
    this.chat_api_facade.takeover(this.chat_api_url, this.chat_api_token)
    .subscribe(
      (response)=>{
        this.loading_takeover = false;
        console.log("RESPONSE:: "+JSON.stringify(response));
        this.configurarChatAPI();
      },
      (error)=>{
        this.loading_takeover = false;
        console.error("Error from chat api:: "+JSON.stringify(error));
      }
    )
  }

}
