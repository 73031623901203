import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrar-clientes-screen',
  templateUrl: './administrar-clientes-screen.component.html',
  styleUrls: ['./administrar-clientes-screen.component.scss']
})
export class AdministrarClientesScreenComponent implements OnInit {
  public clientes:any=[];
  public loading:boolean=false;
  public url_api:string;
  public es_cliente_segundo = false;
  esEditor: any;
  constructor(
    public router:Router, 
    public facade:FacadeService
  ) {
    this.url_api = environment.url_api;
  }

  ngOnInit() {
    this.esEditor = this.facade.comprobarPermisoDeEdicion("seccion_administrar_clientes");
    if (this.facade.getUserGroup().includes("cliente-segundo")){
      this.es_cliente_segundo = true;
    }
    this.loadClientes();
  }

  loadClientes(){
    this.loading = true;

    this.facade.listarClientesSegundos()
    .subscribe(
      (response)=>{
        this.loading = false;
        this.clientes = response;
      },
      (error)=>{
        if(error.status == 403){
          this.router.navigate(['']);
        }
        this.loading=false;
        console.log("ERROR:: "+JSON.stringify(error));
      }
    );
  }

  irDetalleCliente(id_cliente){
    if(!this.esEditor){
      return;
    }
    if(!id_cliente){
      id_cliente=0;
    }
    this.router.navigate(['cliente/admin/'+id_cliente]);
  }
  
  eliminarCliente(cliente){
    let mensaje_confirm = "¿Estás seguro que deseas eliminar al cliente?"
    if(confirm(mensaje_confirm)){
      cliente.estatus_loading = true;
      this.facade.eliminarClienteSegundo(cliente.id)
      .subscribe(
        (response)=>{
          cliente.estatus_loading = false;
          if(response.success){
            if (response.autoeliminacion){
              this.router.navigate(['']);
            }
            else{
              this.loadClientes();
            }
          }
          else{
            console.log(response.error);
          }
        },
        (error)=>{
          cliente.estatus_loading = false;
        }
      );
    }
  }

  editarMiPerfil(){
    this.irDetalleCliente(this.facade.getSecondClientID());
  }
}
