<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-12">
            <h4 style="font-weight: 600;">
                Encuesta
            </h4>        
        </div>
        <div class="col-12">&nbsp;</div>
        <div class="col-md-4 col-12">
            <h6 style="font-weight: 600;">
                Título
            </h6>   
        </div>
        <div class="col-md-8 col-12">
            <mat-form-field appearance="outline">
                <mat-label>Título</mat-label>
                <input matInput [(ngModel)]="atributos_json.titulo">
            </mat-form-field>
        </div>
        <div class="col-12">&nbsp;</div>
        <div class="col-md-4 col-12">
            <h6 *ngIf="tipo == 'consumidor'" style="font-weight: 600;">
                Color primario
            </h6>   
            <h6 *ngIf="tipo == 'agente'" style="font-weight: 600;">
                Color primario (Botón)
            </h6>   
        </div>
        <div class="col-md-8 col-12">
            <mat-form-field appearance="outline">
                <mat-label>Hexadecimal</mat-label>
                <input matInput placeholder="Ej. #000000" [(ngModel)]="atributos_json.primary_color">
            </mat-form-field>
        </div>
        <div class="col-12">&nbsp;</div>
        <div *ngIf="tipo == 'consumidor'" class="col-md-4 col-12">
            <h6 style="font-weight: 600;">
                Color secundario (Botón)
            </h6>   
        </div>
        <div *ngIf="tipo == 'consumidor'" class="col-md-8 col-12">
            <mat-form-field appearance="outline">
                <mat-label>Hexadecimal</mat-label>
                <input matInput placeholder="Ej. #000000" [(ngModel)]="atributos_json.secondary_color">
            </mat-form-field>
        </div>
        <div class="col-12">&nbsp;</div>
        <div class="col-md-4 col-12">
            <h6 style="font-weight: 600;">
                Logo
            </h6>   
        </div>
        <div class="col-md-8 col-12">
            <button 
            *ngIf="atributos_json.logo.valor ==''"
            (click)="clickInput('input_file')" 
            mat-raised-button 
            color="primary" >
                Agregar imagen
                <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i>
            </button>
            <button 
            *ngIf="atributos_json.logo.valor !=''"
            (click)="clickInput('input_file')" 
            mat-raised-button 
            color="primary" >
                Cambiar imagen
                <i *ngIf="loading_upload_file" class="fa-spin fas fa-spinner"></i>
            </button>
            <div *ngIf="!loading_upload_file">
                <input style="display: none;" type="file" id="input_file" name="archivo" (change)="onFileSelect($event)" accept="image/x-png,image/gif,image/jpeg"/>
            </div>
            <img 
            *ngIf="atributos_json.logo.valor !=''"
            [src]="atributos_json.logo.valor"
            style="box-sizing:unset; height: 50px; padding: 10px;cursor: pointer;">
        </div>
    </div>
    <div class="col-12">&nbsp;</div>
    <div class="col-12">&nbsp;</div>
    <div class="col-12">&nbsp;</div>
    <div class="col-12" style="text-align: center; width: 100%; cursor: pointer;">
        <button 
        (click)="guardarAtributos()"
        mat-raised-button 
        color="primary" >
            GUARDAR
            <i *ngIf="loading_button" class="fa-spin fas fa-spinner"></i>
        </button>
    </div>
</mat-card>